import { Toaster } from "react-hot-toast";
import styles from "./UpdateModal.module.scss";
import { Modal, Input, InputNumber } from "antd";
import { UpdateModalLogic } from "./UpdateModalLogic";

function UpdateModal({
  item,
  setSelectedItemForUpdate,
  visibleUpdateModal,
  setVisibleUpdateModal,
  setClassesWithSubClass,
}: {
  item: any;
  setSelectedItemForUpdate: object;
  visibleUpdateModal: boolean;
  setVisibleUpdateModal: (visibleUpdateModal: boolean) => void;
  setClassesWithSubClass: (value: any) => void;
}) {
  const {
    handleOk,
    confirmLoading,
    handleCancel,
    newUpdatedClass,
    handleInputChange,
  }: {
    handleOk: () => void;
    confirmLoading: boolean;
    handleCancel: () => void;
    newUpdatedClass: any;
    handleInputChange: (e: any, key: string) => void;
  } = UpdateModalLogic({
    item,
    setSelectedItemForUpdate,
    visibleUpdateModal,
    setVisibleUpdateModal,
    setClassesWithSubClass,
  });

  return (
    <>
      <Modal
        centered
        title="Update Class"
        visible={visibleUpdateModal}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <label className={styles.updateComponent_inputLabels}>Class Name</label>
        <Input
          value={newUpdatedClass?.name}
          onChange={(e) => handleInputChange(e, "name")}
          className={styles.updateComponent_inputFields}
        />
        <label className={styles.updateComponent_inputLabels}>Class Key</label>
        <InputNumber
          min={0}
          defaultValue={0}
          value={newUpdatedClass?.key}
          className={
            styles.updateComponent_inputFields +
            " " +
            styles.updateComponent_inputNumberField
          }
          onChange={(e) => handleInputChange(e, "key")}
        />
        <label className={styles.updateComponent_inputLabels}>
          Description
        </label>
        <Input
          value={newUpdatedClass?.description}
          onChange={(e) => handleInputChange(e, "description")}
          className={styles.updateComponent_inputFields}
        />
      </Modal>
      {/* Toaster */}
      <Toaster
        toastOptions={{
          success: {
            style: {
              border: "1px solid #199c3c",
            },
          },
          error: {
            style: {
              border: "1px solid #FF4C4C",
            },
          },
        }}
      />
    </>
  );
}

export default UpdateModal;
