import api, { authHeader } from "./index";

export async function adminViewOneUserAnnotation({
  body,
  pageCount,
  pageLimit,
}: {
  body: {
    dataset_id: string;
    classifier_id: string;
    user_id: string;
  };
  pageCount: number;
  pageLimit: number;
}) {
  const response = await api.post(
    `/annotation/by-user?page=${pageCount}&limit=${pageLimit}`,
    body,
    authHeader()
  );
  return response.data?.result;
}

export async function adminViewAllUserAnnotation({
  body,
  pageCount,
  pageLimit,
}: {
  body: {
    dataset_id: string;
    classifier_id: string;
  };
  pageCount: number;
  pageLimit: number;
}) {
  const response = await api.post(
    `/annotation/by-dataset-and-classifier?page=${pageCount}&limit=${pageLimit}`,
    body,
    authHeader()
  );
  return response.data?.result;
}

export async function handleSuspensionApi(datasetId: string, userId: string) {
  const response = await api.post(
    `/access/toggle`,
    {
      dataset_id: datasetId,
      user_id: userId,
    },
    authHeader()
  );
  return response.data?.result;
}
