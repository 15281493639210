import { useSelector } from "react-redux";
import { userSelector } from "store/userSlice";
import ViewCategories from "pages/category/ViewCategories";
import AnnotatorDataset from "pages/datasets/AnnotatorDataset";

function Dashboard({
  datasets,
  setDatasets,
  pageCount,
  setPageCount,
  pageLimit,
  setPageLimit,
  totalAnnotationCount,
  setTotalAnnotationCount,
}: any) {
  const userInfo = useSelector(userSelector);

  return (
    <>
      {!userInfo?.permission_level && userInfo?.permission_level !== 0 ? (
        "" // handling edge case (when loading)
      ) : userInfo.permission_level > 0 ? (
        <ViewCategories />
      ) : (
        <AnnotatorDataset
          datasets={datasets}
          setDatasets={setDatasets}
          pageCount={pageCount}
          setPageCount={setPageCount}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          totalAnnotationCount={totalAnnotationCount}
          setTotalAnnotationCount={setTotalAnnotationCount}
        />
      )}
    </>
  );
}

export default Dashboard;
