import {
  Button,
  Col,
  List,
  Popover,
  Row,
  Typography
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./NotificationList.module.scss";
import { NotificationListLogic } from "./NotificationListLogic";

// Icons
import { BsCheck2All } from "react-icons/bs";
import { TbBell } from "react-icons/tb";
import { setSearchImageInfo } from "store/searchImageInfoSlice";
import { userSelector } from "store/userSlice";

const { Title } = Typography;

function NotificationList() {
  const {
    fetchAllNotifications,
    allNotifications,
    unreadNotificationCount,
    markNotificationAsRead,
    markAllAsReadHandler,
  }: {
    fetchAllNotifications: () => void;
    allNotifications: {
      loading: boolean;
      error: boolean;
      data: any[];
    };
    unreadNotificationCount: number;
    markNotificationAsRead: (notificationId: string) => void;
    markAllAsReadHandler: () => void;
  } = NotificationListLogic();

  const dispatch = useDispatch();
  const userInfo = useSelector(userSelector);

  return (
    <>
      <Popover
        trigger={["click"]}
        placement="bottomRight"
        onVisibleChange={(visible: boolean) => {
          if (visible) fetchAllNotifications();
        }}
        title={
          <div style={{ marginTop: "1rem" }}>
            <Row style={{ minWidth: "20rem" }}>
              <Col span={10}>
                <Title level={5} className={styles.notification_popoverHeading}>
                  Notifications
                </Title>
              </Col>
              <Col span={2}></Col>
              <Col span={12}>
                <div>
                  <Button
                    type="link"
                    size="small"
                    icon={
                      <BsCheck2All
                        style={{
                          marginRight: "0.3rem",
                        }}
                      />
                    }
                    className={styles.notifications_popoverMarkAllRead}
                    onClick={() => {
                      markAllAsReadHandler();
                    }}
                  >
                    Mark all as read
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        }
        content={
          <div style={{ overflowY: "auto", height: "10rem" }}>
            <List
              // header={}
              bordered
              dataSource={allNotifications.data}
              renderItem={(item) => (
                <Link
                  to={
                    item.type === "dataset-access"
                      ? `/classifiers/${item?.target?.dataset_id}`
                      : item.type === "reviewbucket-access"
                      ? userInfo.permission_level >= 2
                        ? `/review-bucket/${item?.target?.bucket_id}/${item?.target?.classifier_id}`
                        : `/admin/review-bucket/${item?.target?.bucket_id}/${item?.target?.classifier_id}`
                      : item.type === "review-request"
                      ? `/images/${item?.target?.dataset_id}/${item?.target?.classifier_id}/${item?.target?.bucket_id}/0`
                      : item.type === "reannotation-request"
                      ? `/images/${item?.target?.dataset_id}/${item?.target?.classifier_id}/${item?.target?.bucket_id}/0`
                      : ""
                  }
                  onClick={() => {
                    (item.type === "review-request" ||
                      item.type === "reannotation-request") &&
                      item?.target?.image_id &&
                      dispatch(setSearchImageInfo(item?.target?.image_id));
                    markNotificationAsRead(item._id);
                  }}
                >
                  <List.Item
                    style={{
                      backgroundColor: item.isRead
                        ? "#fff"
                        : "rgba(230, 230, 252, 0.7)",
                    }}
                  >
                    {item?.message && item?.message.substring(0, 50) + "..."}
                  </List.Item>
                </Link>
              )}
            />
          </div>
        }
      >
        {/* <Tooltip title="Notifications"> */}
        <Button
          type="ghost"
          shape="circle"
          style={{
            border: "none",
            color: "var(--gray-400)",
            position: "relative",
            marginRight: "1.8rem",
          }}
          icon={
            <>
              <TbBell className={styles.navbar_profile_bellIcon} />
              {
                //   allNotifications.data?.unreadCount
                5 > 0 ? (
                  <div
                    style={{
                      background: "red",
                      color: "white",
                      borderRadius: 20,
                      fontSize: 10,
                      height: 16,
                      minWidth: 16,
                      position: "absolute",
                      top: "0px",
                      right: "0px",
                      lineHeight: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      maxWidth: 22,
                      paddingInline: 2,
                    }}
                  >
                    {unreadNotificationCount}
                  </div>
                ) : null
              }
            </>
            // </Badge>
          }
        />
        {/* </Tooltip> */}
      </Popover>
    </>
  );
}

export default NotificationList;
