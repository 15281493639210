import { useState, useEffect } from "react";
import { getEveryDatasetForAdmin } from "api/datasetApi";
import { downloadFileApi, getMetricsInfoApi } from "api/metricsApi";
import { timestampToDate } from "utils/functions";
import * as FileSaver from "file-saver";

interface MetricsTableType {
  key: string;
  _id: string;
  fileName: string;
  metricType: string;
  updatedAt: string;
  status: string;
}

export const MetricsLogic = ({ allDatasets, setAllDatasets }: any) => {
  let metricsDataKeyCount = 0;
  let metricsTableInfo: MetricsTableType[] = [];
  const [metricsData, setMetricsData] = useState<{
    loading: boolean;
    error: boolean;
    data: any[];
  }>({
    loading: false,
    error: false,
    data: [],
  });
  const [metricsFetchErrMsg, setMetricsFetchErrMsg] = useState<string>("");
  const [openCreateMetricModal, setOpenCreateMetricModal] =
    useState<boolean>(false);
  const [createMetricSubmitLoading, setCreateMetricSubmitLoading] =
    useState<boolean>(false);
  const [totalAnnotationCount, setTotalAnnotationCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(10);

  /** Fetch metrics data info */
  async function getMetricsData() {
    setMetricsFetchErrMsg("");
    setMetricsData({ ...metricsData, loading: true });
    try {
      const res = await getMetricsInfoApi({
        pageCount,
        pageLimit,
      });

      setMetricsData({
        ...metricsData,
        loading: false,
        data: res.metrics,
      });
      setTotalAnnotationCount(res.totalObjects);
    } catch (error: any) {
      setMetricsData({ ...metricsData, loading: false, error: true });
      setMetricsFetchErrMsg(error.response.data.message);
    }
  }

  useEffect(() => {
    getMetricsData();
  }, [pageCount, pageLimit]);

  /** Formatting datasets data for table view */
  function addDatasetsInTable() {
    metricsTableInfo = [];

    metricsData.data.length > 0 &&
      metricsData.data.forEach((eachDataset: any) => {
        //
        metricsTableInfo.push({
          key: metricsDataKeyCount.toString(),
          _id: eachDataset._id,
          fileName: eachDataset.filename,
          metricType: eachDataset.type,
          updatedAt: timestampToDate(eachDataset.updatedAt),
          status: eachDataset.status,
        });
        metricsDataKeyCount += 1;
      });
  }
  addDatasetsInTable();

  /** For downloading csv file */
  const downloadFile = async (fileId: string, fileName: string) => {
    try {
      const res = await downloadFileApi({ fileId, fileName });

      const data = new Blob([res], { type: "text/csv" });
      FileSaver.saveAs(data, fileName);
    } catch (error) {}
  };

  return {
    openCreateMetricModal,
    setOpenCreateMetricModal,
    createMetricSubmitLoading,
    setCreateMetricSubmitLoading,
    allDatasets,
    setAllDatasets,
    totalAnnotationCount,
    pageCount,
    setPageCount,
    pageLimit,
    setPageLimit,
    metricsData,
    metricsTableInfo,
    metricsFetchErrMsg,
    downloadFile,
    getMetricsData,
  };
};
