import api, { authHeader } from "./index";

export async function createDataset(
  name: string,
  description: string,
  category_id: any,
  cameraType_id: any,
  cameraType_name: any,
  classifiers: any
) {
  const response = await api.post(
    "/dataset/create",
    {
      name,
      description,
      category_id,
      cameraType_id,
      cameraType_name,
      classifiers,
    },
    authHeader()
  );
  return response.data?.result;
}

export async function getAllDatasetsbyCategoryId(categoryId: any) {
  const response = await api.get(
    `/dataset/category/count/${categoryId}`,
    authHeader()
  );
  return response.data?.result;
}

export async function getDatasetById(datasetId: any) {
  const response = await api.get(`/dataset/get/${datasetId}`, authHeader());
  return response.data?.result;
}

export async function getDatasetsbyCameraType(
  categoryId: any,
  camTypeId: any,
  fieldData: any
) {
  const response = await api.get(
    `/category/datasets/${categoryId}/${camTypeId}?fieldData=${fieldData}`,
    authHeader()
  );
  return response.data?.result;
}

export async function getEveryDatasetForAdmin() {
  const response = await api.get("/admin/datasets", authHeader());
  return response.data?.result;
}

export async function getAllAccessibleDatasets({
  pageCount,
  pageLimit,
}: {
  pageCount: number;
  pageLimit: number;
}) {
  const response = await api.get(
    `/dataset/get?page=${pageCount}&limit=${pageLimit}`,
    authHeader()
  );
  return response.data?.result;
}

export async function updateDataset(data: object) {
  const response = await api.post("/dataset/update", data, authHeader());
  return response.data?.result;
}

export async function deleteDataset(datasetId: string) {
  const response = await api.delete(
    `/dataset/remove/${datasetId}`,
    authHeader()
  );
  return response.data?.result;
}

export async function addClassifierToDataset(
  datasetId: string,
  classifiersArray: string[]
) {
  const response = await api.post(
    `/dataset/add-classifiers`,
    {
      dataset_id: datasetId,
      classifiers: classifiersArray,
    },
    authHeader()
  );
  return response.data?.result;
}

export async function removeClassifierFromDataset(
  datasetId: string,
  classifiers: string[]
) {
  const response = await api.post(
    `/dataset/remove-classifiers`,
    {
      dataset_id: datasetId,
      classifiers,
    },
    authHeader()
  );
  return response.data?.result;
}
