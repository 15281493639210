import { getBucketsByReviewBucketId } from "api/reviewBucketApi";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const ViewReviewBucketsLogic = () => {

  const reviewerViewAnnotatorName: string | null = localStorage.getItem(
    "reviewerViewAnnotatorName"
  );

  const { reviewBucketId, classifierId } = useParams<string>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [datasetInfo, setDatasetInfo] = useState<any>({});
  const [classifiers, setClassifiers] = useState<any[]>([]);
  const [selectedClassifier, setSelectedClassifier] = useState<any>({});
  const [buckets, setBuckets] = useState<any>({
    loading: false,
    error: false,
    data: [],
  });
  const [errMsg, setErrMsg] = useState<string>("");

  /** Fetching related buckets every time there is a change in classifier filter */
  useEffect(() => {
    const fetchBuckets = async () => {
      setBuckets({
        ...buckets,
        loading: true,
      });
      try {
        const classifierBuckets = await getBucketsByReviewBucketId(
          reviewBucketId
        );
        // Sorting buckets based on key
        // classifierBuckets = sortBucketsByKey(classifierBuckets);
        setBuckets({
          ...buckets,
          loading: false,
          data: classifierBuckets,
        });
      } catch (error: any) {
        setBuckets({
          loading: false,
          error: true,
          data: [],
        });

        setErrMsg(
          error.response.data?.message.includes("Cache not found")
            ? "You don't have access to this dataset. Please contact administrator "
            : error.response.data?.message
        );
      }
    };
    fetchBuckets();
  }, []);

  /** Changing btnColor if bucket is a Bad image or Ungraded */
  function bucketBtnColor(bucketName: string) {
    const btnColor =
      bucketName === "Ungraded"
        ? "#FFBF43"
        : bucketName === "Bad Image"
        ? "#F66262"
        : "#6254FF";

    return btnColor;
  }

  return {
    reviewBucketId,
    classifierId,
    datasetInfo,
    classifiers,
    selectedClassifier,
    setSelectedClassifier,
    buckets,
    searchTerm,
    setSearchTerm,
    bucketBtnColor,
    errMsg,
    reviewerViewAnnotatorName,
  };
};
