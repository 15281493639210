import {
  Button,
  Col,
  Grid,
  Input,
  Modal,
  Popover,
  Row,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import UpdateModal from "components/category/UpdateModal";
import toast, { Toaster } from "react-hot-toast";
import { numFormatterInK } from "utils/functions";
import styles from "./AdminViewCategories.module.scss";
import { AdminViewCategoriesLogic } from "./AdminViewCategoriesLogic";

// Icons
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { useState } from "react";
import { BsImages } from "react-icons/bs";
import { HiOutlineDatabase } from "react-icons/hi";
import { IoIosSearch } from "react-icons/io";
import { MdEdit } from "react-icons/md";

const { Title } = Typography;
const { useBreakpoint } = Grid;
const { confirm } = Modal;

function AdminViewCategories() {
  const { md } = useBreakpoint();

  const {
    searchTerm,
    setSearchTerm,
    categories,
    categoriesTableInfo,
    visibleUpdateModal,
    setVisibleUpdateModal,
    selectedCatForUpdate,
    setSelectedCatForUpdate,
  }: {
    searchTerm: string;
    setSearchTerm: (term: string) => void;
    categories: any;
    categoriesTableInfo: any[];
    visibleUpdateModal: boolean;
    setVisibleUpdateModal: (visible: boolean) => void;
    selectedCatForUpdate: any;
    setSelectedCatForUpdate: (cat: any) => void;
  } = AdminViewCategoriesLogic();

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleCategoryDelete = (_id: any) => {
    setConfirmLoading(true);
    try {
      // Todo: await deleteApi(categoryId);
      setTimeout(() => {
        setOpen(false);
        setConfirmLoading(false);
        toast.success("Modality deleted successfully");
      }, 1500);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const columns = [
    {
      title: "Modality",
      key: "nameAndDes",
      width: "33.3%",
      render: (details: any) => {
        return (
          <>
            {details?.name}
            <Popover
              content={details?.description}
              placement="right"
              title="Description"
            >
              <InfoCircleOutlined className={styles.tableCol_infoIcon} />
            </Popover>
          </>
        );
      },
    },
    {
      title: "Edit",
      key: "edit",
      width: "33.3%",

      render: (details: any) => {
        return (
          <Space size="middle">
            <Button
              className={styles.table_btn}
              onClick={() => {
                setVisibleUpdateModal(true);
                setSelectedCatForUpdate(details);
              }}
            >
              Edit <MdEdit className={styles.table_editBtnIcon} />
            </Button>
          </Space>
        );
      },
    },
    {
      title: "Delete",
      key: "delete",
      width: "33.3%",
      render: (details: any) => {
        return (
          <Space size="middle">
            <Button
              className={styles.table_btn + " " + styles.table_deleteBtn}
              onClick={() => setOpen(true)}
            >
              Delete
            </Button>
            <Modal
              maskStyle={{ backgroundColor: "rgba(0,0,0, 0.2)" }}
              centered
              visible={open}
              onOk={() => handleCategoryDelete(details._id)}
              confirmLoading={confirmLoading}
              onCancel={() => setOpen(false)}
              footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  loading={confirmLoading}
                  onClick={() => handleCategoryDelete(details._id)}
                  className={styles.deleteModal_deleteBtn}
                >
                  Delete
                </Button>,
              ]}
            >
              <p>Are you sure you want to delete {details?.type}?</p>
            </Modal>
          </Space>
        );
      },
    },
  ];

  return (
    <div
      style={{
        backgroundColor: "#F0F2F5",
        height: "100%",
        marginBottom: "4rem",
      }}
    >
      <Row className={styles.categories_headingRow}>
        <Col md={12}>
          <Title level={2} className={styles.categories_heading}>
            View Modalities
          </Title>
        </Col>
        <Col md={12} xs={24}>
          <Input
            size="large"
            placeholder="Search..."
            prefix={<IoIosSearch className={styles.categories_searchBoxIcon} />}
            className={styles.categories_searchBox}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Col>
      </Row>

      {/* Modalities */}
      <div className={styles.categories_tableRow}>
        {categories.error ? (
          <>Error</>
        ) : categories.loading ? (
          <div className={styles.loadingDiv}>
            <Spin
              size="large"
              indicator={
                <LoadingOutlined className={styles.loadingSpinIcon} spin />
              }
              tip="Fetching Modalities..."
              className={styles.loadingDiv_spin}
            />
          </div>
        ) : md ? (
          <Table
            columns={columns}
            dataSource={categoriesTableInfo}
            className={styles.table}
          />
        ) : (
          <Row className={styles.categories_row}>
            {categories.data.length &&
              categories.data
                .filter((val: any) =>
                  searchTerm === ""
                    ? val
                    : val.type
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()) && val
                )
                .map((eachCat: any) => {
                  return (
                    <Col
                      lg={8}
                      md={12}
                      xs={24}
                      className={styles.categories_col}
                      key={eachCat._id}
                    >
                      <div className={styles.categories_card}>
                        <Title level={4} className={styles.categoriesCard_type}>
                          {eachCat.type}
                          <Popover
                            content={eachCat.name}
                            placement="bottom"
                            title="Description"
                          >
                            <InfoCircleOutlined
                              className={styles.categoriesCard_infoIcon}
                            />
                          </Popover>
                        </Title>
                        <Row className={styles.categoriesCard_dbAndImgsRow}>
                          <Col span={10}>
                            <Title
                              level={4}
                              className={styles.categoriesCard_dbAndImgs}
                            >
                              <HiOutlineDatabase />
                              <span>{eachCat.datasetsCount}</span>
                            </Title>
                          </Col>
                          <Col span={14}>
                            <Title
                              level={4}
                              className={styles.categoriesCard_dbAndImgs}
                            >
                              <BsImages />
                              <span>
                                {numFormatterInK(eachCat.imagesCount)}
                              </span>
                            </Title>
                          </Col>
                        </Row>
                        <div className={styles.categoriesCard_btnsDiv}>
                          <Button
                            className={styles.table_btn}
                            onClick={() => {
                              setVisibleUpdateModal(true);
                              setSelectedCatForUpdate(eachCat);
                            }}
                          >
                            Edit <MdEdit className={styles.table_editBtnIcon} />
                          </Button>
                          <Button
                            className={
                              styles.table_btn + " " + styles.table_deleteBtn
                            }
                            onClick={() => {
                              // handleCategoryDelete(details._id);
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    </Col>
                  );
                })}
          </Row>
        )}
      </div>

      {/* Update Modal */}
      <UpdateModal
        category={selectedCatForUpdate}
        setSelectedCatForUpdate={setSelectedCatForUpdate}
        visibleUpdateModal={visibleUpdateModal}
        setVisibleUpdateModal={setVisibleUpdateModal}
      />
      {/* Toaster */}
      <Toaster
        toastOptions={{
          success: {
            style: {
              border: "1px solid #199c3c",
            },
          },
          error: {
            style: {
              border: "1px solid #FF4C4C",
            },
          },
        }}
      />
    </div>
  );
}

export default AdminViewCategories;
