import api, { authHeader } from "./index";

export async function getDatasetbyDatasetId(datasetId: any) {
  const response = await api.get(`/dataset/get/${datasetId}`, authHeader());
  return response.data?.result;
}

export async function getClassifierByDatasetId(datasetId: any) {
  const response = await api.get(
    `/classifier/by-dataset/${datasetId}`,
    authHeader()
  );
  return response.data?.result;
}

export async function getBuckets({
  datasetId,
  classifierId,
  selectedUserIdForReview,
}: {
  datasetId: any;
  classifierId: any;
  selectedUserIdForReview?: string;
}) {
  const link = selectedUserIdForReview ? selectedUserIdForReview : "self";

  const body = {
    dataset_id: datasetId,
    classifier_id: classifierId,
  };

  const response = await api.post(
    `/images/buckets/${link}`,
    body,
    authHeader()
  );
  return response.data?.result;
}

export async function get_Buckets_By_DatasetId_And_ClassifierId(
  datasetId: any,
  classifierId: any
) {
  const response = await api.post(
    `/images/buckets/self`,
    {
      dataset_id: datasetId,
      classifier_id: classifierId,
    },
    authHeader()
  );
  return response.data?.result;
}
