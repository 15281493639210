import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

interface SetUserData {
  _id: string;
  email: string;
  is_verified: boolean;
  name: string;
  permission_level: any;
  profile_picture: string;
  type: string;
}

interface UserInitialStateData extends SetUserData {
  isAuthenticated: boolean;
}

const initialState: UserInitialStateData = {
  _id: "",
  email: "",
  is_verified: false,
  name: "",
  permission_level: 0,
  profile_picture: "",
  type: "",
  isAuthenticated: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setIsAuthenticated: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuthenticated = payload;
    },
    setUser: (state, { payload }: PayloadAction<SetUserData>) => {
      state._id = payload._id;
      state.email = payload.email;
      state.is_verified = payload.is_verified;
      state.name = payload.name;
      state.permission_level = payload.permission_level;
      state.profile_picture = payload.profile_picture;
      state.type = payload.type;
    },
    setLogout: (state) => {
      Object.assign(state, initialState);
      window.localStorage.removeItem("authToken");
      window.localStorage.removeItem("refreshToken");
    },
  },
});

export const userSelector = (state: RootState) => state.user;
export const { setIsAuthenticated, setUser, setLogout } = userSlice.actions;
