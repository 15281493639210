import { getEveryDatasetForAdmin, updateDataset } from "api/datasetApi";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

export const UpdateModalLogic = ({
  dataset,
  setSelectedItemForUpdate,
  visibleUpdateModal,
  setVisibleUpdateModal,
  setAllDatasets,
  setDatasetsToShow,
}: {
  dataset: any;
  setSelectedItemForUpdate: any;
  visibleUpdateModal: boolean;
  setVisibleUpdateModal: (visibleUpdateModal: boolean) => void;
  setAllDatasets: (datasets: any) => void;
  setDatasetsToShow: (datasetsToShow: any) => void;
}) => {
  const [cameraTypesList, setCameraTypesList] = useState<any>();
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [newUpdatedCat, setNewUpdatedCat] = useState<object>({
    _id: "",
    description: "",
    cameraType_name: "",
    cameraType_id: "",
  });

  /**  Adding selectedCat val */
  useEffect(() => {
    setCameraTypesList(dataset?.dataset?.category_id.cameraTypes);
    setNewUpdatedCat({
      _id: dataset?.dataset._id,
      description: dataset?.dataset.description,
      cameraType_name: dataset?.dataset.cameraType_name,
      cameraType_id: dataset?.dataset.cameraType_id,
    });
  }, [dataset]);

  const handleInputChange = (e: any, key: string) => {
    key === "cameraType_name"
      ? cameraTypesList.length &&
        cameraTypesList.forEach((item: any) => {
          if (item.cameraType === e) {
            setNewUpdatedCat({
              ...newUpdatedCat,
              [key]: e,
              cameraType_id: item._id,
            });
          }
        })
      : setNewUpdatedCat({
          ...newUpdatedCat,
          [key]: e.target.value,
        });
  };

  /**  Updation submit */
  async function handleOk() {
    try {
      setConfirmLoading(true);
      await updateDataset(newUpdatedCat);
      setConfirmLoading(false);
      setVisibleUpdateModal(false);
      toast.success("Dataset updated successfully");
      const res = await getEveryDatasetForAdmin();
      setAllDatasets({ loading: false, error: false, data: res.data });
      setDatasetsToShow(res.data);
    } catch (err) {
      toast.error("Error updating dataset");
    }
  }

  /** Close modal */
  const handleCancel = () => {
    setVisibleUpdateModal(false);
    setSelectedItemForUpdate(null);
  };

  return {
    visibleUpdateModal,
    handleOk,
    confirmLoading,
    handleCancel,
    newUpdatedCat,
    handleInputChange,
    cameraTypesList,
  };
};
