import { getAllCategoriesWithCount } from "api/categoryApi";
import { useEffect, useState } from "react";

export const AdminViewCategoriesLogic = () => {
  let dataKeyCount = 0;
  let categoriesTableInfo: any[] = [];
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [categories, setCategories] = useState<any>({
    loading: false,
    error: false,
    data: [],
  });
  const [visibleUpdateModal, setVisibleUpdateModal] = useState<boolean>(false);
  const [selectedCatForUpdate, setSelectedCatForUpdate] = useState<any>();

  /** Fetching categories */
  useEffect(() => {
    setCategories({ loading: true, error: false, data: [] });
    try {
      const getCategories = async () => {
        const res = await getAllCategoriesWithCount();
        setCategories({
          loading: false,
          error: false,
          data: res,
        });
      };
      getCategories();
    } catch (err) {
      setCategories({ loading: false, error: true, data: [] });
    }
  }, []);

  /** For search & table integration */
  function addCategoriesInTable() {
    categoriesTableInfo = [];

    categories.data?.length &&
      categories.data
        .filter((val: any) =>
          searchTerm === ""
            ? val
            : val.type.toLowerCase().includes(searchTerm.toLowerCase()) && val
        )
        .forEach((eachCat: any) => {
          categoriesTableInfo.push(eachCat);
          dataKeyCount += 1;
        });
  }
  addCategoriesInTable();

  useEffect(() => {
    addCategoriesInTable();
  }, [searchTerm]);

  return {
    searchTerm,
    setSearchTerm,
    categories,
    categoriesTableInfo,
    visibleUpdateModal,
    setVisibleUpdateModal,
    selectedCatForUpdate,
    setSelectedCatForUpdate,
  };
};
