import { Button, Col, Input, Popover, Row, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { userSelector } from "store/userSlice";

import {
  getAllCategories,
  getAllCategoriesWithCount
} from "api/categoryApi";
import { setAllSidebarItems } from "store/sidebarDynamicItemsSlice";
import styles from "./ViewCategories.module.scss";

// Icons
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { BsImages } from "react-icons/bs";
import { HiOutlineDatabase } from "react-icons/hi";
import { ImArrowRight2 } from "react-icons/im";
import { IoIosSearch } from "react-icons/io";

const { Title, Text } = Typography;

function ViewCategories() {
  const dispatch = useDispatch();
  const userInfo = useSelector(userSelector);
  const [categories, setCategories] = useState<{
    loading: boolean;
    error: boolean;
    data: object[];
  }>({
    loading: false,
    error: false,
    data: [],
  });
  const [searchTerm, setSearchTerm] = useState<string>("");

  /** Fetch all categories */
  useEffect(() => {
    async function getCategories() {
      setCategories({ ...categories, loading: true });
      const res = await getAllCategoriesWithCount();
      setCategories({ ...categories, loading: false, data: res });

      const allCategoriesWithCameraTypes = await getAllCategories();

      const sidebarItems = allCategoriesWithCameraTypes.map((eachCat: any) => {
        return {
          _id: eachCat._id,
          link: `modality/${eachCat._id}/cameras`,
          name: eachCat.type,
          cameraTypes: eachCat.cameraTypes,
        };
      });
      dispatch(setAllSidebarItems(sidebarItems));
    }
    getCategories();
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#F0F2F5",
        height: "100%",
        marginBottom: "4rem",
      }}
    >
      <h3>Welcome, {userInfo.name}</h3>

      <Row className={styles.categories_headingRow}>
        <Col md={12}>
          <Title level={2} className={styles.categories_heading}>
            Modalities
          </Title>
        </Col>
        <Col md={12} xs={24}>
          <Input
            size="large"
            placeholder="Search..."
            prefix={<IoIosSearch className={styles.categories_searchBoxIcon} />}
            className={styles.categories_searchBox}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Col>
      </Row>

      {/* Categories */}
      <Row className={styles.categories_row}>
        {categories.loading ? (
          <div className={styles.loadingDiv}>
            <Spin
              size="large"
              indicator={
                <LoadingOutlined className={styles.loadingSpinIcon} spin />
              }
              tip="Fetching Modalities..."
              className={styles.loadingDiv_spin}
            />
          </div>
        ) : !categories.error && categories.data.length > 0 ? (
          categories.data
            .filter((val: any) =>
              searchTerm === ""
                ? val
                : val.type.toLowerCase().includes(searchTerm.toLowerCase()) &&
                  val
            )
            .map((category: any) => {
              return (
                <Col
                  lg={8}
                  md={12}
                  xs={24}
                  className={styles.categories_col}
                  key={category._id}
                >
                  <div className={styles.categories_card}>
                    <Row>
                      <Title level={4} className={styles.categoriesCard_type}>
                        {category?.name}
                        <Popover
                          content={category?.description}
                          placement="right"
                          title="Description"
                        >
                          <InfoCircleOutlined
                            className={styles.categoriesCard_infoIcon}
                          />
                        </Popover>
                      </Title>
                    </Row>
                    <Row
                      wrap={false}
                      className={styles.categoriesCard_dbAndImgsRow}
                    >
                      <Col flex={1 / 3} style={{ background: "" }}>
                        <Popover
                          content={category.datasetsCount}
                          placement="bottom"
                          title={<b>Dataset Count</b>}
                        >
                          <Text
                            ellipsis={true}
                            className={styles.categoriesCard_db_img_text}
                          >
                            <HiOutlineDatabase /> {category.datasetsCount}
                          </Text>
                        </Popover>
                      </Col>
                      <Col flex={2 / 3} style={{ background: "" }}>
                        <Popover
                          content={category.imagesCount}
                          placement="bottom"
                          title={<b>Image Count</b>}
                        >
                          <Text
                            ellipsis={true}
                            className={styles.categoriesCard_db_img_text}
                          >
                            <BsImages /> {category.imagesCount}
                          </Text>
                        </Popover>
                      </Col>
                    </Row>
                    <Row>
                      <Link
                        to={`/modality/${category._id}/cameras`}
                        className={styles.categoriesCard_link}
                      >
                        <Button
                          size="large"
                          className={styles.categoriesCard_btn}
                          style={{
                            backgroundColor:
                              category.datasetsCount > 0
                                ? "#6254FF"
                                : "#dbdbdb",
                          }}
                          disabled={category.datasetsCount > 0 ? false : true}
                        >
                          Browse{" "}
                          <ImArrowRight2
                            className={styles.categoriesCard_btnArrowIcon}
                          />
                        </Button>
                      </Link>
                    </Row>
                  </div>
                </Col>
              );
            })
        ) : (
          <p>Error</p>
        )}
      </Row>
    </div>
  );
}

export default ViewCategories;
