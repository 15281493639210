import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any[] = [];

export const reviewerSelectSlice = createSlice({
  name: "reviewerSelectSliceInfo",
  initialState: { value: initialState },
  reducers: {
    setReviewerViewUserDataInfo: (state, { payload }: PayloadAction<any[]>) => {
      state.value = payload;
    },
  },
});

export const { setReviewerViewUserDataInfo } = reviewerSelectSlice.actions;
export default reviewerSelectSlice.reducer;
