import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = "";

export const searchImageInfoSlice = createSlice({
  name: "searchImageInfo",
  initialState: { value: initialState },
  reducers: {
    setSearchImageInfo: (state, { payload }: PayloadAction<string>) => {
      state.value = payload;
    },
  },
});

export const { setSearchImageInfo } = searchImageInfoSlice.actions;
export default searchImageInfoSlice.reducer;
