import { MenuOutlined } from "@ant-design/icons";
import { Layout as AntLayout, Button, Popover, Typography } from "antd";
import { BiChevronDown, BiUserCircle } from "react-icons/bi";
import { TbLogout } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setLogout, userSelector } from "store/userSlice";
import styles from "./Navbar.module.scss";

import NotificationList from "./NotificationList";

const { Header } = AntLayout;
const { Title } = Typography;

function Navbar({
  md,
  setHamPosition,
}: {
  md: any;
  setHamPosition: (hamPosition: string) => void;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isImgRoute = location.pathname.includes("/images/");
  const user = useSelector(userSelector);

  /** Logout handler */
  function handleLogout() {
    dispatch(setLogout());
    navigate("/login");
  }

  return (
    <Header
      className={styles.navbar}
      style={{
        justifyContent: isImgRoute || !md ? "space-between" : "flex-end",
        backgroundColor: "#fff",
        padding: "0 0 0 50px",
      }}
    >
      {!md && (
        <MenuOutlined
          onClick={() => setHamPosition("0px")}
          className={styles.navbar_menuIcon}
        />
      )}
      {isImgRoute && (
        <a href="/" className={styles.navLogoImgRoute}>
          Data<span>Prime</span>
        </a>
      )}
      <div>
        {isImgRoute && (
          <Link to="/">
            <Button className={styles.goHomeBtn}>Go Home</Button>
          </Link>
        )}
        <NotificationList />
        <Popover
          style={{
            padding: "0",
            boxShadow:
              "0px 4px 4px rgba(0, 0, 0, 0.25), -8px -4px 5px rgba(0, 0, 0, 0.16)",
          }}
          placement="bottomRight"
          content={
            <>
              {/* <div>
                                <Link to="/annotation/history">
                                    <p className={styles.navbar_popupLink}>
                                        <span>
                                            <FiBook
                                                style={{ fontSize: "1rem" }}
                                            />
                                        </span>
                                        My Annotation History
                                    </p>
                                </Link>
                            </div> */}
              <div>
                <p
                  className={styles.navbar_popupLink}
                  onClick={() => handleLogout()}
                >
                  <span>
                    <TbLogout />
                  </span>
                  Logout
                </p>
              </div>
            </>
          }
          trigger="click"
        >
          <Title level={5} className={styles.navbar_profile}>
            <BiUserCircle className={styles.navbar_profile_userIcon} />
            <span>
              {md && <>{user?.name}</>}
              <BiChevronDown className={styles.navbar_profile_arrowIcon} />
            </span>
          </Title>
        </Popover>
      </div>
    </Header>
  );
}

export default Navbar;
