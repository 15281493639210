import {
  getAllNotifications,
  markAllAsReadApi,
  markNotificationAsReadApi,
} from "api/notificationsApi";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

export const NotificationListLogic = () => {
  const [allNotifications, setAllNotifications] = useState<{
    loading: boolean;
    error: boolean;
    data: any[];
  }>({
    loading: false,
    error: false,
    data: [],
  });
  const [unreadNotificationCount, setUnreadNotificationCount] =
    useState<number>(0);

  /** Fetch all notifications */
  async function fetchAllNotifications() {
    try {
      const res = await getAllNotifications();
      setAllNotifications({
        loading: false,
        error: false,
        data: res.notifications,
      });
      setUnreadNotificationCount(res.unreadCount);
    } catch (error: any) {
      toast.error(error.response?.data?.message);
    }
  }

  useEffect(() => {
    fetchAllNotifications();
  }, []);

  /** Mark notification as read */
  async function markNotificationAsRead(notificationId: string) {
    try {
      await markNotificationAsReadApi(notificationId);
      fetchAllNotifications();
    } catch (err: any) {
      toast.error(err.response?.data?.message);
    }
  }

  /** Mark all as Read */
  async function markAllAsReadHandler() {
    try {
      await markAllAsReadApi();
      fetchAllNotifications();
    } catch (err: any) {
      toast.error(err.response?.data?.message);
    }
  }

  return {
    fetchAllNotifications,
    allNotifications,
    unreadNotificationCount,
    markNotificationAsRead,
    markAllAsReadHandler,
  };
};
