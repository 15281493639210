import type { PaginationProps } from "antd";
import {
  Button,
  Col,
  Input,
  Pagination,
  Row,
  Spin,
  Table,
  Typography,
} from "antd";
import { Toaster } from "react-hot-toast";
import styles from "./Metrics.module.scss";
import { MetricsLogic } from "./MetricsLogic";

import CreateMetricModal from "components/metrics/CreateMetricModal/CreateMetricModal";

// Icons
import { LoadingOutlined } from "@ant-design/icons";
import { useState } from "react";
import { IoIosSearch } from "react-icons/io";

const { Title } = Typography;

function Metrics({ allDatasets, setAllDatasets }: any) {
  const {
    openCreateMetricModal,
    setOpenCreateMetricModal,
    createMetricSubmitLoading,
    setCreateMetricSubmitLoading,
    totalAnnotationCount,
    pageCount,
    setPageCount,
    pageLimit,
    setPageLimit,
    metricsData,
    metricsTableInfo,
    metricsFetchErrMsg,
    downloadFile,
    getMetricsData,
  }: {
    openCreateMetricModal: boolean;
    setOpenCreateMetricModal: (open: boolean) => void;
    createMetricSubmitLoading: boolean;
    setCreateMetricSubmitLoading: (loading: boolean) => void;
    totalAnnotationCount: number;
    pageCount: number;
    setPageCount: (count: number) => void;
    pageLimit: number;
    setPageLimit: (limit: number) => void;
    metricsData: {
      loading: boolean;
      error: boolean;
      data: any[];
    };
    metricsTableInfo: any;
    metricsFetchErrMsg: string;
    downloadFile: (fileId: string, fileName: string) => void;
    getMetricsData: () => void;
  } = MetricsLogic({ allDatasets, setAllDatasets });

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    newPageLimit
  ) => {
    setPageLimit(newPageLimit);
  };
  const onChange: PaginationProps["onChange"] = (pageNumber) => {
    setPageCount(pageNumber);
  };
  const [searchQuery, setSearchQuery] = useState("");

  const tableHeaders = [
    {
      title: "Filename",
      key: "fileName",
      dataIndex: "fileName",
      width: "18%",
    },
    {
      title: "Metric Type",
      key: "metricType",
      dataIndex: "metricType",
      width: "25%",
    },
    {
      title: "Updated At",
      key: "updatedAt",
      dataIndex: "updatedAt",
      width: "25%",
    },
    {
      title: "Status",
      key: "status",
      width: "15%",
      align: "center" as const,
      render: (details: any) => {
        return (
          <>
            {details?.status === "pending" ? (
              <Title level={5} className={styles.metris_downloadPending}>
                Pending...
              </Title>
            ) : (
              <>
                <Button
                  className={styles.metrics_downloadBtn}
                  onClick={() => downloadFile(details._id, details.fileName)}
                >
                  Download
                </Button>
              </>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Row>
        <Col lg={8} md={24} sm={24} xs={24}>
          <Title level={2} className={styles.metrics_heading}>
            Metrics
          </Title>
        </Col>
        <Col lg={16} md={24} sm={24} xs={24}>
          <Row justify={"end"}>
            <Col
              flex={"none"}
              sm={12}
              xs={24}
              className={styles.metrics_column}
            >
              <Input
                size="large"
                placeholder="Search..."
                prefix={
                  <IoIosSearch className={styles.metrics__searchFilterIcon} />
                }
                className={styles.metrics__searchFilter}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
            </Col>
            <Col
              flex={"none"}
              sm={12}
              xs={24}
              className={styles.metrics_column}
            >
              <Button
                className={styles.metrics_createBtn}
                onClick={() => setOpenCreateMetricModal(true)}
              >
                Create New Metric
              </Button>

              <CreateMetricModal
                allDatasets={allDatasets}
                openCreateMetricModal={openCreateMetricModal}
                setOpenCreateMetricModal={setOpenCreateMetricModal}
                createMetricSubmitLoading={createMetricSubmitLoading}
                setCreateMetricSubmitLoading={setCreateMetricSubmitLoading}
                getMetricsData={getMetricsData}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <div className={styles.metrics_paginationDiv}>
        {metricsTableInfo.length ? (
          <Pagination
            current={pageCount}
            total={totalAnnotationCount}
            pageSize={pageLimit}
            className={styles.datasets_pagination}
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            onChange={onChange}
          />
        ) : (
          ""
        )}
      </div>

      {metricsData.error ? (
        <>
          <Title level={4} className={styles.datasets_heading}>
            {metricsFetchErrMsg}
          </Title>
        </>
      ) : metricsData.loading ? (
        <div className={styles.loadingDiv}>
          <Spin
            size="large"
            indicator={
              <LoadingOutlined className={styles.loadingSpinIcon} spin />
            }
            tip="Fetching Metrics Data..."
            className={styles.loadingDiv_spin}
          />
        </div>
      ) : (
        <>
          <Table
            dataSource={metricsTableInfo.filter((metric: any) =>
              metric.fileName.toLowerCase().includes(searchQuery.toLowerCase())
            )}
            columns={tableHeaders}
            className={styles.metrics_table}
            scroll={{ x: 1000 }}
            pagination={false}
          />
        </>
      )}

      <Toaster
        toastOptions={{
          success: {
            style: {
              border: "1px solid #199c3c",
            },
          },
          error: {
            style: {
              border: "1px solid #FF4C4C",
            },
          },
        }}
      />
    </>
  );
}

export default Metrics;
