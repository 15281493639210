import {
  Button,
  Col,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Slider,
  Spin,
  Switch,
} from "antd";
import styles from "./CreateMetricModal.module.scss";
import { CreateMetricModalLogic } from "./CreateMetricModalLogic";

// Icons
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";

const { Option } = Select;

function CreateMetricModal({
  allDatasets,
  openCreateMetricModal,
  setOpenCreateMetricModal,
  createMetricSubmitLoading,
  setCreateMetricSubmitLoading,
  getMetricsData,
}: {
  allDatasets: { [key: string]: any };
  openCreateMetricModal: boolean;
  setOpenCreateMetricModal: (open: boolean) => void;
  createMetricSubmitLoading: boolean;
  setCreateMetricSubmitLoading: (loading: boolean) => void;
  getMetricsData: () => void;
}) {
  const {
    selectedMetricType,
    setSelectedMetricType,
    selectedCreateMetricUsers,
    setSelectedCreateMetricUsers,
    setSelectedDatasetId,
    selectedDatasetName,
    setSelectedDatasetName,
    allClassifiersOfDataset,
    setSelectedClassifierId,
    selectedClassifierName,
    setSelectedClassifierName,
    allUsers,
    createNewMetricFileName,
    setCreateNewMetricFileName,
    sliderAgreementInput,
    setSliderAgreementInput,
    discardToggler,
    setDiscardToggler,
    handleCreateMetricSubmit,
    handleCancel,
  }: {
    selectedMetricType: string;
    setSelectedMetricType: (val: string) => void;
    selectedCreateMetricUsers: string[];
    setSelectedCreateMetricUsers: (users: string[]) => void;
    selectedDatasetId: string;
    setSelectedDatasetId: (datasetId: string) => void;
    selectedDatasetName: string;
    setSelectedDatasetName: (datasetName: string) => void;
    allClassifiersOfDataset: any[];
    selectedClassifierId: string;
    setSelectedClassifierId: (classifierId: string) => void;
    selectedClassifierName: string;
    setSelectedClassifierName: (classifierName: string) => void;
    allUsers: any[];
    createNewMetricFileName: string;
    setCreateNewMetricFileName: (fileName: string) => void;
    sliderAgreementInput: number;
    setSliderAgreementInput: (value: number) => void;
    discardToggler: boolean;
    setDiscardToggler: (toggler: boolean) => void;
    handleCreateMetricSubmit: () => void;
    handleCancel: () => void;
  } = CreateMetricModalLogic({
    allDatasets,
    setOpenCreateMetricModal,
    setCreateMetricSubmitLoading,
    getMetricsData,
  });

  return (
    <>
      <Modal
        bodyStyle={{
          overflowY: "auto",
          maxHeight: "calc(90vh - 200px)",
        }}
        visible={openCreateMetricModal}
        title="Create New Metric"
        onOk={() => handleCreateMetricSubmit()}
        onCancel={() => handleCancel()}
        footer={[
          <Button key="back" onClick={() => handleCancel()}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={createMetricSubmitLoading}
            onClick={() => handleCreateMetricSubmit()}
          >
            Submit
          </Button>,
        ]}
      >
        <label className={styles.metrics_inputLabels}>File name</label>
        <Input
          value={createNewMetricFileName}
          onChange={(e: any) => setCreateNewMetricFileName(e.target.value)}
          className={styles.metrics_inputField}
        />

        <label className={styles.metrics_inputLabels}>Select Metric Type</label>
        <Select
          placeholder="Select a type of metric you want to generate"
          style={{ width: "90%" }}
          value={selectedMetricType}
          onChange={(e) => setSelectedMetricType(e)}
          className={styles.metrics_dropDownField}
          options={[
            {
              value: "0",
              label: "Particular user annotation without subclasses",
            },
            {
              value: "1",
              label: "Particular user annotation with subclasses",
            },
            {
              value: "2",
              label: "Multiple user's annotation (without Agreement factor)",
            },
            {
              value: "3",
              label: "Multiple user's annotation (with Agreement factor)",
            },
          ]}
        />

        {/* Dataset */}
        <label className={styles.metrics_inputLabels}>Select Dataset</label>
        <Select
          style={{ width: "90%" }}
          placeholder="Select dataset"
          value={selectedDatasetName}
          onChange={(e) => {
            setSelectedDatasetName(e);
            allDatasets.data?.map((eachDataset: any) => {
              if (eachDataset.dataset?.name === e) {
                setSelectedDatasetId(eachDataset.dataset._id);
              }
            });
          }}
          className={styles.metrics_dropDownField}
        >
          {allDatasets.loading ? (
            <div className={styles.loadingDiv}>
              <Spin
                size="large"
                indicator={
                  <LoadingOutlined className={styles.loadingSpinIcon} spin />
                }
                tip="Fetching all datasets..."
                className={styles.loadingDiv_spin}
              />
            </div>
          ) : allDatasets.error ? (
            <Option key={null} value={""} disabled>
              Oops
            </Option>
          ) : (
            allDatasets.data.length > 0 && (
              <>
                {allDatasets.data.map((eachDataset: any) => (
                  <Option
                    key={eachDataset.dataset._id}
                    value={eachDataset.dataset?.name}
                  >
                    {eachDataset.dataset?.name}
                  </Option>
                ))}
              </>
            )
          )}
        </Select>

        {/* Classifier */}
        <label className={styles.metrics_inputLabels}>Select Classifier</label>
        <Select
          defaultValue={""}
          style={{ width: "90%" }}
          placeholder="Select classifier"
          value={selectedClassifierName}
          onChange={(e) => {
            setSelectedClassifierName(e);
            allClassifiersOfDataset?.map((eachClassifier: any) => {
              if (eachClassifier?.pathology === e) {
                setSelectedClassifierId(eachClassifier._id);
              }
            });
          }}
          className={styles.metrics_dropDownField}
        >
          {allClassifiersOfDataset.length > 0 ? (
            allClassifiersOfDataset.map((eachClassifier: any) => (
              <Option
                key={eachClassifier._id}
                value={eachClassifier?.pathology}
              >
                {eachClassifier?.pathology}
              </Option>
            ))
          ) : (
            <Option key={null} value={"0"} disabled>
              Select a dataset first
            </Option>
          )}
        </Select>

        {/* User */}
        {selectedMetricType === "0" || selectedMetricType === "1" ? (
          <>
            <label className={styles.metrics_inputLabels}>Select User</label>
            <Select
              allowClear
              style={{ width: "90%" }}
              placeholder="Select users"
              value={selectedCreateMetricUsers[0]}
              onChange={(e) => setSelectedCreateMetricUsers([e])}
              className={styles.metrics_dropDownField}
            >
              {allUsers.length > 0 ? (
                allUsers.map((eachUser: any) => (
                  <Option key={eachUser._id} value={eachUser?._id}>
                    {eachUser?.email}
                  </Option>
                ))
              ) : (
                <Option key={null} value={""} disabled>
                  Select a classifier first
                </Option>
              )}
            </Select>
          </>
        ) : selectedMetricType === "2" || selectedMetricType === "3" ? (
          <>
            <label className={styles.metrics_inputLabels}>Select Users</label>
            <Select
              mode="multiple"
              allowClear
              style={{ width: "90%" }}
              placeholder="Select users"
              value={selectedCreateMetricUsers}
              onChange={(e) => setSelectedCreateMetricUsers(e)}
              className={styles.metrics_dropDownField}
            >
              {allUsers.length > 0 ? (
                allUsers.map((eachUser: any) => (
                  <Option key={eachUser._id} value={eachUser?._id}>
                    {eachUser?.email}
                  </Option>
                ))
              ) : (
                <Option key={null} value={""} disabled>
                  Select a classifier first
                </Option>
              )}
            </Select>
          </>
        ) : (
          <></>
        )}

        {selectedMetricType === "3" && (
          <>
            <label
              className={styles.metrics_inputLabels}
              style={{ margin: "1rem auto 0.5rem" }}
            >
              Select Agreement percentage
            </label>
            <Row>
              <Col span={3}>
                <p className={styles.metrics_imgSliderCount}>20%</p>
              </Col>
              <Col span={18}>
                <Slider
                  handleStyle={{
                    borderColor: "#6254FF",
                  }}
                  trackStyle={{ backgroundColor: "#626DF6" }}
                  min={20}
                  max={100}
                  value={sliderAgreementInput}
                  onChange={(newVal: number) => setSliderAgreementInput(newVal)}
                />
              </Col>
              <Col span={3}>
                <p
                  className={styles.metrics_imgSliderCount}
                  style={{ paddingLeft: "0" }}
                >
                  100%
                </p>
              </Col>
            </Row>

            <Row style={{ marginTop: "1rem" }}>
              <Col span={8}>
                <label
                  className={styles.metrics_inputLabels}
                  style={{ margin: "1rem auto 0.5rem" }}
                >
                  Discard
                  <Popover
                    content={
                      "If yes, images below the agreement factor will be discarded"
                    }
                    placement="right"
                    title="Description"
                  >
                    <InfoCircleOutlined
                      className={styles.metrics_createFormInfoIcon}
                    />
                  </Popover>
                </label>
              </Col>
              <Col span={16}>
                <Switch
                  checkedChildren={"Yes"}
                  unCheckedChildren={"No"}
                  defaultChecked
                  checked={discardToggler}
                  onClick={(e) => setDiscardToggler(e)}
                />
              </Col>
            </Row>
          </>
        )}
      </Modal>
    </>
  );
}

export default CreateMetricModal;
