import api, { authHeader } from "./index";

export async function getAnnotatedHistoryDetails({
  pageCount,
  pageLimit,
  searchTerm,
}: {
  pageCount: number;
  pageLimit: number;
  searchTerm: string;
}) {
  const response = await api.get(
    `/annotation/history?page=${pageCount}&limit=${pageLimit}&search=${searchTerm}`,
    authHeader()
  );
  return response.data?.result;
}

export async function getAnnotatedPerDayDetails({
  pageCount,
  pageLimit,
  dateTerm,
  searchTerm,
}: {
  pageCount: number;
  pageLimit: number;
  dateTerm: string;
  searchTerm: string;
}) {
  const response = await api.get(
    `/annotation/history-by-date?date=${dateTerm}&page=${pageCount}&limit=${pageLimit}&search=${searchTerm}`,
    authHeader()
  );
  return response.data?.result;
}

export async function getAnnotatedPerDayDatasetDetails({
  pageCount,
  pageLimit,
  dateTerm,
  datasetId,
}: {
  pageCount: number;
  pageLimit: number;
  dateTerm: string;
  datasetId: string;
}) {
  const response = await api.get(
    `/annotation/history-by-date?date=${dateTerm}&dataset_id=${datasetId}&page=${pageCount}&limit=${pageLimit}`,
    authHeader()
  );
  return response.data?.result;
}
