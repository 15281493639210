import { useState, useEffect } from "react";
import { updateCategory } from "api/categoryApi";
import toast from "react-hot-toast";

export const UpdateModalLogic = ({
  category,
  setSelectedCatForUpdate,
  visibleUpdateModal,
  setVisibleUpdateModal,
}: {
  category: any;
  setSelectedCatForUpdate: any;
  visibleUpdateModal: boolean;
  setVisibleUpdateModal: (visibleUpdateModal: boolean) => void;
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [newUpdatedCat, setNewUpdatedCat] = useState({
    category_id: "",
    name: "",
    type: "",
    description: "",
  });

  /** Adding/Updating selectedCat val */
  useEffect(() => {
    setNewUpdatedCat({
      category_id: category?._id,
      name: category?.name,
      type: category?.type,
      description: category?.description,
    });
  }, [category]);

  /** onChnage Updation of category details */
  const handleInputChange = (e: any, key: string) => {
    setNewUpdatedCat({
      ...newUpdatedCat,
      [key]: e.target.value,
    });
  };

  /** Submit updated category details */
  async function handleOk() {
    if (newUpdatedCat.name && newUpdatedCat.type && newUpdatedCat.description) {
      setConfirmLoading(true);
      try {
        await updateCategory(newUpdatedCat);
        setConfirmLoading(false);
        setVisibleUpdateModal(false);

        toast.success("Modality updated successfully");
      } catch (err) {
        toast.error("Error! Couldn't update modality");
      }
    } else {
      toast.error("Please fill all the fields");
    }
    // setTimeout(() => {
    //   setVisibleUpdateModal(false);
    //   setConfirmLoading(false);
    // }, 2000);
  }

  // Close modal
  const handleCancel = () => {
    setVisibleUpdateModal(false);
    setSelectedCatForUpdate(null);
  };

  return {
    visibleUpdateModal,
    handleOk,
    confirmLoading,
    handleCancel,
    newUpdatedCat,
    handleInputChange,
  };
};
