import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any[] = [];

export const sidebarDynamicItemsSlice = createSlice({
  name: "sidebarDynamicItems",
  initialState: { value: initialState },
  reducers: {
    setAllSidebarItems: (state, { payload }: PayloadAction<any[]>) => {
      state.value = payload;
    },
  },
});

export const { setAllSidebarItems } = sidebarDynamicItemsSlice.actions;
export default sidebarDynamicItemsSlice.reducer;
