import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Row,
  Col,
  Input,
  Button,
  Space,
  Table,
  Grid,
  Spin,
  Popover,
  Breadcrumb,
} from "antd";
import type { ColumnsType } from "antd/es/table";

import styles from "./ViewDatasets.module.scss";
import { getDatasetsbyCameraType } from "api/datasetApi";
import { useDispatch, useSelector } from "react-redux";
import { setAllSidebarItems } from "store/sidebarDynamicItemsSlice";
import { getAllCategories } from "api/categoryApi";

// Icons
import { LoadingOutlined } from "@ant-design/icons";
import { IoIosSearch } from "react-icons/io";
import { BsImages } from "react-icons/bs";
import { InfoCircleOutlined } from "@ant-design/icons";
import { FaCamera } from "react-icons/fa";
import { timestampToDate } from "utils/functions";

const { Title } = Typography;
const { useBreakpoint } = Grid;

interface DatasetType {
  key: string;
  _id: string;
  name: string;
  description: string;
  cameraType_name: string;
  imagesCount: number;
  updatedAt: string;
}

function ViewDatasets() {
  let dataKeyCount = 0;
  const { md } = useBreakpoint();
  const navigate = useNavigate();
  const { categoryId, camTypeId, fieldData } = useParams<string>();
  const dispatch = useDispatch();
  const existingSidebarCategoryItems = useSelector(
    (state: any) => state.sidebarDynamicItems.value
  );
  let datasetTableInfo: DatasetType[] = [];
  const [datasets, setDatasets] = useState<any>({
    loading: false,
    error: false,
    data: [],
  });
  const [searchTerm, setSearchTerm] = useState<string>("");

  /** Fetch datasets depending on selected camera type */
  useEffect(() => {
    setDatasets({
      loading: true,
      error: false,
      data: {},
    });
    try {
      const getDatasets = async () => {
        const res = await getDatasetsbyCameraType(
          categoryId,
          camTypeId,
          fieldData
        );
        setDatasets({
          loading: false,
          error: false,
          data: res.result,
        });

        if (!existingSidebarCategoryItems.length) {
          const allCategoriesWithCameraTypes = await getAllCategories();

          const sidebarItems = allCategoriesWithCameraTypes.map(
            (eachCat: any) => {
              return {
                _id: eachCat._id,
                link: `modality/${eachCat._id}/cameras`,
                name: eachCat.type,
                cameraTypes: eachCat.cameraTypes,
              };
            }
          );

          dispatch(setAllSidebarItems(sidebarItems));
        }
      };
      getDatasets();
    } catch (error) {
      setDatasets({
        loading: false,
        error: true,
        data: {},
      });
    }
  }, [categoryId, camTypeId]);

  const normalUserColumns: ColumnsType<any> = [
    {
      title: "Dataset Name",
      key: "nameAndDes",
      render: (details) => {
        return (
          <>
            {details.name}
            <Popover
              content={details.description}
              placement="right"
              title="Description"
            >
              <InfoCircleOutlined
                className={styles.datasetsTableCol_infoIcon}
              />
            </Popover>
          </>
        );
      },
    },
    {
      title: "Camera Type",
      dataIndex: "cameraType_name",
      key: "cameraType_name",
    },
    {
      title: "Images Count",
      dataIndex: "imagesCount",
      key: "imagesCount",
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
    },
    {
      title: "View",
      key: "action",
      render: (details) => {
        return (
          <Space size="middle">
            <Link to={`/classifiers/${details._id}`}>
              <Button
                className={styles.datasets_browseBtn}
                onClick={() => localStorage.removeItem("reviewerUsersInfo")}
              >
                Browse Images
              </Button>
            </Link>
          </Space>
        );
      },
    },
  ];

  /** Putting datasets in the interface form for ease of showing in table */
  function addDatasetsInTable() {
    datasetTableInfo = [];

    datasets.data?.length &&
      datasets.data
        .filter((val: any) =>
          searchTerm === ""
            ? val
            : val.name.toLowerCase().includes(searchTerm.toLowerCase()) && val
        )
        .forEach((eachDataset: any) => {
          datasetTableInfo.push({
            key: dataKeyCount.toString(),
            _id: eachDataset._id,
            name: eachDataset.name,
            description: eachDataset.description,
            cameraType_name: eachDataset.cameraType_name,
            imagesCount: eachDataset.count,
            updatedAt: timestampToDate(eachDataset.updatedAt),
          });
          dataKeyCount += 1;
        });
  }
  addDatasetsInTable();

  useEffect(() => {
    addDatasetsInTable();
  }, [searchTerm]);

  return (
    <>
      {datasetTableInfo.length > 0 && (
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <span>Home</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/">
            <span>Modality</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span
              onClick={() => navigate(-1)}
              className={styles.breadcrumbs_cameraType}
            >
              {datasetTableInfo.length > 0 &&
                datasetTableInfo[0]?.cameraType_name}
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Datasets</Breadcrumb.Item>
        </Breadcrumb>
      )}

      <Row className={styles.datasets_headingRow}>
        <Col md={12}>
          <Title level={2} className={styles.datasets_heading}>
            Datasets
          </Title>
          {/* <Title level={5} className={styles.datasets_description}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tem.
          </Title> */}
        </Col>
        <Col md={12} xs={24}>
          <Input
            size="large"
            placeholder="Search..."
            prefix={<IoIosSearch className={styles.datasets_searchBoxIcon} />}
            className={styles.datasets_searchBox}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Col>
      </Row>

      <div className={styles.datasets_tableRow}>
        {datasets.loading ? (
          <div className={styles.loadingDiv}>
            <Spin
              size="large"
              indicator={
                <LoadingOutlined className={styles.loadingSpinIcon} spin />
              }
              tip="Fetching Datasets..."
              className={styles.loadingDiv_spin}
            />
          </div>
        ) : md ? (
          <Table
            columns={normalUserColumns}
            dataSource={datasetTableInfo}
            className={styles.table}
            scroll={{ x: 1000 }}
          />
        ) : (
          <Row className={styles.categories_row}>
            {datasetTableInfo.length ? (
              datasetTableInfo.map((eachDataset) => {
                return (
                  <Col
                    lg={8}
                    md={12}
                    xs={24}
                    className={styles.categories_col}
                    key={eachDataset._id}
                  >
                    <div className={styles.categories_card}>
                      <Title level={4} className={styles.categoriesCard_type}>
                        {eachDataset.cameraType_name}
                      </Title>
                      <Title level={4} className={styles.categoriesCard_name}>
                        {eachDataset.name}
                        <Popover
                          content={eachDataset.description}
                          placement="bottom"
                          title="Description"
                        >
                          <InfoCircleOutlined
                            className={styles.cameras_infoIcon}
                          />
                        </Popover>
                      </Title>
                      <Row className={styles.categoriesCard_dbAndImgsRow}>
                        <Col span={16}>
                          <Title
                            level={4}
                            className={styles.categoriesCard_dbAndImgs}
                          >
                            <BsImages />
                            <span>{eachDataset.imagesCount}</span>
                          </Title>
                        </Col>
                      </Row>
                      <Link to={`/classifiers/${eachDataset._id}`}>
                        <Button
                          className={styles.categoriesCard_btn}
                          style={{
                            margin: "1rem 0 0 auto",
                          }}
                          onClick={() => {
                            localStorage.removeItem("reviewerUsersInfo");
                            localStorage.removeItem(
                              "reviewerViewAnnotatorName"
                            );
                          }}
                        >
                          Browse Images
                        </Button>
                      </Link>
                    </div>
                  </Col>
                );
              })
            ) : (
              <div className={styles.loadingDiv}>
                <p>No Datasets Found</p>
              </div>
            )}
          </Row>
        )}
      </div>
    </>
  );
}

export default ViewDatasets;
