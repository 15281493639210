import { getBucketData, getImageGalleryDetails } from "api/imageGallery";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { userSelector } from "store/userSlice";

interface DatasetType {
  key: string;
  url: string;
  datasetId: string;
  classifierId: string;
  bucketId: string;
  imgId: string;
}

export const ImageGalleryLogic = (datasetId: any, radioVal: any) => {
  let dataKeyCount = 0;
  let datasetTableInfo: DatasetType[] = [];
  const userInfo = useSelector(userSelector);

  const [totalAnnotationCount, setTotalAnnotationCount] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searched, setSearched] = useState<boolean>(false);
  const [datasets, setDatasets] = useState<{
    loading: boolean;
    error: boolean;
    data: any[];
  }>({
    loading: false,
    error: false,
    data: [],
  });
  const [bucketData, setBucketData] = useState<{
    loading: boolean;
    error: boolean;
    data: any[];
  }>({
    loading: false,
    error: false,
    data: [],
  });
  const [pageCount, setPageCount] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(500);

  const [totalImageCount, setTotalImageCount] = useState<number>(0);
 
  useEffect(() => {
    setDatasets({
      loading: true,
      error: false,
      data: [],
    });
    try {
      const fetchDatasets = async () => {
        const res = await getImageGalleryDetails({
          pageCount,
          pageLimit,
          datasetId,
        });
        setTotalAnnotationCount(res.count);
        setTotalImageCount(res.counts[0].count);

        setDatasets({
          loading: false,
          error: false,
          data: res.thumbnails,
        });
      };
      fetchDatasets();
    } catch (error) {
      setDatasets({
        loading: false,
        error: true,
        data: [],
      });
    }
  }, [pageCount, pageLimit, datasetId]);

  // Bucket Data
  useEffect(() => {
    setBucketData({
      loading: true,
      error: false,
      data: [],
    });
    try {
      const fetchDatasets = async () => {
        const res = await getBucketData();

        setBucketData({
          loading: false,
          error: false,
          data: res,
        });
      };
      fetchDatasets();
    } catch (error) {
      setBucketData({
        loading: false,
        error: true,
        data: [],
      });
    }
  }, []);

  /** Putting datasets in the interface form for ease of showing in table */
  function addDatasetsInTable() {
    datasetTableInfo = [];

    datasets.data.length &&
      datasets.data.forEach((eachDataset: any) => {
        datasetTableInfo.push({
          key: dataKeyCount.toString(),
          url: eachDataset.url,
          datasetId: eachDataset.image.dataset_id,
          classifierId: eachDataset.image.initial_annotation[0].classifier,
          bucketId: eachDataset.image.initial_annotation[0].class,
          imgId: eachDataset.image._id,
        });
        dataKeyCount += 1;
      });
  }
  addDatasetsInTable();

  return {
    userInfo,
    datasets,
    setSearchTerm,
    datasetTableInfo,
    pageCount,
    setPageCount,
    pageLimit,
    setPageLimit,
    totalAnnotationCount,
    searched,
    setSearched,
    totalImageCount,
    bucketData,
  };
};
