import api, { authHeader } from "./index";

export async function getImageGalleryDetails({
  pageCount,
  pageLimit,
  datasetId,
}: {
  pageCount: number;
  pageLimit: number;
  datasetId: string;
}) {
  const response = await api.get(
    `/images/cache-thumb/${datasetId}?page=${pageCount}&count=${pageLimit}`,
    authHeader()
  );
  return response.data?.result;
}

export async function getBucketData() {
  const response = await api.get(
    `/classifier/classes/629c9e4b7a9b2be72c1e0226`,
    authHeader()
  );

  return response.data?.result;
}
