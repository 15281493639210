import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { getAllCategories } from "api/categoryApi";
import { createDataset } from "api/datasetApi";
import { getAllClassifiers } from "api/classifierApi";

export const CreateDatasetLogic = (form: any) => {
  const [allCategoryData, setAllCategoryData] = useState<any>([]);
  const [cameraType, setCameraType] = useState<any>([]);
  const [classifiers, setClassifiers] = useState<any>([]);
  const [classifiersList, setClassifiersList] = useState<any>([]);
  const [selectedCatId, setSelectedCatId] = useState<any>("");

  /** Fetching categories & classifiers */
  useEffect(() => {
    async function getCategories() {
      const res = await getAllCategories();
      setAllCategoryData(res);
    }
    async function getClassifiers() {
      const res = await getAllClassifiers();
      setClassifiers(res);
    }
    getCategories();
    getClassifiers();
  }, []);

  /** Updating cameraTypes based on selectedCategory */
  useEffect(() => {
    setCameraType([]);
    allCategoryData &&
      allCategoryData?.forEach((eachCat: any) => {
        if (eachCat._id === selectedCatId) {
          eachCat.cameraTypes.forEach((eachType: any) => {
            setCameraType((prevState: any) => [
              ...prevState,
              {
                cameraType: eachType.cameraType,
                cameraTypeId: eachType._id,
              },
            ]);
          });
        }
      });
  }, [selectedCatId, allCategoryData]);

  /** Updating classifiersList based on selectedCategory */
  useEffect(() => {
    setClassifiersList([]);
    if (classifiers.length && selectedCatId) {
      classifiers.forEach((eachClassifier: any) => {
        if (eachClassifier.category_id === selectedCatId) {
          setClassifiersList((prevState: any) => [
            ...prevState,
            eachClassifier,
          ]);
        }
      });
    }
  }, [selectedCatId, classifiers]);

  // Handling submit
  async function onFinish(values: any) {
    const cameraType_id = values.cameraType.split(" ")[0];
    const cameraType_name = values.cameraType.split(" ")[1];
    try {
      const res = await createDataset(
        values.name,
        values.description,
        values.category_id,
        cameraType_id,
        cameraType_name,
        values.classifiers
      );

      form.resetFields();

      toast.success("Dataset created successfully");
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  }

  const onFinishFailed = (errorInfo: any) => {};

  return {
    onFinish,
    onFinishFailed,
    selectedCatId,
    setSelectedCatId,
    allCategoryData,
    cameraType,
    classifiersList,
  };
};
