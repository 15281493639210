import {
  Button,
  Checkbox,
  Input,
  Modal,
  Popover,
  Radio ,
  Select as AntSelect ,
  Table,
  Typography,
} from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { userSelector } from "store/userSlice";
import styles from "./CreateAnnotation.module.scss";
// mui 
import { Theme, useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import FormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
// import Radio from "@mui/material/Radio";

// import Select  , { SelectChangeEvent } from "@mui/material/Select";
import { InfoCircleOutlined } from "@ant-design/icons";
import { MdLabel } from "react-icons/md";

import Stack from "@mui/material/Stack";
import React from "react";
import { Select, SelectChangeEvent } from "@mui/material";
// mui value 
const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  function getStyles(name: string, selectedClasses: string[], theme: Theme) {
    return {
      fontWeight:
        selectedClasses.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

// Icons



// import Button from "@mui/material/Button";

//  mui for radio

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": checked && {
    color: theme.palette.primary.main,
  },
}));

function MyFormControlLabel(props: FormControlLabelProps) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

const { Title } = Typography;
const { Option } = AntSelect;
const { TextArea } = Input;

function CreateAnnotation({
  datasetName,
  classifierPathId,
  onChange,
  selectedBucketId,
  selectedRadioClass,
  selectedRadioSubClasses,
  setSelectedRadioClass,
  setSelectedRadioSubClasses,
  handleSelectedSubClassCount,
  radioBucketNames,
  onCheckboxChange,
  selectedImgCount,
  setSelectedImgCount,
  postAnnotation,
  images,
  setSelectedDropdownClassifier,
  reviewAnnotatorDetails,
  setUserAnnotationId,
  userAnnotationRemarks,
  setUserAnnotationRemarks,
  saveForLater,
  setSaveForLater,
  isReviewRoute,
  showClassesForReview,
  setShowClassesForReview,
  setReviewAnnotationIsAccepted,
  annotatedImagesForOneDay,
  goToSelectedImgFromAnnoImgs,
  allUsersAnnotationEqual,
  setSelectedColorForSubClass,
  selectedClasses,
  setSelectedClasses,
  setSelectedOption,
  selectedOption,
  initialAnnoImgDetails,
  initialAnnoDetails,
}: {
  initialAnnoDetails: any;
  datasetName: string;
  initialAnnoImgDetails: any;
  selectedOption: any;
  setSelectedOption: any;
  classifierPathId: any;
  selectedImgCount: number;
  setSelectedImgCount: (count: number) => void;
  onChange: (e: any) => void;
  selectedBucketId: string;
  radioBucketNames: any;
  onCheckboxChange: (e: any) => void;
  selectedRadioClass: any;
  selectedRadioSubClasses: any;
  setSelectedRadioClass: (classId: string) => void;
  setSelectedRadioSubClasses: (subClass: any) => void;
  selectedClasses: any;
  setSelectedClasses: (selectedClass: any) => void;
  handleSelectedSubClassCount: (
    subClassId: string,
    updatedSubClassCount: number
  ) => void;
  postAnnotation: () => void;
  images: object[];
  selectedDropdownClassifier: any;
  setSelectedDropdownClassifier: (classifier: object) => void;
  checkAnnoSaveAndNext: any;
  reviewAnnotatorDetails: any[];
  setUserAnnotationId: (id: string) => void;
  userAnnotationRemarks: string;
  setUserAnnotationRemarks: (remarks: string) => void;
  saveForLater: boolean;
  setSaveForLater: (checked: boolean) => void;
  isReviewRoute: any;
  showClassesForReview: any;
  setShowClassesForReview: (checked: any) => void;
  agreeAnnotatorImgStatus: () => void;
  reviewAnnotationIsAccepted: boolean;
  setReviewAnnotationIsAccepted: (checked: boolean) => void;
  annotatedImagesForOneDay: any[];
  goToSelectedImgFromAnnoImgs: (img: any) => void;
  allUsersAnnotationEqual: boolean;
  setSelectedColorForSubClass: React.Dispatch<React.SetStateAction<string>>;
}) {
  const userInfo: any = useSelector(userSelector);
  const classifiers = useSelector((state: any) => state.classifierInfo.value);
  const reviewUserIds: any = localStorage.getItem("reviewerUsersInfo")
    ? JSON.parse(localStorage.getItem("reviewerUsersInfo") as string)
    : [];
  const [selectAmongAnnotatedModalOpen, setSelectAmongAnnotatedModalOpen] =
    useState<boolean>(false);
  const [jumpToSelectedFromAnnotatedImgs, setJumpToSelectedFromAnnotatedImgs] =
    useState<any>({});
    
  useEffect(() => {
    classifiers?.map((classifier: any) => {
      if (classifier._id === classifierPathId) {
        setSelectedDropdownClassifier(classifier);
      }
    });
  }, []);

  const [mappedClasses, setMappedClasses] = useState<any>([]);

useEffect(() => {
  const result = mapClassesToRadioBucketNames(initialAnnoDetails, radioBucketNames);
  setMappedClasses(result);
}, [initialAnnoDetails, radioBucketNames]);
  
  const bucketMap = radioBucketNames.reduce(
    (acc: { [key: string]: string }, bucket: any) => {
        acc[bucket.id] = bucket.name;
        return acc;
    },
    {}
  );
  
  const theme = useTheme();
 // const [selectedClasses, setSelectedClasses] = React.useState<string[]>([]);
  // console.log(selectedClasses)
   console.log(initialAnnoDetails);
   console.log(radioBucketNames);
   function mapClassesToRadioBucketNames(
    initialAnnoDetails: any[], 
    radioBucketNames: any[]
) {
    if (!Array.isArray(initialAnnoDetails) || initialAnnoDetails.length === 0) {
        // Return an empty array if initialAnnoDetails is not an array or is empty
        return [];
    }

    return initialAnnoDetails.map((annotation: any) => {
        // Handle classesArray if it exists and is an array
        const mappedClassesArray = Array.isArray(annotation?.classesArray)
            ? annotation.classesArray.map((classId: any) => {
                const match = radioBucketNames.find((bucket: any) => bucket._id === classId);
                return match ? { id: match._id, name: match.name } : null;
            }).filter((item: any) => item !== null) // Filter out any null values if no match is found
            : [];

        // Handle class if it exists and is a string
        const mappedClass = typeof annotation?.class === 'string'
            ? (() => {
                const match = radioBucketNames.find((bucket: any) => bucket._id === annotation.class);
                return match ? { id: match._id, name: match.name } : null;
            })()
            : null;

        // Combine the results, include mappedClass only if it's not null
        return {
            mappedClasses: [...mappedClassesArray, ...(mappedClass ? [mappedClass] : [])]
        };
    });
}


// Usage
const result = mapClassesToRadioBucketNames(initialAnnoDetails, radioBucketNames);

// Logging the result to see the mapping
console.log(result);


  const handleChange = (event: SelectChangeEvent<typeof selectedClasses>) => {
    
      const {
        target: { value },
      } = event;
      setSelectedClasses(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
      
    };
    let classes :any= [
    ];
    let hashmap = new Map<string, string>();
    radioBucketNames.map((bucket: any) => {
      if(bucket.name !== "Ungraded"){
      hashmap.set(bucket._id, bucket.name);
          return classes.push(bucket);
      }
      }    
    );
const [agreeSection, setAgreeSection] = useState<boolean>(false);
const [disagreeSection, setDisagreeSection] = useState<boolean>(false);
const disAgreeOnClickHandler = () => {
  setDisagreeSection(true);
  setAgreeSection(false);
  setSelectedRadioClass("");
};
const agreeSectionHandler = () => {
  setAgreeSection(true);
  setDisagreeSection(false);
  setSelectedRadioClass(initialAnnoImgDetails[0].class);
  if(selectedClasses)
    setSelectedClasses([]);
}
const classiInform = JSON.parse(localStorage.getItem("classifierInformation") || "");
const hardCodeClassifierTypeValue = classiInform && classiInform[0]?.type;
  /** For review in review bucket (table) */
  const reviewTableHeaders = [
    {
      title: "Annotator",
      key: "annotator",
      width: "45%",
      render: (details: any) => {
        return <>{details.user.name}</>;
      },
    },
    {
      title: "Value",
      key: "value",
      align: "center" as const,
      width: "25%",
      render: (details: any) => {
        return (
          <>
            {details.annotation !== null ? (
              <>
                {details.annotation?.class.name}
                <Popover
                  content={
                    <>
                      {details.annotation?.subclasses.length > 0 ? (
                        details.annotation.subclasses.map(
                          (eachSubclass: any) => {
                            return (
                              <li>
                                {eachSubclass.id?.name} ({eachSubclass?.count})
                              </li>
                            );
                          }
                        )
                      ) : (
                        <p>No subclasses found</p>
                      )}
                    </>
                  }
                  placement="right"
                  title="More Details"
                >
                  <InfoCircleOutlined className={styles.tableCol_infoIcon} />
                </Popover>
              </>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
  ];

  /** For review in normal annotation mode (table) */
  const reviewTableHeadersForNormalReview = [
    ...reviewTableHeaders,
    {
      title: "Action",
      key: "action",
      align: "center" as const,
      width: "25%",
      render: (details: any) => {
        return (
          <>
            {!details.annotation ? (
              <Title
                level={5}
                className={styles.annotationReviewTable_agreeBtn}
                style={{
                  color: "grey",
                  textDecoration: "none",
                  cursor: "default",
                }}
              >
                Agree
              </Title>
            ) : (
              <Title
                level={5}
                className={styles.annotationReviewTable_agreeBtn}
                style={{ color: "#625fff" }}
                onClick={() => {
                  if (details.annotation) {
                    setSelectedRadioClass(details.annotation.class._id);
                    setSelectedRadioSubClasses(details.annotation.subclasses);
                  }
                  setReviewAnnotationIsAccepted(true);
                }}
              >
                Agree
              </Title>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      {userInfo.permission_level === 0 ? (
        <Title
          level={4}
          className={
            styles.images_radioBucketHeading +
            " " +
            styles.images_datasetHeading
          }
        >
          Viewing {datasetName}
        </Title>
      ) : (
        <div className={styles.images_createAnnotationDiv}>
          {annotatedImagesForOneDay.length > 0 && (
            <Title
              level={5}
              className={styles.searchImg_filterHeading}
              onClick={() => setSelectAmongAnnotatedModalOpen(true)}
            >
              Your Annotated Images
            </Title>
          )}

          <Modal
            title="Select Among Annotated Images"
            visible={selectAmongAnnotatedModalOpen}
            onCancel={() => setSelectAmongAnnotatedModalOpen(false)}
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={() => {
                  goToSelectedImgFromAnnoImgs(jumpToSelectedFromAnnotatedImgs);
                  setSelectAmongAnnotatedModalOpen(false);
                }}
              >
                Search
              </Button>,
            ]}
          >
            <AntSelect
              style={{ width: 150 }}
              className={styles.searchImg_filter}
              value={jumpToSelectedFromAnnotatedImgs?.data?.imgName}
              onChange={(nameOfImg: string) => {
                annotatedImagesForOneDay.length &&
                  annotatedImagesForOneDay?.forEach((img: any) => {
                    if (img.data?.imgName === nameOfImg) {
                      setJumpToSelectedFromAnnotatedImgs(img);
                    }
                  });
              }}
            >
              {annotatedImagesForOneDay.length ? (
                annotatedImagesForOneDay?.map((img: any) => (
                  <Option key={img.data._id} value={img.data?.imgName}>
                    {img.data?.imgName}
                  </Option>
                ))
              ) : (
                <Option value="disabled" disabled>
                  Annotate some images first
                </Option>
              )}
            </AntSelect>
          </Modal>

          {/* Dataset Name */}
          <Title
            level={4}
            className={
              styles.images_radioBucketHeading +
              " " +
              styles.images_datasetHeading
            }
          >
            {datasetName}
          </Title>
          {/* Reviewer Table part */}
          {(userInfo.permission_level === 2 ||
            (userInfo.permission_level >= 3 && isReviewRoute) ||
            (reviewUserIds && reviewUserIds.length > 0)) && (
            <div className={styles.images_reviewTableDiv}>
              <Table
                dataSource={reviewAnnotatorDetails && reviewAnnotatorDetails}
                columns={
                  isReviewRoute
                    ? reviewTableHeaders
                    : reviewTableHeadersForNormalReview
                }
                className={styles.images_reviewTable}
                size="middle"
                scroll={{ x: 250 }}
                pagination={{ pageSize: 3 }}
              />
            </div>
          )}
          {/* Review part */}
          <Title level={4} className={styles.images_radioBucketHeading}>
            Your Review
          </Title>

          {/* Agree or Disagree (Review) */}
          {hardCodeClassifierTypeValue === 1 ? (
            <div>
              <div style={{display:"flex", justifyContent:"center"}}>
                <Radio.Group
                  onChange={(e) => {
                    setSelectedOption(e.target.value);
                    if (e.target.value === "agree") {
                      agreeSectionHandler();
                    } else if (e.target.value === "disagree") {
                      disAgreeOnClickHandler();
                    }
                  }}
                  value={selectedOption}
                >
                  <Radio value="agree">Agree</Radio>
                  <Radio value="disagree">Disagree</Radio>
                </Radio.Group>
              </div>

              {disagreeSection && (
                <div style={{display:"flex", justifyContent:"center"}}>
                  <FormControl sx={{ m: 1, width: 350 }}>
                    <InputLabel id="demo-multiple-chip-label">Class</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      value={selectedClasses}
                      onChange={handleChange}
                      input={
                        <OutlinedInput id="select-multiple-chip" label="Chip" />
                      }
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map(
                            (value:any) => (
                              (<Chip key={value} label={hashmap.get(value)} />)
                            )
                          )}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {classes.map((clas: any) => (
                        <MenuItem
                          key={clas.name}
                          value={clas._id}
                          style={getStyles(clas.name, selectedClasses, theme)}
                        >
                          {clas.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
            </div>
          ) : (
            <div>
              <div className="">
                {" "}
                {(userInfo.permission_level === 2 || isReviewRoute) && (
                  // reviewAnnotatorDetails.length > 0 &&
                  // reviewAnnotatorDetails[0]?.annotation?._id &&
                  <div className={styles.images_annotationSaveBtnDiv}>
                    <Button
                      disabled={
                        allUsersAnnotationEqual
                          ? false
                          : isReviewRoute && selectedRadioClass
                          ? false
                          : true
                      }
                      className={styles.images_imgControlBtns}
                      style={{
                        backgroundColor: allUsersAnnotationEqual
                          ? "#625fff"
                          : isReviewRoute && selectedRadioClass && "#625fff",
                        color: allUsersAnnotationEqual
                          ? "#fff"
                          : isReviewRoute && selectedRadioClass && "#fff",
                      }}
                      onClick={() => {
                        setReviewAnnotationIsAccepted(true);
                        // agreeAnnotatorImgStatus();
                        // postAnnotation();
                      }}
                    >
                      Agree
                    </Button>
                    <Button
                      // disabled={!selectedRadioClass && true}
                      className={styles.images_imgControlBtns}
                      style={{
                        backgroundColor: "#6254FF",
                        color: "#fff",
                      }}
                      onClick={() => setShowClassesForReview(true)}
                    >
                      Disagree
                    </Button>
                  </div>
                )}
                {/* Classes */}
                {showClassesForReview &&
                  (!selectedRadioClass ? (
                    <>
                      <Radio.Group
                        onChange={onChange}
                        value={selectedRadioClass}
                        style={{ marginLeft: "1.5rem" }}
                      >
                        {radioBucketNames.map((bucket: any) => {
                          if (bucket.name !== "Ungraded") {
                            return (
                              <Radio
                                value={bucket._id}
                                className={styles.images_radioBucketNames}
                              >
                                {bucket.name}
                              </Radio>
                            );
                          }
                        })}
                      </Radio.Group>
                    </>
                  ) : (
                    <>
                      {radioBucketNames.map((bucket: any) => {
                        if (
                          selectedRadioClass === bucket._id &&
                          bucket.subclasses.length > 0
                        ) {
                          return (
                            <AntSelect
                              defaultValue={bucket.name}
                              style={{ width: 120 }}
                              className={styles.images_classesFilter}
                              onChange={(val: string) => {
                                setSelectedRadioSubClasses([]);
                                setSelectedRadioClass(val);
                              }}
                            >
                              {radioBucketNames.map((eachBucket: any) => {
                                if (eachBucket.name !== "Ungraded") {
                                  return (
                                    <Option
                                      key={eachBucket._id}
                                      value={eachBucket._id}
                                    >
                                      {eachBucket.name}
                                    </Option>
                                  );
                                }
                              })}
                            </AntSelect>
                          );
                        }
                        if (
                          selectedRadioClass === bucket._id &&
                          bucket.subclasses.length === 0
                        ) {
                          return (
                            <>
                              <Radio.Group
                                onChange={onChange}
                                value={selectedRadioClass}
                                style={{
                                  marginLeft: "1.5rem",
                                }}
                              >
                                {radioBucketNames.map((bucket: any) => {
                                  if (bucket.name !== "Ungraded") {
                                    return (
                                      <Radio
                                        value={bucket._id}
                                        className={
                                          styles.images_radioBucketNames
                                        }
                                      >
                                        {bucket.name}
                                      </Radio>
                                    );
                                  }
                                })}
                              </Radio.Group>
                            </>
                          );
                        }
                      })}
                    </>
                  ))}
                {/* SubClasses */}
                {showClassesForReview && (
                  <div className={styles.images_allCheckboxSubClassesDiv}>
                    {radioBucketNames.map((bucket: any) => {
                      if (bucket.name !== "Ungraded") {
                        return (
                          <div className={styles.images_checkboxSubClassesDiv}>
                            {bucket._id === selectedRadioClass &&
                            bucket.subclasses.length
                              ? bucket.subclasses.map((subclass: any) => {
                                  return (
                                    <div
                                      className={
                                        styles.images_radioBucketSubclasses
                                      }
                                    >
                                      <Checkbox
                                        checked={
                                          selectedRadioSubClasses.length
                                            ? selectedRadioSubClasses.some(
                                                (radioSubClass: any) =>
                                                  radioSubClass.id ===
                                                  subclass._id
                                              )
                                            : false
                                        }
                                        onChange={() => {
                                          onCheckboxChange(subclass._id);
                                          setSelectedColorForSubClass(
                                            subclass.colour
                                          );

                                          const toolbarButtons =
                                            document.querySelectorAll(
                                              '[class*="_toolbar_button"]'
                                            );
                                          if (toolbarButtons.length > 0) {
                                            (
                                              toolbarButtons[4] as HTMLDivElement
                                            ).click();
                                          }
                                        }}
                                      >
                                        <MdLabel
                                          style={{
                                            color: subclass.colour
                                              ? subclass.colour
                                              : "#808080",
                                          }}
                                          className={
                                            styles.images_radioBucketSubclasses_label
                                          }
                                        />
                                        <span
                                          className={
                                            styles.images_radioBucketSubclassesName
                                          }
                                        >
                                          {subclass?.name}
                                        </span>
                                        {selectedRadioSubClasses.map(
                                          (radioSubClass: any) => {
                                            return radioSubClass.id ===
                                              subclass._id ? (
                                              <>
                                                <button
                                                  style={{
                                                    marginLeft: "0.5rem",
                                                  }}
                                                  onClick={() =>
                                                    handleSelectedSubClassCount(
                                                      radioSubClass.id,
                                                      radioSubClass.count > 1
                                                        ? radioSubClass.count -
                                                            1
                                                        : radioSubClass.count
                                                    )
                                                  }
                                                >
                                                  -
                                                </button>
                                                <Title
                                                  level={5}
                                                  className={
                                                    styles.images_radioSubClassCount
                                                  }
                                                >
                                                  {radioSubClass.count}
                                                </Title>
                                                <button
                                                  onClick={() =>
                                                    handleSelectedSubClassCount(
                                                      radioSubClass.id,
                                                      radioSubClass.count + 1
                                                    )
                                                  }
                                                >
                                                  +
                                                </button>
                                              </>
                                            ) : (
                                              ""
                                            );
                                          }
                                        )}
                                      </Checkbox>
                                    </div>
                                  );
                                })
                              : ""}
                          </div>
                        );
                      }
                    })}
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Comment Box */}
          {showClassesForReview && (
            <TextArea
              value={userAnnotationRemarks}
              onChange={(e: any) => setUserAnnotationRemarks(e.target.value)}
              className={styles.images_commentBoxInput}
              placeholder="Any comments?"
              autoSize={{ minRows: 2, maxRows: 3 }}
            />
          )}

          {!isReviewRoute && (
            <Checkbox
              checked={saveForLater}
              onChange={(e: CheckboxChangeEvent) => {
                setSaveForLater(e.target.checked);
              }}
              className={styles.images_saveForLaterCheckbox}
            >
              Save for later (Doubt)
            </Checkbox>
          )}

          {showClassesForReview && radioBucketNames.length ? (
            <div className={styles.images_annotationSaveBtnDiv}>
              <Button
                disabled={ !agreeSection && (  !selectedRadioClass && (!selectedClasses || selectedClasses.length === 0))}
                className={styles.images_imgControlBtns}
                style={{
                  backgroundColor: (agreeSection || (selectedRadioClass || (selectedClasses && selectedClasses.length > 0))) ? "#6254FF" : undefined,
                  color: (agreeSection ||  (selectedRadioClass || (selectedClasses && selectedClasses.length > 0))) ? "#fff" : undefined,
                }}
                onClick={() => {
                  postAnnotation();
                }}
              >
                Save
              </Button>
              {selectedImgCount !== images.length - 1 ? (
                selectedRadioClass ? (
                  <>
                    <Button
                      className={styles.images_imgControlSaveBtns}
                      onClick={() => {
                        postAnnotation();
                        selectedBucketId === selectedRadioClass
                          ? setSelectedImgCount(selectedImgCount + 1)
                          : setSelectedImgCount(selectedImgCount);
                        // setSelectedImgCount(selectedImgCount + 1);
                        setUserAnnotationId("");
                      }}
                    >
                      Save & Next
                    </Button>
                    <Link to="/">
                      <Button
                        className={styles.images_imgControlSaveBtns}
                        style={{
                          backgroundColor: selectedRadioClass && "#6254FF",
                          color: selectedRadioClass && "#fff",
                        }}
                        onClick={() => {
                          postAnnotation()
                          }
                        }
                      >
                        Save & Exit
                      </Button>
                    </Link>
                  </>
                ) : (
                  <Button
                    className={styles.images_imgControlSaveBtns}
                    onClick={() => {
                      setSelectedImgCount(selectedImgCount + 1);
                      if(selectedClasses)
                        setSelectedClasses([])
                      // setNewRegionalAnnotatedImg(null)
                    }}
                  >
                    Next
                  </Button>
                )
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          <Toaster
            toastOptions={{
              success: {
                style: {
                  border: "1px solid #199c3c",
                },
              },
            }}
          />
        </div>
      )}
      {hardCodeClassifierTypeValue === 1 && mappedClasses.length > 0 && mappedClasses[0].mappedClasses.length > 0 && (
  <div style={{ display: "flex", flexDirection: "column", alignItems: "center",margin:"5%" }}>
    <h4>Your Annotation</h4>
    <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
      {mappedClasses[0].mappedClasses.map((mappedClass: any) => (
        <Chip key={mappedClass.id} label={mappedClass.name} />
      ))}
    </div>
  </div>
)}

    </>
  );
}

export default CreateAnnotation;