import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { timestampToDate } from "utils/functions";
import { getJobsDataApi, startJobApi } from "api/uploadAndJobsApi";

interface JobsTableType {
  key: string;
  _id: string;
  jobName: string;
  datasetName: string;
  classifierName: string;
  createdAt: string;
  percentage: number;
  status: string;
}

export const JobsLogic = () => {
  let jobsDataKeyCount = 0;
  let jobsTableInfo: JobsTableType[] = [];
  const [jobsData, setJobsData] = useState<{
    loading: boolean;
    error: boolean;
    data: any[];
  }>({
    loading: false,
    error: false,
    data: [],
  });
  const [jobsFetchErrMsg, setJobsFetchErrMsg] = useState<string>("");
  const [totalAnnotationCount, setTotalAnnotationCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(10);

  /** Fetch jobsData */
  async function getJobsData() {
    setJobsFetchErrMsg("");
    setJobsData({ ...jobsData, loading: true });
    try {
      const res = await getJobsDataApi({
        pageCount,
        pageLimit,
      });

      setJobsData({ ...jobsData, loading: false, data: res.jobs });
      setTotalAnnotationCount(res.totalObjects);
    } catch (error: any) {
      setJobsData({ ...jobsData, loading: false, error: true });
      setJobsFetchErrMsg(error.response.data.message);
    }
  }

  useEffect(() => {
    getJobsData();
  }, [pageCount, pageLimit]);

  /** Add jobsData in table interface */
  function addDatasetsInTable() {
    jobsTableInfo = [];

    jobsData.data.length > 0 &&
      jobsData.data.forEach((eachJob: any) => {
        //
        jobsTableInfo.push({
          key: jobsDataKeyCount.toString(),
          _id: eachJob._id,
          jobName: eachJob?.name,
          datasetName: eachJob.dataset_id?.name,
          classifierName: eachJob.classifier_id?.pathology,
          createdAt: timestampToDate(eachJob.createdAt),
          percentage: eachJob?.percentage,
          status: eachJob.status,
        });
        jobsDataKeyCount += 1;
      });
  }
  addDatasetsInTable();

  /** Post start Job */
  const handleJobStart = async (jobId: string) => {
    try {
      const res = await startJobApi([jobId]);

      toast.success("Job started successfully");
      getJobsData();
    } catch (error: any) {
      toast.error(error.response?.data.message);
    }
  };

  return {
    totalAnnotationCount,
    pageCount,
    setPageCount,
    pageLimit,
    setPageLimit,
    jobsData,
    jobsTableInfo,
    jobsFetchErrMsg,
    handleJobStart,
  };
};
