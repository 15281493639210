import { useState, useEffect } from "react";
import {
  getClassifierById,
  updateClass,
} from "api/classifierApi";
import toast from "react-hot-toast";

export const UpdateModalLogic = ({
  item,
  setSelectedItemForUpdate,
  visibleUpdateModal,
  setVisibleUpdateModal,
  setClassesWithSubClass,
}: {
  item: any;
  setSelectedItemForUpdate: any;
  visibleUpdateModal: boolean;
  setVisibleUpdateModal: (visibleUpdateModal: boolean) => void;
  setClassesWithSubClass: (val: any) => void;
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [newUpdatedClass, setnewUpdatedClass] = useState({
    name: "",
    key: "",
    description: "",
  });

  /** Adding/Updating selectedCat val */
  useEffect(() => {
    setnewUpdatedClass({
      name: item?.name,
      key: item?.key,
      description: item?.description,
    });
  }, [item]);

  /** onChange Updation of class details */
  const handleInputChange = (e: any, key: string) => {
    setnewUpdatedClass({
      ...newUpdatedClass,
      [key]: key === "key" ? e : e.target.value,
    });
  };

  /** Submit updated class details */
  async function handleOk() {
    try {
      setConfirmLoading(true);
      await updateClass(item._id, newUpdatedClass);
      setConfirmLoading(false);
      setVisibleUpdateModal(false);
      // setSelectedItemForUpdate(newUpdatedClass);
      toast.success("Class updated successfully");

      const res = await getClassifierById(item.classifier_id);
      setClassesWithSubClass(res.classes);
    } catch (error) {
      setConfirmLoading(false);
      toast.error("Error updating class");
    }
  }

  // Close modal
  const handleCancel = () => {
    setVisibleUpdateModal(false);
    setSelectedItemForUpdate(null);
  };

  return {
    visibleUpdateModal,
    handleOk,
    confirmLoading,
    handleCancel,
    newUpdatedClass,
    handleInputChange,
  };
};
