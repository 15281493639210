import { Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsAuthenticated, userSelector } from "store/userSlice";
import parseJwt from "./parseJWT";
import { getInfoFromRefreshToken } from "api/usersApi";
import toast from "react-hot-toast";

export function RequireAuth({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const authToken = localStorage.getItem("authToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const isAuthenticated = useSelector(userSelector).isAuthenticated;

  if (!isAuthenticated && !authToken) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  const decodedJwt = parseJwt(authToken!);

  let newAuthToken = "";

  async function getNewAuthToken() {
    if (decodedJwt.exp * 1000 < Date.now()) {
      try {
        const res = await getInfoFromRefreshToken(refreshToken!);

        localStorage.setItem("authToken", res.authToken);
        localStorage.setItem("refreshToken", res.refToken);

        newAuthToken = localStorage.getItem("authToken")!;

        if (parseJwt(newAuthToken).exp * 1000 > Date.now()) {
          dispatch(setIsAuthenticated(true));
        }
      } catch (err) {
        toast.error("Pls login again");
      }
    }
  }

  getNewAuthToken();
  return children;
}

export function ReverseAuth({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const isAuthenticated = useSelector(userSelector).isAuthenticated;

  if (isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} />;
  }
  return children;
}
