import { getAnnotatedHistoryDetails } from "api/annotationData";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { userSelector } from "store/userSlice";
import { timestampToDate } from "utils/functions";

interface DatasetType {
  key: string;
  _id: string;
  imageName: string;
  datasetName: string;
  datasetDescription: string;
  cameraType_name: string;
  classifier: string;
  imagesCount: number;
  updatedAt: string;
  selectedClass: string;
  selectedSubClasses: any[];
  selectedClasses: any[];
  identifier: string;
  imgKey: string;
}

export const AnnotationHistoryLogic = () => {
  let dataKeyCount = 0;
  let datasetTableInfo: DatasetType[] = [];
  const userInfo = useSelector(userSelector);

  const [totalAnnotationCount, setTotalAnnotationCount] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searched, setSearched] = useState<boolean>(false);
  const [datasets, setDatasets] = useState<{
    loading: boolean;
    error: boolean;
    data: any[];
  }>({
    loading: false,
    error: false,
    data: [],
  });
  const [pageCount, setPageCount] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(10);

  /** Fetch datasets depending on pageCOunt, limit & searched */
  useEffect(() => {
    setDatasets({
      loading: true,
      error: false,
      data: [],
    });
    try {
      const fetchDatasets = async () => {
        const res = await getAnnotatedHistoryDetails({
          pageCount,
          pageLimit,
          searchTerm,
        });
        setTotalAnnotationCount(res.count);
        setDatasets({
          loading: false,
          error: false,
          data: res.annotations,
        });
      };
      fetchDatasets();
    } catch (error) {
      setDatasets({
        loading: false,
        error: true,
        data: [],
      });
    }
  }, [pageCount, pageLimit, searched]);

  /** Putting datasets in the interface form for ease of showing in table */
  function addDatasetsInTable() {
    datasetTableInfo = [];

    datasets.data.length &&
      datasets.data.forEach((eachDataset: any) => {
        datasetTableInfo.push({
          key: dataKeyCount.toString(),
          imageName: eachDataset.image?.metadata.filename,
          _id: eachDataset.dataset_id._id,
          datasetName: eachDataset.dataset_id.name,
          datasetDescription: eachDataset.dataset_id.description,
          cameraType_name: eachDataset.dataset_id.cameraType_name,
          classifier: eachDataset?.classifier_id?.pathology,
          imagesCount: eachDataset.imagesCount,
          updatedAt: timestampToDate(eachDataset.updatedAt),
          selectedClass: eachDataset?.class?.name,
          selectedSubClasses: eachDataset?.subclasses,
          selectedClasses: eachDataset?.classesArray,
          identifier: eachDataset.dataset_id.identifier,
          imgKey: eachDataset.image?.key,
        });
        dataKeyCount += 1;
      });
  }
  addDatasetsInTable();

  return {
    userInfo,
    datasets,
    setSearchTerm,
    datasetTableInfo,
    pageCount,
    setPageCount,
    pageLimit,
    setPageLimit,
    totalAnnotationCount,
    searched,
    setSearched,
  };
};
