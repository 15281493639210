import { notification } from "antd";
import api, { authHeader } from "./index";

export async function getAllNotifications() {
  const response = await api.get("/notifications", authHeader());
  return response.data?.result;
}

export async function markNotificationAsReadApi(notificationId: string) {
  const response = await api.post(
    "/notifications/read",
    {
      notification_id: notificationId,
    },
    authHeader()
  );
  return response.data?.result;
}

export async function markAllAsReadApi() {
  const response = await api.get("/notifications/read/all", authHeader());
  return response.data?.result;
}
