export const toTitleCase = (text: string, sep?: string) => {
  return text
    ?.split(sep ?? "_")
    .map((substr) => substr.charAt(0).toUpperCase() + substr.substring(1))
    .join(" ");
};

export const sortBucketsByClassKey = (buckets: object[]) => {
  return buckets.sort(function (a: any, b: any) {
    return parseFloat(a.class.key) - parseFloat(b.class.key);
  });
};

export const sortBucketsByKey = (buckets: object[]) => {
  return buckets.sort(function (a: any, b: any) {
    return parseFloat(a.key) - parseFloat(b.key);
  });
};

// Count change to kFormat
export const numFormatterInK = (num: number) => {
  return num > 999 ? num.toString().slice(0, -3) + "k" : num.toString();
};

// Change timestamp to human date and time format
export const timestampToDate = (timestamp: number) => {
  return (
    new Date(timestamp).toLocaleDateString() +
    " " +
    new Date(timestamp).toLocaleTimeString()
  );
};
