import { getAllCategories } from "api/categoryApi";
import { createClassifier } from "api/classifierApi";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

export const CreateClassifierLogic = (form: any) => {
  const [allCategoryData, setAllCategoryData] = useState<any>([]);

  /** Fetching categories & classifiers */
  useEffect(() => {
    async function getCategories() {
      const res = await getAllCategories();
      setAllCategoryData(res);
    }
    getCategories();
  }, []);

  /** Handling submit */
  async function onFinish(values: any) {
    try {

      form.resetFields();
      toast.success("Classifier created successfully");
    } catch (error) {
      toast.error("Not able to create dataset at this moment");
    }
  }

  const onFinishFailed = () => {};

  return {
    onFinish,
    onFinishFailed,
    allCategoryData,
  };
};
