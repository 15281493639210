import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { userSelector } from "store/userSlice";
import toast, { Toaster } from "react-hot-toast";
import {
  Typography,
  Row,
  Col,
  Input,
  Button,
  Space,
  Table,
  Grid,
  Spin,
  Popover,
  Breadcrumb,
  Modal,
  Select,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import styles from "./ViewAllReviewBuckets.module.scss";
import { ViewAllReviewBucketsLogic } from "./ViewAllReviewBucketsLogic";

// Icons
import { LoadingOutlined } from "@ant-design/icons";
import { IoIosSearch } from "react-icons/io";
import { BsImages } from "react-icons/bs";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Title } = Typography;
const { Option } = Select;
const { useBreakpoint } = Grid;

function ViewAllReviewBuckets() {
  const {
    datasets,
    datasetTableInfo,
    openAssignModal,
    setOpenAssignModal,
    confirmReviewAssignLoading,
    handleOk,
    searchTerm,
    setSearchTerm,
    allReviewersList,
    setAllReviewersList,
    setAssignedReviewerIds,
    handleAssignReviewerModal,
    openReassignModal,
    setOpenReassignModal,
    confirmReviewReassignLoading,
    setConfirmReviewReassignLoading,
    existingAnnotatorsList,
    setExistingAnnotatorsList,
    handleReassignAnnotators,
    handleReassignAnnotatorModal,
  }: {
    datasets: {
      loading: boolean;
      error: boolean;
      data: any[];
    };
    datasetTableInfo: any[];
    openAssignModal: boolean;
    setOpenAssignModal: (openAssignModal: boolean) => void;
    confirmReviewAssignLoading: boolean;
    handleOk: () => void;
    searchTerm: string;
    setSearchTerm: (term: string) => void;
    allReviewersList: {
      loading: boolean;
      error: boolean;
      data: any[];
    };
    setAllReviewersList: (list: any) => void;
    setAssignedReviewerIds: (reviewerIds: string[]) => void;
    handleAssignReviewerModal: (
      datasetId: string,
      reviewBucketId: string
    ) => void;
    openReassignModal: boolean;
    setOpenReassignModal: (openReassignModal: boolean) => void;
    confirmReviewReassignLoading: boolean;
    setConfirmReviewReassignLoading: (
      confirmReviewReassignLoading: boolean
    ) => void;
    existingAnnotatorsList: {
      loading: boolean;
      error: boolean;
      data: any[];
    };
    setExistingAnnotatorsList: (list: any) => void;
    handleReassignAnnotators: () => void;
    handleReassignAnnotatorModal: (
      datasetId: string,
      reviewBucketId: string
    ) => void;
  } = ViewAllReviewBucketsLogic();

  const { md } = useBreakpoint();
  const userInfo = useSelector(userSelector);

  const normalUserColumns: ColumnsType<any> = [
    {
      title: "Bucket Name",
      key: "bucketName",
      render: (details: any) => {
        return (
          <>
            <Popover content={<>{details?.bucketName}</>} placement="right">
              {details?.bucketName &&
                details.bucketName.substring(0, 15) + "..."}
            </Popover>
          </>
        );
      },
    },
    {
      title: "Dataset",
      key: "dataset",
      render: (details) => {
        return (
          <>
            {details?.dataset}
            <Popover
              content={details?.description}
              placement="right"
              title="Description"
            >
              <InfoCircleOutlined
                className={styles.datasetsTableCol_infoIcon}
              />
            </Popover>
          </>
        );
      },
    },
    {
      title: "Classifier",
      key: "classifier",
      dataIndex: "classifier",
    },

    {
      title: "Annotation Type",
      key: "type",
      dataIndex: "annotationType",
    },
    {
      title: "More Details",
      key: "moreDetails",
      render: (details) => {
        return (
          <>
            <Popover
              trigger="click"
              content={
                <>
                  <Title
                    level={5}
                    className={styles.reviewBucketTable_detailsPopover}
                  >
                    Image Count : <span>{details?.imagesCount}</span>
                  </Title>
                  {details.annotationType === "user_annotation" && (
                    <Title
                      level={5}
                      className={styles.reviewBucketTable_detailsPopover}
                    >
                      Annotator : <span>{details?.annotator}</span>
                    </Title>
                  )}

                  <Title
                    level={5}
                    className={styles.reviewBucketTable_detailsPopover}
                  >
                    Assigned reviewers
                  </Title>
                  <div
                    className={styles.reviewBucketTable_detailsPopoverReviewers}
                  >
                    {details.alreadyAssignedReviewers.length > 0 &&
                      details.alreadyAssignedReviewers.map((reviewer: any) => {
                        return (
                          <Title
                            level={5}
                            className={styles.reviewBucketTable_detailsPopover}
                          >
                            <span>
                              {reviewer.name + " (" + reviewer.email + ")"}
                            </span>
                          </Title>
                        );
                      })}
                  </div>
                </>
              }
              placement="left"
              title="More Details"
            >
              <span style={{ cursor: "pointer", color: "#1890ff" }}>View</span>
            </Popover>
          </>
        );
      },
    },
    {
      title: "Analytics",
      // key: "analytics",
      align: "center" as const,
      render: (details) => {
        return (
          <>
            <Button
              className={styles.datasets_browseBtn}
              // onClick={() => }
            >
              Download
            </Button>
          </>
        );
      },
    },
    {
      title: "Actions",
      key: "action",
      render: (details) => {
        return (
          <Space size="middle">
            <Link
              to={
                userInfo.permission_level >= 3
                  ? `/admin/review-bucket/${details._id}/${details.classifierId}`
                  : `/review-bucket/${details._id}/${details.classifierId}`
              }
            >
              <Button
                className={styles.datasets_browseBtn}
                onClick={() => {
                  details.datasetId
                    ? localStorage.setItem(
                        "datasetIdWhenReview",
                        JSON.stringify([details.datasetId])
                      )
                    : localStorage.removeItem("datasetIdWhenReview");

                  details.annotatorId
                    ? localStorage.setItem(
                        "reviewerUsersInfo",
                        JSON.stringify([details.annotatorId])
                      )
                    : localStorage.removeItem("reviewerUsersInfo");
                  details.annotator !== ""
                    ? localStorage.setItem(
                        "reviewerViewAnnotatorName",
                        JSON.stringify(details.annotator)
                      )
                    : localStorage.removeItem("reviewerViewAnnotatorName");
                }}
              >
                Browse
              </Button>
            </Link>
            {userInfo.permission_level >= 3 && (
              <>
                <Button
                  className={styles.datasets_browseBtn}
                  onClick={() => {
                    handleAssignReviewerModal(details.datasetId, details._id);
                  }}
                >
                  Assign
                </Button>
                <Modal
                  title="Assign Reviewers to Review Bucket"
                  visible={openAssignModal}
                  onOk={() => handleOk()}
                  confirmLoading={confirmReviewAssignLoading}
                  onCancel={() => {
                    setOpenAssignModal(false);
                    setAllReviewersList({
                      loading: false,
                      error: false,
                      data: [],
                    });
                  }}
                >
                  {allReviewersList.loading ? (
                    <div
                      className={styles.loadingDiv}
                      style={{ height: "10rem" }}
                    >
                      <Spin
                        size="large"
                        indicator={
                          <LoadingOutlined
                            className={styles.loadingSpinIcon}
                            spin
                          />
                        }
                        tip="Loading..."
                        className={styles.loadingDiv_spin}
                      />
                    </div>
                  ) : allReviewersList.error ? (
                    <div className={styles.errorDiv}>
                      <Title level={4} className={styles.buckets_errorMsg}>
                        {/* {reviewersListErrMsg} */}
                      </Title>
                    </div>
                  ) : allReviewersList.data.length > 0 ? (
                    <>
                      <Select
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Select reviewers"
                        onChange={(e) => setAssignedReviewerIds(e)}
                      >
                        {allReviewersList.data.map((eachReviewer) => {
                          return (
                            <Option value={eachReviewer._id}>
                              {eachReviewer.email}
                            </Option>
                          );
                        })}
                      </Select>
                    </>
                  ) : (
                    <>No other Reviewers found</>
                  )}
                </Modal>
              </>
            )}

            {/* Reassign */}
            <>
              {/* <Button
                                className={styles.datasets_browseBtn}
                                onClick={() => {
                                    handleReassignAnnotatorModal(
                                        details.datasetId,
                                        details._id
                                    );
                                }}
                            >
                                Reassign
                            </Button> */}
              <Modal
                title="Reassign existing annotators"
                visible={openReassignModal}
                onOk={() => handleReassignAnnotators()}
                confirmLoading={confirmReviewReassignLoading}
                onCancel={() => {
                  setOpenReassignModal(false);
                  setExistingAnnotatorsList({
                    loading: false,
                    error: false,
                    data: [],
                  });
                }}
              >
                {existingAnnotatorsList.loading ? (
                  <div
                    className={styles.loadingDiv}
                    style={{ height: "10rem" }}
                  >
                    <Spin
                      size="large"
                      indicator={
                        <LoadingOutlined
                          className={styles.loadingSpinIcon}
                          spin
                        />
                      }
                      tip="Loading..."
                      className={styles.loadingDiv_spin}
                    />
                  </div>
                ) : existingAnnotatorsList.error ? (
                  <div className={styles.errorDiv}>
                    <Title level={4} className={styles.buckets_errorMsg}>
                      {/* {reviewersListErrMsg} */}
                    </Title>
                  </div>
                ) : existingAnnotatorsList.data.length > 0 ? (
                  <>
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Select annotators"
                      onChange={(e) => setAssignedReviewerIds(e)}
                    >
                      {existingAnnotatorsList.data.map((eachAnnotator) => {
                        return (
                          <Option value={eachAnnotator._id}>
                            {eachAnnotator.email}
                          </Option>
                        );
                      })}
                    </Select>
                  </>
                ) : (
                  <>No other Annotators found</>
                )}
              </Modal>
            </>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      {datasetTableInfo.length > 0 && (
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <span>Home</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {/* <span onClick={() => navigate(-1)}>
              {datasetTableInfo.length > 0 &&
                datasetTableInfo[0]?.cameraType_name}
            </span> */}
          </Breadcrumb.Item>
          <Breadcrumb.Item>My reviews</Breadcrumb.Item>
        </Breadcrumb>
      )}

      <Row className={styles.datasets_headingRow}>
        <Col md={12}>
          <Title level={2} className={styles.datasets_heading}>
            My Reviews
          </Title>
          {/* <Title level={5} className={styles.datasets_description}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tem.
          </Title> */}
        </Col>
        <Col md={12} xs={24}>
          <Input
            size="large"
            placeholder="Search..."
            prefix={<IoIosSearch className={styles.datasets_searchBoxIcon} />}
            className={styles.datasets_searchBox}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Col>
      </Row>

      <div className={styles.datasets_tableRow}>
        {datasets.loading ? (
          <div className={styles.loadingDiv}>
            <Spin
              size="large"
              indicator={
                <LoadingOutlined className={styles.loadingSpinIcon} spin />
              }
              tip="Fetching Review Buckets..."
              className={styles.loadingDiv_spin}
            />
          </div>
        ) : md ? (
          <Table
            columns={normalUserColumns}
            dataSource={datasetTableInfo}
            className={styles.table}
            scroll={{ x: 1000 }}
          />
        ) : !datasets.error ? (
          <Row className={styles.categories_row}>
            {datasetTableInfo.length ? (
              datasetTableInfo.map((eachDataset) => {
                return (
                  <Col
                    lg={8}
                    md={12}
                    xs={24}
                    className={styles.categories_col}
                    key={eachDataset._id}
                  >
                    <div className={styles.categories_card}>
                      <Title level={4} className={styles.categoriesCard_type}>
                        {eachDataset.bucketName}
                        <Popover
                          content={eachDataset.description}
                          placement="bottom"
                          title="Description"
                        >
                          <InfoCircleOutlined
                            className={styles.cameras_infoIcon}
                          />
                        </Popover>
                      </Title>
                      <Title level={4} className={styles.categoriesCard_name}>
                        {eachDataset.annotationType}
                      </Title>
                      <Row className={styles.categoriesCard_dbAndImgsRow}>
                        <Col span={16}>
                          <Title
                            level={4}
                            className={styles.categoriesCard_dbAndImgs}
                          >
                            <BsImages />
                            <span>{eachDataset.imagesCount}</span>
                          </Title>
                        </Col>
                      </Row>
                      <Link
                        to={`/admin/review-bucket/${eachDataset._id}/${eachDataset.classifierId}`}
                      >
                        <Button
                          className={styles.categoriesCard_btn}
                          style={{
                            margin: "1rem 0 0 auto",
                          }}
                          onClick={() => {
                            localStorage.removeItem("reviewerUsersInfo");
                            localStorage.removeItem(
                              "reviewerViewAnnotatorName"
                            );
                          }}
                        >
                          Browse Images
                        </Button>
                      </Link>
                    </div>
                  </Col>
                );
              })
            ) : (
              <div className={styles.loadingDiv}>
                <p>No Datasets Found</p>
              </div>
            )}
          </Row>
        ) : (
          <Title level={4} className={styles.datasets_errMsg}>
            Some error occured
          </Title>
        )}
      </div>
      <Toaster
        toastOptions={{
          success: {
            style: {
              border: "1px solid #199c3c",
            },
          },
          error: {
            style: {
              border: "1px solid #FF4C4C",
            },
          },
        }}
      />
    </>
  );
}

export default ViewAllReviewBuckets;
