/**************************************** 
 * Developed by Mind Webs Ventures <support@mindwebs.org>
 * © 2022-23 | Confidential | All Rights Reserved
*****************************************/
 
import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
 // baseURL: 'https://conquer-api.artelus.ai/',
});

export function authHeader(): object {
  const authToken = localStorage.getItem("authToken");

  if (authToken) {
    return {
      headers: { Authorization: `Bearer ` + authToken },
      withCredentials: true,
    };
  } else return {};
}

export default api;
