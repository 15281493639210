import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Select, Spin, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { getClassifierByDatasetId } from "api/bucketApi";
import { getAllClassifiers } from "api/classifierApi";
import {
  addClassifierToDataset,
  removeClassifierFromDataset,
} from "api/datasetApi";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FiTrash2 } from "react-icons/fi";
import styles from "./CRUD_ClassifierModal.module.scss";

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

function UpdateClassifierModal({
  visibleUpdateModal,
  setVisibleUpdateModal,
  selectedDatasetId,
}: {
  visibleUpdateModal: boolean;
  setVisibleUpdateModal: (visibleUpdateModal: boolean) => void;
  selectedDatasetId: string;
}) {
  const [isNewClassifierAdded, setIsNewClassifierAdded] = useState(false);
  const { classifier, isLoading, classifierCategoryId } =
    useGetClassifierByDatasetId(selectedDatasetId, isNewClassifierAdded);
  const [allClassifiers, setAllClassifiers] = useState<any[]>([]);
  const [selectedClassifiersArray, setSelectedClassifiersArray] = useState<
    string[]
  >([]);
  const [
    selectedClassifierPathologyToBeDeleted,
    setSelectedClassifierPathologyToBeDeleted,
  ] = useState("");
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [selectedClassifierId, setSelectedClassifierId] = useState("");

  const columns: ColumnsType<DataType> = [
    {
      title: "Classifier",
      dataIndex: "pathology",
      width: "80%",
    },
    {
      title: "Actions",
      width: "20%",
      render: (details) => {
        return (
          <div className={styles.classifier_subClassEditIcon}>
            <Tooltip
              placement="bottom"
              title="Delete Dataset"
              color={"#777"}
              key={"deleteDatasetModal"}
            >
              <FiTrash2
                size={22}
                onClick={() => {
                  setVisibleDeleteModal(true);
                  setSelectedClassifierPathologyToBeDeleted(details.pathology);
                  setSelectedClassifierId(details._id);
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Modal
        centered
        title="Update Classifier"
        visible={visibleUpdateModal}
        onOk={() => {
          setVisibleUpdateModal(false);
        }}
        confirmLoading={false}
        onCancel={() => {
          setVisibleUpdateModal(false);
        }}
      >
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "3rem",
            }}
          >
            <Spin
              size="large"
              indicator={
                <LoadingOutlined className={styles.loadingSpinIcon} spin />
              }
              tip="Loading Classifiers..."
              className={styles.loadingDiv_spin}
            />
          </div>
        ) : (
          <Table
            columns={columns}
            dataSource={classifier}
            pagination={false}
            scroll={{
              y: 150,
            }}
            style={{
              marginBottom: "20px",
            }}
          />
        )}
        <Row>
          <Col span={18}>
            <Select
              className={styles.searchImg_filter}
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Select Classifiers"
              onChange={(classifierIdArray) => {
                setSelectedClassifiersArray(classifierIdArray);
              }}
              defaultValue={[]}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              onFocus={async () => {
                const allClassifiers = await getAllClassifiers();
                setAllClassifiers(allClassifiers);
              }}
              options={allClassifiers
                .map((classifier) => {
                  return {
                    label: classifier.pathology,
                    value: classifier._id,
                    categoryId: classifier.category_id,
                  };
                })
                .filter((classifier) => {
                  return classifier.categoryId === classifierCategoryId;
                })
                .filter(
                  ({ value }) => !classifier.some(({ _id }) => _id === value)
                )}
            />
          </Col>

          <Col
            span={6}
            style={{
              padding: "0 2.5rem",
            }}
          >
            <Button
              type="primary"
              style={{
                borderRadius: "7px",
              }}
              onClick={async () => {
                if (selectedClassifiersArray.length > 0) {
                  try {
                    const response = await addClassifierToDataset(
                      selectedDatasetId,
                      selectedClassifiersArray
                    );
                    if (response) {
                      toast.success("Classifier added successfully");
                    } else {
                      toast.error("Error! Could not add classifier");
                    }
                    setIsNewClassifierAdded((prev) => !prev);
                  } catch (error) {
                    toast.error("Error! Could not add classifier");
                  }
                }
              }}
            >
              Add
            </Button>
          </Col>
        </Row>
      </Modal>

      <DeletePopUpModal
        selectedDatasetId={selectedDatasetId}
        setVisibility={(val: boolean) => setVisibleDeleteModal(val)}
        selectedClassifierForDelete={selectedClassifierPathologyToBeDeleted}
        visible={visibleDeleteModal}
        selectedClassifierId={selectedClassifierId}
        setIsClassifierChanged={() => setIsNewClassifierAdded((prev) => !prev)}
      />
    </>
  );
}

function DeletePopUpModal({
  visible,
  selectedClassifierForDelete,
  setVisibility,
  selectedClassifierId,
  setIsClassifierChanged,
  selectedDatasetId,
}: {
  visible: boolean;
  selectedClassifierForDelete: any;
  setVisibility: (val: boolean) => void;
  selectedClassifierId: string;
  setIsClassifierChanged: () => void;
  selectedDatasetId: string;
}) {
  return (
    <Modal
      maskStyle={{
        backgroundColor: "rgba(0, 0, 0, 0.2)",
      }}
      width={300}
      centered
      visible={visible}
      footer={[
        <Button
          key="back"
          onClick={() => {
            setVisibility(false);
          }}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          danger
          loading={false}
          onClick={async () => {
            setVisibility(false);
            // deleting classifier from dataset
            if (selectedClassifierId !== "") {
              try {
                const response = await removeClassifierFromDataset(
                  selectedDatasetId,
                  [selectedClassifierId]
                );
                if (response) {
                  toast.success("Classifier deleted successfully");
                  setIsClassifierChanged();
                } else {
                  toast.error("Error! Could not delete classifier");
                }
              } catch (error) {
                toast.error("Error! Could not delete classifier");
              }
            }
          }}
          className={styles.deleteModal_deleteBtn}
        >
          Delete
        </Button>,
      ]}
    >
      <p>
        Are you sure you want to delete <b>{selectedClassifierForDelete}</b>{" "}
      </p>
    </Modal>
  );
}

function useGetClassifierByDatasetId(
  datasetId: string,
  isNewClassifierAdded: boolean
) {
  const [classifier, setClassifier] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [classifierCategoryId, setClassifierCategoryId] = useState<string>("");

  useEffect(() => {
    try {
      if (datasetId) {
        setIsLoading(true);
        const getClassifier = async () => {
          const classifier = await getClassifierByDatasetId(datasetId);
          setClassifierCategoryId(classifier[0].category_id);
          setClassifier(classifier);
          setIsLoading(false);
        };
        getClassifier();
      }
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
    }
  }, [datasetId, isNewClassifierAdded]);
  return { classifier, isLoading, isError, classifierCategoryId };
}

export default UpdateClassifierModal;
