import { Button, Card, Form, Input, Typography } from "antd";
import { createCategory } from "api/categoryApi";
import toast, { Toaster } from "react-hot-toast";
import styles from "./CreateCategories.module.scss";

const { Title } = Typography;

function CreateCategories() {
  const [form] = Form.useForm();

  /** On submit */
  async function onFinish(values: any) {
    try {
      await createCategory(
        values.categoryName,
        values.categoryType,
        values.categoryDesc
      );
      toast.success("Modality created successfully");
      form.resetFields();
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  const onFinishFailed = () => {
  };

  return (
    <div className={styles.createComponentDiv}>
      <Title level={2} className={styles.createComponent_heading}>
        Add a Modality
      </Title>
      <Card bordered={false} className={styles.createComponent_card}>
        <Form
          form={form}
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          requiredMark={"optional"}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label={
              <label className={styles.createComponent_inputLabels}>
                Modality Name
              </label>
            }
            name="categoryName"
            rules={[
              {
                required: true,
                message: "Please enter modality name",
              },
            ]}
          >
            <Input className={styles.createComponent_inputFields} />
          </Form.Item>

          <Form.Item
            label={
              <label className={styles.createComponent_inputLabels}>Type</label>
            }
            name="categoryType"
            rules={[{ required: true, message: "Please add type" }]}
          >
            <Input className={styles.createComponent_inputFields} />
          </Form.Item>

          <Form.Item
            label={
              <label className={styles.createComponent_inputLabels}>
                Description
              </label>
            }
            name="categoryDesc"
            rules={[
              {
                required: true,
                message: "Please enter some description",
              },
            ]}
          >
            <Input className={styles.createComponent_inputFields} />
          </Form.Item>

          <Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              className={styles.createComponent_submitBtn}
            >
              Add Modality
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Toaster
        toastOptions={{
          success: {
            style: {
              border: "1px solid #199c3c",
            },
          },
          error: {
            style: {
              border: "1px solid #FF4C4C",
            },
          },
        }}
      />
    </div>
  );
}

export default CreateCategories;
