import api, { authHeader } from "./index";

export async function createCategory(
  name: string,
  type: string,
  description: string
) {
  const response = await api.post(
    "/category/create",
    {
      name,
      type,
      description,
    },
    authHeader()
  );

  return response.data;
}

export async function getAllCategoriesWithCount() {
  const response = await api.get(`/category/count/all`, authHeader());
  return response.data?.result;
}

export async function getAllCategories() {
  const response = await api.get(`/category/all`, authHeader());
  return response.data?.result;
}

export async function getCategoryByCatId(categoryId: any) {
  const response = await api.get(`/category/${categoryId}`, authHeader());
  return response.data?.result;
}

export async function getCategoryStatsByCatId(
  categoryId: any,
  fieldData: number
) {
  const response = await api.get(
    `/category/stats/${categoryId}?fieldData=${fieldData}`,
    authHeader()
  );
  return response.data?.result;
}

export async function updateCategory(updatedCategory: object) {
  const response = await api.post(
    "/category/update",
    updatedCategory,
    authHeader()
  );
  return response.data?.result;
}
