import api, { authHeader } from "./index";

export async function getAllImagesByDatasetId(
  datasetId: any,
  isDoubtToggler: boolean,
  isReviewRoute: boolean
) {
  const response = await api.get(
    isReviewRoute
      ? `/images/all/review/${datasetId}`
      : `/images/all/${datasetId}?seeDoubt=${isDoubtToggler}`,
    authHeader()
  );
  return response.data?.result;
}

export async function getAllBucketImages(
  datasetId: any,
  classifierId: any,
  bucketId: any,
  isReviewRoute: boolean
) {
  const response = await api.get(
    isReviewRoute
      ? `/images/all/review/${datasetId}/${bucketId}`
      : `/images/bucket/${datasetId}/${classifierId}/${bucketId}`,
    authHeader()
  );
  return response.data?.result;
}

export async function getAllVirtualBucketImages(
  datasetId: any,
  classifierId: any,
  virtualId: any
) {
  const response = await api.get(
    `/images/virtual/${datasetId}/${classifierId}/${virtualId}`,
    authHeader()
  );
  return response.data?.result;
}

export async function getImageByPath(classifierId: any, imageId: string) {
  const response = await api.get(
    `/images/getById/${imageId}/${classifierId}`,
    authHeader()
  );
  return response.data?.result;
}

export async function getClassesByClassifierId(classifierId: any) {
  const response = await api.get(
    `/classifier/getClassifierById/${classifierId}`,
    authHeader()
  );
  return response.data?.result;
}

export async function getReviewAnnotatorDetails({
  datasetId,
  classifierId,
  imageId,
  userIds,
  reviewBucketId,
  isReviewRoute,
}: {
  datasetId: string;
  classifierId: string;
  imageId: string;
  userIds: string[];
  reviewBucketId: string;
  isReviewRoute: boolean;
}) {
  const response = await api.post(
    `/annotation/image/getRemarksByUsers`,
    isReviewRoute
      ? {
          reviewbucket_id: reviewBucketId,
          dataset_id: datasetId,
          classifier_id: classifierId,
          image_id: imageId,
          user_ids: userIds,
        }
      : {
          dataset_id: datasetId,
          classifier_id: classifierId,
          image_id: imageId,
          user_ids: userIds,
        },
    authHeader()
  );
  return response.data?.result;
}

interface AnnotationBody {
  image: any;
  dataset_id: any;
  classifier_id: any;
  remarks?: string;
  regional_annotation?: any;
  coordinates?: object[];
  is_doubt?: boolean;
  review?: any;
  subclasses?: string[];
  class?: string;
  classesArray?: string[];
  annotation_id?: any;
}

export async function createAnnotation({
  imageId,
  datasetId,
  classifierId,
  classId,
  classesArray,
  subclasses,
  annotationId,
  newRegionalAnnotatedImg,
  allDrawnCoordinates,
  userAnnotationRemarks,
  saveForLater,
  review,
}: {
  imageId: any;
  datasetId: any;
  classifierId: any;
  classId?: string;
  classesArray?: string[];
  subclasses?: string[];
  annotationId?: any;
  newRegionalAnnotatedImg?: any;
  allDrawnCoordinates?: object[];
  userAnnotationRemarks?: string;
  saveForLater?: boolean;
  review?: any;
}) {
  const body: AnnotationBody = {
    image: imageId,
    dataset_id: datasetId,
    classifier_id: classifierId,
    remarks: userAnnotationRemarks,
    regional_annotation: newRegionalAnnotatedImg || "",
    coordinates: allDrawnCoordinates,
    is_doubt: saveForLater,
    review,
    subclasses,
  };

  if (classId) {
    body.class = classId;
  } else if (classesArray && classesArray.length > 0) {
    body.classesArray = classesArray;
  }

  if (annotationId) {
    body.annotation_id = annotationId;
  }

  const response = await api.post("/annotation/update", body, authHeader());
  return response.data?.result;
}

export async function getSearchedImagesData({
  datasetId,
  classifierId,
  imageKey,
  pageCount,
  pageLimit,
}: {
  datasetId: string;
  classifierId?: string;
  imageKey: number;
  pageCount: number;
  pageLimit: number;
}) {
  const payload = {
    dataset: datasetId,
    classifier: classifierId,
    imageKey,
    page: pageCount,
    limit: pageLimit,
  };
  if (
    classifierId === undefined ||
    classifierId === null ||
    classifierId === ""
  ) {
    delete payload.classifier;
  }
  const response = await api.post(
    "/images/searchImageWithFilters",
    payload,
    authHeader()
  );
  return response.data?.result;
}

export async function getSearchImageByFile({
  fileName,
  classifierId,
  limit = 20,
  skip = 0,
}: {
  fileName: string;
  classifierId?: string;
  limit: number;
  skip: number;
}) {
  const payload = {
    query: fileName,
    limit,
    skip,
    classifierId,
  };
  if (
    classifierId === undefined ||
    classifierId === null ||
    classifierId === ""
  ) {
    delete payload.classifierId;
  }
  const response = await api.post("/images/search", payload, authHeader());
  return response.data?.result;
}
export async function getDatasetById({ datasetId }: { datasetId: string }) {
  const url = `/dataset/get/${datasetId}`;
  const response = await api.get(url, authHeader());
  return response.data?.result;
}

export async function getImageAnnotation({
  image_id,
  classifier_id,
  dataset_id,
}: {
  image_id: string;
  classifier_id: string;
  dataset_id: string;
}) {
  const payload = {
    image_id,
    classifier_id,
    dataset_id,
  };
  const response = await api.post(
    "/annotation/image/self",
    payload,
    authHeader()
  );
  return response.data?.result;
}
