/**************************************** 
 * Developed by Mind Webs Ventures <support@mindwebs.org>
 * © 2022-23 | Confidential | All Rights Reserved
*****************************************/
 
import Layout from "components/Layout";
import { useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "styles/global.scss";
import { AdminRoute } from "utils/AdminRoute";
import { ReviewerRoute } from "utils/ReviewerRoute";
import { RequireAuth, ReverseAuth } from "utils/RouteProtection";

// pages
import { getAllCategories } from "api/categoryApi";
import {
  getAllAccessibleDatasets,
  getEveryDatasetForAdmin,
} from "api/datasetApi";
import { getAllUsersForAdmin, getUserInfoApi } from "api/usersApi";
import ClassifierPage from "components/classifiers/ClassifierPage/classifierPage";
import AllUserAnnotation from "pages/AdminViewUserAnnotation/AllUserAnnotation";
import OneUserAnnotation from "pages/AdminViewUserAnnotation/OneUserAnnotation";
import AnnotationHistory from "pages/AnnotationData/AnnotationHistory";
import AnnotationPerDay from "pages/AnnotationData/AnnotationPerDay";
import AnnotationPerDayDataset from "pages/AnnotationData/AnnotationPerDayDataset";
import Dashboard from "pages/Dashboard";
import NotFound404 from "pages/NotFound404";
import SearchImages from "pages/SearchImages";
import UploadData from "pages/UploadData";
import ImageGallery from "pages/buckets/ImageGallery";
import ViewBuckets from "pages/buckets/ViewBuckets";
import ViewReviewBuckets from "pages/buckets/ViewReviewBuckets";
import ViewCameras from "pages/cameras/ViewCameras";
import AdminViewCategories from "pages/category/AdminViewCategories";
import CreateCategories from "pages/category/CreateCategories";
import RemoveClasses from "pages/classifiers/Classes/RemoveClasses";
import CreateClassifier from "pages/classifiers/CreateClassifier";
import CreateSubClasses from "pages/classifiers/SubClasses/CreateSubClasses";
import RemoveSubClasses from "pages/classifiers/SubClasses/RemoveSubClasses";
import ViewAll from "pages/classifiers/ViewAll";
import ViewClassifiers from "pages/classifiers/ViewClassifiers";
import AdminViewDatasets from "pages/datasets/AdminViewDatasets";
import AnnotatorDataset from "pages/datasets/AnnotatorDataset";
import CreateDataset from "pages/datasets/CreateDataset";
import Jobs from "pages/datasets/Jobs";
import ReviewerDataset from "pages/datasets/ReviewerDataset";
import ViewAllReviewBuckets from "pages/datasets/ViewAllReviewBuckets";
import ViewDatasets from "pages/datasets/ViewDatasets";
import ImageViewController from "pages/imagesData/ViewImages/ImageViewController";
import Login from "pages/login";
import Metrics from "pages/metrics";
import CreateUsers from "pages/users/CreateUsers";
import ManageUsers from "pages/users/CreateUsers/ManageUsers";
import toast from "react-hot-toast";

function App() {

  const [loggedInUserPermission, setLoggedInUserPermission] = useState<number>(0);
  const [datasets, setDatasets] = useState<{
    loading: boolean;
    error: boolean;
    fetched: boolean;
    data: any[];
  }>({
    loading: false,
    error: false,
    fetched: false,
    data: [],
  });
  const [pageCount, setPageCount] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [totalAnnotationCount, setTotalAnnotationCount] = useState<number>(0);
  const [allDatasets, setAllDatasets] = useState<any>({
    loading: false,
    error: false,
    data: [],
  });
  const [allUsers, setAllUsers] = useState<{
    loading: boolean;
    error: boolean;
    data: any[];
  }>({
    loading: false,
    error: false,
    data: [],
  });
  const [allCategories, setAllCategories] = useState<object[]>([]);
  const [datasetsToShow, setDatasetsToShow] = useState<object[]>([]);
  const [pageCount2, setPageCount2] = useState<number>(1);
  const [pageLimit2, setPageLimit2] = useState<number>(10);
  const [totalAnnotationCount2, setTotalAnnotationCount2] = useState<number>(0);

  async function getUserInfo() {
    try {
      const res = await getUserInfoApi();
        setLoggedInUserPermission(res.permission_level)
    } catch (error: any) {
      toast.error(error.response.data.message + " Please login again.");
    }
  }

    /** Fetch user info everytime the page loads fr the first time */
    useEffect(() => {
      getUserInfo();
    }, []);
  

  /** Fetch datasets */
  useEffect(() => {
    setDatasets({
      loading: true,
      error: false,
      fetched: true,
      data: [],
    });
    try {
      const fetchDatasets = async () => {
        const res = await getAllAccessibleDatasets({
          pageCount,
          pageLimit,
        });

        setDatasets({
          loading: false,
          error: false,
          fetched: true,
          data: res,
        });
        setTotalAnnotationCount(res.totalObjects);
      };
      fetchDatasets();
    } catch (error) {
      setDatasets({
        loading: false,
        error: true,
        fetched: true,
        data: [],
      });
    }
  }, [pageCount, pageLimit, loggedInUserPermission]);

  async function getDatasets() {
    allDatasets.data.length === 0 &&
      setAllDatasets({ ...allDatasets, loading: true });
    try {
      const res = await getEveryDatasetForAdmin();
      setAllDatasets({
        ...allDatasets,
        loading: false,
        data: res.data,
      });
      setDatasetsToShow(res.data);

      const categoriesRes = await getAllCategories();
      categoriesRes.unshift({
        type: "All",
      });
      setAllCategories(categoriesRes);
    } catch (error) {
      setAllDatasets({ ...allDatasets, loading: false, error: true });
    }
  }
  async function getAllUsers() {
    setAllUsers({ ...allUsers, loading: true });
    try {
      const res = await getAllUsersForAdmin();
      setAllUsers({ ...allUsers, loading: false, data: res.result.data });
    } catch (error) {
      setAllUsers({ ...allUsers, loading: false, error: true });
    }
  }
  /** Get allUsers & allDatasets */
  useEffect(() => {
    if (loggedInUserPermission > 3) {
      getDatasets();
      getAllUsers();
    }
  }, [loggedInUserPermission]);
  return (
    <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
      
        <BrowserRouter>
          <Routes>
            <Route
              path="/login"
              element={
                <ReverseAuth>
                  <Login getUserInfo={getUserInfo} />
                </ReverseAuth>
              }
            />
            <Route
              path="/"
              element={
                <RequireAuth>
                  <Layout />
                </RequireAuth>
              }
            >
              <Route path="/" element={<Dashboard 
                datasets={datasets}
                setDatasets={setDatasets}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageLimit={pageLimit}
                setPageLimit={setPageLimit}
                totalAnnotationCount={totalAnnotationCount}
                setTotalAnnotationCount={setTotalAnnotationCount}
              />} />
              <Route
                path="/modality/:categoryId/cameras"
                element={<ViewCameras />}
              />
              <Route
                path="/classifiers/:datasetId"
                element={<ClassifierPage />}
              />
              <Route
                path="/modality/:categoryId/:camTypeId/datasets/:fieldData"
                element={<ViewDatasets />}
              />
              <Route
                path="/buckets/:datasetId/:classifierId"
                element={<ViewBuckets />}
              />
              <Route
                path="/buckets/:datasetId/:datasetName/images"
                element={<ImageGallery />}
              />
              <Route path="/image/search" element={<SearchImages />} />
              <Route
                path="/images/:datasetId/:classifierId/:bucketId/:isDoubt"
                element={<ImageViewController />}
              />
              <Route
                path="/images/review/:datasetId/:classifierId/:bucketId/:isDoubt"
                element={<ImageViewController />}
              />
              <Route path="/annotations-today" element={<AnnotationPerDay />} />
              <Route
                path="/annotations-today-dataset/:datasetId"
                element={<AnnotationPerDayDataset />}
              />
              {/* ReviewerRoutes */}
              <Route element={<ReviewerRoute />}>
                <Route
                  path="/datasets/reviewer/all"
                  element={
                    <ReviewerDataset
                      datasets={datasets}
                      setDatasets={setDatasets}
                    />
                  }
                />
                <Route
                  path="/review-bucket/:reviewBucketId/:classifierId"
                  element={<ViewReviewBuckets />}
                />
                <Route
                  path="/datasets/review-buckets/all"
                  element={<ViewAllReviewBuckets />}
                />
              </Route>
              <Route
                path="/annotation/history"
                element={<AnnotationHistory />}
              />

              {/* AdminRoutes */}
              <Route element={<AdminRoute />}>
                <Route
                  path="/datasets/all"
                  element={
                    <AnnotatorDataset
                      datasets={datasets}
                      setDatasets={setDatasets}
                      pageCount={pageCount}
                      setPageCount={setPageCount}
                      pageLimit={pageLimit}
                      setPageLimit={setPageLimit}
                      totalAnnotationCount={totalAnnotationCount}
                      setTotalAnnotationCount={setTotalAnnotationCount}
                    />
                  }
                />
                <Route
                  path="/admin/datasets/review-buckets/all"
                  element={<ViewAllReviewBuckets />}
                />
                <Route
                  path="/admin/review-bucket/:reviewBucketId/:classifierId"
                  element={<ViewReviewBuckets />}
                />
                <Route path="/modality/create" element={<CreateCategories />} />
                <Route
                  path="/admin/modality/all"
                  element={<AdminViewCategories />}
                />
                <Route path="/dataset/create" element={<CreateDataset />} />
                <Route path="/upload/data" element={<UploadData />} />
                <Route path="/upload/jobs" element={<Jobs />} />
                <Route
                  path="/metrics"
                  element={
                    <Metrics
                      allDatasets={allDatasets}
                      setAllDatasets={setAllDatasets}
                    />
                  }
                />
                <Route
                  path="/admin/dataset/all"
                  element={
                    <AdminViewDatasets
                      allDatasets={allDatasets}
                      setAllDatasets={setAllDatasets}
                      datasetsToShow={datasetsToShow}
                      setDatasetsToShow={setDatasetsToShow}
                      allCategories={allCategories}
                      setAllCategories={setAllCategories}
                      allUsers={allUsers}
                      setAllUsers={setAllUsers}
                      getDatasets={getDatasets}
                      getAllUsers={getAllUsers}
                    />
                  }
                />
                <Route
                  path="/classifier/create"
                  element={<CreateClassifier />}
                />
                <Route path="/classifiers/edit" element={<ViewClassifiers />} />
                <Route path="/classifiers/all" element={<ViewAll />} />
                <Route path="/classes/remove" element={<RemoveClasses />} />
                <Route
                  path="/classifier/subclasses/create"
                  element={<CreateSubClasses />}
                />
                <Route
                  path="/classifier/subclasses/remove"
                  element={<RemoveSubClasses />}
                />
                <Route path="/user/register" element={<CreateUsers />} />
                <Route path="/user/manage" element={<ManageUsers />} />
                <Route
                  path="/user/annotations"
                  element={
                    <OneUserAnnotation
                      allDatasets={allDatasets}
                      setAllDatasets={setAllDatasets}
                    />
                  }
                />
                <Route
                  path="/dataset/classifier/annotations"
                  element={
                    <AllUserAnnotation
                      allDatasets={allDatasets}
                      setAllDatasets={setAllDatasets}
                      pageCount2={pageCount2}
                      setPageCount2={setPageCount2}
                      pageLimit2={pageLimit2}
                      setPageLimit2={setPageLimit2}
                      totalAnnotationCount2={totalAnnotationCount2}
                      setTotalAnnotationCount2={setTotalAnnotationCount2}
                    />
                  }
                />
              </Route>
            </Route>
            <Route path="*" element={<NotFound404 />} />
          </Routes>
        </BrowserRouter>
      
    </IconContext.Provider>
  );
}

export default App;
