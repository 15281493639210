import type { PaginationProps } from "antd";
import {
  Button,
  Col,
  Empty,
  Grid,
  Input,
  InputNumber,
  Modal,
  Pagination,
  Popover,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { setSearchImageInfo } from "store/searchImageInfoSlice";
import styles from "./SearchImages.module.scss";
import { SearchImagesLogic } from "./SearchImagesLogic";

// Icons
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import SearchImageByFileNameLogic from "./SearchImageByFileNameLogic";

const { Title, Text } = Typography;
const { Option } = Select;
const { useBreakpoint } = Grid;

export default function SearchImages() {
  const [filter, setFilter] = useSearchParams();
  const query = filter.get("filter");
  return query === "imageNumber" ? (
    <SearchImageByNumber />
  ) : (
    <SearchImageByFileName />
  );
}

function SearchImageByNumber() {
  const dispatch = useDispatch();
  const { md, lg } = useBreakpoint();
  const {
    datasets,
    imageKey,
    setImageKey,
    setSelectedDataset,
    classifiers,
    selectedClassifier,
    setSelectedClassifier,
    fetchImagesData,
    pageCount,
    setPageCount,
    pageLimit,
    setPageLimit,
    imagesDataTableInfo,
    imagesData,
    imagesErrMsg,
    totalAnnotationCount,
    disableVisitInTable,
    isClassifierSelectorVisible,
  }: {
    imageKey: number;
    setImageKey: (value: number) => void;
    datasets: {
      loading: boolean;
      error: boolean;
      data: any;
    };
    selectedDataset: string;
    setSelectedDataset: (value: string) => void;
    classifiers: any[];
    selectedClassifier: string;
    setSelectedClassifier: (value: string) => void;
    fetchImagesData: () => void;
    pageCount: number;
    setPageCount: (page: number) => void;
    pageLimit: number;
    setPageLimit: (limit: number) => void;
    imagesDataTableInfo: any[];
    imagesData: {
      loading: boolean;
      error: boolean;
      data: any[];
    };
    imagesErrMsg: string;
    totalAnnotationCount: number | null;
    disableVisitInTable: boolean;
    isClassifierSelectorVisible: boolean;
  } = SearchImagesLogic();

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current: any,
    newPageLimit: number
  ) => {
    setPageLimit(newPageLimit);
  };
  const onChange: PaginationProps["onChange"] = (pageNumber: number) => {
    setPageCount(pageNumber);
  };

  const previewModal = (imgName: string, imgSrc: string) => {
    Modal.info({
      title: <Title level={5}>{imgName}</Title>,
      content: (
        <div>
          {/* <p>{imgFileName}</p> */}
          <img src={imgSrc} alt="preview" className={styles.previewModal_img} />
        </div>
      ),
      onOk() {},
    });
  };
  const normalUserColumns: ColumnsType<any> = [
    {
      title: "Image",
      key: "imageName",
      width: "20%",
      render: (details: any) => {
        return (
          <>
            <Popover content={<>{details?.imageName}</>} placement="right">
              {details?.imageName ? details.imageName : "-"}
            </Popover>
          </>
        );
      },
    },
    {
      title: "Your annotation",
      key: "yourAnnotation",
      render: (details) => {
        return (
          <>
            {details?.selectedClass ? details.selectedClass : "-"}
            {details?.selectedClass && (
              <Popover
                content={
                  <>
                    {details.selectedSubClasses.length > 0 ? (
                      details.selectedSubClasses.map((eachSubclass: any) => {
                        return (
                          <li>
                            {eachSubclass?.name} ({eachSubclass?.count})
                          </li>
                        );
                      })
                    ) : (
                      <p>No subclasses found</p>
                    )}
                  </>
                }
                placement="right"
                title="More Details"
              >
                <InfoCircleOutlined
                  className={styles.imagesDataTableCol_infoIcon}
                />
              </Popover>
            )}
          </>
        );
      },
    },
    {
      title: "Image",
      key: "image",
      render: (details) => {
        return (
          <>
            <Title
              level={5}
              className={styles.table_previewHeading}
              onClick={() => previewModal(details.imageName, details.imgSrc)}
            >
              Preview
            </Title>
          </>
        );
      },
    },
    {
      title: "View",
      key: "action",
      render: (details) => {
        return (
          <Space size="middle">
            <Link
              to={`/images/${details.datasetId}/${details.classifierId}/${details.bucketId}/0`}
            >
              <Button
                className={styles.table_visitBtn}
                onClick={() => dispatch(setSearchImageInfo(details.imgId))}
                disabled={disableVisitInTable}
                style={{
                  backgroundColor: "red",
                  borderColor: "red",
                }}
              >
                Visit
              </Button>
            </Link>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Title level={2} className={styles.searchImg_heading}>
        Search Image
      </Title>

      <Row style={{ marginTop: "0" }}>
        <Col lg={6} md={12} sm={12} xs={24}>
          <Title level={5} className={styles.searchImg_filterHeading}>
            Select Dataset
          </Title>
          <OptionSearchFilter
            options={datasets.data}
            onChange={(val: string) => {
              setSelectedDataset(val);
            }}
          />
        </Col>
        <Col lg={6} md={12} sm={12} xs={24}>
          <Title level={5} className={styles.searchImg_filterHeading}>
            Image Number
          </Title>
          <InputNumber
            min={0}
            value={imageKey}
            className={styles.searchImg_inputNumberField}
            onChange={(e: any) => setImageKey(e)}
          />
        </Col>
        <Col lg={3} md={12} sm={12} xs={24}>
          <Button
            className={styles.searchImg_btn}
            onClick={() => fetchImagesData()}
          >
            Search
          </Button>
        </Col>
        <Col lg={3} md={0} sm={0} xs={0}></Col>
        {
          //    only visible when user have already searched for images using dataset and number
          isClassifierSelectorVisible && (
            <Col lg={5} md={12} sm={12} xs={24}>
              <Title level={5} className={styles.searchImg_filterHeading}>
                Select Classifier
              </Title>
              <Select
                style={{ width: 150 }}
                className={styles.searchImg_filter}
                value={selectedClassifier}
                onChange={(val: string) => {
                  setSelectedClassifier(val);
                }}
              >
                {classifiers.length ? (
                  classifiers?.map((classifier: any) => (
                    <Option key={classifier._id} value={classifier._id}>
                      {classifier.pathology}
                    </Option>
                  ))
                ) : (
                  <Option value="disabled" disabled>
                    Select a dataset first
                  </Option>
                )}
              </Select>
            </Col>
          )
        }
      </Row>

      <div>
        {imagesData.loading ? (
          <div className={styles.loadingDiv}>
            <Spin
              size="large"
              indicator={
                <LoadingOutlined className={styles.loadingSpinIcon} spin />
              }
              tip="Searching for Images..."
              className={styles.loadingDiv_spin}
            />
          </div>
        ) : imagesData.error ? (
          <div className={styles.errorDiv}>
            <Title level={4} className={styles.errorMsgHeading}>
              {imagesErrMsg}
            </Title>
          </div>
        ) : totalAnnotationCount !== null ? (
          imagesData.data.length ? (
            <>
              <Pagination
                current={pageCount}
                total={totalAnnotationCount}
                pageSize={pageLimit}
                className={styles.searchImg_pagination}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                onChange={onChange}
              />
              <Table
                columns={normalUserColumns}
                dataSource={imagesDataTableInfo}
                className={styles.table}
                pagination={false}
                scroll={{ x: 1000 }}
              />
            </>
          ) : (
            <div style={{ marginTop: "7rem" }}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                imageStyle={{
                  height: 60,
                }}
                description={"No images found"}
              />
            </div>
          )
        ) : (
          ""
        )}
      </div>

      <Toaster
        toastOptions={{
          success: {
            style: {
              border: "1px solid #199c3c",
            },
          },
          error: {
            style: {
              border: "1px solid #FF4C4C",
            },
          },
        }}
      />
    </>
  );
}

function OptionSearchFilter({
  options,
  onChange,
}: {
  options: any;
  onChange: (val: string) => void;
}) {
  const datasetOptions: any[] = [];
  options.datasets &&
    options.datasets.forEach((dataset: any) => {
      datasetOptions.push({
        id: dataset._id,
        label: dataset.name,
        value: dataset._id,
      });
    });
  return (
    <Select
      showSearch
      onChange={(val) => onChange(val)}
      className={styles.searchImg_filter}
      style={{ width: "90%" }}
      filterOption={(input, option) =>
        ((option?.label ?? "") as string)
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      options={datasetOptions}
    />
  );
}

function SearchImageByFileName() {
  const {
    searchQuery,
    setSearchQuery,
    setSearchLimit,
    getSearchedImagesData,
    handleSearch,
  }: {
    searchLimit: number;
    searchQuery: string;
    setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
    setSearchLimit: React.Dispatch<React.SetStateAction<number>>;
    searchSkip: number;
    setSearchSkip: React.Dispatch<React.SetStateAction<number>>;
    selectedClassifierId: string | undefined;
    setSelectedClassifierId: React.Dispatch<
      React.SetStateAction<string | undefined>
    >;
    totalAnnotationCount: number | null;
    getSearchedImagesData: {
      loading: boolean;
      error: boolean;
      data: any[];
    };
    handleSearch: (limit?: number, skip?: number) => void;
  } = SearchImageByFileNameLogic();
  const dispatch = useDispatch();

  const previewModal = (imgName: string, imgSrc: string) => {
    Modal.info({
      title: <Title level={5}>{imgName}</Title>,
      content: (
        <div>
          {/* <p>{imgFileName}</p> */}
          <img src={imgSrc} alt="preview" className={styles.previewModal_img} />
        </div>
      ),
      onOk() {},
    });
  };
  const normalUserColumns: ColumnsType<any> = [
    {
      title: "FileName",
      key: "fileName",
      width: "50%",
      render: (details: any) => {
        return (
          <>
            <Popover content={<>{details?.fileName}</>} placement="right">
              <Text
                ellipsis={true}
                style={{
                  maxWidth: "32rem",
                }}
              >
                {details?.fileName ? details.fileName : "-"}
              </Text>
            </Popover>
          </>
        );
      },
    },
    {
      title: "Dataset Name",
      key: "dataset",
      width: "20%",
      render: (details) => {
        return <>{details?.dataset}</>;
      },
    },
    {
      title: "Image",
      key: "image",
      width: "10%",
      render: (details) => {
        return (
          <>
            <Title
              level={5}
              className={styles.table_previewHeading}
              onClick={() => previewModal(details.imageName, details.thumbnail)}
            >
              Preview
            </Title>
          </>
        );
      },
    },
    {
      title: "View",
      key: "action",
      width: "10%",
      fixed: "right",
      render: (details) => {
        return (
          <Space size="middle">
            <Link
              to={`/images/${details.datasetId}/${details.classifierId}/${details.classId}/0`}
            >
              <Button
                className={styles.table_visitBtn}
                disabled={
                  !details.classifierId ||
                  !details.classId ||
                  !details.datasetId
                }
                onClick={() => dispatch(setSearchImageInfo(details.id))}
                style={{
                  backgroundColor: "red",
                  borderColor: "red",
                }}
              >
                Visit
              </Button>
            </Link>
          </Space>
        );
      },
    },
  ];
  return (
    <div className={styles.searchImage__container}>
      <Title level={2} className={styles.searchImg_heading}>
        Search Image
      </Title>
      <Row>
        <Col lg={10} md={16} sm={16} xs={24}>
          <Title level={5} className={styles.searchImg_filterHeading}>
            Image FileName
          </Title>
          <Input
            value={searchQuery}
            placeholder="Enter File name"
            className={styles.searchImg_input}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Col>
        <Col flex={"auto"} offset={2}>
          <Button
            className={styles.searchImg_btn}
            onClick={() => handleSearch()}
          >
            Search
          </Button>
        </Col>
      </Row>
      <div>
        {getSearchedImagesData.loading ? (
          <div className={styles.loadingDiv}>
            <Spin
              size="large"
              indicator={
                <LoadingOutlined className={styles.loadingSpinIcon} spin />
              }
              tip="Searching for Images..."
              className={styles.loadingDiv_spin}
            />
          </div>
        ) : getSearchedImagesData.error ? (
          <div className={styles.errorDiv}>
            <Title level={4} className={styles.errorMsgHeading}>
              {/* {imagesErrMsg} */}
            </Title>
          </div>
        ) : true ? (
          getSearchedImagesData.data.length ? (
            <>
              <Table
                columns={normalUserColumns}
                dataSource={getSearchedImagesData.data}
                className={styles.table}
                pagination={{
                  total: getSearchedImagesData.data.length,
                  defaultPageSize: 10,
                  defaultCurrent: 1,
                  showSizeChanger: true,
                  onShowSizeChange: (current, size) => {
                    handleSearch(size);
                    setSearchLimit(size);
                  },
                  position: ["topRight"],
                }}
                scroll={{ x: 1000 }}
              />
            </>
          ) : (
            <div style={{ marginTop: "7rem" }}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                imageStyle={{
                  height: 60,
                }}
                description={"No images found"}
              />
            </div>
          )
        ) : (
          ""
        )}
      </div>

      <Toaster
        toastOptions={{
          success: {
            style: {
              border: "1px solid #199c3c",
            },
          },
          error: {
            style: {
              border: "1px solid #FF4C4C",
            },
          },
        }}
      />
    </div>
  );
}
