import {
  Button,
  Col,
  Empty,
  Pagination,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import AdminViewOneUserTable from "components/AdminViewUserAnnotation/AdminViewOneUserTable";
import styles2 from "../../metrics/Metrics.module.scss";
import styles from "./OneUserAnnotation.module.scss";
import { OneUserAnnotationLogic } from "./OneUserAnnotationLogic";

// Icons
import { LoadingOutlined } from "@ant-design/icons";
import { PaginationProps } from "antd/es/pagination";

const { Title } = Typography;
const { Option } = Select;

function AdminViewUserAnnotation({ allDatasets, setAllDatasets }: any) {
  const {
    pageCount,
    setPageCount,
    pageLimit,
    setPageLimit,
    totalAnnotationCount,
    classifiers,
    selectedClassifier,
    usersUnderClassifier,
    selectedUser,
    handleDatasetFilter,
    handleClassifierChange,
    handleUserChange,
    getAllAnnotationDetails,
    tableData,
  }: {
    selectedDataset: any;
    pageCount: number;
    setPageCount: (e: any) => void;
    pageLimit: number;
    setPageLimit: (e: any) => void;
    totalAnnotationCount: number;
    setSelectedDataset: (e: any) => void;
    classifiers: any;
    selectedClassifier: any;
    usersUnderClassifier: any;
    selectedUser: any;
    handleDatasetFilter: (datasetName: string) => void;
    handleClassifierChange: (classifierId: string) => void;
    handleUserChange: (userId: string) => void;
    getAllAnnotationDetails: () => void;
    tableData: any[];
  } = OneUserAnnotationLogic({ allDatasets, setAllDatasets });

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    _current,
    newPageLimit
  ) => {
    setPageLimit(newPageLimit);
  };
  const onChange: PaginationProps["onChange"] = (pageNumber) => {
    setPageCount(pageNumber);
  };

  return (
    <>
      {allDatasets.loading ? (
        <div className={styles.loadingDiv}>
          <Spin
            size="large"
            indicator={
              <LoadingOutlined className={styles.loadingSpinIcon} spin />
            }
            tip="Loading..."
            className={styles.loadingDiv_spin}
          />
        </div>
      ) : allDatasets.error ? (
        <Title level={4} className={styles.oneUser_errMsg}>
          Some error occured
        </Title>
      ) : (
        <>
          <Row>
            <Col md={7} sm={24} xs={24}>
              <label className={styles.filter_inputLabels}>
                Select Dataset
              </label>
              <Select
                className={styles.filter_inputField}
                showSearch
                placeholder="Select Dataset"
                optionFilterProp="children"
                onChange={(e) => handleDatasetFilter(e)}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {allDatasets.data.length &&
                  allDatasets.data.map((eachDataset: any) => {
                    return (
                      <Option value={eachDataset.dataset.name}>
                        {eachDataset.dataset.name}
                      </Option>
                    );
                  })}
              </Select>
            </Col>
            <Col md={6} sm={24} xs={24}>
              <label className={styles.filter_inputLabels}>
                Select Classifier
              </label>
              <Select
                value={selectedClassifier && selectedClassifier.pathology}
                className={styles.filter_inputField}
                placeholder="Select classifier"
                onChange={(e) => handleClassifierChange(e)}
              >
                {classifiers.length &&
                  classifiers.map((eachClassifier: any) => {
                    return (
                      <Option value={eachClassifier._id}>
                        {eachClassifier.pathology}
                      </Option>
                    );
                  })}
              </Select>
            </Col>
            <Col md={7} sm={24} xs={24}>
              <label className={styles.filter_inputLabels}>Select User</label>
              <Select
                value={selectedUser?.user_id && selectedUser.user_id.name}
                className={styles.filter_inputField}
                showSearch
                placeholder="Select User"
                optionFilterProp="children"
                onChange={(e) => handleUserChange(e)}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {usersUnderClassifier.length &&
                  usersUnderClassifier.map((eachUser: any) => {
                    return (
                      <Option value={eachUser.user_id._id}>
                        {eachUser.user_id.name + " - " + eachUser.user_id.email}
                      </Option>
                    );
                  })}
              </Select>
            </Col>
            <Col md={4} sm={24} xs={24}>
              <Button
                className={styles.filter_submitBtn}
                onClick={() => getAllAnnotationDetails()}
              >
                Submit
              </Button>
            </Col>
          </Row>
          <hr className={styles.viewOneUserAnno_hrLine} />

          <div className={styles2.metrics_paginationDiv}>
            {tableData.length ? (
              <Pagination
                current={pageCount}
                total={totalAnnotationCount}
                pageSize={pageLimit}
                className={styles.datasets_pagination}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                onChange={onChange}
              />
            ) : (
              ""
            )}
          </div>

          {tableData.length > 0 ? (
            <AdminViewOneUserTable
              selectedUser={selectedUser}
              tableData={tableData}
            />
          ) : (
            <Empty
              className={styles.table_empty}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                // "No data available for this user."
                "Please select the filters to view a user's annotation details."
              }
            />
          )}
        </>
      )}
    </>
  );
}

export default AdminViewUserAnnotation;
