import {
  createClasses,
  getAllClassifiers
} from "api/classifierApi";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

type NewClassInputType = {
  name: string;
  key: number | null;
};

export const CreateClassesLogic = () => {
  const [classifiers, setClassifiers] = useState([]);
  const [classes, setClasses] = useState<any[]>([]);
  const [newClassInput, setNewClassInput] = useState<NewClassInputType>({
    name: "",
    key: 0,
  });

  const [selectedClassifier, setSelectedClassifier] = useState("");

  /** Fetch classifiers */
  useEffect(() => {
    async function getClassifiers() {
      const classifiers = await getAllClassifiers();
      setClassifiers(classifiers);
    }
    getClassifiers();
  }, []);

  /** onchange check */
  function handleNewClass() {
    if (newClassInput.name && newClassInput.key !== null) {
      setClasses([...classes, newClassInput]);
      setNewClassInput({
        name: "",
        key: null,
      });
    } else {
      toast.error("Please fill in both fields");
    }
  }

  /** Delete class */
  function handleClassDelete(details: any) {
    const index = classes.indexOf(details);
    if (index !== -1) {
      classes.splice(index, 1);
    }
    setClasses([...classes]);
  }

  /** Handling submit of new class creation */
  async function handleSubmit() {
    if (selectedClassifier === "") {
      toast.error("Please select a classifier");
      return;
    }
    if (classes.length === 0) {
      toast.error("Add atleast one class");
      return;
    }
    try {
      const res = await createClasses({
        classifier_id: selectedClassifier,
        classes,
      });
      if (res) {
        toast.success("Classes created successfully");
        setClasses([]);
        setSelectedClassifier("");
      }
    } catch (err: any) {
      if (err) {
        toast.error("Could not create classes");
      }
    }
  }

  return {
    classifiers,
    newClassInput,
    setNewClassInput,
    handleNewClass,
    handleClassDelete,
    classes,
    handleSubmit,
    selectedClassifier,
    setSelectedClassifier,
  };
};
