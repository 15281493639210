import { Breadcrumb, Button, Col, Input, Row, Spin, Typography } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "./classifierPage.module.scss";

// Icons
import { LoadingOutlined } from "@ant-design/icons";
import { getClassifierByDatasetId, getDatasetbyDatasetId } from "api/bucketApi";
import { useEffect, useState } from "react";
import { FaCamera } from "react-icons/fa";
import { ImArrowRight2 } from "react-icons/im";
import { IoIosSearch } from "react-icons/io";

const { Title } = Typography;

function ClassiferPage() {
  const navigate = useNavigate();
  const { datasetId } = useParams();
  const { classifiers, loading, error, datasetInfo } =
    useGetClassifierByDatasetId(datasetId);
  const [searchQuery, setSearchQuery] = useState("");
  return (
    <>
      {!loading && (
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <span>Home</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/">
            <span>Modality</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span
              onClick={() => navigate(-1)}
              className={styles.breadcrumbs_link}
            >
              {datasetInfo?.cameraType_name}
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span
              onClick={() => navigate(-1)}
              className={styles.breadcrumbs_link}
            >
              {datasetInfo?.name}
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Classifiers</Breadcrumb.Item>
        </Breadcrumb>
      )}
      <div>
        <Title level={2} className={styles.buckets_heading}>
          Classifiers
        </Title>
      </div>
      {(datasetInfo?.description || datasetInfo?.cameraType_name) && (
        <Title level={5} className={styles.buckets_description}>
          {datasetInfo?.description}
          <hr /> <FaCamera className={styles.buckets_descriptionCamera} />{" "}
          {datasetInfo?.cameraType_name}
        </Title>
      )}

      <Row className={styles.buckets_headingRow}>
        <Col xl={12} lg={24} md={24} sm={24} xs={24}>
          <Input
            size="large"
            placeholder="Search..."
            prefix={<IoIosSearch className={styles.buckets_searchBoxIcon} />}
            className={styles.buckets_searchBox}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Col>
      </Row>
      {/* Classifiers */}
      <Row className={styles.buckets_row}>
        {loading ? (
          <div className={styles.loadingDiv}>
            <Spin
              size="large"
              indicator={
                <LoadingOutlined className={styles.loadingSpinIcon} spin />
              }
              tip="Loading Classifiers..."
              className={styles.loadingDiv_spin}
            />
          </div>
        ) : !error && classifiers.length > 0 ? (
          classifiers
            .filter((classifier: ClassifierInterface) => {
              if (searchQuery === "") return classifier;
              else if (
                classifier.pathology
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
              )
                return classifier;
            })
            .map((classifier: ClassifierInterface) => {
              return (
                <>
                  <Col
                    lg={8}
                    md={12}
                    xs={24}
                    className={styles.buckets_col}
                    key={classifier._id}
                  >
                    <div className={styles.buckets_card}>
                      <Title level={4} className={styles.bucketsCard_name}>
                        {classifier.pathology}
                      </Title>
                      {/* <Title
                                                level={4}
                                                className={
                                                    styles.bucketsCard_imgCount
                                                }
                                            >
                                                {bucket.finalImagesCount} images
                                            </Title> */}
                      <Link
                        to={`/buckets/${datasetId}/${classifier._id}`}
                        state={{
                          pathology: classifier.pathology,
                        }}
                      >
                        <Button
                          className={styles.bucketsCard_btn}
                          style={{
                            margin: "1rem 0 0 auto",
                            backgroundColor: "dodgerblue",
                            border: "none",
                          }}
                        >
                          View Buckets{" "}
                          <ImArrowRight2
                            className={styles.bucketsCard_btnArrowIcon}
                          />
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </>
              );
            })
        ) : classifiers.length > 0 ? (
          <Title level={4} className={styles.buckets_errorMsg}>
            No Classifier found
          </Title>
        ) : (
          <div className={styles.errorDiv}>
            <Title level={4} className={styles.buckets_errorMsg}>
              Error Message
            </Title>
          </div>
        )}
      </Row>
    </>
  );
}

interface ClassifierInterface {
  _id: string;
  category_id: string;
  pathology: string;
  createdAt: string;
  updatedAt: string;
}

export function useGetClassifierByDatasetId(datasetId: String | undefined) {
  const [classifiers, setClassifiers] = useState<ClassifierInterface[]>([]);
  const [datasetInfo, setDatasetInfo] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (!datasetId) return;
    (async () => {
      try {
        setLoading(true);
        (async () => {
          const res = await getDatasetbyDatasetId(datasetId);
          setDatasetInfo(res);
        })();
        const data = await getClassifierByDatasetId(datasetId);
        setClassifiers(data);
        setLoading(false);
      } catch (err) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [datasetId]);
  return { classifiers, loading, error, datasetInfo };
}

export default ClassiferPage;
