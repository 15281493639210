import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LeftOutlined,
  LoadingOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Button, Popover, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { BsZoomIn, BsZoomOut } from "react-icons/bs";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import styles from "./ImageViewer.module.scss";
const { Title } = Typography;

function ImageControl({
  imgNameAndDes,
  annotationMode,
  selectedImage,
  selectedImageErrMsg,
  images,
  selectedImgCount,
  setSelectedImgCount,
  emptyBucketImgHandler,
  setUserAnnotationId,
  setShowClassesForReview,
  isReviewRoute,
  reviewAnnotatorDetails,
  handleAnnotationMarkerJs,
  selectedColorForSubClass,
}: {
  imgRef: React.MutableRefObject<HTMLImageElement | null>;
  imgNameAndDes: {
    name: string;
    filename: string;
  };
  annotationMode: boolean;
  setAnnotationMode: (mode: boolean) => void;
  selectedImage: any;
  handleAnnotationMode: (mode: boolean) => void;
  selectedImageErrMsg: string;
  images: object[];
  selectedRadioClass: any;
  selectedImgCount: number;
  setSelectedImgCount: (count: number) => void;
  emptyBucketImgHandler: boolean;
  setUserAnnotationId: (id: any) => void;
  isDoubtToggler: boolean;
  showClassesForReview: any;
  setShowClassesForReview: (value: any) => void;
  isReviewRoute: boolean;
  reviewAnnotatorDetails: any[];
  handleAnnotationMarkerJs: (
    viewerImageRef: HTMLImageElement,
    annotatorImageRef: HTMLImageElement
  ) => void;
  selectedColorForSubClass: string;
}) {
  const generateGoToButtons = () => {
    const buttons = [];
    for (let i = 500; i < images.length; i += 500) {
      buttons.push(
        <Button
          key={i}
          icon={<RightOutlined />}
          type="default"
          size={"large"}
          disabled={selectedImgCount === i || selectedImage.loading}
          className={styles.images_prevAndNextBtn}
          onClick={() => {
            setSelectedImgCount(i);
            setUserAnnotationId("");
            setShowClassesForReview(
              reviewAnnotatorDetails[0]?.annotation === null
                ? true
                : isReviewRoute
                ? false
                : true
            );
          }}
        >
          Go to {i}
        </Button>
      );
    }
    return buttons;
  };
  return (
    <div className={styles.ImageViewer__container}>
      <div className={styles.ImageViewer__titleContainer}>
        {!selectedImage.loading && (
          <Popover
            content={imgNameAndDes?.filename}
            placement="left"
            title={<b>Filename</b>}
          >
            <Title level={4} className={styles.images_imgName}>
              {imgNameAndDes?.name}
            </Title>
          </Popover>
        )}
      </div>

      <LoadingComponent
        annotationMode={annotationMode}
        images={images}
        selectedImage={selectedImage}
        selectedImageErrMsg={selectedImageErrMsg}
        emptyBucketImgHandler={emptyBucketImgHandler}
      >
        {annotationMode ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ImageAnnotator
              selectedColorForSubClass={selectedColorForSubClass}
              selectedImage={selectedImage}
              handleAnnotationMarkerJs={handleAnnotationMarkerJs}
            />
          </div>
        ) : (
          <TransformContainer
            annotationMode={annotationMode}
            images={images}
            selectedImage={selectedImage}
          >
            <ImageViewer selectedImage={selectedImage} />
          </TransformContainer>
        )}
      </LoadingComponent>

      {/* previous and next button container */}
      <div id="buttonContainer">
        {images.length ? (
          <div className={styles.images_prevAndNextBtnDiv}>
            <Button
              type="default"
              size={"large"}
              icon={<DoubleLeftOutlined />}
              className={styles.images_prevAndNextBtn}
              onClick={() => {
                setSelectedImgCount(0);
                setUserAnnotationId("");
                setShowClassesForReview(
                  reviewAnnotatorDetails[0].annotation === null
                    ? true
                    : isReviewRoute
                    ? false
                    : true
                );
              }}
            >
              First
            </Button>
            <Button
              type="default"
              size={"large"}
              disabled={
                (selectedImgCount === 0 || selectedImage.loading) && true
              }
              className={styles.images_prevAndNextBtn}
              icon={<LeftOutlined />}
              onClick={() => {
                setSelectedImgCount(selectedImgCount - 1);
                // setNewRegionalAnnotatedImg(null)
                setUserAnnotationId("");
                setShowClassesForReview(
                  reviewAnnotatorDetails[0].annotation === null
                    ? true
                    : isReviewRoute
                    ? false
                    : true
                );
              }}
            >
              Previous
            </Button>
            <Button
              icon={<RightOutlined />}
              type="default"
              size={"large"}
              disabled={
                (selectedImgCount === images.length - 1 ||
                  selectedImage.loading) &&
                true
              }
              className={styles.images_prevAndNextBtn}
              onClick={() => {
                setSelectedImgCount(selectedImgCount + 1);
                // setNewRegionalAnnotatedImg(null)
                setUserAnnotationId("");
                setShowClassesForReview(
                  reviewAnnotatorDetails[0]?.annotation === null
                    ? true
                    : isReviewRoute
                    ? false
                    : true
                );
              }}
            >
              Next
            </Button>
            <Button
              type="default"
              size={"large"}
              icon={<DoubleRightOutlined />}
              className={styles.images_prevAndNextBtn}
              onClick={() => {
                setSelectedImgCount(images.length - 1);
                setUserAnnotationId("");
                setShowClassesForReview(
                  reviewAnnotatorDetails[0].annotation === null
                    ? true
                    : isReviewRoute
                    ? false
                    : true
                );
              }}
            >
              Last
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
      <div id="buttonContainer">{generateGoToButtons()}</div>
    </div>
  );
}

// Component for handling loading and errors
function LoadingComponent({
  children,
  images,
  selectedImage,
  selectedImageErrMsg,
  emptyBucketImgHandler,
}: {
  children: any;
  annotationMode: boolean;
  images: Object[];
  selectedImage: any;
  selectedImageErrMsg: string;
  emptyBucketImgHandler: boolean;
}) {
  return (
    <div className={styles.ImageViewer__skeleton_container}>
      {selectedImage.error && (
        <div className={styles.loadingDiv}>
          <p
            className={styles.loadingDiv_spin}
            style={{
              color: "red",
              textAlign: "center",
            }}
          >
            {selectedImageErrMsg
              ? selectedImageErrMsg
              : "Something went wrong."}
          </p>
        </div>
      )}
      {emptyBucketImgHandler && (
        <div className={styles.loadingDiv}>
          <p
            className={styles.loadingDiv_spin}
            style={{
              color: "red",
              textAlign: "center",
            }}
          >
            There are no images in this bucket.
          </p>
        </div>
      )}
      {(!emptyBucketImgHandler && images.length === 0) ||
      selectedImage.loading ? (
        <div className={styles.loadingDiv}>
          <Spin
            size="large"
            indicator={
              <LoadingOutlined className={styles.loadingSpinIcon} spin />
            }
            tip="Loading..."
            className={styles.loadingDiv_spin}
          />
        </div>
      ) : images.length && !selectedImage.error && selectedImage.data ? (
        children
      ) : null}
    </div>
  );
}

// For handling zoom in, zoom out and reset function
function TransformContainer({
  children,
  annotationMode,
  images,
  selectedImage,
}: {
  children: any;
  annotationMode: boolean;
  images: object[];
  selectedImage: any;
}) {
  return (
    <TransformWrapper>
      {({ zoomIn, zoomOut, resetTransform }) => (
        <div className={styles.ImageViewer__imageViewerContainer}>
          <div
            style={{
              position: "relative",
            }}
          >
            <TransformComponent
              contentStyle={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* image container will be rendered here */}
              {children}
            </TransformComponent>
            {/* transform button container */}
            <div className={styles.ImageViewer__transformButtonContainer}>
              {annotationMode ||
              images.length === 0 ||
              selectedImage.loading ? null : (
                <>
                  <Button
                    onClick={() => zoomIn()}
                    className={styles.images_imgControlBtns}
                  >
                    <BsZoomIn />
                  </Button>
                  <Button
                    onClick={() => zoomOut()}
                    className={styles.images_imgControlBtns}
                  >
                    <BsZoomOut />
                  </Button>
                  <Button
                    onClick={() => resetTransform()}
                    className={styles.images_imgControlBtns}
                  >
                    Reset
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </TransformWrapper>
  );
}

function ImageViewer({ selectedImage }: { selectedImage: any }) {
  return (
    <img
      id="sourceImage"
      className={styles.ImageViewer__image}
      alt="sampleImage"
      src={`data:image/png;base64,${selectedImage.data?.encodedImg}`}
    />
  );
}

function ImageAnnotator({
  selectedImage,
  handleAnnotationMarkerJs,
  selectedColorForSubClass,
}: {
  selectedImage: any;
  handleAnnotationMarkerJs: (
    viewerImageRef: HTMLImageElement,
    annotatorImageRef: HTMLImageElement
  ) => void;
  selectedColorForSubClass: string;
}) {
  const [onClickRenderAnnotator, setOnClickRenderAnnotator] = useState(false);
  useEffect(() => {
    const annotatorImageReference = document.getElementById(
      "sampleImage"
    ) as HTMLImageElement;
    const sourceImageReference = document.getElementById(
      "sourceImage"
    ) as HTMLImageElement;
    // this set timeout is introduced to remove the render collision between the annotator and sidebar(already present class and subclass)
    setTimeout(() => {
      handleAnnotationMarkerJs(sourceImageReference, annotatorImageReference);
    }, 100);
    return () => {
      // cleanup
      setTimeout(() => {
        const markerAreaAnnotators =
          document.getElementsByClassName("__markerjs2_");
        if (markerAreaAnnotators.length > 0) {
          markerAreaAnnotators[0].remove();
        }
      }, 100);
    };
  }, [
    selectedImage.data?.encodedImg,
    selectedColorForSubClass,
    onClickRenderAnnotator,
  ]);

  return (
    <div className={styles.ImageViewer__imageAnnotatorContainer}>
      <img
        id="sourceImage"
        className={styles.ImageViewer__image}
        alt="lower layer"
        src={`data:image/png;base64,${selectedImage.data?.encodedImg}`}
      />

      <img
        onClick={() => setOnClickRenderAnnotator((prev) => !prev)}
        alt="sampleImage"
        id="sampleImage"
        src={`data:image/png;base64,${selectedImage.data?.encodedImg}`}
      />
    </div>
  );
}

export default ImageControl;
