import api, { authHeader } from "./index";

export async function createClassifier(body: object) {
  const response = await api.post(
    `/classifier/create-classifier`,
    body,
    authHeader()
  );
  return response.data?.result;
}

export async function getAllClassifiers() {
  const response = await api.get(`/classifier/all`, authHeader());
  return response.data?.result;
}

export async function getClassifierById(classifierId: string) {
  const response = await api.get(
    `/classifier/getClassifierById/${classifierId}`,
    authHeader()
  );
  return response.data?.result;
}

export async function updateClassifier(updateClassifier: object) {
  const response = await api.post(
    `/classifier/update`,
    updateClassifier,
    authHeader()
  );
  return response.data?.result;
}

export async function createClass(body: object) {
  const response = await api.post(
    `/classifier/create-class`,
    body,
    authHeader()
  );
  return response.data?.result;
}

export async function createClasses(body: object) {
  const response = await api.post(
    `/classifier/create-classes`,
    body,
    authHeader()
  );
  return response.data?.result;
}

export async function updateClass(classId: string, body: object) {
  const response = await api.post(
    `/classifier/edit-class/${classId}`,
    body,
    authHeader()
  );
  return response.data?.result;
}

export async function removeClasses(classifierId: string, classIds: string[]) {
  const response = await api.post(
    `/classifier/remove-classes`,
    {
      classifier_id: classifierId,
      classIds,
    },
    authHeader()
  );
  return response.data?.result;
}

export async function createSubClasses(body: object) {
  const response = await api.post(
    `/classifier/create-subclasses`,
    body,
    authHeader()
  );
  return response.data?.result;
}

export async function updateSubClass(subClassId: string, body: object) {
  const response = await api.post(
    `/classifier/edit-subclass/${subClassId}`,
    body,
    authHeader()
  );
  return response.data?.result;
}

export async function removeSubClasses(classId: string, subClasses: string[]) {
  const response = await api.post(
    `/classifier/remove-subclasses`,
    {
      class_id: classId,
      subClassIDs: subClasses,
    },
    authHeader()
  );
  return response.data?.result;
}
