import { Button, Col, Row, Select, Space, Table, Typography } from "antd";
import React from "react";
import { Toaster } from "react-hot-toast";
import styles from "./RemoveClasses.module.scss";
import { RemoveClassesLogic } from "./RemoveClassesLogic";

const { Title } = Typography;
const { Option } = Select;

function RemoveClasses() {
  const {
    classifiers,
    selectedClassifier,
    setSelectedClassifier,
    classes,
    setSelectedClasses,
    handleSingleClassDelete,
    handleClassesDelete,
  }: {
    classifiers: any;
    selectedClassifier: any;
    setSelectedClassifier: any;
    classes: object[];
    setSelectedClasses: (e: any) => void;
    handleSingleClassDelete: (classId: any) => void;
    handleClassesDelete: () => void;
  } = RemoveClassesLogic();

  const columns = [
    {
      title: "Class",
      key: "nameAndDes",
      width: "33.3%",
      render: (details: any) => {
        return <>{details.name}</>;
      },
    },
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
      width: "33.3%",
    },
    {
      title: "Delete",
      key: "delete",
      width: "33.3%",

      render: (details: any) => {
        return (
          <Space size="middle">
            <Button
              onClick={() => {
                handleSingleClassDelete(details._id);
              }}
            >
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (_selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedClasses(selectedRows.map((row: any) => row._id));
    },
  };

  return (
    <div>
      <Title level={2} className={styles.classes_heading}>
        Remove Classes
      </Title>
      <Title level={5} className={styles.classes_description}>
        Select classifier first in order to remove classes
      </Title>
      <Row>
        <Col md={12}>
          <div className={styles.filterCol}>
            <Title level={5} className={styles.classes_filterHeading}>
              Select classifier :
            </Title>
            {classifiers.length && (
              <Select
                style={{ width: 120 }}
                className={styles.classes_filter}
                onChange={(val: string) => {
                  const classifier = classifiers.find(
                    (classifier: any) => classifier.pathology === val
                  );
                  setSelectedClassifier(classifier);
                }}
              >
                {classifiers?.map((classifier: any) => (
                  <Option key={classifier._id} value={classifier.pathology}>
                    {classifier.pathology}
                  </Option>
                ))}
              </Select>
            )}
          </div>
        </Col>
        <Col md={12}>
          <Button onClick={() => handleClassesDelete()}>
            Delete Selected Classes
          </Button>
        </Col>
      </Row>

      {/* Table */}
      {selectedClassifier && (
        <Table
          dataSource={classes}
          columns={columns}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
        />
      )}

      <Toaster
        toastOptions={{
          error: {
            style: {
              border: "1px solid #FF4C4C",
            },
          },
        }}
      />
    </div>
  );
}

export default RemoveClasses;
