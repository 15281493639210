import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { userSelector } from "store/userSlice";
import { getAllAccessibleDatasets } from "api/datasetApi";

interface DatasetType {
  key: string;
  _id: string;
  name: string;
  description: string;
  cameraType_name: string;
  imagesCount: number;
  dataset_id: object;
  accessible_classifiers: string[];
}

export const ReviewerDatasetLogic = ({ datasets, setDatasets }: any) => {
  let dataKeyCount = 0;
  let datasetTableInfo: DatasetType[] = [];
  const userInfo = useSelector(userSelector);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [createReviewOpenModal, setCreateReviewOpenModal] =
    useState<boolean>(false);

  /** Putting datasets in the interface form for ease of showing in table */
  function addDatasetsInTable() {
    datasetTableInfo = [];

    datasets.data.length &&
      datasets.data
        .filter((val: any) =>
          searchTerm === ""
            ? val
            : val.dataset_id.name
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) && val
        )
        .forEach((eachDataset: any) => {
          //
          datasetTableInfo.push({
            key: dataKeyCount.toString(),
            _id: eachDataset.dataset_id._id,
            name: eachDataset.dataset_id.name,
            description: eachDataset.dataset_id.description,
            cameraType_name: eachDataset.dataset_id.cameraType_name,
            imagesCount: eachDataset.imagesCount,
            dataset_id: eachDataset.dataset_id,
            accessible_classifiers: eachDataset.accessible_classifiers,
          });
          dataKeyCount += 1;
        });
  }
  addDatasetsInTable();

  useEffect(() => {
    addDatasetsInTable();
  }, [searchTerm]);

  return {
    userInfo,
    datasets,
    setSearchTerm,
    datasetTableInfo,
    createReviewOpenModal,
    setCreateReviewOpenModal,
  };
};
