import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { userSelector } from "store/userSlice";

export const ReviewerRoute = () => {
  const user = useSelector(userSelector);

  return (
    user.permission_level &&
    (user.isAuthenticated && user.permission_level === 2 ? (
      <Outlet />
    ) : (
      <Navigate to="/not-found" />
    ))
  );
};
