import type { PaginationProps } from "antd";
import {
  Button,
  Col,
  Input,
  Pagination,
  Progress,
  Row,
  Spin,
  Table,
  Typography,
} from "antd";
import { Toaster } from "react-hot-toast";
import styles from "./Jobs.module.scss";
import { JobsLogic } from "./JobsLogic";

// Icons
import { LoadingOutlined } from "@ant-design/icons";
import { useState } from "react";
import { IoIosSearch } from "react-icons/io";

const { Title } = Typography;

function Jobs() {
  const {
    totalAnnotationCount,
    pageCount,
    setPageCount,
    pageLimit,
    setPageLimit,
    jobsData,
    jobsTableInfo,
    jobsFetchErrMsg,
    handleJobStart,
  }: {
    totalAnnotationCount: number;
    pageCount: number;
    setPageCount: (count: number) => void;
    pageLimit: number;
    setPageLimit: (limit: number) => void;
    jobsData: {
      loading: boolean;
      error: boolean;
      data: any[];
    };
    jobsTableInfo: any;
    jobsFetchErrMsg: string;
    handleJobStart: (fileId: string) => void;
  } = JobsLogic();

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    newPageLimit
  ) => {
    setPageLimit(newPageLimit);
  };
  const onChange: PaginationProps["onChange"] = (pageNumber) => {
    setPageCount(pageNumber);
  };
  const [searchQuery, setSearchQuery] = useState("");

  const tableHeaders = [
    {
      title: "Job Name",
      key: "jobName",
      dataIndex: "jobName",
      width: "25%",
    },
    {
      title: "Dataset",
      key: "datasetName",
      dataIndex: "datasetName",
      width: "15%",
    },
    {
      title: "Classifier",
      key: "classifierName",
      dataIndex: "classifierName",
      width: "15%",
    },
    {
      title: "Created At",
      key: "createdAt",
      dataIndex: "createdAt",
      width: "20%",
    },
    {
      title: "Progress",
      key: "progress",
      align: "center" as const,
      width: "10%",
      render: (details: any) => {
        return (
          <>
            {details?.status === "ready" || details?.status === "pending" ? (
              <Title level={5} className={styles.jobs_tableReadyProgressDot}>
                ...
              </Title>
            ) : (
              <Progress
                className={styles.jobs_tableProgressBar}
                percent={
                  details?.status === "completed" ? 100 : details.percentage
                }
                strokeColor={
                  details?.status === "completed" ? "#52C41A" : "#e3a805"
                }
                size="small"
              />
            )}
          </>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      width: "15%",
      align: "center" as const,
      render: (details: any) => {
        return (
          <>
            {details?.status === "completed" ? (
              <Title
                level={5}
                className={styles.metrics_downloadPending}
                style={{ color: "#52C41A" }}
              >
                Completed
              </Title>
            ) : details?.status === "in-progress" ? (
              <>
                <Title
                  level={5}
                  className={styles.metrics_downloadPending}
                  style={{ color: "#e3a805" }}
                >
                  In progress
                </Title>
              </>
            ) : details?.status === "pending" ? (
              <>
                <Title level={5} className={styles.metrics_downloadPending}>
                  Pending
                </Title>
              </>
            ) : (
              <>
                <Button
                  className={styles.metrics_downloadBtn}
                  onClick={() => handleJobStart(details._id)}
                >
                  Start
                </Button>
              </>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Row
        style={{
          marginBottom: "1rem",
        }}
      >
        <Col lg={8} md={4} sm={4} xs={24}>
          <Title level={2} className={styles.jobs_heading}>
            Jobs
          </Title>
        </Col>
        <Col lg={16} md={20} sm={20} xs={24}>
          <Row justify={"end"}>
            <Col flex={"none"} sm={12} xs={24} className={styles.jobs_column}>
              <Input
                size="large"
                placeholder="Search..."
                prefix={
                  <IoIosSearch className={styles.jobs__searchFilterIcon} />
                }
                className={styles.jobs__searchFilter}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <div className={styles.metrics_paginationDiv}>
        {jobsTableInfo.length ? (
          <Pagination
            current={pageCount}
            total={totalAnnotationCount}
            pageSize={pageLimit}
            className={styles.datasets_pagination}
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            onChange={onChange}
          />
        ) : (
          ""
        )}
      </div>

      {jobsData.error ? (
        <>
          <Title level={4} className={styles.datasets_heading}>
            {jobsFetchErrMsg}
          </Title>
        </>
      ) : jobsData.loading ? (
        <div className={styles.loadingDiv}>
          <Spin
            size="large"
            indicator={
              <LoadingOutlined className={styles.loadingSpinIcon} spin />
            }
            tip="Fetching Jobs Data..."
            className={styles.loadingDiv_spin}
          />
        </div>
      ) : (
        <>
          <Table
            dataSource={jobsTableInfo.filter((job: any) =>
              job.jobName.toLowerCase().includes(searchQuery.toLowerCase())
            )}
            columns={tableHeaders}
            className={styles.metrics_table}
            scroll={{ x: 1000 }}
            pagination={false}
          />
        </>
      )}

      <Toaster
        toastOptions={{
          success: {
            style: {
              border: "1px solid #199c3c",
            },
          },
          error: {
            style: {
              border: "1px solid #FF4C4C",
            },
          },
        }}
      />
    </>
  );
}

export default Jobs;
