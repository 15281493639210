import React, { useState } from "react";
import { Toaster } from "react-hot-toast";
import styles from "./ReviewerDataset.module.scss";
import { Link, useNavigate } from "react-router-dom";
import {
  Typography,
  Row,
  Col,
  Input,
  Button,
  Space,
  Table,
  Grid,
  Spin,
  Popover,
  Modal,
  Select,
} from "antd";
import { ReviewerDatasetLogic } from "./ReviewerDatasetLogic";
import type { ColumnsType } from "antd/es/table";

// Components
import CreateReviewBucketModal from "components/dataset/AnnotatorDataset/CreateReviewBucketModal";

// Icons
import { LoadingOutlined } from "@ant-design/icons";
import { IoIosSearch } from "react-icons/io";
import { BsImages } from "react-icons/bs";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Title } = Typography;
const { useBreakpoint } = Grid;
const { Option } = Select;

function AnnotatorDataset({ datsets, setDatasets }: any) {
  const { md } = useBreakpoint();
  const {
    userInfo,
    datasets,
    setSearchTerm,
    datasetTableInfo,
    createReviewOpenModal,
    setCreateReviewOpenModal,
  }: {
    userInfo: any;
    datasets: {
      loading: boolean;
      error: boolean;
      data: any[];
    };
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
    datasetTableInfo: any[];
    createReviewOpenModal: boolean;
    setCreateReviewOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  } = ReviewerDatasetLogic({ datsets, setDatasets });

  const navigate = useNavigate();
  const [reviewViewModalOpen, setReviewViewModalOpen] =
    useState<boolean>(false);
  const [selectedUserIdsForView, setSelectedUserIdsForView] = useState<
    string[]
  >([]);

  function handleSelectedViewUsers(selectedUsers: string[], datasetId: string) {
    const userIds: string[] = [];
    selectedUsers.forEach((eachUserEmail: string) => {
      datasetTableInfo.forEach((eachDataset) => {
        if (eachDataset._id === datasetId) {
          eachDataset.dataset_id.annotators.length > 0 &&
            eachDataset.dataset_id.annotators.forEach((eachAnnotator: any) => {
              if (eachAnnotator.email === eachUserEmail) {
                !userIds.includes(eachAnnotator._id) &&
                  userIds.push(eachAnnotator._id);
              }
            });
        }
      });
    });
    setSelectedUserIdsForView(userIds);
  }

  function selectedViewUsersSubmit(datasetId: string) {
    localStorage.removeItem("reviewerUsersInfo");
    localStorage.setItem(
      "reviewerUsersInfo",
      JSON.stringify(selectedUserIdsForView)
    );
    // If one user id is selected, add the name of the user in localStorage
    if (selectedUserIdsForView.length === 1) {
      datasetTableInfo.forEach((eachDataset) => {
        if (eachDataset._id === datasetId) {
          eachDataset.dataset_id.annotators.length > 0 &&
            eachDataset.dataset_id.annotators.forEach((eachAnnotator: any) => {
              if (eachAnnotator._id === selectedUserIdsForView[0]) {
                localStorage.setItem(
                  "reviewerViewAnnotatorName",
                  JSON.stringify(eachAnnotator.name)
                );
              }
            });
        }
      });
    }
    setReviewViewModalOpen(false);
    // redirect to buckets pg
    navigate(`/classifiers/${datasetId}`);
  }

  const normalUserColumns: ColumnsType<any> = [
    {
      title: "Dataset Name",
      key: "nameAndDes",
      render: (details) => {
        return (
          <>
            {details.name}
            <Popover
              content={details.description}
              placement="right"
              title="Description"
            >
              <InfoCircleOutlined
                className={styles.datasetsTableCol_infoIcon}
              />
            </Popover>
          </>
        );
      },
    },
    {
      title: "Camera Type",
      dataIndex: "cameraType_name",
      key: "cameraType_name",
    },
    {
      title: "Images Count",
      dataIndex: "imagesCount",
      key: "imagesCount",
    },
    {
      title: "Actions",
      key: "action",
      render: (details) => {
        return (
          <Space size="middle">
            <Link to={`/classifiers/${details._id}`}>
              <Button
                className={styles.datasets_browseBtn}
                onClick={() => localStorage.removeItem("reviewerUsersInfo")}
              >
                Browse
              </Button>
            </Link>
            <Popover
              placement="right"
              title={"Select mode"}
              content={
                <>
                  <Title
                    level={5}
                    className={styles.datasets_reviewModePopoverBtn}
                    onClick={() => setReviewViewModalOpen(true)}
                  >
                    Browse Annotators
                  </Title>
                  <Title
                    level={5}
                    className={styles.datasets_reviewModePopoverBtn}
                    onClick={() => setCreateReviewOpenModal(true)}
                  >
                    Review
                    {/* //TODO: Ask about name change */}
                  </Title>

                  {/* View Modal */}
                  <Modal
                    title={"Select annotators to view their annotation details"}
                    centered
                    visible={reviewViewModalOpen}
                    onOk={() => selectedViewUsersSubmit(details._id)}
                    onCancel={() => setReviewViewModalOpen(false)}
                  >
                    <p>Currently viewing {details.name}</p>
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Please select annotators"
                      onChange={(e) => handleSelectedViewUsers(e, details._id)}
                    >
                      {details.dataset_id.annotators?.map((annotator: any) => {
                        return (
                          <Option key={annotator.email}>
                            {annotator.email}
                          </Option>
                        );
                      })}
                    </Select>
                  </Modal>

                  {/* Create Review Bucket Modal */}
                  <CreateReviewBucketModal
                    dataset={details}
                    createReviewOpenModal={createReviewOpenModal}
                    setCreateReviewOpenModal={setCreateReviewOpenModal}
                  />
                </>
              }
              // trigger="click"
            >
              <Button className={styles.datasets_browseBtn}>Review</Button>
            </Popover>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <h3>Welcome, {userInfo.name}</h3>
      <Row className={styles.datasets_headingRow}>
        <Col md={12}>
          <Title level={2} className={styles.datasets_heading}>
            Datasets
          </Title>
          {/* <Title level={5} className={styles.datasets_description}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tem.
          </Title> */}
        </Col>
        <Col md={12} xs={24}>
          <Input
            size="large"
            placeholder="Search..."
            prefix={<IoIosSearch className={styles.datasets_searchBoxIcon} />}
            className={styles.datasets_searchBox}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Col>
      </Row>

      <div className={styles.datasets_tableRow}>
        {datasets.loading ? (
          <div className={styles.loadingDiv}>
            <Spin
              size="large"
              indicator={
                <LoadingOutlined className={styles.loadingSpinIcon} spin />
              }
              tip="Fetching Datasets..."
              className={styles.loadingDiv_spin}
            />
          </div>
        ) : md ? (
          <Table
            columns={normalUserColumns}
            dataSource={datasetTableInfo}
            className={styles.table}
            scroll={{ x: 1000 }}
          />
        ) : (
          <Row className={styles.categories_row}>
            {datasetTableInfo.length ? (
              datasetTableInfo.map((eachDataset) => {
                return (
                  <Col
                    lg={8}
                    md={12}
                    xs={24}
                    className={styles.categories_col}
                    key={eachDataset._id}
                  >
                    <div className={styles.categories_card}>
                      <Title level={4} className={styles.categoriesCard_type}>
                        {eachDataset.cameraType_name}
                      </Title>
                      <Title level={4} className={styles.categoriesCard_name}>
                        {eachDataset.name}
                        <Popover
                          content={eachDataset.description}
                          placement="bottom"
                          title="Description"
                        >
                          <InfoCircleOutlined
                            className={styles.cameras_infoIcon}
                          />
                        </Popover>
                      </Title>
                      <Row className={styles.categoriesCard_dbAndImgsRow}>
                        <Col span={16}>
                          <Title
                            level={4}
                            className={styles.categoriesCard_dbAndImgs}
                          >
                            <BsImages />
                            <span>{eachDataset.imagesCount}</span>
                          </Title>
                        </Col>
                      </Row>
                      <Link to={`/classifiers/${eachDataset._id}`}>
                        <Button
                          className={styles.categoriesCard_btn}
                          style={{
                            margin: "1rem 0 0 auto",
                          }}
                        >
                          Browse Images
                        </Button>
                      </Link>
                    </div>
                  </Col>
                );
              })
            ) : (
              <div className={styles.loadingDiv}>
                <p>No Datasets Found</p>
              </div>
            )}
          </Row>
        )}
      </div>
      <Toaster
        toastOptions={{
          success: {
            style: {
              border: "1px solid #199c3c",
            },
          },
          error: {
            style: {
              border: "1px solid #FF4C4C",
            },
          },
        }}
      />
    </>
  );
}

export default AnnotatorDataset;
