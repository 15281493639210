import { Layout as AntLayout, Menu, MenuProps } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { userSelector } from "store/userSlice";
import styles from "./Sidebar.module.scss";

// Icons
import {
  CloseOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { AiOutlineEye } from "react-icons/ai";
import {
  BsBox,
  BsClipboardData,
  BsCloudUpload,
  BsMenuButtonWide,
  BsUpload
} from "react-icons/bs";
import { CgUserList } from "react-icons/cg";
import { FaLaptopMedical, FaRegUser } from "react-icons/fa";
import {
  FiBook,
  FiCamera,
  FiDatabase,
  FiInbox,
  FiSearch,
} from "react-icons/fi";
import { GrFilter } from "react-icons/gr";
import { HiOutlinePencil, HiOutlineUsers } from "react-icons/hi";
import { IoMdAdd } from "react-icons/io";
import { VscCircleOutline } from "react-icons/vsc";

const { Sider } = AntLayout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

function Sidebar({
  md,
  collapsed,
  setCollapsed,
  hamPosition,
  setHamPosition,
}: {
  md: any;
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  hamPosition: string;
  setHamPosition: (hamPosition: string) => void;
}) {
  const location = useLocation();
  const userInfo = useSelector(userSelector);
  const sidebarDynamicItems = useSelector(
    (state: any) => state.sidebarDynamicItems.value
  );

  const sidebarDynamics: any[] = [];

  if (location.pathname === "/") {
    const links = sidebarDynamicItems?.map((eachItem: any, index: number) => {
      return getItem(
        <Link to={eachItem.link} rel="noopener noreferrer">
          {eachItem.name}
        </Link>,
        (index + 2).toString(),
        <FiInbox />
      );
    });
    sidebarDynamics.push(...links);
  } else {
    let cameraIndexNumber = 0;
    const links = sidebarDynamicItems?.map((eachItem: any, index: number) => {
      const eachItemCameras = eachItem?.cameraTypes.map((eachCamera: any) => {
        cameraIndexNumber += 1;
        return getItem(
          <Link
            to={`/modality/${eachItem._id}/${eachCamera._id}/datasets`}
            rel="noopener noreferrer"
          >
            {eachCamera.cameraType}
          </Link>,
          "cameraTypes" + cameraIndexNumber.toString(),
          <FiCamera />
        );
      });

      return getItem(
        eachItem.name,
        "subHeadings" + (index + 2).toString(),
        <FiInbox />,
        eachItemCameras
      );
    });
    sidebarDynamics.push(...links);
  }

  const normalUserItems: MenuItem[] = [
    getItem(
      <p className={styles.sidebar_subHeadings}>MAIN</p>,
      "headings1",
      collapsed && <VscCircleOutline />
    ),
    getItem(
      <Link to="/" rel="noopener noreferrer">
        Home
      </Link>,
      "1",
      <BsBox />
    ),
    getItem("Search Image", "2", <FiSearch />, [
      getItem(
        <Link to="/image/search?filter=imageNumber" rel="noopener noreferrer">
          Search Image by number
        </Link>,
        "2002",
        <FiSearch />
      ),
      getItem(
        <Link to="/image/search?filter=fileName" rel="noopener noreferrer">
          Search Image by file
        </Link>,
        "2003",
        <FiSearch />
      ),
    ]),
    getItem("My Annotations", "annotationHeading", <FaLaptopMedical />, [
      getItem(
        <Link to="/annotation/history">
          {/* <p className={styles.navbar_popupLink}> */}
          All Annotations
          {/* </p> */}
        </Link>,
        "150",
        <FiBook style={{ fontSize: "1rem" }} />
      ),
      getItem(
        <Link to="/annotations-today" rel="noopener noreferrer">
          Today's Annotations
        </Link>,
        "151",
        <BsBox />
      ),
    ]),
  ];

  const reviewerUserItems: MenuItem[] = [
    ...normalUserItems,
    getItem(
      <Link to="/datasets/reviewer/all" rel="noopener noreferrer">
        My Datasets
      </Link>,
      "3",
      <FiDatabase />
    ),
    getItem(
      <Link to="/datasets/review-buckets/all" rel="noopener noreferrer">
        My Reviews
      </Link>,
      "4",
      <FiDatabase />
    ),
  ];

  const adminUserItems: MenuItem[] = [
    ...normalUserItems,
    getItem(
      <Link to="/datasets/all" rel="noopener noreferrer">
        Available Datasets
      </Link>,
      "3",
      <FiDatabase />
    ),
    getItem(
      <Link to="/admin/datasets/review-buckets/all" rel="noopener noreferrer">
        My Reviews
      </Link>,
      "4",
      <FiDatabase />
    ),
    // getItem(
    //     "Modalities",
    //     "subHeadings1",
    //     <FaLaptopMedical />,
    //     sidebarDynamics
    // ),
    getItem(
      <p className={styles.sidebar_subHeadings}>ADMIN</p>,
      "headingsAdmin1",
      collapsed && <VscCircleOutline />
    ),
    getItem("Modality", "subHeadingsAdmin1", <FaLaptopMedical />, [
      getItem(
        <Link to="/modality/create" rel="noopener noreferrer">
          Add Modality
        </Link>,
        "100",
        <IoMdAdd />
      ),
      getItem(
        <Link to="/admin/modality/all" rel="noopener noreferrer">
          View Modalities
        </Link>,
        "101",
        <AiOutlineEye />
      ),
    ]),
    getItem("Dataset", "subHeadingsAdmin2", <FiDatabase />, [
      getItem(
        <Link to="/dataset/create" rel="noopener noreferrer">
          Create Dataset
        </Link>,
        "102",
        <IoMdAdd />
      ),
      getItem(
        <Link to="/admin/dataset/all" rel="noopener noreferrer">
          Manage Datasets
        </Link>,
        "103",
        <AiOutlineEye />
      ),
    ]),
    getItem("Classifiers", "subHeadingsAdmin3", <GrFilter />, [
      getItem(
        <Link to="/classifiers/edit" rel="noopener noreferrer">
          Edit Classifiers
        </Link>,
        "105",
        <HiOutlinePencil />
      ),
      getItem(
        <Link to="/classifiers/all" rel="noopener noreferrer">
          Manage Classifiers
        </Link>,
        "106",
        <AiOutlineEye />
      ),
    ]),
    getItem("Users", "subHeadingsAdmin4", <FaRegUser />, [
      getItem(
        <Link to="/user/register" rel="noopener noreferrer">
          Create Users
        </Link>,
        "107",
        <IoMdAdd />
      ),
      getItem(
        <Link to="/user/manage" rel="noopener noreferrer">
          View Users
        </Link>,
        "108",
        <AiOutlineEye />
      ),
    ]),
    getItem("Annotation Data", "subHeadingsAdmin5", <BsClipboardData />, [
      getItem(
        <Link to="/metrics" rel="noopener noreferrer">
          Metrics
        </Link>,
        "109",
        <CgUserList />
      ),
      getItem(
        <Link to="/user/annotations" rel="noopener noreferrer">
          User Annotations
        </Link>,
        "110",
        <CgUserList />
      ),
      getItem(
        <Link to="/dataset/classifier/annotations" rel="noopener noreferrer">
          Dataset Annotations
        </Link>,
        "111",
        <HiOutlineUsers />
      ),
    ]),
    getItem("Upload Dataset", "subHeadingsAdmin6", <BsCloudUpload />, [
      getItem(
        <Link to="/upload/data" rel="noopener noreferrer">
          Upload New Dataset
        </Link>,
        "112",
        <BsUpload />
      ),
      getItem(
        <Link to="/upload/jobs" rel="noopener noreferrer">
          View Upload Jobs
        </Link>,
        "113",
        <BsMenuButtonWide />
      ),
    ]),
  ];

  return (
    <>
      <div
        style={{
          width: collapsed ? "78px" : "15rem",
          boxShadow: md ? "none" : "4px 4px 6px 3px rgba(0, 0, 0, 0.06)",
          position: md ? "fixed" : "fixed",
          zIndex: md ? "0" : "2",
          left: md ? "0" : hamPosition,
        }}
        className={styles.sidebarDiv}
      >
        <Sider
          collapsed={collapsed}
          onCollapse={(value: boolean) => setCollapsed(value)}
          className={styles.sidebarLayout}
          style={{
            backgroundColor: "#fff",
          }}
        >
          <div className={styles.sidebarLogoDiv}>
            {!collapsed && (
              <a href="/" className={styles.sidebarLogo}>
                Data<span>Prime</span>
              </a>
            )}
            {collapsed ? (
              <MenuFoldOutlined
                style={{ marginTop: collapsed && "0.9rem" }}
                className={styles.toggleCollapseBtn}
                onClick={() => setCollapsed(!collapsed)}
              />
            ) : md ? (
              <MenuUnfoldOutlined
                className={styles.toggleCollapseBtn}
                onClick={() => setCollapsed(!collapsed)}
              />
            ) : (
              <CloseOutlined
                className={styles.toggleCollapseBtn}
                onClick={() => setHamPosition("-240px")}
              />
            )}
          </div>
          <div>
            <Menu
              defaultSelectedKeys={["0"]}
              defaultOpenKeys={["sub1"]}
              mode="inline"
              theme="light"
              items={
                userInfo.permission_level === 2
                  ? reviewerUserItems
                  : userInfo.permission_level >= 3
                  ? adminUserItems
                  : normalUserItems
              }
            />
          </div>
        </Sider>
      </div>
    </>
  );
}

export default Sidebar;
