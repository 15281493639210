import {
  Col,
  Input,
  Modal,
  Row,
  Select,
  Slider,
  Switch,
  Typography,
} from "antd";
import styles from "./CreateReviewBucketModal.module.scss";
import { CreateReviewBucketModalLogic } from "./CreateReviewBucketModalLogic";

const { Title } = Typography;
const { Option } = Select;

function CreateReviewBucketModal({
  dataset,
  createReviewOpenModal,
  setCreateReviewOpenModal,
}: {
  dataset: any;
  createReviewOpenModal: boolean;
  setCreateReviewOpenModal: (value: boolean) => void;
}) {
  const {
    confirmLoading,
    handleOk,
    reviewBucketInputDetails,
    setReviewBucketInputDetails,
    handleReviewBucketInputChange,
    sliderOverallDatasetInput,
    setSliderOverallDatasetInput,
    buckets,
    bucketWiseToggler,
    setBucketWiseToggler,
    handleBucketWiseToggler,
    bucketWiseClassImagesCount,
    reviewBucketName,
    setReviewBucketName,
  }: {
    confirmLoading: boolean;
    handleOk: () => void;
    sliderOverallDatasetInput: number;
    setSliderOverallDatasetInput: (value: number) => void;
    reviewBucketInputDetails: any;
    setReviewBucketInputDetails: (value: any) => void;
    handleReviewBucketInputChange: (e: any, key: string) => void;
    buckets: any[];
    bucketWiseToggler: any;
    setBucketWiseToggler: (value: any) => void;
    handleBucketWiseToggler: (e: any, key: string, _id: string) => void;
    bucketWiseClassImagesCount: any[];
    reviewBucketName: string;
    setReviewBucketName: (value: string) => void;
  } = CreateReviewBucketModalLogic({ dataset, setCreateReviewOpenModal });

  return (
    <>
      <Modal
        width={550}
        bodyStyle={{
          overflowY: "auto",
          maxHeight: "calc(90vh - 200px)",
        }}
        title="Create Review Bucket"
        visible={createReviewOpenModal}
        onOk={handleOk}
        // onOk={() => toast.error("Under construction ⚒️")}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setCreateReviewOpenModal(false);
          setReviewBucketInputDetails({
            classifierId: "",
            reviewAgainst: "",
            reviewAgainstUser: "",
          });
        }}
      >
        {/* Review Bucket Name */}
        <label className={styles.filterComponent_inputLabels}>
          Review Bucket Name
        </label>
        <Input
          value={reviewBucketName}
          onChange={(e: any) => setReviewBucketName(e.target.value)}
          className={styles.filterComponent_inputFields}
        />

        {/* Select Classifier */}
        <label className={styles.filterComponent_inputLabels}>
          Select Classifier
        </label>
        <Select
          className={styles.filterComponent_dropdowns}
          value={reviewBucketInputDetails?.classifierId}
          onChange={(e) => handleReviewBucketInputChange(e, "classifierId")}
        >
          {dataset.dataset_id.classifiers?.length > 0 &&
            dataset.dataset_id.classifiers.map((eachClassifier: any) => {
              return (
                <Option
                  key={eachClassifier.pathology}
                  value={eachClassifier._id}
                >
                  {eachClassifier.pathology}
                </Option>
              );
            })}
        </Select>

        {/* Review Against */}
        <label className={styles.filterComponent_inputLabels}>
          Review Against
        </label>
        <Select
          className={styles.filterComponent_dropdowns}
          value={reviewBucketInputDetails?.reviewAgainst}
          onChange={(e) => handleReviewBucketInputChange(e, "reviewAgainst")}
        >
          <Option key={"initial_annotation"} value={"initial_annotation"}>
            Initial annotation
          </Option>
          <Option key={"final_annotation"} value={"final_annotation"}>
            Final annotation
          </Option>
          <Option key={"user_annotation"} value={"user_annotation"}>
            User's annotation
          </Option>
        </Select>

        {/* Select User */}
        {reviewBucketInputDetails?.reviewAgainst === "user_annotation" ? (
          <>
            <label className={styles.filterComponent_inputLabels}>
              Select User
            </label>
            <Select
              showSearch
              optionFilterProp="children"
              className={styles.filterComponent_dropdowns}
              value={reviewBucketInputDetails?.reviewAgainstUser}
              onChange={(e) =>
                handleReviewBucketInputChange(e, "reviewAgainstUser")
              }
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {dataset.dataset_id.annotators?.length > 0 &&
                dataset.dataset_id.annotators.map((eachAnnotator: any) => {
                  return (
                    <Option
                      key={eachAnnotator.pathology}
                      value={eachAnnotator.email}
                    >
                      {eachAnnotator.name} - {eachAnnotator.email}
                    </Option>
                  );
                })}
            </Select>
          </>
        ) : (
          ""
        )}

        {/* Select Type */}
        <label className={styles.filterComponent_inputLabels}>
          Select Type
        </label>
        <Select
          className={styles.filterComponent_dropdowns}
          value={reviewBucketInputDetails?.reviewSelectionType}
          onChange={(e) =>
            handleReviewBucketInputChange(e, "reviewSelectionType")
          }
        >
          <Option key={"overall"} value={"overall"}>
            Overall Dataset
          </Option>
          <Option key={"bucket-wise"} value={"bucket-wise"}>
            Bucket Wise
          </Option>
        </Select>

        {/* Bucket Wise / Overall Dataset */}
        {reviewBucketInputDetails?.reviewSelectionType === "overall" ? (
          <>
            <p
              className={styles.filterComponent_inputLabels}
              style={{ margin: "1rem auto" }}
            >
              Select percentage
            </p>
            <Row>
              <Col span={2}>
                <p className={styles.reviewBucket_imgSliderCount}>20%</p>
              </Col>
              <Col span={17}>
                <Slider
                  handleStyle={{
                    borderColor: "#6254FF",
                  }}
                  trackStyle={{ backgroundColor: "#626DF6" }}
                  min={20}
                  max={100}
                  value={sliderOverallDatasetInput}
                  onChange={(newVal: number) =>
                    setSliderOverallDatasetInput(newVal)
                  }
                />
              </Col>
              <Col span={2}>
                <p
                  className={styles.reviewBucket_imgSliderCount}
                  style={{ paddingLeft: "0" }}
                >
                  100%
                </p>
              </Col>
              <Col span={3}>
                <p
                  className={
                    styles.reviewBucket_sliderOverallDatasetImagesCount
                  }
                  style={{ paddingLeft: "0" }}
                >
                  &#40;{dataset?.imagesCount}&#41;
                </p>
              </Col>
            </Row>
          </>
        ) : reviewBucketInputDetails?.reviewSelectionType === "bucket-wise" ? (
          <>
            <Row className={styles.reviewBucketCountImgHeadingDiv}>
              <Col span={15}>
                <label
                  className={styles.filterComponent_inputLabels}
                  style={{ top: "0" }}
                >
                  Select from buckets
                </label>
              </Col>
              <Col span={9}>
                <div className={styles.reviewBucket_toggleBtnHeadingDiv}>
                  <span className={styles.reviewBucket_toggleBtnHeading}>
                    Toggle view
                  </span>
                  <Switch
                    className={styles.reviewBucket_toggleBtn}
                    checkedChildren="Count"
                    unCheckedChildren="%"
                    defaultChecked
                    checked={bucketWiseToggler}
                    onChange={(e) => setBucketWiseToggler(e)}
                  />
                </div>
              </Col>
            </Row>
            {bucketWiseToggler ? (
              <>
                {buckets.length > 0 &&
                  buckets.map((eachBucket: any, index: number) => {
                    return (
                      <Row key={index}>
                        <Col span={8}>
                          <Title
                            level={5}
                            className={styles.reviewBucket_imgSliderCount}
                          >
                            {eachBucket?.class.name}
                          </Title>
                        </Col>
                        <Col span={1}>
                          <p
                            className={styles.reviewBucket_imgSliderCount}
                            style={{
                              paddingLeft: "0",
                            }}
                          >
                            0
                          </p>
                        </Col>
                        <Col span={11}>
                          <Slider
                            handleStyle={{
                              borderColor: "#6254FF",
                            }}
                            trackStyle={{
                              backgroundColor: "#626DF6",
                            }}
                            min={0}
                            max={eachBucket?.finalImagesCount}
                            value={
                              bucketWiseClassImagesCount[index].name ===
                              eachBucket?.class.name
                                ? bucketWiseClassImagesCount[index].count
                                : 0
                            }
                            onChange={(e: number) => {
                              handleBucketWiseToggler(
                                e,
                                eachBucket?.class.name,
                                eachBucket?.class._id
                              );
                            }}
                          />
                        </Col>
                        <Col span={4}>
                          <p className={styles.reviewBucket_imgSliderCount}>
                            {eachBucket?.finalImagesCount}
                          </p>
                        </Col>
                      </Row>
                    );
                  })}
              </>
            ) : (
              <>
                {buckets.length > 0 &&
                  buckets.map((eachBucket: any, index: number) => {
                    return (
                      <Row key={index}>
                        <Col span={6}>
                          <Title level={5}>{eachBucket?.class.name}</Title>
                        </Col>
                        <Col span={2}>
                          <p className={styles.reviewBucket_imgSliderCount}>
                            0%
                          </p>
                        </Col>
                        <Col span={11}>
                          <Slider
                            handleStyle={{
                              borderColor: "#6254FF",
                            }}
                            trackStyle={{
                              backgroundColor: "#626DF6",
                            }}
                            min={0}
                            max={100}
                            value={
                              bucketWiseClassImagesCount[index].name ===
                              eachBucket?.class.name
                                ? bucketWiseClassImagesCount[index].count
                                : 0
                            }
                            onChange={(e: number) => {
                              handleBucketWiseToggler(
                                e,
                                eachBucket?.class.name,
                                eachBucket?.class._id
                              );
                            }}
                          />
                        </Col>
                        <Col span={2}>
                          <p
                            className={styles.reviewBucket_imgSliderCount}
                            style={{
                              paddingLeft: "0",
                            }}
                          >
                            100%
                          </p>
                        </Col>
                        <Col span={3}>
                          <p
                            className={
                              styles.reviewBucket_sliderOverallDatasetImagesCount
                            }
                            style={{
                              paddingLeft: "0",
                            }}
                          >
                            &#40;
                            {eachBucket?.finalImagesCount}
                            &#41;
                          </p>
                        </Col>
                      </Row>
                    );
                  })}
              </>
            )}
          </>
        ) : (
          ""
        )}
      </Modal>
    </>
  );
}

export default CreateReviewBucketModal;
