import { adminViewOneUserAnnotation } from "api/adminViewAnnotations";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

export const OneUserAnnotationLogic = ({
  allDatasets,
}: any) => {
  const [classifiers, setClassifiers] = useState<any>([]);
  const [usersUnderClassifier, setUsersUnderClassifier] = useState<any>([]);

  const [selectedDataset, setSelectedDataset] = useState<any>({});
  const [selectedClassifier, setSelectedClassifier] = useState<any>({});
  const [selectedUser, setSelectedUser] = useState<any>({});

  const [tableData, setTableData] = useState<any[]>([]);
  const [totalAnnotationCount, setTotalAnnotationCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(10);

  /** Updating dropdown with all classifiers when dataset selected */
  useEffect(() => {
    setClassifiers([]);
    selectedDataset.dataset?._id &&
      allDatasets.data?.map((eachDataset: any) => {
        if (eachDataset.dataset._id === selectedDataset.dataset._id) {
          setSelectedClassifier(null);
          setSelectedUser(null);
          setTableData([]);
          setClassifiers(eachDataset.dataset.classifiers);
        }
      });
  }, [selectedDataset]);

  /** Updating users when classifier selected */
  useEffect(() => {
    const users: any[] = [];
    setUsersUnderClassifier([]);
    selectedClassifier?._id &&
      selectedDataset.maps.map((eachUser: any) => {
        eachUser.accessible_classifiers.map(
          (eachUserAccessClassifierId: string) => {
            if (eachUserAccessClassifierId === selectedClassifier._id) {
              users.push(eachUser);
            }
          }
        );
      });
    setSelectedUser(null);
    setTableData([]);
    setUsersUnderClassifier(users);
  }, [selectedClassifier]);

  /** Search for particualr dataset */
  const handleDatasetFilter = (value: any) => {
    const filteredDataset = allDatasets.data?.filter(
      (eachDataset: any) => eachDataset.dataset.name === value
    );
    setSelectedDataset(filteredDataset[0]);
  };

  /** Selection of a classifier */
  function handleClassifierChange(classifierId: any) {
    const filteredClassifier = classifiers.filter(
      (eachClassifier: any) => eachClassifier._id === classifierId
    );
    setSelectedClassifier(filteredClassifier[0]);
  }

  /** Selection of a particular user */
  function handleUserChange(userId: string) {
    const filteredUser = usersUnderClassifier.filter(
      (eachUser: any) => eachUser.user_id._id === userId
    );
    setSelectedUser(filteredUser[0]);
  }

  /** Fetch one user annotation */
  async function getAllAnnotationDetails() {
    try {
      const res = await adminViewOneUserAnnotation({
        body: {
          dataset_id: selectedDataset.dataset._id,
          classifier_id: selectedClassifier._id,
          user_id: selectedUser.user_id._id,
        },
        pageCount,
        pageLimit,
      });
      setTableData(res.data);
      setTotalAnnotationCount(res.totalObjects);
    } catch (error: any) {
      toast.error(error.response?.data?.message);
    }
  }

  useEffect(() => {
    if (
      Object.keys(selectedDataset).length &&
      Object.keys(selectedClassifier).length &&
      Object.keys(selectedUser).length
    )
      getAllAnnotationDetails();
  }, [pageCount, pageLimit]);

  return {
    allDatasets,
    selectedDataset,
    pageCount,
    setPageCount,
    pageLimit,
    setPageLimit,
    totalAnnotationCount,
    setSelectedDataset,
    classifiers,
    setClassifiers,
    selectedClassifier,
    setSelectedClassifier,
    usersUnderClassifier,
    selectedUser,
    handleDatasetFilter,
    handleClassifierChange,
    handleUserChange,
    getAllAnnotationDetails,
    tableData,
  };
};
