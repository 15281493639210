import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any[] = [];

export const classifierSlice = createSlice({
  name: "classifierInfo",
  initialState: { value: initialState },
  reducers: {
    setAllClassifiersInfo: (state, { payload }: PayloadAction<any[]>) => {
      state.value = payload;
      localStorage.setItem("classifierInformation", JSON.stringify(payload));
    },
  },
});

export const { setAllClassifiersInfo } = classifierSlice.actions;
export default classifierSlice.reducer;
