import { useState, useEffect } from "react";
import { colors } from "../../../../pages/classifiers/ViewAll/Colors";
import { getClassifierById, updateSubClass } from "api/classifierApi";
import toast from "react-hot-toast";
import { ViewAllLogic } from "../../../../pages/classifiers/ViewAll/ViewAllLogic";

export const UpdateSubClassModalLogic = ({
  item,
  setSelectedItemForUpdate,
  visibleUpdateModal,
  setVisibleUpdateModal,
  setClassesWithSubClass,
}: {
  item: any;
  setSelectedItemForUpdate: any;
  visibleUpdateModal: boolean;
  setVisibleUpdateModal: (visibleUpdateModal: boolean) => void;
  setClassesWithSubClass: (val: any) => void;
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [newUpdatedClass, setnewUpdatedClass] = useState({
    name: "",
  });

  const [newUpdatedSubclassObj, setNewUpdatedSubclassObj] = useState<any>({
    name: "",
    colour: "",
    key: "",
  });

  const [selectedClass, setSelectedClass] = useState<any>({});

  const {
    updatedColor,
    setUpdatedColor,
    isLoading,
    setIsLoading,
    setNewSubClassesColor,
  }: {
    existingColor: string[];
    updatedColor: any[];
    setIsLoading: (value: boolean) => void;

    setExistingColor: (value: any) => void;
    setUpdatedColor: (value: string[]) => void;
    isLoading: boolean;

    setNewSubClassesColor: (value: string) => void;
  } = ViewAllLogic();

  const [chosenColor, setChosenColor] = useState<string>("");
  const [chosenKey, setChosenKey] = useState<number>(0);
  const [existingColor, setExistingColor] = useState<string[]>([]);

  // Adding selectedCat val
  useEffect(() => {
    setSelectedClass(item);
    setNewUpdatedSubclassObj({
      name: item?.subclass.name,
      color: item?.subclass.colour,
      key: item?.subclass.key,
    });
    setnewUpdatedClass({
      name: item?.subclass.name,
    });
  }, [item]);

  // this block of code will run every time the existingColor array updates itself
  useEffect(() => {
    if (existingColor.length === 0) {
      setUpdatedColor(colors);
    } else {
      const updatedColorArray: any[] = [];
      colors.forEach((element: any) => {
        !existingColor.includes(element) && updatedColorArray.push(element);
      });
      setUpdatedColor(updatedColorArray);
      setNewSubClassesColor(updatedColorArray[0]);
    }
  }, [existingColor]);

  // Updating existingColor
  async function fetchExistingColors(classId: string, selectedClassifier: any) {
    setIsLoading(true);
    const res = await getClassifierById(selectedClassifier._id);
    res.classes.forEach((element: any) => {
      if (element._id === classId) {
        const subClasses = element.subclasses;
        const existingColorsArray: string[] = [];
        subClasses.forEach((e: any) => {
          if (e.colour) {
            !existingColorsArray.includes(e.colour) &&
              existingColorsArray.push(e.colour);
          }
        });
        setExistingColor(existingColorsArray);
      }
    });
    setIsLoading(false);
  }

  const handleInputChange = (e: any) => {
    setnewUpdatedClass({
      name: e.target.value,
    });
  };

  // Updation submit
  async function handleOk() {
    setIsLoading(true);

    try {
      setConfirmLoading(true);
      const newUpdatedSubclass = {
        name: newUpdatedClass.name,
        colour: chosenColor ? chosenColor : item?.color,
        key: chosenKey ? chosenKey : item?.Key,
      };
      await updateSubClass(item?.subclass._id, newUpdatedSubclass);
      setConfirmLoading(false);
      setVisibleUpdateModal(false);
      toast.success("Sub Class updated successfully");
      const res = await getClassifierById(item?.classifierId);
      if (existingColor.length === 0) {
        setUpdatedColor(colors);
      } else {
        const updatedColorArray: any[] = [];
        colors.forEach((element: any) => {
          !existingColor.includes(element) && updatedColorArray.push(element);
        });
        setUpdatedColor(updatedColorArray);
        setNewSubClassesColor(updatedColorArray[0]);
      }
      setClassesWithSubClass(res.classes);
      setChosenColor("");
      setChosenKey(0);
      setNewUpdatedSubclassObj({
        name: "",
        color: "",
        key: "",
      });
      setIsLoading(false);
    } catch (error) {
      setConfirmLoading(false);
      toast.error("Error updating sub class");
    }
  }

  // Close modal
  const handleCancel = () => {
    setVisibleUpdateModal(false);
    setSelectedItemForUpdate(null);
  };

  return {
    visibleUpdateModal,
    handleOk,
    confirmLoading,
    handleCancel,
    newUpdatedClass,
    selectedClass,
    handleInputChange,
    newUpdatedSubclassObj,
    fetchExistingColors,
    updatedColor,
    isLoading,
    existingColor,
    chosenColor,
    chosenKey,
    setChosenKey,
    setChosenColor,
    setNewSubClassesColor,
    setNewUpdatedSubclassObj,
  };
};
