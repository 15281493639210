import { Input, Modal, Select } from "antd";
import { Toaster } from "react-hot-toast";
import styles from "./UpdateModal.module.scss";
import { UpdateModalLogic } from "./UpdateModalLogic";

const { Option } = Select;

function UpdateModal({
  dataset,
  setSelectedItemForUpdate,
  visibleUpdateModal,
  setVisibleUpdateModal,
  setAllDatasets,
  setDatasetsToShow,
}: {
  dataset: any;
  setSelectedItemForUpdate: object;
  visibleUpdateModal: boolean;
  setVisibleUpdateModal: (visibleUpdateModal: boolean) => void;
  setAllDatasets: (arg0: any[]) => void;
  setDatasetsToShow: (arg0: any) => void;
}) {
  const {
    handleOk,
    confirmLoading,
    handleCancel,
    newUpdatedCat,
    handleInputChange,
    cameraTypesList,
  }: {
    handleOk: () => void;
    confirmLoading: boolean;
    handleCancel: () => void;
    newUpdatedCat: any;
    handleInputChange: (e: any, key: string) => void;
    cameraTypesList: any[];
  } = UpdateModalLogic({
    dataset,
    setSelectedItemForUpdate,
    visibleUpdateModal,
    setVisibleUpdateModal,
    setAllDatasets,
    setDatasetsToShow,
  });

  return (
    <>
      <Modal
        centered
        title="Update Dataset"
        visible={visibleUpdateModal}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        {/* Description */}
        <label className={styles.updateComponent_inputLabels}>
          Description
        </label>
        <Input
          value={newUpdatedCat?.description}
          onChange={(e) => handleInputChange(e, "description")}
          className={styles.updateComponent_inputFields}
        />
        {/* CameraType Name */}
        <label className={styles.updateComponent_inputLabels}>
          Camera Type
        </label>
        <Select
          className={styles.updateComponent_dropdowns}
          value={newUpdatedCat?.cameraType_name}
          onChange={(e) => handleInputChange(e, "cameraType_name")}
        >
          {cameraTypesList?.length > 0 &&
            cameraTypesList.map((eachType: any) => {
              return (
                <Option key={eachType.cameraType} value={eachType.cameraType}>
                  {eachType.cameraType}
                </Option>
              );
            })}
        </Select>
      </Modal>
      <Toaster
        toastOptions={{
          success: {
            style: {
              border: "1px solid #199c3c",
            },
          },
          error: {
            style: {
              border: "1px solid #FF4C4C",
            },
          },
        }}
      />
    </>
  );
}

export default UpdateModal;
