import {
  Button,
  Col,
  Collapse,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography
} from "antd";
import { Toaster } from "react-hot-toast";
import styles from "./ViewAll.module.scss";
import { ViewAllLogic } from "./ViewAllLogic";
// Components
import UpdateModal from "components/classifiers/classes/UpdateModal";
import UpdateSubClassModal from "components/classifiers/subClasses/UpdateSubClassModal";
// Icons
import { CaretRightOutlined, LoadingOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import { MdOutlineFormatColorReset } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CreateClassesLogic } from "../Classes/CreateClasses/CreateClassesLogic";
import { CreateClassifierLogic } from "./CreateClassifierLogic";

const { Panel } = Collapse;
const { Title } = Typography;
const { Option } = Select;

function ViewAll() {
  const {
    classifiers,
    selectedClassifier,
    setSelectedClassifier,
    selectedClass,
    setSelectedClass,
    classesWithSubClass,
    setClassesWithSubClass,
    openClassDeleteModal,
    setOpenClassDeleteModal,
    selectedClassForDelete,
    setSelectedClassForDelete,
    confirmClassDeleteLoading,
    handleClassDelete,
    openSubClassDeleteModal,
    setOpenSubClassDeleteModal,
    confirmDeleteLoading,
    selectedSubClassForDelete,
    handleSubClassDelete,
    addNewSubClassVisible,
    updatedColor,
    isLoading,
    setAddNewSubClassVisible,
    newSubClasses,
    newSubClassesColor,
    fetchExistingColors,
    newSubClassKeyInput,
    setNewSubClassKeyInput,
    setNewSubClasses,
    setNewSubClassesColor,
    handleNewSubClasses,
    visibleUpdateModal,
    setVisibleUpdateModal,
    selectedItemForUpdate,
    setSelectedItemForUpdate,
    visibleSubClassUpdateModal,
    setVisibleSubClassUpdateModal,
    selectedSubClassForUpdate,
    setSelectedSubClassForUpdate,
    setSelectedSubClassIdArrayForDelete,
    setSelectedClassIdForSubClassDelete,
    isModalOpen,
    setIsModalOpen,
    isClassModalOpen,
    setIsClassModalOpen,
  }: {
    classifiers: any;
    selectedClassifier: any;
    existingColor: string[];
    setSelectedClassifier: (value: any) => void;
    classesWithSubClass: any[];
    setClassesWithSubClass: (value: any) => void;
    openClassDeleteModal: boolean;
    setOpenClassDeleteModal: (value: boolean) => void;
    selectedClassForDelete: any;
    setSelectedClassForDelete: (value: any) => void;
    confirmClassDeleteLoading: boolean;
    handleClassDelete: () => void;
    openSubClassDeleteModal: boolean;
    setOpenSubClassDeleteModal: (value: boolean) => void;
    confirmDeleteLoading: boolean;
    setConfirmDeleteLoading: (value: boolean) => void;
    selectedSubClassForDelete: any;
    setSelectedSubClassForDelete: (value: {
      selectedClassId: string;
      subClassId: string;
    }) => void;
    handleSubClassDelete: () => void;
    updatedColor: any[];
    addNewSubClassVisible: boolean;
    selectedClass: any;
    setSelectedClass: (value: any) => void;
    setAddNewSubClassVisible: (value: boolean) => void;
    isLoading: boolean;
    newSubClasses: string;
    fetchExistingColors: (classId: string, classKey: number) => void;
    newSubClassesColor: string;
    newSubClassKeyInput: number;
    setNewSubClassKeyInput: (value: number) => void;
    setNewSubClasses: (value: string) => void;
    setNewSubClassesColor: (value: string) => void;
    handleNewSubClasses: (classId: string, classKey: number) => void;
    visibleUpdateModal: boolean;
    setVisibleUpdateModal: (value: boolean) => void;
    selectedItemForUpdate: any;
    setSelectedItemForUpdate: (value: any) => void;
    visibleSubClassUpdateModal: boolean;
    setVisibleSubClassUpdateModal: (value: boolean) => void;
    selectedSubClassForUpdate: any;
    setSelectedSubClassForUpdate: (value: any) => void;
    setSelectedSubClassIdArrayForDelete: React.Dispatch<
      React.SetStateAction<string[]>
    >;
    setSelectedClassIdForSubClassDelete: React.Dispatch<
      React.SetStateAction<string>
    >;
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isClassModalOpen: boolean;
    setIsClassModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  } = ViewAllLogic();

  const genExtra = (eachClass: any) => (
    <div style={{ display: "flex" }}>
      <Tooltip
        placement="bottom"
        title={`Edit ${eachClass.name} class`}
        color={"#777"}
        key={"#777"}
      >
        <div
          className={
            styles.classifier_classesIconBtns +
            " " +
            styles.classifier_classesEditIcon
          }
          onClick={(event) => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
            setVisibleUpdateModal(true);
            setSelectedItemForUpdate(eachClass);
          }}
        >
          <FiEdit3 />
        </div>
      </Tooltip>
      <Tooltip
        placement="bottom"
        title={`Delete ${eachClass.name}`}
        color={"#777"}
        key={"#777"}
      >
        <div
          className={
            styles.classifier_classesIconBtns +
            " " +
            styles.classifier_classesDeleteIcon
          }
          onClick={(event) => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
            // handleClassDelete(eachClass._id);
            setOpenClassDeleteModal(true);
            setSelectedClassForDelete(eachClass);
          }}
        >
          <RiDeleteBin6Line />
        </div>
      </Tooltip>
      <Modal
        maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        centered
        visible={openClassDeleteModal}
        onOk={() => handleClassDelete()}
        confirmLoading={confirmClassDeleteLoading}
        onCancel={() => setOpenClassDeleteModal(false)}
        footer={[
          <Button
            key="back"
            onClick={(event) => {
              event.stopPropagation();
              setOpenClassDeleteModal(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            loading={confirmClassDeleteLoading}
            onClick={() => handleClassDelete()}
            className={styles.deleteModal_deleteBtn}
          >
            Delete
          </Button>,
        ]}
      >
        <p>
          Are you sure you want to delete{" "}
          {selectedClassForDelete && selectedClassForDelete.name}?
        </p>
      </Modal>
    </div>
  );

  const handleChange = (value: string) => {
    setNewSubClasses(value);
  };

  const colorContent = (
    <>
      <p className={styles.popoverContent_heading}>
        Choose from default colors:
      </p>
      <div className={styles.popoverContent}>
        {updatedColor.map((e: string) => {
          return (
            <div
              className={styles.popoverContentColor}
              style={{
                backgroundColor: e,
              }}
              onClick={() => {
                setNewSubClassesColor(e);
              }}
            ></div>
          );
        })}
      </div>
    </>
  );

  const loaderContent = (
    <Spin
      size="large"
      indicator={<LoadingOutlined className={styles.loadingSpinIcon} spin />}
      tip="Loading..."
      className={styles.loadingDiv_spin}
    />
  );
  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      // cleaning selected row and getting only the id
      const selectedSubClassIdArrayForDelete = selectedRows.map((e) => e._id);
      setSelectedSubClassIdArrayForDelete(selectedSubClassIdArrayForDelete);
    },
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
  };
  const columns: ColumnsType<any> = [
    {
      title: "Subclass",
      dataIndex: "name",
      render: (name) => <h3>{name}</h3>,
    },
    {
      title: "Color",
      render: (subclass) => {
        return (
          <>
            {subclass.colour ? (
              <div
                className={styles.classifier_subClassColor}
                style={{
                  backgroundColor: subclass.colour,
                }}
              ></div>
            ) : (
              <MdOutlineFormatColorReset
                style={{
                  fontSize: "1.6rem",
                }}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Action",
      render: (subclass) => {
        return (
          <div className={styles.classifier_subClassBtnDiv}>
            <Tooltip
              placement="bottom"
              title={`Edit subclass`}
              color={"#777"}
              key={"#777"}
            >
              <FiEdit3
                className={styles.classifier_subClassEditIcon}
                onClick={() => {
                  setVisibleSubClassUpdateModal(true);
                  setSelectedSubClassForUpdate({
                    classifierId: selectedClassifier._id,
                    subclass,
                  });
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Row>
        <Col md={16} sm={16} xs={24}>
          <Title level={5} className={styles.classifier_filterHeading}>
            Select classifier :
          </Title>
          {classifiers.length ? (
            <Select
              showSearch
              style={{ width: 220 }}
              className={styles.classifier_filter}
              optionFilterProp="children"
              filterOption={(input, option) =>
                ((option?.label as string) ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                ((optionA?.label as string) ?? "")
                  .toLowerCase()
                  .localeCompare(
                    ((optionB?.label as string) ?? "").toLowerCase()
                  )
              }
              onChange={(val: string) => {
                const classifier = classifiers.find(
                  (classifier: any) => classifier.pathology === val
                );
                setSelectedClassifier(classifier);
              }}
              options={classifiers.map((classifier: any) => {
                return {
                  label: classifier.pathology,
                  value: classifier.pathology,
                };
              })}
            />
          ) : (
            ""
          )}
        </Col>
        <Col
          md={8}
          sm={8}
          xs={24}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
          flex={"auto"}
        >
          <Button
            className={styles.classifier_createBtn}
            onClick={() => setIsModalOpen((prev) => !prev)}
          >
            Create Classifier
          </Button>
          <CreateClassifierModal
            isModalOpen={isModalOpen}
            setIsModalOpen={() => setIsModalOpen((prev) => !prev)}
          />
        </Col>
      </Row>
      <hr className={styles.classifier_hrLine} />
      <div>
        <Button
          className={styles.classifier_createBtn}
          onClick={() => {
            setIsClassModalOpen((prev) => !prev);
          }}
        >
          Create Class
        </Button>
        <CreateClassModal
          isModalOpen={isClassModalOpen}
          setIsModalOpen={() => setIsClassModalOpen((prev) => !prev)}
        />
      </div>
      {selectedClassifier ? (
        <Collapse
          accordion
          expandIcon={({ isActive }) => (
            <CaretRightOutlined
              rotate={isActive ? 90 : 0}
              className={styles.classifier_classesHeadingArrow}
            />
          )}
          className={styles.classifier_collapse}
        >
          {classesWithSubClass.length &&
            classesWithSubClass.map((eachClass: any) => {
              return (
                <Panel
                  header={
                    <Title
                      level={4}
                      onClick={() => {
                        setSelectedClass(eachClass._id);
                      }}
                      className={styles.classifier_classesHeading}
                    >
                      <span className={styles.classifier_classesHeadingIcon}>
                        <CaretRightOutlined />
                      </span>
                      <span>{eachClass.name}</span>
                    </Title>
                  }
                  key={eachClass._id}
                  extra={genExtra(eachClass)}
                  className={styles.classifier_collapsePanel}
                >
                  {}
                  {eachClass.subclasses.length ? (
                    <Table
                      pagination={false}
                      scroll={{
                        y: 350,
                      }}
                      rowSelection={{
                        type: "checkbox",
                        ...rowSelection,
                      }}
                      columns={columns}
                      dataSource={eachClass.subclasses}
                    />
                  ) : (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={
                        <Title level={5} className={styles.emptyBoxText}>
                          No sub classes present in this class
                        </Title>
                      }
                    />
                  )}
                  <hr className={styles.classifier_subClassHrLine} />

                  {/* Subclass create */}
                  <Row>
                    <Col span={8}>
                      <p
                        className={styles.classifier_subClassesAddNewText}
                        onClick={() => {
                          setAddNewSubClassVisible(true);
                        }}
                      >
                        Add New Sub Class +
                      </p>
                    </Col>
                    <Col span={8}></Col>
                    <Col
                      span={8}
                      style={{
                        padding: "0 1.725rem",
                      }}
                    >
                      <Tooltip
                        placement="bottom"
                        title={`Delete subclass`}
                        color={"#777"}
                        key={"#777"}
                      >
                        <FiTrash2
                          className={styles.classifier_subClassDelIcon}
                          onClick={() => {
                            // setSelectedSubClassForDelete({
                            //     selectedClassId:
                            //         eachClass._id,
                            //     subClassId: subclass._id,
                            // });
                            setSelectedClassIdForSubClassDelete(eachClass._id);
                            setOpenSubClassDeleteModal(true);
                          }}
                        />
                      </Tooltip>
                      <Modal
                        maskStyle={{
                          backgroundColor: "rgba(0, 0, 0, 0.2)",
                        }}
                        centered
                        visible={openSubClassDeleteModal}
                        onOk={() => handleSubClassDelete()}
                        confirmLoading={confirmDeleteLoading}
                        onCancel={() => setOpenSubClassDeleteModal(false)}
                        footer={[
                          <Button
                            key="back"
                            onClick={() => setOpenSubClassDeleteModal(false)}
                          >
                            Cancel
                          </Button>,
                          <Button
                            key="submit"
                            loading={confirmDeleteLoading}
                            onClick={() => handleSubClassDelete()}
                            className={styles.deleteModal_deleteBtn}
                          >
                            Delete
                          </Button>,
                        ]}
                      >
                        <p>
                          Are you sure you want to delete{" "}
                          {selectedSubClassForDelete &&
                            selectedSubClassForDelete.name}
                          ?
                        </p>
                      </Modal>
                    </Col>
                  </Row>

                  {addNewSubClassVisible && (
                    <CreateSubClassComponent
                      classId={eachClass._id}
                      classKey={eachClass.key}
                      colorContent={colorContent}
                      loaderContent={loaderContent}
                      fetchExistingColors={fetchExistingColors}
                      handleChange={handleChange}
                      handleNewSubClasses={handleNewSubClasses}
                      isLoading={isLoading}
                      newSubClassKeyInput={newSubClassKeyInput}
                      newSubClasses={newSubClasses}
                      newSubClassesColor={newSubClassesColor}
                      setNewSubClassKeyInput={setNewSubClassKeyInput}
                      setAddNewSubClassVisible={setAddNewSubClassVisible}
                    />
                  )}
                </Panel>
              );
            })}
        </Collapse>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <Title level={5} className={styles.emptyBoxText}>
              Select a classifier to view it's classes and subclasses
            </Title>
          }
        />
      )}
      {/* Update Modal */}
      <UpdateModal
        item={selectedItemForUpdate}
        setSelectedItemForUpdate={setSelectedItemForUpdate}
        visibleUpdateModal={visibleUpdateModal}
        setVisibleUpdateModal={setVisibleUpdateModal}
        setClassesWithSubClass={setClassesWithSubClass}
      />
      {/* Update SubClass Modal */}
      <UpdateSubClassModal
        selectedClassifier={selectedClassifier}
        selectedClass={selectedClass}
        item={selectedSubClassForUpdate}
        setSelectedItemForUpdate={setSelectedSubClassForUpdate}
        visibleUpdateModal={visibleSubClassUpdateModal}
        setVisibleUpdateModal={setVisibleSubClassUpdateModal}
        setClassesWithSubClass={setClassesWithSubClass}
        classesWithSubClass={classesWithSubClass}
      />
      {/* Toaster */}
      <Toaster
        toastOptions={{
          success: {
            style: {
              border: "1px solid #199c3c",
            },
          },
          error: {
            style: {
              border: "1px solid #FF4C4C",
            },
          },
        }}
      />
    </>
  );
}
function CreateSubClassComponent({
  newSubClasses,
  handleChange,
  newSubClassKeyInput,
  setNewSubClassKeyInput,
  newSubClassesColor,
  isLoading,
  fetchExistingColors,
  loaderContent,
  colorContent,
  classId,
  classKey,
  handleNewSubClasses,
  setAddNewSubClassVisible,
}: {
  newSubClasses: any;
  handleChange: (value: string) => void;
  newSubClassKeyInput: number;
  setNewSubClassKeyInput: (value: number) => void;
  newSubClassesColor: string;
  isLoading: boolean;
  fetchExistingColors: (classId: string, classKey: number) => void;
  loaderContent: JSX.Element;
  colorContent: JSX.Element;
  classId: string;
  classKey: number;
  handleNewSubClasses: (classId: string, classKey: number) => void;
  setAddNewSubClassVisible: (value: boolean) => void;
}): JSX.Element {
  return (
    <div className={styles.classifier_createSubClassDiv}>
      <div style={{ width: "60%" }}>
        <label className={styles.classifier_subClassInputLabels}>
          Subclass Name
        </label>
        <Input
          // mode="tags"
          value={newSubClasses}
          onChange={(e) => {
            handleChange(e.target.value);
          }}
          // tokenSeparators={[',']}
          className={styles.addNewSubClassSelect}
        />
      </div>
      <div
        style={{
          width: "40%",
          marginLeft: "1rem",
        }}
      >
        <label className={styles.classifier_subClassInputLabels}>Key</label>
        <InputNumber
          min={0}
          defaultValue={0}
          value={newSubClassKeyInput}
          className={
            styles.createComponent_inputFields +
            " " +
            styles.createComponent_inputNumberField
          }
          onChange={(e: any) => setNewSubClassKeyInput(e)}
        />
      </div>
      <div>
        <Popover
          placement="rightBottom"
          content={isLoading ? loaderContent : colorContent}
          trigger="click"
        >
          <div className={styles.classifier_subClassInputColorDiv}>
            <label className={styles.classifier_subClassInputLabels}>
              Color
            </label>
            <button
              className={styles.popoverBtn}
              style={
                newSubClassesColor
                  ? {
                      backgroundColor: newSubClassesColor,
                      borderColor: newSubClassesColor,
                    }
                  : {
                      backgroundColor: newSubClassesColor,
                    }
              }
              onClick={() => {
                fetchExistingColors(classId, classKey);
              }}
            ></button>
          </div>
        </Popover>
      </div>
      <Button
        type="primary"
        className={
          styles.classifier_createBtn + " " + styles.addNewSubClassCreateBtn
        }
        onClick={() => handleNewSubClasses(classId, classKey)}
      >
        Create
      </Button>
      <Button
        danger
        className={styles.addNewSubClassCreateBtn}
        onClick={() => setAddNewSubClassVisible(false)}
      >
        Close
      </Button>
    </div>
  );
}

function CreateClassifierModal({
  isModalOpen,
  setIsModalOpen,
}: {
  isModalOpen: boolean;
  setIsModalOpen: () => void;
}) {
  const [form] = Form.useForm();
  const {
    onFinish,
    allCategoryData,
    isLoading,
    pathologyName,
    pathologyModality,
    setPathologyName,
    setPathologyModality,
  }: {
    onFinish: () => void;
    onFinishFailed: (errorInfo: any) => void;
    allCategoryData: any;
    isLoading: boolean;
    pathologyName: string;
    pathologyModality: string;
    setPathologyName: React.Dispatch<React.SetStateAction<string>>;
    setPathologyModality: React.Dispatch<React.SetStateAction<string>>;
  } = CreateClassifierLogic(form);

  return (
    <Modal
      title="Create Classifier"
      open={isModalOpen}
      onCancel={setIsModalOpen}
      confirmLoading={isLoading}
      footer={[
        <Button key="back" onClick={setIsModalOpen}>
          Close
        </Button>,
        <Button htmlType="submit" type="primary" onClick={onFinish}>
          Create
        </Button>,
      ]}
    >
      <Row
        gutter={[0, 24]}
        style={{
          padding: "0 1rem",
        }}
      >
        <Row
          gutter={[0, 16]}
          style={{
            width: "100%",
          }}
        >
          <Col span={24}>
            <label className={styles.createComponent_inputLabels}>
              Classifier Pathology Name
            </label>
          </Col>
          <Col span={24}>
            <Input
              value={pathologyName}
              className={styles.createComponent_inputFields}
              onChange={(e) => setPathologyName(e.target.value)}
            />
          </Col>
        </Row>
        <Row
          gutter={[0, 16]}
          style={{
            width: "100%",
          }}
        >
          <Col span={24}>
            <label className={styles.createComponent_inputLabels}>
              Select Modality
            </label>
          </Col>
          <Col span={24}>
            <Select
              value={pathologyModality}
              className={styles.createComponent_dropdowns}
              onChange={(e) => setPathologyModality(e)}
            >
              {allCategoryData.length &&
                allCategoryData.map((eachCat: any) => {
                  return <Option value={eachCat._id}>{eachCat.name}</Option>;
                })}
            </Select>
          </Col>
        </Row>
      </Row>
    </Modal>
  );
}

function CreateClassModal({
  isModalOpen,
  setIsModalOpen,
}: {
  isModalOpen: boolean;
  setIsModalOpen: () => void;
}) {
  const {
    classifiers,
    newClassInput,
    setNewClassInput,
    handleNewClass,
    handleClassDelete,
    classes,
    handleSubmit,
    selectedClassifier,
    setSelectedClassifier,
  }: {
    classifiers: any[];
    newClassInput: any;
    setNewClassInput: (e: any) => void;
    handleNewClass: () => void;
    handleClassDelete: (details: any) => void;
    classes: object[];
    handleSubmit: () => void;
    selectedClassifier: string;
    setSelectedClassifier: React.Dispatch<React.SetStateAction<string>>;
  } = CreateClassesLogic();
  const columns = [
    {
      title: "Class",
      dataIndex: "name",
      key: "name",
      width: "33.3%",
    },
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
      width: "33.3%",
    },
    {
      title: "Delete",
      key: "delete",
      width: "33.3%",

      render: (details: any) => {
        return (
          <Space size="middle">
            <Button onClick={() => handleClassDelete(details)}>Delete</Button>
          </Space>
        );
      },
    },
  ];
  return (
    <Modal
      title="Create Class"
      open={isModalOpen}
      onCancel={setIsModalOpen}
      footer={[
        <Button key="back" onClick={setIsModalOpen}>
          Close
        </Button>,
        <Button htmlType="submit" type="primary" onClick={handleSubmit}>
          Create
        </Button>,
      ]}
    >
      <Row gutter={[0, 24]}>
        <Row
          gutter={[0, 16]}
          style={{
            width: "100%",
          }}
        >
          <Col span={24}>
            <label className={styles.createComponent_inputLabels}>
              Select Classifier
            </label>
          </Col>
          <Col span={24}>
            <Select
              value={selectedClassifier}
              className={styles.createComponent_dropdowns}
              onChange={(e) => setSelectedClassifier(e)}
            >
              {classifiers.length &&
                classifiers.map((eachClassifier: any) => {
                  return (
                    <Option value={eachClassifier._id}>
                      {eachClassifier.pathology}
                    </Option>
                  );
                })}
            </Select>
          </Col>
        </Row>
        <Row
          style={{
            marginBottom: "1rem",
            width: "100%",
          }}
        >
          {/* Name */}
          <Col lg={9} sm={24}>
            <label
              className={styles.createComponent_inputLabels}
              style={{ marginBottom: "0.6rem" }}
            >
              Name of Class
            </label>
            <Input
              value={newClassInput.name}
              className={styles.createComponent_inputFields}
              onChange={(e) =>
                setNewClassInput({
                  ...newClassInput,
                  name: e.target.value,
                })
              }
            />
          </Col>
          {/* Key */}
          <Col
            lg={{
              span: 7,
              offset: 1,
            }}
            sm={24}
          >
            <label
              className={styles.createComponent_inputLabels}
              style={{ marginBottom: "0.6rem" }}
            >
              Key
            </label>
            <InputNumber
              min={0}
              defaultValue={0}
              value={newClassInput.key}
              className={
                styles.createComponent_inputFields +
                " " +
                styles.createComponent_inputNumberField
              }
              onChange={(e) => {
                setNewClassInput({
                  ...newClassInput,
                  key: e,
                });
              }}
            />
          </Col>
          <Col
            lg={{
              span: 6,
              offset: 1,
            }}
            sm={24}
          >
            <Button
              className={
                styles.createComponent_btn +
                " " +
                styles.createComponent_addClassBtn
              }
              onClick={() => handleNewClass()}
            >
              Add Class
            </Button>
          </Col>
        </Row>
      </Row>
      {classes.length > 0 && (
        <div
          style={{
            marginTop: "1.5rem",
            maxHeight: "15rem",
            overflowY: "auto",
          }}
        >
          <hr />
          <Title level={5} className={styles.table_classesHeading}>
            Classes
          </Title>
          <Table dataSource={classes} columns={columns} pagination={false} />
        </div>
      )}
      <Toaster
        toastOptions={{
          success: {
            style: {
              border: "1px solid #199c3c",
            },
          },
          error: {
            style: {
              border: "1px solid #FF4C4C",
            },
          },
        }}
      />
    </Modal>
  );
}

export default ViewAll;
