import type { ColumnsType } from "antd/es/table";
import { getEveryDatasetForAdmin } from "api/datasetApi";
import { uploadDatasetFileApi } from "api/uploadAndJobsApi";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

export const UploadDataLogic = () => {
  const [allDatasets, setAllDatasets] = useState<{
    loading: boolean;
    error: boolean;
    data: any[];
  }>({
    loading: false,
    error: false,
    data: [],
  });
  const [allClassifiersOfDataset, setAllClassifiersOfDataset] = useState<
    object[]
  >([]);
  const [selectedDatasetId, setSelectedDatasetId] = useState<string>("");
  const [selectedDatasetName, setSelectedDatasetName] = useState<string>("");
  const [selectedClassifierId, setSelectedClassifierId] = useState<string>("");
  const [selectedClassifierName, setSelectedClassifierName] =
    useState<string>("");
  const [uploadedCSVFileName, setUploadedCSVFileName] = useState<string>("");
  const [uploadedZipFileName, setUploadedZipFileName] = useState<string>("");
  const [openPreviewTableModal, setOpenPreviewTableModal] =
    useState<boolean>(false);
  // State to store parsed data
  const [, setParsedData] = useState([]);

  // State to store table Column name
  const [tableHeaders, setTableHeaders] = useState<ColumnsType<any>>([]);

  // State to store the values
  const [values, setValues] = useState([]);
  // State to store file
  const [uploadedCSVFile, setUploadedCSVFile] = useState<any>(null);
  const [uploadedZipFile, setUploadedZipFile] = useState<any>(null);
  // Upload Form
  const [openUploadSubmitModal, setOpenUploadSubmitModal] =
    useState<boolean>(false);
  const [uploadSubmitModalLoading, setUploadSubmitModalLoading] =
    useState<boolean>(false);

  // Get Datasets
  useEffect(() => {
    async function getDatasets() {
      setAllDatasets({ ...allDatasets, loading: true });
      try {
        const res = await getEveryDatasetForAdmin();
        setAllDatasets({
          ...allDatasets,
          loading: false,
          data: res.data,
        });
      } catch (error) {
        setAllDatasets({ ...allDatasets, loading: false, error: true });
      }
    }
    getDatasets();
  }, []);

  // Update classifiers depenind on selectedDataset
  useEffect(() => {
    setSelectedClassifierId("");
    setSelectedClassifierName("");
    allDatasets.data.length > 0 &&
      allDatasets.data.map((eachDataset: any) => {
        if (eachDataset.dataset._id === selectedDatasetId) {
          setAllClassifiersOfDataset(eachDataset.dataset.classifiers);
        }
      });
  }, [selectedDatasetId]);

  //  For handling CSV and mapping it into table
  const uploadedCSVHandler = (event: any) => {
    setUploadedCSVFileName(event.target.files[0]?.name);
    setUploadedCSVFile(event.target.files[0]);
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete(results: any) {
        const rowsArray: any[] = [];
        const valuesArray: any = [];

        // Iterating data to get column name and their values
        results.data.map((d: any) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });

        // Parsed Data Response in array format
        setParsedData(results.data);

        // Filtered Column Names
        const headers = rowsArray[0].map((eachData: string) => {
          return {
            title: eachData,
            key: eachData,
            dataIndex: eachData,
          };
        });

        setTableHeaders(headers);
        // setTableRows(rowsArray[0]);

        // Filtered Values
        const newValArr = valuesArray.map((val: any[]) => {
          const arr: any = {};
          rowsArray[0].forEach((header: string, index: number) => {
            arr[header] = val[index] ? val[index] : "-";
          });
          return arr;
        });

        setValues(newValArr);
      },
    });
  };

  /** Func to run when Submit is clicked */
  async function handleUploadSubmit() {
    if (
      selectedDatasetId &&
      selectedClassifierId &&
      uploadedCSVFile &&
      uploadedZipFile
    ) {
      const toastId = toast.loading("Your file is being uploaded...");

      try {
        const formData = new FormData();
        formData.append("csv", uploadedCSVFile);
        formData.append("zip", uploadedZipFile);
        formData.append("dataset_id", selectedDatasetId);
        formData.append("classifier_id", selectedClassifierId);

        await uploadDatasetFileApi({
          formData,
        });

        toast.dismiss(toastId);

        setTimeout(() => {
          setUploadSubmitModalLoading(false);
          setOpenUploadSubmitModal(false);
        }, 3000);

        setSelectedDatasetId("");
        setSelectedDatasetName("");
        setSelectedClassifierId("");
        setSelectedClassifierName("");
        setUploadedCSVFile(null);
        setUploadedCSVFileName("");
        setUploadedZipFile("");
        setUploadedZipFileName("");
        setTableHeaders([]);
        setValues([]);
        toast.success("Uploaded successfully");
      } catch (err: any) {
        toast.dismiss(toastId);

        toast.error(err.response.data.message);
      }
    } else {
      toast.error("Please fill all fields");
    }
  }

  return {
    uploadedCSVFileName,
    setUploadedCSVFileName,
    uploadedZipFileName,
    setUploadedZipFileName,
    tableHeaders,
    values,
    uploadedCSVHandler,
    openPreviewTableModal,
    setOpenPreviewTableModal,
    allDatasets,
    openUploadSubmitModal,
    setOpenUploadSubmitModal,
    uploadSubmitModalLoading,
    setUploadSubmitModalLoading,
    uploadedCSVFile,
    setUploadedCSVFile,
    uploadedZipFile,
    setUploadedZipFile,

    selectedDatasetId,
    setSelectedDatasetId,
    selectedDatasetName,
    setSelectedDatasetName,
    allClassifiersOfDataset,
    selectedClassifierId,
    setSelectedClassifierId,
    selectedClassifierName,
    setSelectedClassifierName,
    handleUploadSubmit,
  };
};
