import api, { authHeader } from "./index";

export async function createReviewBucket({
  reviewBucketName,
  selectedUserIdForReview,
  datasetId,
  classifierId,
  reviewerId,
  modeOfAnnotation,
  typeOfBucketReview,
  bucketPercentageDivisions,
}: {
  reviewBucketName: string;
  selectedUserIdForReview?: string; // annotatorId
  datasetId: any;
  classifierId: any;
  reviewerId: string; // the one who is creating
  modeOfAnnotation: string; // final / initial / user
  typeOfBucketReview: string; // overall dataset / bucket wise
  bucketPercentageDivisions: // total percentage (if overall) or array of objects(bucket wise)
  | number
    | {
        class_id: string;
        count: number;
        name: string;
      }[];
}) {
  const body =
    typeOfBucketReview === "overall"
      ? modeOfAnnotation === "user_annotation"
        ? {
            name: reviewBucketName,
            type: typeOfBucketReview,
            against: modeOfAnnotation,
            annotator_id: selectedUserIdForReview,
            percentage: bucketPercentageDivisions,
            dataset_id: datasetId,
            classifier_id: classifierId,
            reviewer_id: reviewerId,
          }
        : {
            name: reviewBucketName,
            type: typeOfBucketReview,
            against: modeOfAnnotation,
            percentage: bucketPercentageDivisions,
            dataset_id: datasetId,
            classifier_id: classifierId,
            reviewer_id: reviewerId,
          }
      : modeOfAnnotation === "user_annotation"
      ? {
          name: reviewBucketName,
          type: typeOfBucketReview,
          against: modeOfAnnotation,
          annotator_id: selectedUserIdForReview,
          bucket_wise_count: bucketPercentageDivisions,
          dataset_id: datasetId,
          classifier_id: classifierId,
          reviewer_id: reviewerId,
        }
      : {
          name: reviewBucketName,
          type: typeOfBucketReview,
          against: modeOfAnnotation,
          bucket_wise_count: bucketPercentageDivisions,
          dataset_id: datasetId,
          classifier_id: classifierId,
          reviewer_id: reviewerId,
        };

  const response = await api.post(`/review-bucket/create`, body, authHeader());
  return response.data?.result;
}

export async function getAllReviewBuckets() {
  const response = await api.get("/review-bucket/my", authHeader());
  return response.data?.result;
}

export async function getBucketsByReviewBucketId(bucketId: any) {
  const response = await api.post(
    `/review-bucket/buckets`,
    {
      bucket_id: bucketId,
    },
    authHeader()
  );
  return response.data?.result;
}

// export async function getBucketsByReviewBucketId(bucketId: any) {
//     const response = await api.get(`/review-bucket/${bucketId}`, authHeader());
//     return response.data?.result;
// }

export async function getReviewBucketReviewersApi(
  datasetId: string,
  reviewBucketId: string
) {
  const response = await api.post(
    `/review-bucket/available-reviewers`,
    {
      dataset_id: datasetId,
      bucket_id: reviewBucketId,
    },
    authHeader()
  );
  return response.data?.result;
}

export async function assignReviewersInBucketApi(
  reviewBucketId: string,
  reviewerIds: string[]
) {
  const response = await api.post(
    `/review-bucket/add-reviewers`,
    {
      bucket_id: reviewBucketId,
      reviewer_ids: reviewerIds,
    },
    authHeader()
  );
  return response.data?.result;
}

export async function updateAnnotatorImgStatusWhenReview(
  annotationId: string,
  review: object
) {
  const response = await api.post(
    `/annotation/update`,
    {
      annotation_id: annotationId,
      review,
    },
    authHeader()
  );
  return response.data?.result;
}
