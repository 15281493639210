import {
  Button,
  Col,
  Empty,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import styles from "./AdminViewDatasets.module.scss";
import { AdminViewDatasetsLogic } from "./AdminViewDatasetsLogic";

// Components
import UserViewMapModal from "components/dataset/AdminViewDatasets/UserMapModal";
import UpdateModal from "components/dataset/UpdateModal";

// Icons
import {
  FormOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import UpdateClassifierModal from "components/classifiers/CRUD_Modal/CRUD_ClassifierModal";
import { AiOutlineForm } from "react-icons/ai";
import { FiEdit3, FiEye, FiTrash2, FiUsers } from "react-icons/fi";
import { IoIosSearch } from "react-icons/io";
import { RiNodeTree } from "react-icons/ri";

const { TabPane } = Tabs;
const { Option } = Select;
const { Title } = Typography;

function AdminViewDatasets({
  allDatasets,
  setAllDatasets,
  datasetsToShow,
  setDatasetsToShow,
  allCategories,
  setAllCategories,
  allUsers,
  setAllUsers,
  getDatasets,
  getAllUsers,
}: any) {
  const {
    handleDeleteDataset,
    selectedCategory,
    setSelectedCategory,
    openDeleteModal,
    setOpenDeleteModal,
    confirmLoading,
    setConfirmLoading,
    selectedDatasetForDelete,
    setSelectedDatasetForDelete,
    loadingOnUserSuspension,
    handleSuspension,
    setSearchedText,
  }: {
    handleDeleteDataset: () => void;
    allCategories: any[];
    selectedCategory: any;
    setSelectedCategory: (classifier: any) => void;
    openDeleteModal: boolean;
    setOpenDeleteModal: (open: boolean) => void;
    confirmLoading: boolean;
    setConfirmLoading: (loading: boolean) => void;
    selectedDatasetForDelete: any;
    setSelectedDatasetForDelete: (dataset: any) => void;
    loadingOnUserSuspension: boolean;
    handleSuspension: (datasetId: string, userId: string) => void;
    setSearchedText: (text: string) => void;
  } = AdminViewDatasetsLogic({
    allDatasets,
    setAllDatasets,
    datasetsToShow,
    setDatasetsToShow,
    allCategories,
    setAllCategories,
    allUsers,
    setAllUsers,
    getDatasets,
    getAllUsers,
  });

  const [visibleUpdateModal, setVisibleUpdateModal] = useState<boolean>(false);
  const [selectedDatasetForUpdate, setSelectedDatasetForUpdate] =
    useState<any>();
  // update classifier modal
  const [visibleUpdateClassifierModal, setVisibleUpdateClassifierModal] =
    useState(false);
  const [selectedDatasetId, setSelectedDatasetId] = useState("");
  const columns = [
    {
      title: "Dataset Name",
      key: "nameAndDes",
      width: "32%",
      render: (details: any) => {
        return (
          <>
            {details.dataset.name}
            <Popover
              content={
                details.dataset.description
                  ? details.dataset.description
                  : "No description available"
              }
              placement="right"
              title="Description"
            >
              <InfoCircleOutlined
                className={styles.datasetsTableCol_infoIcon}
              />
            </Popover>
          </>
        );
      },
    },
    {
      title: "Modality",
      key: "categoryId",
      width: "23%",
      render: (details: any) => {
        return <>{details.dataset.category_id.type}</>;
      },
    },
    {
      title: "More Details",
      key: "moreDetails",
      width: "25%",
      render: (details: any) => {
        return (
          <div style={{ display: "flex" }}>
            <Tooltip
              placement="bottom"
              title="View classifiers"
              color={"#777"}
              key={"#777"}
            >
              <div
                className={
                  styles.datasets_viewDataIconBtns +
                  " " +
                  styles.datasets_viewDataEyeIcon
                }
                onClick={() => {
                  Modal.info({
                    title: `Classifiers under ${details?.dataset.name}`,
                    content: (
                      <>
                        {details.dataset.classifiers.length > 0 ? (
                          <Table
                            showHeader={false}
                            pagination={false}
                            dataSource={details.dataset.classifiers}
                            columns={[
                              {
                                dataIndex: "pathology",
                                key: "name",
                              },
                            ]}
                          />
                        ) : (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={"No classifiers present"}
                          />
                        )}
                      </>
                    ),
                  });
                }}
              >
                <FiEye />
              </div>
            </Tooltip>
            <Tooltip
              placement="bottom"
              title="View Users"
              color={"#777"}
              key={"#777"}
            >
              <div
                className={
                  styles.datasets_viewDataIconBtns +
                  " " +
                  styles.datasets_viewDataEyeIcon
                }
                onClick={() => {
                  Modal.info({
                    title: `Users under ${details.dataset.name}`,
                    content: (
                      <div
                        style={{
                          marginRight: "2rem",
                        }}
                      >
                        <Tabs defaultActiveKey="1">
                          <TabPane tab="Reviewers" key="1">
                            {details.dataset.reviewers.length > 0 ? (
                              <Table
                                showHeader={false}
                                pagination={{
                                  pageSize: 5,
                                }}
                                dataSource={details.dataset.reviewers}
                                columns={[
                                  {
                                    key: "name",
                                    render: (eachReviewer: any) => {
                                      return (
                                        <>
                                          {eachReviewer.name} -{" "}
                                          {eachReviewer.email}
                                        </>
                                      );
                                    },
                                  },
                                ]}
                              />
                            ) : (
                              <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={"No reviewers are present"}
                              />
                            )}
                          </TabPane>
                          <TabPane tab="Annotators" key="2">
                            {details.dataset.annotators.length > 0 ? (
                              <Table
                                showHeader={false}
                                pagination={{
                                  pageSize: 5,
                                }}
                                dataSource={details.dataset.annotators}
                                scroll={{
                                  x: 450,
                                }}
                                columns={[
                                  {
                                    key: "name",
                                    render: (eachAnnotator: any) => {
                                      return (
                                        <>
                                          {eachAnnotator.name} -{" "}
                                          {eachAnnotator.email}
                                        </>
                                      );
                                    },
                                  },
                                  {
                                    key: "suspension",
                                    render: (eachAnnotator: any) => {
                                      return (
                                        <>
                                          <Button
                                            loading={loadingOnUserSuspension}
                                            className={
                                              styles.dataset_suspendBtns
                                            }
                                            style={{
                                              backgroundColor:
                                                eachAnnotator.isSuspended
                                                  ? "#6254FF"
                                                  : "#fa6f5c",
                                              color: "#fff",
                                            }}
                                            onClick={() =>
                                              handleSuspension(
                                                details.dataset._id,
                                                eachAnnotator._id
                                              )
                                            }
                                          >
                                            {eachAnnotator.isSuspended
                                              ? "Revoke"
                                              : "Suspend"}
                                          </Button>
                                        </>
                                      );
                                    },
                                  },
                                ]}
                              />
                            ) : (
                              <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={"No annotators are present"}
                              />
                            )}
                          </TabPane>
                        </Tabs>
                      </div>
                    ),
                  });
                }}
              >
                <FiUsers />
              </div>
            </Tooltip>

            {/* For Map */}
            <Tooltip
              placement="bottom"
              title="View Mapped Users"
              color={"#777"}
              key={"#777"}
            >
              <div
                className={
                  styles.datasets_viewDataIconBtns +
                  " " +
                  styles.datasets_viewDataEyeIcon
                }
                onClick={() => {
                  Modal.info({
                    title: `Mapped Users under ${details.dataset.name}`,
                    content: (
                      <>
                        <UserViewMapModal
                          details={details}
                          allUsers={allUsers}
                        />
                      </>
                    ),
                  });
                }}
              >
                <RiNodeTree />
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      width: "15%",
      render: (details: any) => {
        return (
          <>
            <div
              style={{
                margin: "auto 0",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <Tooltip
                placement="bottom"
                title="Edit Classifier"
                color={"#777"}
                key={"update_classiifier_modal"}
              >
                <AiOutlineForm
                  className={styles.classifier_subClassEditIcon}
                  onClick={(e) => {
                    setVisibleUpdateClassifierModal(true);
                    setSelectedDatasetId(details.dataset._id);
                  }}
                />
              </Tooltip>
              <Tooltip
                placement="bottom"
                title="Edit Dataset"
                color={"#777"}
                key={"update_dataset_modal"}
              >
                <FiEdit3
                  className={styles.classifier_subClassEditIcon}
                  onClick={(e) => {
                    setVisibleUpdateModal(true);
                    setSelectedDatasetForUpdate(details);
                  }}
                />
              </Tooltip>
              <Modal
                maskStyle={{
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                }}
                centered
                visible={openDeleteModal}
                onOk={() => handleDeleteDataset()}
                confirmLoading={confirmLoading}
                onCancel={() => setOpenDeleteModal(false)}
                footer={[
                  <Button key="back" onClick={() => setOpenDeleteModal(false)}>
                    Cancel
                  </Button>,
                  <Button
                    key="submit"
                    loading={confirmLoading}
                    onClick={() => handleDeleteDataset()}
                    className={styles.deleteModal_deleteBtn}
                  >
                    Delete
                  </Button>,
                ]}
              >
                <p>
                  Are you sure you want to delete{" "}
                  {selectedDatasetForDelete &&
                    selectedDatasetForDelete.dataset?.name}
                  ?
                </p>
              </Modal>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <div className={styles.viewDatasets__container}>
      <Row gutter={[16, 16]} wrap={true}>
        <Col>
          <Title level={2} className={styles.datasets_heading}>
            All Datasets
          </Title>
        </Col>
        {allDatasets.loading ? null : (
          <Col flex={"auto"}>
            <Row gutter={[16, 8]} justify="end">
              <Col span={12} style={{ textAlign: "end" }}>
                <Title level={5} className={styles.datasets_filterHeading}>
                  Filter categories :
                </Title>
              </Col>
              <Col>
                <Select
                  defaultValue={
                    selectedCategory?.type ? selectedCategory.type : "All"
                  }
                  style={{ width: 200 }}
                  className={styles.datasets_filter}
                  onChange={(val: string) => {
                    let category;
                    if (val !== "All") {
                      category = allCategories.find(
                        (category: any) => category.type === val
                      );
                    } else {
                      category = {
                        type: "All",
                      };
                    }
                    setSelectedCategory(category);
                  }}
                >
                  {allCategories.map((category: any) => (
                    <Option key={category._id} value={category.type}>
                      {category.type}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={"auto"}>
                <Input
                  size="large"
                  placeholder="Search..."
                  prefix={
                    <IoIosSearch className={styles.datasets_searchBoxIcon} />
                  }
                  className={styles.datasets_searchBox}
                  onChange={(e) => setSearchedText(e.target.value)}
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>

      {allDatasets.loading ? (
        <div className={styles.loadingDiv}>
          <Spin
            size="large"
            indicator={
              <LoadingOutlined className={styles.loadingSpinIcon} spin />
            }
            tip="Fetching all datasets..."
            className={styles.loadingDiv_spin}
          />
        </div>
      ) : datasetsToShow.length > 0 ? (
        <Table
          dataSource={datasetsToShow}
          columns={columns}
          className={styles.table}
          scroll={{ x: 1000, y: 450 }}
        />
      ) : selectedCategory ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={"No datasets found under this category"}
          className={styles.table}
        />
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={"No datasets found"}
          className={styles.table}
        />
      )}

      {/* Update Modal */}
      <UpdateModal
        dataset={selectedDatasetForUpdate}
        setSelectedItemForUpdate={setSelectedDatasetForUpdate}
        visibleUpdateModal={visibleUpdateModal}
        setVisibleUpdateModal={setVisibleUpdateModal}
        setAllDatasets={setAllDatasets}
        setDatasetsToShow={setDatasetsToShow}
      />
      {/* Update classifier modal for performing CRUD operations */}
      <UpdateClassifierModal
        selectedDatasetId={selectedDatasetId}
        visibleUpdateModal={visibleUpdateClassifierModal}
        setVisibleUpdateModal={setVisibleUpdateClassifierModal}
      />
      {/* Toaster */}
      <Toaster
        toastOptions={{
          success: {
            style: {
              border: "1px solid #199c3c",
            },
          },
          error: {
            style: {
              border: "1px solid #FF4C4C",
            },
          },
        }}
      />
    </div>
  );
}

export default AdminViewDatasets;
