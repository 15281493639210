import { Layout as AntLayout, Grid } from "antd";
import { getUserInfoApi } from "api/usersApi";
import { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { setIsAuthenticated, setUser, userSelector } from "store/userSlice";
import { toTitleCase } from "utils/functions";
import { PageTitle } from "utils/hooks";
import styles from "./Layout.module.scss";

import Navbar from "./navbar";
import Sidebar from "./sidebar";

const { Content } = AntLayout;
const { useBreakpoint } = Grid;

function Layout() {
  const dispatch = useDispatch();
  const location = useLocation();
  const userInfo = useSelector(userSelector);

  const path = location?.pathname?.split("/")[1];

  const { md } = useBreakpoint();
  const [collapsed, setCollapsed] = useState(false);
  const [hamPosition, setHamPosition] = useState("0px");

  /** Fetch user info everytime the page loads fr the first time */
  useEffect(() => {
    async function getUserInfo() {
      try {
        const res = await getUserInfoApi();
        const userInfoExists = Object.values(userInfo);

        if (!userInfoExists[0]) {
          dispatch(
            setUser({
              _id: res._id,
              email: res.email,
              is_verified: res.is_verified,
              name: res.name,
              permission_level: res.permission_level,
              profile_picture: res.profile_picture,
              type: res.type,
            })
          );
          dispatch(setIsAuthenticated(true));
        }
      } catch (error: any) {
        toast.error(error.response.data.message + " Please login again.");
      }
    }

    getUserInfo();
  }, []);

  return (
    <>
      <AntLayout style={{ overflowX: "hidden" }}>
        <PageTitle
          title={location.pathname === "/" ? "Dashboard" : toTitleCase(path)}
        />
        <AntLayout>
          {/* Allowing ham menu sidebar on images route (only in mobile view) */}
          {path === "images" ? (
            !md && (
              <Sidebar
                md={md}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                hamPosition={hamPosition}
                setHamPosition={setHamPosition}
              />
            )
          ) : location.pathname === "/classifier/create" ||
            location.pathname === "/classes/create" ? null : (
            <Sidebar
              md={md}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
              hamPosition={hamPosition}
              setHamPosition={setHamPosition}
            />
          )}
          {location.pathname === "/classifier/create" ||
          location.pathname === "/classes/create" ? null : (
            <AntLayout
              style={{
                position: "relative",
                marginLeft:
                  path === "images" || !md ? "" : collapsed ? "78px" : "15rem",
              }}
            >
              <Navbar md={md} setHamPosition={setHamPosition} />
              <Content className={path !== "images" ? styles.content : ""}>
                <Outlet />
              </Content>
            </AntLayout>
          )}
        </AntLayout>
      </AntLayout>

      {location.pathname === "/classifier/create" ||
      location.pathname === "/classes/create" ? (
        <Content className={styles.contentLayoutForCreation}>
          <Outlet />
        </Content>
      ) : (
        ""
      )}

      <Toaster
        toastOptions={{
          success: {
            style: {
              border: "1px solid #199c3c",
            },
          },
          error: {
            style: {
              border: "1px solid #FF4C4C",
            },
          },
        }}
      />
    </>
  );
}

export default Layout;
