import { getSearchImageByFile } from "api/imagesDataApi";
import React, { useEffect, useState } from "react";

function SearchImageByFileNameLogic() {
  const [getSearchedImagesData, setGetSearchedImagesData] = useState({
    loading: false,
    error: false,
    data: [],
  });
  const [totalAnnotationCount, setTotalAnnotationCount] = useState<
    number | null
  >(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedClassifierId, setSelectedClassifierId] = useState<string>();
  const [searchLimit, setSearchLimit] = useState(10);
  const [searchSkip, setSearchSkip] = useState(0);
  function handleSearch(limit?: number, skip?: number) {
    setGetSearchedImagesData({
      loading: true,
      error: false,
      data: [],
    });
    try {
      (async () => {
        let searchResults = await getSearchImageByFile({
          fileName: searchQuery,
          classifierId: selectedClassifierId,
          limit: limit || 10,
          skip: skip || 0,
        });
        if (searchResults.length) searchResults = searchResults.slice(0, 1);
        const filteredSearchData = searchResults?.map((eachResult: any) => {
          return {
            id: eachResult.image._id,
            thumbnail: eachResult.image.thumbnail.uri,
            fileName: eachResult.image.metadata.filename,
            datasetId: eachResult.image.dataset_id._id,
            classifierId: eachResult.annotation.length
              ? eachResult.annotation[0].classifier_id
              : null,
            classId: eachResult.annotation.length
              ? eachResult.annotation[0].class?._id
              : null,
            dataset: eachResult.image.dataset_id.name,
            imageName: eachResult.image.imageName,
          };
        });
        setGetSearchedImagesData({
          loading: false,
          error: false,
          data: filteredSearchData,
        });
      })();
    } catch (error) {
      setGetSearchedImagesData({
        loading: false,
        error: true,
        data: [],
      });
    }
  }

  return {
    getSearchedImagesData,
    totalAnnotationCount,
    searchQuery,
    selectedClassifierId,
    searchLimit,
    searchSkip,
    setSearchQuery,
    setSelectedClassifierId,
    setSearchLimit,
    setSearchSkip,
    handleSearch,
  };
}

export default SearchImageByFileNameLogic;
