import {
  getAllClassifiers,
  getClassifierById,
  removeSubClasses,
} from "api/classifierApi";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { setAllClassifiersInfo } from "store/classifierSlice";

export const RemoveSubClassesLogic = () => {
  const dispatch = useDispatch();
  const [classifiers, setClassifiers] = useState<any>([]);
  const [selectedClassifier, setSelectedClassifier] = useState<any>();
  const [classes, setClasses] = useState<any>([]);
  const [selectedClassId, setSelectedClassId] = useState<any>();
  const [subClasses, setSubClasses] = useState<any[]>([]);
  const [selectedSubClasses, setSelectedSubClasses] = useState<string[]>([]);
  const classifiersList = useSelector(
    (state: any) => state.classifierInfo.value
  );

  /** Fetch classifiers */
  useEffect(() => {
    async function getClassifiers() {
      if (classifiersList.length) {
        setClassifiers(classifiersList);
      } else {
        const res = await getAllClassifiers();
        setClassifiers(res);
        dispatch(setAllClassifiersInfo(res));
      }
    }
    getClassifiers();
  }, []);

  /** Fetch classes when classifier selected */
  useEffect(() => {
    async function getClassesByClassifierId() {
      if (selectedClassifier) {
        const res = await getClassifierById(selectedClassifier._id);

        setClasses(res.classes);
      }
    }
    getClassesByClassifierId();
  }, [selectedClassifier]);

  useEffect(() => {
    classes.map((eachClass: any) => {
      if (eachClass._id === selectedClassId) {
        let manipulatedKey = 0;
        const newUpdatedSubClass: any[] = [];
        eachClass.subclasses.forEach((subClass: any) => {
          newUpdatedSubClass.push({
            key: manipulatedKey,
            _id: subClass._id,
            name: subClass.name,
            description: subClass.description,
          });
          manipulatedKey++;
        });
        setSubClasses(newUpdatedSubClass);
      }
    });
  }, [classes, selectedClassId]);

  /** When deleting a single subclass */
  async function handleSingleSubClassDelete(subClassId: string) {
    if (subClassId) {
      const res = await removeSubClasses(selectedClassId, [subClassId]);

      toast.promise(res, {
        loading: "Loading",
        success: "SubClass removed successfully",
        error: "Error! Couldn't remove SubClass",
      });
    } else {
      toast.error("Error! Couldn't remove subClass");
    }
  }

  /** Deleting multiple subclasses */
  async function handleSubClassesDelete() {
    if (selectedSubClasses.length) {
      const res = await removeSubClasses(selectedClassId, selectedSubClasses);

      toast.promise(res, {
        loading: "Loading",
        success: "SubClasses removed successfully",
        error: "Error! Couldn't remove Sub classes",
      });
    } else {
      toast.error("Please select at least one sub class to delete");
    }
  }

  return {
    classifiers,
    selectedClassifier,
    setSelectedClassifier,
    classes,
    selectedClassId,
    setSelectedClassId,
    subClasses,
    selectedSubClasses,
    setSelectedSubClasses,
    handleSingleSubClassDelete,
    handleSubClassesDelete,
  };
};
