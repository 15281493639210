import {
  multipleUserAgreementFactorApi,
  oneUserWithSubClassApi,
  oneUserWithoutSubClassApi,
} from "api/metricsApi";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

export const CreateMetricModalLogic = ({
  allDatasets,
  setOpenCreateMetricModal,
  setCreateMetricSubmitLoading,
  getMetricsData,
}: {
  allDatasets: { [key: string]: any };
  setOpenCreateMetricModal: (open: boolean) => void;
  setCreateMetricSubmitLoading: (loading: boolean) => void;
  getMetricsData: () => void;
}) => {
  const [selectedMetricType, setSelectedMetricType] = useState<string>("");
  const [selectedCreateMetricUsers, setSelectedCreateMetricUsers] = useState<
    string[]
  >([]);
  const [allClassifiersOfDataset, setAllClassifiersOfDataset] = useState<
    object[]
  >([]);
  const [allUsers, setAllUsers] = useState<object[]>([]);
  const [selectedDatasetId, setSelectedDatasetId] = useState<string>("");
  const [selectedDatasetName, setSelectedDatasetName] = useState<string>("");
  const [selectedClassifierId, setSelectedClassifierId] = useState<string>("");
  const [selectedClassifierName, setSelectedClassifierName] =
    useState<string>("");
  const [createNewMetricFileName, setCreateNewMetricFileName] =
    useState<string>("");
  const [sliderAgreementInput, setSliderAgreementInput] = useState<number>(20);
  const [discardToggler, setDiscardToggler] = useState<boolean>(true);

  /** Emptying when metric type changes */
  useEffect(() => {
    setSelectedDatasetId("");
    setSelectedDatasetName("");
    setAllClassifiersOfDataset([]);
    setSelectedClassifierId("");
    setSelectedClassifierName("");
    setSelectedCreateMetricUsers([]);
  }, [selectedMetricType]);

  /** Update classifier field depending on dataset selected */
  useEffect(() => {
    setSelectedClassifierId("");
    setSelectedClassifierName("");
    setSelectedCreateMetricUsers([]);
    allDatasets.data.length > 0 &&
      allDatasets.data.map((eachDataset: any) => {
        if (eachDataset.dataset._id === selectedDatasetId) {
          setAllClassifiersOfDataset(eachDataset.dataset.classifiers);
        }
      });
  }, [selectedDatasetId]);

  /** Update list of users depending on classifier selected */
  useEffect(() => {
    setSelectedCreateMetricUsers([]);
    const userList: any[] = [];
    allDatasets.data.length > 0 &&
      allDatasets.data.map((eachDataset: any) => {
        if (eachDataset.dataset._id === selectedDatasetId) {
          eachDataset.maps.length > 0 &&
            eachDataset.maps.map((eachUser: any) => {
              eachUser.accessible_classifiers.length > 0 &&
                eachUser.accessible_classifiers.map((eachClassifierId: any) => {
                  if (eachClassifierId === selectedClassifierId) {
                    userList.push(eachUser.user_id);
                  }
                });
            });
        }
      });
    setAllUsers(userList);
  }, [selectedClassifierId]);

  /** HandleCancel */
  function handleCancel() {
    setOpenCreateMetricModal(false);
    setCreateNewMetricFileName("");
    setSelectedMetricType("");
    setSelectedDatasetId("");
    setSelectedDatasetName("");
    setAllClassifiersOfDataset([]);
    setSelectedClassifierId("");
    setSelectedClassifierName("");
    setSelectedCreateMetricUsers([]);
    setSliderAgreementInput(20);
    setDiscardToggler(true);
  }

  /** For cases 1 and 2 (one user metrics) */
  async function particularUserSubmit() {
    try {
      selectedMetricType === "0"
        ? await oneUserWithoutSubClassApi({
            user_id: selectedCreateMetricUsers[0],
            classifier_id: selectedClassifierId,
            dataset_id: selectedDatasetId,
            filename: createNewMetricFileName,
          })
        : selectedMetricType === "1" &&
          (await oneUserWithSubClassApi({
            user_id: selectedCreateMetricUsers[0],
            classifier_id: selectedClassifierId,
            dataset_id: selectedDatasetId,
            filename: createNewMetricFileName,
          }));
      setTimeout(() => {
        setCreateMetricSubmitLoading(false);
        setOpenCreateMetricModal(false);
      }, 2000);
      getMetricsData();
      handleCancel();
      toast.success("New metric generation started successfully.");
    } catch (err: any) {
      toast.error(err.response?.data?.message);
    }
  }

  // When create new submit
  const handleCreateMetricSubmit = async () => {
    if (
      createNewMetricFileName &&
      selectedMetricType &&
      selectedDatasetId &&
      selectedClassifierId
    ) {
      setCreateMetricSubmitLoading(true);

      if (selectedMetricType === "0" || selectedMetricType === "1") {
        particularUserSubmit();
      } else if (selectedMetricType === "2") {
        try {
          await multipleUserAgreementFactorApi({
            user_ids: selectedCreateMetricUsers[0],
            classifier_id: selectedClassifierId,
            dataset_id: selectedDatasetId,
            filename: createNewMetricFileName,
          });
          setTimeout(() => {
            setCreateMetricSubmitLoading(false);
            setOpenCreateMetricModal(false);
          }, 2000);
          getMetricsData();
          handleCancel();
          toast.success("New metric generation started successfully.");
        } catch (err: any) {
          toast.error(err.response?.data?.message);
        }
      } else if (selectedMetricType === "3") {
        try {
          await multipleUserAgreementFactorApi({
            user_ids: selectedCreateMetricUsers[0],
            classifier_id: selectedClassifierId,
            dataset_id: selectedDatasetId,
            filename: createNewMetricFileName,
            agreement_factor: sliderAgreementInput,
          });

          setTimeout(() => {
            setCreateMetricSubmitLoading(false);
            setOpenCreateMetricModal(false);
          }, 2000);
          getMetricsData();
          handleCancel();
          toast.success("New metric generation started successfully.");
        } catch (err: any) {
          toast.error(err.response?.data?.message);
        }
      }
    }
  };

  return {
    selectedMetricType,
    setSelectedMetricType,
    selectedCreateMetricUsers,
    setSelectedCreateMetricUsers,
    selectedDatasetId,
    setSelectedDatasetId,
    selectedDatasetName,
    setSelectedDatasetName,
    allClassifiersOfDataset,
    selectedClassifierId,
    setSelectedClassifierId,
    selectedClassifierName,
    setSelectedClassifierName,
    allUsers,
    createNewMetricFileName,
    setCreateNewMetricFileName,
    sliderAgreementInput,
    setSliderAgreementInput,
    discardToggler,
    setDiscardToggler,
    handleCreateMetricSubmit,
    handleCancel,
  };
};
