import React, { useEffect, useState } from "react";
import styles from "./ManageUsers.module.scss";

import { LoadingOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  Empty,
  Input,
  Row,
  Select,
  Spin,
  Table,
  Typography,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  getAllUsersForAdmin,
  getUserBannedApi,
  getUserUnbannedApi,
} from "api/usersApi";
import { IoIosSearch } from "react-icons/io";
const { Option } = Select;
const { Title } = Typography;

interface UserInterface {
  _id: string;
  name: string;
  email: string;
  is_banned: boolean;
  type: string;
}

const ManageUsers: React.FC = () => {
  const [searchText, setSearchText] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<string>("All");
  const [isBannedClicked, setIsBannedClicked] = useState<boolean>(false);
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
  const [alertBoxContent, setAlertBoxContent] = useState("");
  const columns: ColumnsType<UserInterface> = [
    // dataIndex is the key of the object which we are passing to the table
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Permission",
      dataIndex: "type",
      key: "type",
    },
    // if we are not specifying any dataindex then we can have access to the whole user object instead of just the value of that particular column
    {
      title: "Action",
      fixed: "right",
      width: 120,
      render: (user: UserInterface) => (
        <Button
          type={user.is_banned ? "default" : "primary"}
          danger
          onClick={async () => {
            if (user.is_banned) {
              const isUpdated = await revokeBanFunction(user._id);
              if (isUpdated) {
                setIsBannedClicked((prev) => !prev);
                setIsAlertVisible(true);
                setAlertBoxContent(`Successfully Unbanned ${user.name}`);
                setTimeout(() => setIsAlertVisible(false), 2000);
              }
            } else {
              const isUpdated = await banFunction(user._id);
              if (isUpdated) {
                setIsBannedClicked((prev) => !prev);
                setIsAlertVisible(true);
                setAlertBoxContent(`Successfully Banned ${user.name}`);
                setTimeout(() => setIsAlertVisible(false), 2000);
              }
            }
          }}
        >
          {user.is_banned ? "Revoke" : "Ban"}
        </Button>
      ),
    },
  ];

  // for getting all users
  const {
    error,
    isLoading,
    usersToBeDisplayed: users,
    permissions,
  } = useGetAllUsers(searchText, selectedOption, isBannedClicked);
  return (
    <div className={styles.manageUsers__container}>
      {isAlertVisible && (
        <div className={styles.manageUsers__alertbox}>
          <Alert message={alertBoxContent} type="success" showIcon />
        </div>
      )}
      <Row gutter={[16, 16]} wrap={true}>
        {/* Column for Title */}
        <Col>
          <Title level={2} className={styles.manageUsers_heading}>
            All Users
          </Title>
        </Col>
        {/* Column for search filter and select filter */}
        {isLoading ? null : (
          <Col flex={"auto"}>
            <Row gutter={[16, 8]} justify="end">
              <Col span={12} style={{ textAlign: "end" }}>
                <Title level={5} className={styles.manageUsers_filterHeading}>
                  Filter categories :
                </Title>
              </Col>
              <Col>
                <Select
                  defaultValue={"All"}
                  style={{ width: "200px" }}
                  onChange={(value) => {
                    setSelectedOption(value);
                  }}
                  className={styles.manageUsers__selectFilter}
                >
                  {permissions.map((permission: any, index: number) => (
                    <Option key={index} value={permission}>
                      {permission}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={"auto"}>
                <Input
                  size="large"
                  placeholder="Search..."
                  prefix={
                    <IoIosSearch
                      className={styles.manageUsers__searchFilterIcon}
                    />
                  }
                  className={styles.manageUsers__searchFilter}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>

      {/* Body */}
      {isLoading ? (
        <div className={styles.loadingDiv}>
          <Spin
            size="large"
            indicator={
              <LoadingOutlined className={styles.loadingSpinIcon} spin />
            }
            tip="Fetching all users..."
            className={styles.loadingDiv_spin}
          />
        </div>
      ) : users.length > 0 && error == null ? (
        <Table
          dataSource={users}
          columns={columns}
          className={styles.table}
          scroll={{ x: 750, y: 450 }}
        />
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={"No users found"}
          className={styles.table}
        />
      )}
    </div>
  );
};

// custom hook for getting all users
function useGetAllUsers(
  searchText: string,
  selectedOption: string,
  isBannedClicked: boolean
) {
  const [users, setUsers] = useState<UserInterface[]>([]);
  const [usersToBeDisplayed, setUsersToBeDisplayed] = useState<UserInterface[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [permissions, setPermissions] = useState<string[]>([]);

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);
      try {
        const response = await getAllUsersForAdmin();
        const users: UserInterface[] = [];
        const permissions: Set<string> = new Set();
        // adding default option 'All' to the permissions array
        permissions.add("All");
        if (response.code === 200) {
          response.result.data.forEach((user: any) => {
            const { _id, name, email, is_banned, type } = user;
            users.push({ _id, name, email, is_banned, type });
            permissions.add(type);
          });
          // setting the users to the original array
          setUsers(users);
          // setting the users to be displayed in the table
          setUsersToBeDisplayed(users);
          // setting the permissions array for select filter
          setPermissions(Array.from(permissions));
        }
      } catch (error: any) {
        setError(error);
      }
      setIsLoading(false);
    };
    fetchUsers();
  }, [isBannedClicked]);

  useEffect(() => {
    if (selectedOption && searchText && selectedOption !== "All") {
      const filteredUsers = users.filter(
        (user) =>
          user.type === selectedOption &&
          user.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setUsersToBeDisplayed(filteredUsers);
    } else if (selectedOption && selectedOption !== "All") {
      const filteredUsers = users.filter(
        (user) => user.type === selectedOption
      );
      setUsersToBeDisplayed(filteredUsers);
    } else if (searchText && selectedOption === "All") {
      const filteredUsers = users.filter((user) =>
        user.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setUsersToBeDisplayed(filteredUsers);
    } else if (selectedOption === "All" && searchText === "") {
      setUsersToBeDisplayed(users);
    }
  }, [searchText, selectedOption]);

  return { usersToBeDisplayed, permissions, isLoading, error };
}

// user ban function
async function banFunction(userId: string) {
  try {
    const result = await getUserBannedApi(userId);
    return result.acknowledged;
  } catch (error: any) {
    return error;
  }
}

// user revoke ban function
async function revokeBanFunction(userId: string) {
  try {
    const result = await getUserUnbannedApi(userId);
    return result.acknowledged;
  } catch (error: any) {
    return error;
  }
}

export default ManageUsers;
