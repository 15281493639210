function NotFound404() {
  return (
    <div>
      <h1>404 | Not Found</h1>
      <a href="/">Go Home</a>
    </div>
  );
}

export default NotFound404;
