import { getAllCategories, getCategoryStatsByCatId } from "api/categoryApi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setAllSidebarItems } from "store/sidebarDynamicItemsSlice";

export const ViewCamerasLogic = () => {
  const dispatch = useDispatch();
  const existingSidebarCategoryItems = useSelector(
    (state: any) => state.sidebarDynamicItems.value
  );
  const { categoryId } = useParams<string>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedFieldData, setSelectedFieldData] = useState<number>(2);
  const [cameraTypes, setCameraTypes] = useState<object>({
    loading: false,
    error: false,
    data: [],
  });

  /** Fetch camera types */
  useEffect(() => {
    setCameraTypes({
      loading: true,
      error: false,
      data: [],
    });
    try {
      const fetchCameraTypes = async () => {
        const res = await getCategoryStatsByCatId(
          categoryId,
          selectedFieldData
        );
        setCameraTypes({
          loading: false,
          error: false,
          data: res.cameraTypes,
        });

        if (!existingSidebarCategoryItems.length) {
          const allCategoriesWithCameraTypes = await getAllCategories();

          const sidebarItems = allCategoriesWithCameraTypes.map(
            (eachCat: any) => {
              return {
                _id: eachCat._id,
                link: `modality/${eachCat._id}/cameras`,
                name: eachCat.type,
                cameraTypes: eachCat.cameraTypes,
              };
            }
          );
          dispatch(setAllSidebarItems(sidebarItems));
        }
      };
      fetchCameraTypes();
    } catch (error) {
      setCameraTypes({
        loading: false,
        error: true,
        data: [],
      });
    }
  }, [categoryId, selectedFieldData]);

  return {
    categoryId,
    searchTerm,
    setSearchTerm,
    cameraTypes,
    selectedFieldData,
    setSelectedFieldData,
  };
};
