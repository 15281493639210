import { useState } from "react";
import styles from "./UserMapModal.module.scss";
import { Table, Empty, Modal, Select } from "antd";
import { createUserMap } from "api/usersApi";
import toast from "react-hot-toast";

const { Option } = Select;

function UserMapModal({
  details,
  allUsers,
}: {
  details: { [key: string]: any };
  allUsers: { [key: string]: any };
}) {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [formData, setFormData] = useState<{
    [key: string]: any;
  }>({
    user_id: "",
    dataset_id: details.dataset?._id,
    type: "",
    accessible_classifiers: [],
  });

  /** Select user email on onchange */
  const onChange = (value: string) => {
    allUsers.data.length > 0 &&
      allUsers.data.forEach((user: any) => {
        if (user.email === value) {
          setFormData({ ...formData, user_id: user._id });
        }
      });
  };

  /** Select classifier on onchange */
  const handleChange = (value: string[]) => {
    const selectedClassifiers: string[] = [];
    details.dataset.classifiers.forEach((classifier: any) => {
      value.forEach((eachVal: string) => {
        if (classifier.pathology === eachVal) {
          selectedClassifiers.push(classifier._id);
        }
      });
    });
    setFormData({
      ...formData,
      accessible_classifiers: selectedClassifiers,
    });
  };

  /** Mapping new user in dataset */
  const handleOk = async () => {
    setConfirmLoading(true);
    if (
      formData.user_id &&
      formData.type &&
      formData.accessible_classifiers.length > 0
    ) {
      try {
        await createUserMap({ formData });

        setTimeout(() => {
          setOpen(false);
          toast.success("User Map Created Successfully");
          setConfirmLoading(false);
        }, 2000);
      } catch (error: any) {
        toast.error(error.response?.data?.message);
      }
    } else {
      toast.error("Please fill all fields");
      setConfirmLoading(false);
    }
  };

  return (
    <>
      <div style={{ marginRight: "2rem" }}>
        {details.maps.length ? (
          <Table
            // scroll={{ y: 250 }}
            pagination={{ pageSize: 4, hideOnSinglePage: true }}
            showHeader={false}
            dataSource={details.maps}
            columns={[
              {
                key: "User Email",
                render: (details: any) => {
                  return <>{details.user_id.email}</>;
                },
              },
            ]}
          />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={"No mapped users are present"}
          />
        )}
      </div>

      {/* Create New Map */}
      <div>
        <p className={styles.createMap_addNew} onClick={() => setOpen(true)}>
          Add New User Map +
        </p>
        <Modal
          title={`Add New User for Map under ${details.dataset.name}`}
          visible={open}
          onOk={handleOk}
          confirmLoading={confirmLoading}
          onCancel={() => setOpen(false)}
        >
          <div>
            <label className={styles.createMap_inputLabels}>Select Email</label>
            <Select
              className={styles.createMap_inputField}
              showSearch
              placeholder="Select email"
              optionFilterProp="children"
              onChange={onChange}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {allUsers.data.length > 0 && !allUsers.error
                ? allUsers.data.map((user: any) => (
                    <Option key={user._id} value={user.email}>
                      {user.email}
                    </Option>
                  ))
                : null}
            </Select>

            {/* Classifiers Selection */}
            <label className={styles.createMap_inputLabels}>
              Select Classifiers
            </label>
            <Select
              className={styles.createMap_inputField}
              mode="multiple"
              allowClear
              placeholder="Please select"
              //   defaultValue={}
              onChange={handleChange}
            >
              {details.dataset.classifiers.length > 0 &&
                details.dataset.classifiers.map(
                  (eachClassifier: any, index: number) => {
                    return (
                      <Option key={index} value={eachClassifier.pathology}>
                        {eachClassifier.pathology}
                      </Option>
                    );
                  }
                )}
            </Select>

            {/* Select Mode */}
            <label className={styles.createMap_inputLabels}>
              Select Type of User
            </label>
            <Select
              className={styles.createMap_inputField}
              allowClear
              placeholder="Select type of User"
              // defaultValue={""}
              onChange={(val: string) =>
                setFormData({ ...formData, type: val })
              }
            >
              <Option value="viewer">Viewer</Option>
              <Option value="annotator">Annotator</Option>
              <Option value="reviewer">Reviewer</Option>
              <Option value="creator">Creator</Option>
            </Select>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default UserMapModal;
