import { adminViewAllUserAnnotation } from "api/adminViewAnnotations";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

export const AllUserAnnotationLogic = ({
  allDatasets,
  pageCount2,
  pageLimit2,
  setTotalAnnotationCount2,
}: any) => {
  const [classifiers, setClassifiers] = useState<any>([]);

  const [selectedDataset, setSelectedDataset] = useState<any>({});
  const [selectedClassifier, setSelectedClassifier] = useState<any>({});

  const [tableData, setTableData] = useState<{
    permitted_users: any[];
    annotations: any[];
  }>({
    permitted_users: [],
    annotations: [],
  });

  /** Update classifier field dropdown to contain all classifiers depnding on dataset selected */
  useEffect(() => {
    setClassifiers([]);
    selectedDataset.dataset?._id &&
      allDatasets.data?.map((eachDataset: any) => {
        if (eachDataset.dataset._id === selectedDataset.dataset._id) {
          setSelectedClassifier(null);
          setClassifiers(eachDataset.dataset.classifiers);
        }
      });
  }, [selectedDataset]);

  /** Checks for searched dataset */
  const handleDatasetFilter = (value: any) => {
    const filteredDataset = allDatasets.data?.filter(
      (eachDataset: any) => eachDataset.dataset.name === value
    );
    setSelectedDataset(filteredDataset[0]);
  };

  /** Select a particular classifier */
  function handleClassifierChange(classifierId: any) {
    const filteredClassifier = classifiers.filter(
      (eachClassifier: any) => eachClassifier._id === classifierId
    );
    setSelectedClassifier(filteredClassifier[0]);
  }

  /** Fetch users annotation */
  async function getAllAnnotationDetails() {
    try {
      const res = await adminViewAllUserAnnotation({
        body: {
          dataset_id: selectedDataset.dataset._id,
          classifier_id: selectedClassifier._id,
        },
        pageCount: pageCount2,
        pageLimit: pageLimit2,
      });
      setTableData(res);
      setTotalAnnotationCount2(res.totalObjects);
    } catch (error: any) {
      toast.error(error.response?.data?.message);
    }
  }

  return {
    allDatasets,
    selectedDataset,
    setSelectedDataset,
    classifiers,
    setClassifiers,
    selectedClassifier,
    setSelectedClassifier,
    handleDatasetFilter,
    handleClassifierChange,
    getAllAnnotationDetails,
    tableData,
  };
};
