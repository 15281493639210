import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Typography,
  Row,
  Col,
  Input,
  Button,
  Select,
  Spin,
  Breadcrumb,
} from "antd";
import { ViewBucketsLogic } from "./ViewBucketsLogic";
import styles from "./ViewBuckets.module.scss";

// Icons
import { IoIosSearch } from "react-icons/io";
import { FaCamera } from "react-icons/fa";
import { ImArrowRight2 } from "react-icons/im";
import { LoadingOutlined, LeftOutlined } from "@ant-design/icons";
import { setSearchImageInfo } from "store/searchImageInfoSlice";
import { useEffect, useState } from "react";
import { getAnnotatedPerDayDatasetDetails } from "api/annotationData";

const { Title } = Typography;
const { Option } = Select;

function useGetTodayAnnotationCount(datasetId: string) {
  const [annotationsCount, setAnnotationsCount] = useState(0);
  const today = new Date();
  const dateTerm = `${today.getFullYear()}-${
    today.getMonth() <= 8 ? "0" + (today.getMonth() + 1) : today.getMonth() + 1
  }-${today.getDate() <= 8 ? "0" + today.getDate() : today.getDate()}`;

  useEffect(() => {
    try {
      const fetchDatasets = async () => {
        const res = await getAnnotatedPerDayDatasetDetails({
          pageCount: 1,
          pageLimit: 10,
          dateTerm,
          datasetId,
        });
        setAnnotationsCount(res.count);
      };
      fetchDatasets();
    } catch (error) {
      console.log("ERROR : getting today annotations count in viewBuckets");
    }
  }, []);
  return annotationsCount;
}

function ViewBuckets() {
  const dispatch = useDispatch();
  const {
    datasetId,
    datasetInfo,
    classifiers,
    selectedClassifier,
    setSelectedClassifier,
    buckets,
    searchTerm,
    setSearchTerm,
    bucketBtnColor,
    errMsg,
    reviewUserIds,
    reviewerViewAnnotatorName,
    saveForLaterId,
  }: {
    datasetId: any;
    datasetInfo: any;
    classifiers: any;
    selectedClassifier: any;
    setSelectedClassifier: any;
    buckets: any;
    searchTerm: any;
    setSearchTerm: any;
    bucketBtnColor: any;
    errMsg: string;
    reviewUserIds: string[];
    reviewerViewAnnotatorName: string | null;
    saveForLaterId: string;
  } = ViewBucketsLogic();
  const navigate = useNavigate();
  const todayAnnotationCount = useGetTodayAnnotationCount(datasetId);
  const { state } = useLocation();
  return (
    <>
      {datasetInfo && (
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <span>Home</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/">
            <span>Modality</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span
              onClick={() => navigate(-1)}
              className={styles.breadcrumbs_link}
            >
              {datasetInfo?.cameraType_name}
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span
              onClick={() => navigate(-1)}
              className={styles.breadcrumbs_link}
            >
              {datasetInfo?.name}
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span
              onClick={() => navigate(-1)}
              className={styles.breadcrumbs_link}
            >
              {(state as any).pathology}
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Buckets</Breadcrumb.Item>
        </Breadcrumb>
      )}
      <div>
        <Title level={2} className={styles.buckets_heading}>
          Datasets : {datasetInfo?.name}
        </Title>
        <Link
          to={`/annotations-today-dataset/${datasetId}`}
          rel="noopener noreferrer"
        >
          <Button type="primary">
            Today's Annotations in {datasetInfo?.name} :{" "}
            <span style={{ fontWeight: "bold", marginLeft: "1rem" }}>
              {todayAnnotationCount}
            </span>
          </Button>
        </Link>
      </div>
      {(datasetInfo?.description || datasetInfo?.cameraType_name) && (
        <Title level={5} className={styles.buckets_description}>
          {datasetInfo?.description}
          <hr /> <FaCamera className={styles.buckets_descriptionCamera} />{" "}
          {datasetInfo?.cameraType_name}
        </Title>
      )}
      {reviewUserIds?.length === 1 && reviewerViewAnnotatorName && (
        <Title level={5} className={styles.buckets_description}>
          Viewing {JSON.parse(reviewerViewAnnotatorName)}'s annotated buckets
        </Title>
      )}
      <Row className={styles.buckets_headingRow}>
        <Col xl={12} lg={24} md={24} sm={24} xs={24}>
          <Input
            size="large"
            placeholder="Search..."
            prefix={<IoIosSearch className={styles.buckets_searchBoxIcon} />}
            className={styles.buckets_searchBox}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Col>
        <Col xl={12} lg={24} md={24} sm={24} xs={24}>
          {/* <div className={styles.filterCol}>
                        {classifiers.length ? (
                            <>
                                <Title
                                    level={5}
                                    className={styles.buckets_filterHeading}
                                >
                                    Filter classifiers :
                                </Title>
                                <Select
                                    defaultValue={selectedClassifier?.pathology}
                                    style={{ width: 120 }}
                                    className={styles.buckets_filter}
                                    onChange={(val: string) => {
                                        const classifier = classifiers.find(
                                            (classifier: any) =>
                                                classifier.pathology === val
                                        );
                                        setSelectedClassifier(classifier);
                                    }}
                                >
                                    {classifiers?.map((classifier: any) => (
                                        <Option
                                            key={classifier._id}
                                            value={classifier.pathology}
                                        >
                                            {classifier.pathology}
                                        </Option>
                                    ))}
                                </Select>
                            </>
                        ) : (
                            ""
                        )}
                    </div> */}
        </Col>
      </Row>
      {/* Buckets */}
      <Row className={styles.buckets_row}>
        {buckets.loading ? (
          <div className={styles.loadingDiv}>
            <Spin
              size="large"
              indicator={
                <LoadingOutlined className={styles.loadingSpinIcon} spin />
              }
              tip="Loading Buckets..."
              className={styles.loadingDiv_spin}
            />
          </div>
        ) : !buckets.error && buckets.data.length > 0 ? (
          buckets.data
            .filter((val: any) =>
              searchTerm === ""
                ? val
                : val.class.name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) && val
            )
            .map((bucket: any) => {
              return (
                <>
                  <Col
                    lg={8}
                    md={12}
                    xs={24}
                    className={styles.buckets_col}
                    key={bucket._id}
                  >
                    <div className={styles.buckets_card}>
                      <Title level={4} className={styles.bucketsCard_name}>
                        {bucket.class.name}
                      </Title>
                      <Title level={4} className={styles.bucketsCard_imgCount}>
                        {bucket.finalImagesCount} images
                      </Title>
                      {/* <Title level={4} className={styles.bucketsCard_imgCount}>
                      Annotated images : {bucket.annotatedCount}
                    </Title> */}

                      <Link
                        to={
                          bucket.class.name === "Saved for Later"
                            ? `/images/${datasetId}/${selectedClassifier._id}/${saveForLaterId}/1` // the 1 is for savedForLater true
                            : bucket.class.name === "Not Annotated" ||
                              bucket.class.name === "Not Reviewed"
                            ? `/images/${datasetId}/${selectedClassifier._id}/0/1`
                            : `/images/${datasetId}/${selectedClassifier._id}/${bucket.class._id}/0` // 0 means not saved for later
                        }
                      >
                        <Button
                          className={styles.bucketsCard_btn}
                          disabled={
                            bucket.finalImagesCount === 0 ? true : false
                          }
                          style={{
                            margin: "1rem 0 0 auto",
                            backgroundColor: bucketBtnColor(
                              bucket.class.name,
                              bucket.finalImagesCount
                            ),
                            border: "none",
                          }}
                          onClick={() => {
                            dispatch(setSearchImageInfo(""));
                            bucket.class.name === "Saved for Later"
                              ? localStorage.setItem(
                                  "viewingSaveForLater",
                                  "true"
                                )
                              : localStorage.removeItem("viewingSaveForLater");
                            bucket.class.name === "Not Annotated" ||
                            bucket.class.name === "Not Reviewed"
                              ? localStorage.setItem(
                                  "viewingNotAnnotated",
                                  "true"
                                )
                              : localStorage.removeItem("viewingNotAnnotated");
                          }}
                        >
                          Annotate{" "}
                          <ImArrowRight2
                            className={styles.bucketsCard_btnArrowIcon}
                          />
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </>
              );
            })
        ) : buckets.data.length > 0 ? (
          <Title level={4} className={styles.buckets_errorMsg}>
            No buckets found
          </Title>
        ) : (
          <div className={styles.errorDiv}>
            <Title level={4} className={styles.buckets_errorMsg}>
              {errMsg}
            </Title>
          </div>
        )}
      </Row>
    </>
  );
}

export default ViewBuckets;
