import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any[] = [];

export const bucketsDatasetInfoSlice = createSlice({
  name: "bucketsDatasetInfo",
  initialState: { value: initialState },
  reducers: {
    setBucketsDatasetInfo: (state, { payload }: PayloadAction<any[]>) => {
      state.value = payload;
    },
  },
});

export const { setBucketsDatasetInfo } = bucketsDatasetInfoSlice.actions;
export default bucketsDatasetInfoSlice.reducer;
