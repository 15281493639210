import { getAllCategories } from "api/categoryApi";
import { createClassifier } from "api/classifierApi";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

export const CreateClassifierLogic = (form: any) => {
  const [allCategoryData, setAllCategoryData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pathologyName, setPathologyName] = useState("");
  const [pathologyModality, setPathologyModality] = useState("");

  /** Fetching categories & classifiers */
  useEffect(() => {
    async function getCategories() {
      const res = await getAllCategories();
      setAllCategoryData(res);
    }
    getCategories();
  }, []);

  /** Handling submit */
  async function onFinish() {
    setIsLoading(true);
    try {
      if (!pathologyName || !pathologyModality) {
        toast.error("Please fill all the fields");
        setIsLoading(false);
        return;
      }
      const res = await createClassifier({
        category_id: pathologyModality,
        pathology: pathologyName,
      });
      if (res) {
        setIsLoading(false);
        setPathologyName("");
        setPathologyModality("");
        toast.success("Classifier created successfully");
      }
    } catch (error) {
      toast.error("Not able to create dataset at this moment");
    }
  }

  const onFinishFailed = (errorInfo: any) => {};

  return {
    onFinish,
    onFinishFailed,
    allCategoryData,
    isLoading,
    pathologyName,
    pathologyModality,
    setPathologyName,
    setPathologyModality,
  };
};
