export const colors: string[] = [
  "#23282B",
  "#23282B",
  "#EA899A",
  "#F54021",
  "#F3A505",
  "#79553D",
  "#E6D690",
  "#BEBD7F",
  "#C51D34",
  "#FAD201",
  "#84C3BE",
  "#A5A5A5",
  "#A98307",
  "#9B111E",
  "#828282",
  "#909090",
  "#4C9141",
  "#403A3A",
  "#8673A1",
  "#FE0000",
  "#1F3A3D",
  "#F44611",
  "#B44C43",
  "#2C5545",
  "#755C48",
  "#FFFFFF",
  "#587246",
  "#6A5D4D",
  "#D84B20",
  "#6C3B2A",
  "#474B4E",
  "#3F888F",
  "#424632",
  "#721422",
  "#00BB2D",
  "#0E294B",
  "#898176",
  "#343B29",
  "#592321",
  "#316650",
  "#E63244",
  "#EFA94A",
  "#8A6642",
  "#F75E25",
  "#D95030",
  "#9D9101",
  "#EAE6CA",
  "#102C54",
  "#fff",
];
