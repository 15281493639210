import { Popover, Table, Typography } from "antd";
import styles from "./AdminViewOneUserTable.module.scss";

const { Title } = Typography;

function AdminViewUserTable({
  selectedUser,
  tableData,
}: {
  selectedUser: any;
  tableData: any;
}) {
  /** Contains columns with data */
  const tableHeaders = [
    {
      title: "Image",
      key: "imageName",
      width: "25%",
      render: (details: any) => {
        return (
          <>
            <Popover
              content={<>{details?.image?.metadata.filename}</>}
              placement="right"
            >
              {details?.image?.metadata.filename &&
                details.image.metadata.filename.substring(0, 15) + "..."}
            </Popover>
          </>
        );
      },
    },
    {
      title: "Class",
      key: "class",
      width: "10%",
      render: (details: any) => {
        return <div>
        {details.classesArray && details.classesArray.length > 0
          ? details.classesArray.map((cls:any) => cls.name).join(', ')
          : details.class?.name || '-'}
      </div>;
      },
    },
    {
      title: "SubClasses",
      key: "subclasses",
      render: (details: any) => {
        return (
          <div>
            {details.subclasses.length > 0 ? (
              details.subclasses.map((eachSubclass: any) => {
                return (
                  <li>
                    {eachSubclass.id?.name} &nbsp; {eachSubclass?.count}
                  </li>
                );
              })
            ) : (
              <>N/A</>
            )}
          </div>
        );
      },
    },
    {
      title: "Review",
      key: "review",
      align: "center" as const,
      width: "10%",
      render: (details: any) => {
        return <div>{details.review?.status ?? "N/A"}</div>;
      },
    },
    {
      title: "Remarks",
      key: "remarks",
      align: "center" as const,
      width: "28%",
      render: (details: any) => {
        return (
          <div>
            {details.remarks ? (
              details.remarks
            ) : (
              <p style={{ textAlign: "center" }}>-</p>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Title level={5} className={styles.table_annotationsHeading}>
        Annotations done by {selectedUser?.user_id?.name}
        {/* &#40;{selectedUser.user_id.email}&#41; */}
      </Title>
      <Table
        dataSource={tableData}
        columns={tableHeaders}
        className={styles.table}
        pagination={false}
        scroll={{ x: 1000 }}
      />
    </div>
  );
}

export default AdminViewUserTable;
