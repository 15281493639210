import { useState, useEffect } from "react";
import { getAllClassifiers, updateClassifier } from "api/classifierApi";
import toast from "react-hot-toast";

export const UpdateModalLogic = ({
  item,
  setSelectedItemForUpdate,
  visibleUpdateModal,
  setVisibleUpdateModal,
  setClassifiers,
}: {
  item: any;
  setSelectedItemForUpdate: any;
  visibleUpdateModal: boolean;
  setVisibleUpdateModal: (visibleUpdateModal: boolean) => void;
  setClassifiers: (val: any) => void;
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [newUpdatedClassifier, setnewUpdatedClassifier] = useState({
    classifier_id: "",
    pathology: "",
  });

  /**  Adding selectedCat val */
  useEffect(() => {
    setnewUpdatedClassifier({
      classifier_id: item?._id,
      pathology: item?.pathology,
    });
  }, [item]);

  /** onCHange handler for classifier details */
  const handleInputChange = (e: any, key: string) => {
    setnewUpdatedClassifier({
      ...newUpdatedClassifier,
      [key]: e.target.value,
    });
  };

  /** Submit updated classifier details */
  async function handleOk() {
    if (newUpdatedClassifier.pathology) {
      try {
        setConfirmLoading(true);
        await updateClassifier(newUpdatedClassifier);
        setConfirmLoading(false);
        setVisibleUpdateModal(false);
        toast.success("Classifier updated successfully");

        const newFetchedClassifiers = await getAllClassifiers();
        setClassifiers(newFetchedClassifiers);
      } catch (error) {
        setConfirmLoading(false);
        toast.error("Error updating classifier");
      }
    } else {
      alert("Please fill all the fields");
    }
    // setTimeout(() => {
    //   setVisibleUpdateModal(false);
    //   setConfirmLoading(false);
    // }, 2000);
  }
  // Close modal
  const handleCancel = () => {
    setVisibleUpdateModal(false);
    setSelectedItemForUpdate(null);
  };

  return {
    visibleUpdateModal,
    handleOk,
    confirmLoading,
    handleCancel,
    newUpdatedClassifier,
    handleInputChange,
  };
};
