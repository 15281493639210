import { Empty, Table, Typography, Popover, Button } from "antd";
import styles from "./AllUserTable.module.scss";

// Icons
import { InfoCircleOutlined } from "@ant-design/icons";

const { Title } = Typography;

function AllUserTable({
  selectedDataset,
  datasetName,
  classifierName,
  tableData,
  setPageCount2,
  setPageLimit2,
}: {
  selectedDataset: any;
  datasetName: string;
  classifierName: string;
  tableData: any;
  setPageCount2: any;
  setPageLimit2: any;
}) {
  /** Shows table with all users annotation data */
  const showUserSelectedClass =
    tableData.permitted_users.length &&
    tableData.permitted_users.map((user: any) => {
      return {
        title: user.name,
        key: user,
        render: (details: any) => {
          return (
            <div>
              {details.annotations.map((annotation: any) => {
                if (annotation.user === user._id) {
                  return (
                    <>
                      {annotation?.classesArray && annotation?.classesArray?.length > 0
                ? annotation?.classesArray.map((cls:any) => cls.name).join(', ')
                : annotation?.class?.name || '-'}
                      {
                        <Popover
                          content={
                            <>
                              {annotation.subclasses.length > 0 ? (
                                annotation.subclasses.map(
                                  (eachSubclass: any) => {
                                    return (
                                      <li>
                                        {eachSubclass.id?.name} (
                                        {eachSubclass?.count})
                                      </li>
                                    );
                                  }
                                )
                              ) : (
                                <p>No subclasses found</p>
                              )}
                            </>
                          }
                          placement="right"
                          title="More Details"
                        >
                          <InfoCircleOutlined
                            className={styles.tableCol_infoIcon}
                          />
                        </Popover>
                      }
                    </>
                  );
                }
              })}
            </div>
          );
        },
      };
    });

  const tableHeaders = [
    {
      title: "Image",
      key: "imageName",
      render: (details: any) => {
        {}
        return (
          <>
            <Popover
              content={<>{details?._id.metadata.filename}</>}
              placement="right"
            >
              {selectedDataset.dataset.identifier + "-" + details._id.key}
            </Popover>
          </>
        );
      },
    },
    ...showUserSelectedClass,
  ];

  return (
    <>
      <Title level={5} className={styles.table_annotationsHeading}>
        All Annotations under {classifierName} classifier of {datasetName}
      </Title>
      {tableData.annotations.length ? (
        <Table
          dataSource={tableData.annotations}
          columns={tableHeaders}
          className={styles.table}
          scroll={{ x: 1000 }}
          pagination={false}
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={"No annotations found under this filter"}
          />
          <Button
            type="primary"
            onClick={() => {
              setPageLimit2(10);
              setPageCount2(1);
            }}
            style={{ borderRadius: "4px" }}
          >
            Reset Page
          </Button>
        </div>
      )}
    </>
  );
}

export default AllUserTable;
