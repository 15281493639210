import { Button, Col, Input, Row, Select, Spin, Typography } from "antd";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setSearchImageInfo } from "store/searchImageInfoSlice";
import styles from "./ViewReviewBuckets.module.scss";
import { ViewReviewBucketsLogic } from "./ViewReviewBucketsLogic";

// Icons
import { LeftOutlined, LoadingOutlined } from "@ant-design/icons";
import { Toaster } from "react-hot-toast";
import { FaCamera } from "react-icons/fa";
import { ImArrowRight2 } from "react-icons/im";
import { IoIosSearch } from "react-icons/io";

const { Title } = Typography;
const { Option } = Select;

function ViewReviewBuckets() {
  const dispatch = useDispatch();
  const {
    reviewBucketId,
    datasetInfo,
    classifierId,
    buckets,
    searchTerm,
    setSearchTerm,
    bucketBtnColor,
    errMsg,
  }: {
    reviewBucketId: any;
    datasetInfo: any;
    classifiers: any;
    classifierId: any;
    buckets: any;
    searchTerm: any;
    setSearchTerm: any;
    bucketBtnColor: any;
    errMsg: string;
  } = ViewReviewBucketsLogic();

  const navigate = useNavigate();

  return (
    <>
      <div>
        <span onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
          <LeftOutlined className={styles.buckets_goBackArrow} />
          <span className={styles.buckets_goBackBtn}>Go Back</span>
        </span>
      </div>
      <Row className={styles.buckets_headingRow}>
        <Col md={24} lg={12}>
          <Title level={2} className={styles.buckets_heading}>
            Datasets : {datasetInfo?.name}
          </Title>
          {(datasetInfo?.description || datasetInfo?.cameraType_name) && (
            <Title level={5} className={styles.buckets_description}>
              {datasetInfo?.description}
              <hr /> <FaCamera
                className={styles.buckets_descriptionCamera}
              />{" "}
              {datasetInfo?.cameraType_name}
            </Title>
          )}
          <Input
            size="large"
            placeholder="Search..."
            prefix={<IoIosSearch className={styles.buckets_searchBoxIcon} />}
            className={styles.buckets_searchBox}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Col>
        <Col lg={12} md={24} sm={24}></Col>
      </Row>

      {/* Buckets */}
      <Row className={styles.buckets_row}>
        {buckets.loading ? (
          <div className={styles.loadingDiv}>
            <Spin
              size="large"
              indicator={
                <LoadingOutlined className={styles.loadingSpinIcon} spin />
              }
              tip="Loading Buckets..."
              className={styles.loadingDiv_spin}
            />
          </div>
        ) : !buckets.error && buckets.data.length > 0 ? (
          buckets.data
            .filter((val: any) =>
              searchTerm === ""
                ? val
                : val.class.name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) && val
            )
            .map((bucket: any) => {
              return (
                <Col
                  lg={8}
                  md={12}
                  xs={24}
                  className={styles.buckets_col}
                  key={bucket._id}
                >
                  <div className={styles.buckets_card}>
                    <Title level={4} className={styles.bucketsCard_name}>
                      {bucket.class.name}
                    </Title>
                    <Title level={4} className={styles.bucketsCard_imgCount}>
                      {bucket.finalImagesCount} images
                    </Title>
                    <Link
                      to={`/images/review/${reviewBucketId}/${classifierId}/${bucket.class._id}/0`}
                    >
                      <Button
                        className={styles.bucketsCard_btn}
                        style={{
                          margin: "1rem 0 0 auto",
                          backgroundColor: bucketBtnColor(bucket.class.name),
                          border: "none",
                        }}
                        onClick={() => {
                          dispatch(setSearchImageInfo(""));
                          // toast.error("Under construction ⚒️");
                        }}
                      >
                        Discover{" "}
                        <ImArrowRight2
                          className={styles.bucketsCard_btnArrowIcon}
                        />
                      </Button>
                    </Link>
                  </div>
                </Col>
              );
            })
        ) : buckets.data.length <= 0 ? (
          <Title level={4} className={styles.buckets_errorMsg}>
            No buckets found
          </Title>
        ) : (
          <div className={styles.errorDiv}>
            <Title level={4} className={styles.buckets_errorMsg}>
              {errMsg}
            </Title>
          </div>
        )}
      </Row>

      <Toaster
        toastOptions={{
          success: {
            style: {
              border: "1px solid #199c3c",
            },
          },
          error: {
            style: {
              border: "1px solid #FF4C4C",
            },
          },
        }}
      />
    </>
  );
}

export default ViewReviewBuckets;
