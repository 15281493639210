import React from "react";

import BucketsCol from "components/imagesData/ViewImages/BucketsCol";
import CreateAnnotation from "components/imagesData/ViewImages/CreateAnnotation";
// import ImageControl from "components/imagesData/ViewImages/ImageControl";

import ImageControl from "components/imagesData/ViewImages/ImageControl/ImageViewer";
import { ViewImagesLogic } from "./ViewImagesLogic";
import "./index.scss";

function ImageViewController() {
  // const [isAnnotatorOpen, setIsAnnotatorOpen] = React.useState(false);
  const {
    datasetName,
    classifierId,
    buckets,
    selectedBucket,
    selectedDropdownClassifier,
    setSelectedDropdownClassifier,
    handleImages,
    images,
    selectedImage,
    selectedImageErrMsg,
    selectedImgCount,
    setSelectedImgCount,
    onChange,
    radioBucketNames,
    onCheckboxChange,
    selectedRadioClass,
    selectedRadioSubClasses,
    setSelectedRadioClass,
    setSelectedRadioSubClasses,
    setUserAnnotationId,
    userAnnotationRemarks,
    setUserAnnotationRemarks,
    handleSelectedSubClassCount,
    annotationMode,
    setAnnotationMode,
    handleAnnotationMode,
    postAnnotation,
    imgRef,
    emptyBucketImgHandler,
    checkAnnoSaveAndNext,
    reviewAnnotatorDetails,
    saveForLater,
    setSaveForLater,
    isDoubtToggler,
    setIsDoubtToggler,
    isReviewRoute,
    showClassesForReview,
    setShowClassesForReview,
    agreeAnnotatorImgStatus,
    reviewAnnotationIsAccepted,
    setReviewAnnotationIsAccepted,
    imgNameAndDes,
    bucketsNameAndCount,
    handleBucketImagesCount,
    viewingSaveForLater,
    savedForLaterBucketDetails,
    selectedBucketId,
    annotatedImagesForOneDay,
    goToSelectedImgFromAnnoImgs,
    allUsersAnnotationEqual,
    handleAnnotationMarkerJs,
    selectedColorForSubClass,
    setSelectedColorForSubClass,
    notReviewedBucketDetails,
    selectedClasses,
    setSelectedClasses,
    setSelectedOption,
    selectedOption,
    initialAnnoImgDetails,
    initialAnnoDetails,
  }: {
    initialAnnoDetails: any;
    datasetName: any;
    initialAnnoImgDetails: any;
    setSelectedOption: any;
    selectedOption: any;
    classifierId: any;
    buckets: object[];
    selectedBucket: string;
    selectedDropdownClassifier: any;
    setSelectedDropdownClassifier: (classifier: object) => void;
    handleImages: (bucket: object[]) => void;
    images: object[];
    selectedImage: any;
    selectedImageErrMsg: string;
    selectedImgCount: number;
    setSelectedImgCount: (count: number) => void;
    onChange: (e: any) => void;
    radioBucketNames: any;
    onCheckboxChange: (e: any) => void;
    selectedRadioClass: any;
    selectedRadioSubClasses: any;
    setSelectedRadioClass: (classId: string) => void;
    setSelectedRadioSubClasses: (subClass: any) => void;
    selectedClasses: any;
    setSelectedClasses: (selectedClass: any) => void;
    userAnnotationId: string;
    setUserAnnotationId: (id: any) => void;
    userAnnotationRemarks: string;
    setUserAnnotationRemarks: (remarks: string) => void;
    saveForLater: any;
    setSaveForLater: (checked: boolean) => void;
    handleSelectedSubClassCount: (
      subClassId: string,
      updatedSubClassCount: number
    ) => void;
    annotationMode: boolean;
    setAnnotationMode: (mode: boolean) => void;
    handleAnnotationMode: (mode: boolean) => void;
    postAnnotation: () => void;
    imgRef: any;
    emptyBucketImgHandler: any;
    checkAnnoSaveAndNext: any;
    reviewAnnotatorDetails: any[];
    isDoubtToggler: boolean;
    setIsDoubtToggler: (checked: boolean) => void;
    isReviewRoute: any;
    showClassesForReview: any;
    setShowClassesForReview: (checked: any) => void;
    agreeAnnotatorImgStatus: () => void;
    reviewAnnotationIsAccepted: any;
    setReviewAnnotationIsAccepted: (checked: boolean) => void;
    imgNameAndDes: any;
    bucketsNameAndCount: any;
    handleBucketImagesCount: (bucket: any) => void;
    viewingSaveForLater: any;
    savedForLaterBucketDetails: any;
    selectedBucketId: string;
    goToSelectedImgFromAnnoImgs: (selected: any) => void;
    annotatedImagesForOneDay: any[];
    allUsersAnnotationEqual: boolean;
    handleAnnotationMarkerJs: (
      viewerImageRef: HTMLImageElement,
      annotatorImageRef: HTMLImageElement
    ) => void;
    selectedColorForSubClass: string;
    setSelectedColorForSubClass: React.Dispatch<React.SetStateAction<string>>;
    notReviewedBucketDetails: any;
  } = ViewImagesLogic();

  return (
    <div id="app">
      <div className="col1">
        <BucketsCol
          buckets={buckets}
          selectedBucket={selectedBucket}
          handleImages={handleImages}
          annotationMode={annotationMode}
          setAnnotationMode={setAnnotationMode}
          isDoubtToggler={isDoubtToggler}
          setIsDoubtToggler={setIsDoubtToggler}
          isReviewRoute={isReviewRoute}
          bucketsNameAndCount={bucketsNameAndCount}
          handleBucketImagesCount={handleBucketImagesCount}
          images={images}
          viewingSaveForLater={viewingSaveForLater}
          savedForLaterBucketDetails={savedForLaterBucketDetails}
          notReviewedBucketDetails={notReviewedBucketDetails}
        />
      </div>
      <div>
        <ImageControl
          imgRef={imgRef}
          imgNameAndDes={imgNameAndDes}
          annotationMode={annotationMode}
          selectedRadioClass={selectedRadioClass}
          setAnnotationMode={setAnnotationMode}
          selectedImage={selectedImage}
          selectedImageErrMsg={selectedImageErrMsg}
          handleAnnotationMode={handleAnnotationMode}
          handleAnnotationMarkerJs={handleAnnotationMarkerJs}
          images={images}
          selectedImgCount={selectedImgCount}
          setSelectedImgCount={setSelectedImgCount}
          emptyBucketImgHandler={emptyBucketImgHandler}
          setUserAnnotationId={setUserAnnotationId}
          isDoubtToggler={isDoubtToggler}
          showClassesForReview={showClassesForReview}
          setShowClassesForReview={setShowClassesForReview}
          isReviewRoute={isReviewRoute}
          reviewAnnotatorDetails={reviewAnnotatorDetails}
          selectedColorForSubClass={selectedColorForSubClass}
        />
      </div>
      <div className="col2">
        <CreateAnnotation
        initialAnnoImgDetails = {initialAnnoImgDetails}
          datasetName={datasetName}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          classifierPathId={classifierId}
          onChange={onChange}
          selectedBucketId={selectedBucketId}
          selectedRadioClass={selectedRadioClass}
          selectedRadioSubClasses={selectedRadioSubClasses}
          setSelectedRadioClass={setSelectedRadioClass}
          setSelectedRadioSubClasses={setSelectedRadioSubClasses}
          handleSelectedSubClassCount={handleSelectedSubClassCount}
          radioBucketNames={radioBucketNames}
          onCheckboxChange={onCheckboxChange}
          selectedImgCount={selectedImgCount}
          setSelectedImgCount={setSelectedImgCount}
          postAnnotation={postAnnotation}
          images={images}
          setUserAnnotationId={setUserAnnotationId}
          userAnnotationRemarks={userAnnotationRemarks}
          setUserAnnotationRemarks={setUserAnnotationRemarks}
          saveForLater={saveForLater}
          setSaveForLater={setSaveForLater}
          selectedDropdownClassifier={selectedDropdownClassifier}
          setSelectedDropdownClassifier={setSelectedDropdownClassifier}
          checkAnnoSaveAndNext={checkAnnoSaveAndNext}
          reviewAnnotatorDetails={reviewAnnotatorDetails}
          isReviewRoute={isReviewRoute}
          showClassesForReview={showClassesForReview}
          setShowClassesForReview={setShowClassesForReview}
          agreeAnnotatorImgStatus={agreeAnnotatorImgStatus}
          reviewAnnotationIsAccepted={reviewAnnotationIsAccepted}
          setReviewAnnotationIsAccepted={setReviewAnnotationIsAccepted}
          annotatedImagesForOneDay={annotatedImagesForOneDay}
          goToSelectedImgFromAnnoImgs={goToSelectedImgFromAnnoImgs}
          allUsersAnnotationEqual={allUsersAnnotationEqual}
          setSelectedColorForSubClass={setSelectedColorForSubClass}
          selectedClasses={selectedClasses}
          setSelectedClasses={setSelectedClasses}
          initialAnnoDetails = {initialAnnoDetails}
        />
      </div>
    </div>
  );
}

export default ImageViewController;
