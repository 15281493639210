import { Button, Col, Empty, Row, Select, Spin, Typography } from "antd";
import AllUserTable from "components/AdminViewUserAnnotation/AllUserTable";
import { Toaster } from "react-hot-toast";
import style from "../../metrics/Metrics.module.scss";
import styles from "./AllUserAnnotation.module.scss";
import { AllUserAnnotationLogic } from "./AllUserAnnotationLogic";

// Icons
import { LoadingOutlined } from "@ant-design/icons";
import { useEffect } from "react";

const { Title } = Typography;
const { Option } = Select;

function AllUserAnnotation({
  allDatasets,
  setAllDatasets,
  pageCount2,
  setPageCount2,
  pageLimit2,
  setPageLimit2,
  totalAnnotationCount2,
  setTotalAnnotationCount2,
}: any) {
  const {
    selectedDataset,
    classifiers,
    selectedClassifier,
    handleDatasetFilter,
    handleClassifierChange,
    getAllAnnotationDetails,
    tableData,
  }: {
    selectedDataset: any;
    setSelectedDataset: (e: any) => void;
    classifiers: any;
    selectedClassifier: any;
    handleDatasetFilter: (datasetName: string) => void;
    handleClassifierChange: (classifierId: string) => void;
    getAllAnnotationDetails: () => void;
    tableData: {
      permitted_users: any[];
      annotations: any[];
    };
  } = AllUserAnnotationLogic({
    allDatasets,
    setAllDatasets,
    pageCount2,
    setPageCount2,
    pageLimit2,
    setPageLimit2,
    totalAnnotationCount2,
    setTotalAnnotationCount2,
  });

  useEffect(() => {
    if (
      Object.keys(selectedDataset).length &&
      Object.keys(selectedClassifier).length
    )
      getAllAnnotationDetails();
  }, [pageCount2, pageLimit2]);

  return (
    <>
      {allDatasets.loading ? (
        <div className={styles.loadingDiv}>
          <Spin
            size="large"
            indicator={
              <LoadingOutlined className={styles.loadingSpinIcon} spin />
            }
            tip="Loading..."
            className={styles.loadingDiv_spin}
          />
        </div>
      ) : allDatasets.error ? (
        <Title level={4} className={styles.oneUser_errMsg}>
          Some error occured
        </Title>
      ) : (
        <>
          <Row>
            <Col lg={6} sm={24} xs={24}>
              <label className={styles.filter_inputLabels}>
                Select Dataset
              </label>
              <Select
                className={styles.filter_inputField}
                showSearch
                placeholder="Select Dataset"
                optionFilterProp="children"
                onChange={(e) => handleDatasetFilter(e)}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {allDatasets.data.length > 0 &&
                  allDatasets.data.map((eachDataset: any) => {
                    return (
                      <Option value={eachDataset.dataset.name}>
                        {eachDataset.dataset.name}
                      </Option>
                    );
                  })}
              </Select>
            </Col>
            <Col lg={6} sm={24} xs={24}>
              <label className={styles.filter_inputLabels}>
                Select Classifier{" "}
              </label>
              <Select
                value={selectedClassifier && selectedClassifier.pathology}
                className={styles.filter_inputField}
                placeholder="Select classifier"
                onChange={(e) => handleClassifierChange(e)}
              >
                {classifiers.length &&
                  classifiers.map((eachClassifier: any) => {
                    return (
                      <Option value={eachClassifier._id}>
                        {eachClassifier.pathology}
                      </Option>
                    );
                  })}
              </Select>
            </Col>
            <Col lg={4} sm={24} xs={24}>
              <Button
                className={styles.filter_submitBtn}
                onClick={() => getAllAnnotationDetails()}
              >
                Submit
              </Button>
            </Col>
          </Row>
          <hr className={styles.viewOneUserAnno_hrLine} />

          <div className={style.metrics_paginationDiv}>
            {tableData.annotations.length ? (
              <div style={{ display: "flex", gap: "8px" }}>
                <Button
                  onClick={() => setPageCount2(Math.max(pageCount2 - 1, 1))}
                  style={{
                    borderRadius: "4px",
                    fontWeight: "500",
                  }}
                >
                  Prev
                </Button>
                <Button
                  onClick={() => setPageCount2(pageCount2 + 1)}
                  style={{
                    borderRadius: "4px",
                    fontWeight: "500",
                  }}
                >
                  Next
                </Button>
                <Select
                  defaultValue={10}
                  onChange={(e) => {
                    setPageLimit2(e);
                  }}
                  style={{
                    borderRadius: "8px",
                    fontWeight: "500",
                  }}
                >
                  <Option value={10} selected>
                    10
                  </Option>
                  <Option value={20}>20</Option>
                  <Option value={50}>50</Option>
                  <Option value={100}>100</Option>
                </Select>
              </div>
            ) : (
              ""
            )}
          </div>

          {tableData.annotations.length > 0 ||
          tableData.permitted_users.length > 0 ? (
            <AllUserTable
              selectedDataset={selectedDataset}
              datasetName={selectedDataset.dataset?.name}
              classifierName={selectedClassifier?.pathology}
              tableData={tableData}
              setPageCount2={setPageCount2}
              setPageLimit2={setPageLimit2}
            />
          ) : (
            <Empty
              className={styles.table_empty}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                // "No data available for this user."
                "Please select the filters to view annotation details."
              }
            />
          )}
        </>
      )}

      <Toaster
        toastOptions={{
          success: {
            style: {
              border: "1px solid #199c3c",
            },
          },
          error: {
            style: {
              border: "1px solid #FF4C4C",
            },
          },
        }}
      />
    </>
  );
}

export default AllUserAnnotation;
