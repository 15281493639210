import {
  getAllClassifiers,
  getClassifierById
} from "api/classifierApi";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { setAllClassifiersInfo } from "store/classifierSlice";

export const RemoveClassesLogic = () => {
  const dispatch = useDispatch();
  const [classifiers, setClassifiers] = useState<any>([]);
  const [selectedClassifier, setSelectedClassifier] = useState<any>();
  const [classes, setClasses] = useState<any[]>([]);
  const [selectedClasses, setSelectedClasses] = useState<string[]>([]);
  const classifiersList = useSelector(
    (state: any) => state.classifierInfo.value
  );

  useEffect(() => {
    async function getClassifiers() {
      if (classifiersList.length) {
        setClassifiers(classifiersList);
      } else {
        const res = await getAllClassifiers();
        setClassifiers(res);
        dispatch(setAllClassifiersInfo(res));
      }
    }
    getClassifiers();
  }, []);

  useEffect(() => {
    async function getClassesByClassifierId() {
      if (selectedClassifier) {
        const res = await getClassifierById(selectedClassifier._id);
        setClasses(res.classes);
      }
    }
    getClassesByClassifierId();
  }, [selectedClassifier]);

  async function handleSingleClassDelete() {
  }

  async function handleClassesDelete() {
    if (selectedClasses.length) {
    } else {
      toast.error("Please select at least one class to delete");
    }
  }

  return {
    classifiers,
    selectedClassifier,
    setSelectedClassifier,
    classes,
    selectedClasses,
    setSelectedClasses,
    handleSingleClassDelete,
    handleClassesDelete,
  };
};
