import { Toaster } from "react-hot-toast";
import styles from "./UpdateSubClassModal.module.scss";
import { Modal, Input, Spin, Popover, InputNumber } from "antd";
import { UpdateSubClassModalLogic } from "./UpdateSubClassModalLogic";
import { LoadingOutlined } from "@ant-design/icons";

function UpdateModal({
  item,
  selectedClassifier,
  selectedClass,
  setSelectedItemForUpdate,
  visibleUpdateModal,
  setVisibleUpdateModal,
  setClassesWithSubClass,
}: {
  item: any;
  selectedClass: any;
  classesWithSubClass: any;
  selectedClassifier: any;
  setSelectedItemForUpdate: object;
  visibleUpdateModal: boolean;
  setVisibleUpdateModal: (visibleUpdateModal: boolean) => void;
  setClassesWithSubClass: (value: any) => void;
}) {
  const {
    handleOk,
    confirmLoading,
    handleCancel,
    newUpdatedSubclassObj,
    chosenColor,
    setChosenColor,
    chosenKey,
    setChosenKey,
    newUpdatedClass,
    fetchExistingColors,
    updatedColor,
    isLoading,
    handleInputChange,
    setNewUpdatedSubclassObj,
  }: {
    handleOk: () => void;
    setNewSubClassesColor: (value: any) => void;
    existingColor: string[];
    fetchExistingColors: (classId: string, classKey: number) => void;
    newUpdatedSubclassObj: any;
    chosenColor: string;
    setChosenColor: (value: string) => void;
    updatedColor: string[];
    chosenKey: any;
    setChosenKey: (value: number) => void;
    isLoading: boolean;
    confirmLoading: boolean;
    handleCancel: () => void;
    newUpdatedClass: any;
    handleInputChange: (e: any) => void;
    setNewUpdatedSubclassObj: (value: any) => void;
  } = UpdateSubClassModalLogic({
    item,
    setSelectedItemForUpdate,
    visibleUpdateModal,
    setVisibleUpdateModal,
    setClassesWithSubClass,
  });

  /** All colors list */
  const colorContent = (
    <>
      <p className={styles.popoverContent_heading}>
        Choose from default colors:
      </p>
      <div className={styles.popoverContent}>
        {updatedColor.map((e: string) => {
          return (
            <div
              className={styles.popoverContentColor}
              style={{
                backgroundColor: e,
              }}
              onClick={() => {
                setChosenColor(e);
              }}
            ></div>
          );
        })}
      </div>
    </>
  );

  /** Loader for color */
  const loaderContent = (
    <Spin
      size="large"
      indicator={<LoadingOutlined className={styles.loadingSpinIcon} spin />}
      tip="Loading..."
      className={styles.loadingDiv_spin}
    />
  );

  return (
    <>
      <Modal
        centered
        title="Update Sub Class"
        visible={visibleUpdateModal}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <div className={styles.updateModalDiv}>
          <div className={styles.updateModalInput}>
            <label className={styles.updateComponent_inputLabels}>
              Sub Class Name
            </label>
            <Input
              value={newUpdatedClass?.name}
              onChange={(e) => handleInputChange(e)}
              className={styles.updateComponent_inputFields}
            />
          </div>
          <div style={{ width: "30%", marginLeft: "1rem" }}>
            <label className={styles.classifier_subClassInputLabels}>Key</label>
            <InputNumber
              min={0}
              value={
                newUpdatedSubclassObj.key
                  ? newUpdatedSubclassObj.key
                  : chosenKey
              }
              className={
                styles.createComponent_inputFields +
                " " +
                styles.createComponent_inputNumberField
              }
              onChange={(e: any) => {
                setChosenKey(e);
                setNewUpdatedSubclassObj({
                  ...newUpdatedSubclassObj,
                  key: e,
                });
              }}
            />
          </div>
          <div className={styles.updateModalColorDiv}>
            <Popover
              placement="rightBottom"
              content={isLoading ? loaderContent : colorContent}
              trigger="click"
            >
              <div className={styles.classifier_subClassInputColorDiv}>
                <label className={styles.classifier_subClassInputLabels}>
                  Color
                </label>
                <button
                  className={styles.popoverBtn}
                  style={
                    chosenColor
                      ? {
                          backgroundColor: chosenColor,
                          borderColor: chosenColor,
                        }
                      : {
                          borderColor: newUpdatedSubclassObj?.color,
                          backgroundColor: newUpdatedSubclassObj?.color,
                        }
                  }
                  onClick={() => {
                    fetchExistingColors(selectedClass, selectedClassifier);
                  }}
                ></button>
              </div>
            </Popover>
          </div>
        </div>
      </Modal>
      {/* Toaster */}
      <Toaster
        toastOptions={{
          success: {
            style: {
              border: "1px solid #199c3c",
            },
          },
          error: {
            style: {
              border: "1px solid #FF4C4C",
            },
          },
        }}
      />
    </>
  );
}

export default UpdateModal;
