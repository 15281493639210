import { Button, Card, Form, Input, Select, Typography } from "antd";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import styles from "./CreateClassifier.module.scss";
import { CreateClassifierLogic } from "./CreateClassifierLogic";
// Icons
import { IoIosCloseCircleOutline } from "react-icons/io";

const { Title } = Typography;
const { Option } = Select;

function CreateClassifier() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const {
    onFinish,
    onFinishFailed,
    allCategoryData,
  }: {
    onFinish: (values: any) => void;
    onFinishFailed: (errorInfo: any) => void;
    allCategoryData: any;
  } = CreateClassifierLogic(form);

  return (
    <div className={styles.createComponentDiv}>
      <div>
        <div className={styles.createComponent_closeIconDiv}>
          <IoIosCloseCircleOutline
            onClick={() => navigate(-1)}
            className={styles.createComponent_closeIcon}
          />
        </div>
        <Title level={2} className={styles.createComponent_heading}>
          Create a Classifier
        </Title>
        <Card bordered={false} className={styles.createComponent_card}>
          <Form
            form={form}
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            requiredMark={"optional"}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label={
                <label className={styles.createComponent_inputLabels}>
                  Classifier Pathology Name
                </label>
              }
              name="pathology"
              rules={[
                {
                  required: true,
                  message: "Please enter a classifier name",
                },
              ]}
            >
              <Input className={styles.createComponent_inputFields} />
            </Form.Item>

            {/* Modality Selection */}
            <Form.Item
              label={
                <label className={styles.createComponent_inputLabels}>
                  Select Modality
                </label>
              }
              name="category_id"
              rules={[
                {
                  required: true,
                  message: "Please select a modality",
                },
              ]}
            >
              <Select className={styles.createComponent_dropdowns}>
                {allCategoryData.length &&
                  allCategoryData.map((eachCat: any) => {
                    return <Option value={eachCat._id}>{eachCat.name}</Option>;
                  })}
              </Select>
            </Form.Item>

            <Form.Item>
              <Button
                block
                type="primary"
                htmlType="submit"
                className={styles.createComponent_submitBtn}
              >
                Create Classifier
              </Button>
            </Form.Item>
          </Form>
        </Card>
        <Toaster
          toastOptions={{
            success: {
              style: {
                border: "1px solid #199c3c",
              },
            },
            error: {
              style: {
                border: "1px solid #FF4C4C",
              },
            },
          }}
        />
      </div>
    </div>
  );
}

export default CreateClassifier;
