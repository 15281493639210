import api, { authHeader } from "./index";

export async function getInfoFromRefreshToken(token: string) {
  const response = await api.post("/user/refresh", {
    refToken: token,
  });

  return response.data?.result;
}

export async function createNewUser(newUserData: object) {
  const response = await api.post("/user/register", newUserData, authHeader());

  return response.data?.result;
}

export async function getUserInfoApi() {
  const response = await api.get("/user/profile", authHeader());
  return response.data?.result;
}

export async function createUserMap({
  formData,
}: {
  formData: { [key: string]: any };
}) {
  const response = await api.post("/access/create", formData, authHeader());
  return response.data?.result;
}

export async function getAllUsersForAdmin() {
  const response = await api.get("/admin/users", authHeader());
  return response.data;
}

export async function getAllReviewersApi() {
  const response = await api.get("/user/reviewers", authHeader());
  return response.data?.result;
}

export async function getUserBannedApi(userId: string) {
  const response = await api.post(
    "/user/ban",
    {
      user_id: userId,
    },
    authHeader()
  );
  return response.data?.result;
}
export async function getUserUnbannedApi(userId: string) {
  const response = await api.post(
    "/user/unban",
    {
      user_id: userId,
    },
    authHeader()
  );
  return response.data?.result;
}
