import { Breadcrumb, Col, Input, Row, Typography } from "antd";
import { useEffect, useState } from "react";

import { getAllClassifiers } from "api/classifierApi";
import UpdateModal from "components/classifiers/UpdateModal";
import styles from "./ViewClassifiers.module.scss";

// Icons
import { IoIosSearch } from "react-icons/io";
import { MdEdit } from "react-icons/md";

const { Title } = Typography;

function ViewClassifiers() {
  const [classifiers, setClassifiers] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [visibleUpdateModal, setVisibleUpdateModal] = useState<boolean>(false);
  const [selectedClassifierForUpdate, setSelectedClassifierForUpdate] =
    useState<any>();

  /** Fetch classifiers */
  useEffect(() => {
    async function getClassifiers() {
      const res = await getAllClassifiers();
      setClassifiers(res);
    }
    getClassifiers();
  }, []);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href="/">Home</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Classifiers</Breadcrumb.Item>
      </Breadcrumb>

      <Row className={styles.classifiers_headingRow}>
        <Col md={12}>
          <Title level={2} className={styles.classifiers_heading}>
            Classifiers
          </Title>
          {/* <Title level={5} className={styles.classifiers_description}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tem.
          </Title> */}
        </Col>
        <Col md={12} xs={24}>
          <Input
            size="large"
            placeholder="Search..."
            prefix={
              <IoIosSearch className={styles.classifiers_searchBoxIcon} />
            }
            className={styles.classifiers_searchBox}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Col>
      </Row>

      <Row className={styles.classifiers_row}>
        {classifiers &&
          classifiers
            .filter((val: any) =>
              searchTerm === ""
                ? val
                : val.pathology
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) && val
            )
            .map((eachClassifier) => {
              return (
                <Col
                  lg={8}
                  md={12}
                  xs={24}
                  className={styles.classifiers_col}
                  key={eachClassifier._id}
                >
                  <div className={styles.classifiers_card}>
                    <Row style={{ paddingTop: "0.5rem" }}>
                      <Col span={12}>
                        <Title
                          level={4}
                          className={styles.classifiersCard_type}
                        >
                          {eachClassifier?.pathology}
                        </Title>
                      </Col>
                      <Col span={12}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <span
                            className={styles.classifiers_cardEditBtn}
                            onClick={() => {
                              setVisibleUpdateModal(true);
                              setSelectedClassifierForUpdate(eachClassifier);
                            }}
                          >
                            <MdEdit />
                          </span>
                          {/* <span className={styles.classifiers_cardEditBtn}>
                        <MdDelete style={{ fontSize: "1.45rem" }} />
                      </span> */}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              );
            })}
      </Row>
      {/* Update Modal */}
      <UpdateModal
        item={selectedClassifierForUpdate}
        setSelectedItemForUpdate={setSelectedClassifierForUpdate}
        visibleUpdateModal={visibleUpdateModal}
        setVisibleUpdateModal={setVisibleUpdateModal}
        setClassifiers={setClassifiers}
      />
    </>
  );
}

export default ViewClassifiers;
