import {
  Breadcrumb,
  Button,
  Col,
  Input,
  Popover,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import { Link } from "react-router-dom";
import styles from "./ViewCameras.module.scss";
import { ViewCamerasLogic } from "./ViewCamerasLogic";

// Icons
import { LoadingOutlined } from "@ant-design/icons";
import { BsImages } from "react-icons/bs";
import { FaCamera } from "react-icons/fa";
import { HiOutlineDatabase } from "react-icons/hi";
import { ImArrowRight2 } from "react-icons/im";
import { IoIosSearch } from "react-icons/io";

const { Title, Text } = Typography;
const { Option } = Select;

function ViewCameras() {
  const {
    categoryId,
    searchTerm,
    setSearchTerm,
    cameraTypes,
    selectedFieldData,
    setSelectedFieldData,
  }: {
    categoryId: any;
    searchTerm: string;
    setSearchTerm: (searchTerm: string) => void;
    cameraTypes: any;
    selectedFieldData: number;
    setSelectedFieldData: (fieldData: number) => void;
  } = ViewCamerasLogic();

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item href="/">
          <span>Home</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item href="/">
          <span>Modality</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <FaCamera className={styles.cameras_cameraIcon} />
          Camera Types
        </Breadcrumb.Item>
      </Breadcrumb>

      <Title level={2} className={styles.cameras_heading}>
        Camera Types
      </Title>

      <Row className={styles.cameras_headingRow}>
        <Col md={12} xs={24}>
          <Input
            size="large"
            placeholder="Search..."
            prefix={<IoIosSearch className={styles.cameras_searchBoxIcon} />}
            className={styles.cameras_searchBox}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Col>
        <Col md={12} xs={24}>
          <div className={styles.filterCol}>
            <Title level={5} className={styles.cameras_filterHeading}>
              Filter data :
            </Title>
            <Select
              defaultValue={selectedFieldData === 2 ? "All" : "Field Data"}
              style={{ width: 120 }}
              className={styles.cameras_filter}
              onChange={(val: any) => {
                setSelectedFieldData(val);
              }}
            >
              <Option key={1} value={1}>
                Field Data
              </Option>
              <Option key={2} value={2}>
                All
              </Option>
            </Select>
          </div>
        </Col>
      </Row>

      {/* Categories */}
      <Row className={styles.cameras_row}>
        {cameraTypes.loading ? (
          <div className={styles.loadingDiv}>
            <Spin
              size="large"
              indicator={
                <LoadingOutlined className={styles.loadingSpinIcon} spin />
              }
              tip="Fetching Cameras..."
              className={styles.loadingDiv_spin}
            />
          </div>
        ) : (
          cameraTypes.data &&
          cameraTypes.data
            .filter((val: any) =>
              searchTerm === ""
                ? val
                : val.cameraType
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) && val
            )
            .map((eachCameraType: any) => {
              return (
                <Col
                  lg={8}
                  md={12}
                  xs={24}
                  className={styles.cameras_col}
                  key={eachCameraType.type_id}
                >
                  <div className={styles.cameras_card}>
                    <Row>
                      <Title level={4} className={styles.camerasCard_type}>
                        {eachCameraType.cameraType}
                      </Title>
                    </Row>
                    <Row className={styles.camerasCard_dbAndImgsRow}>
                      <Col flex={1 / 3}>
                        <Popover
                          content={eachCameraType.datasetCount}
                          placement="bottom"
                          title={<b>Dataset Count</b>}
                        >
                          <Text
                            ellipsis={true}
                            className={styles.camerasCard_db_img_text}
                          >
                            <HiOutlineDatabase />
                            {eachCameraType.datasetCount}
                          </Text>
                        </Popover>
                      </Col>
                      <Col flex={2 / 3}>
                        <Popover
                          content={eachCameraType.totalImageCount}
                          placement="bottom"
                          title={<b>Image Count</b>}
                        >
                          <Text
                            ellipsis={true}
                            className={styles.camerasCard_db_img_text}
                          >
                            <BsImages />
                            {eachCameraType.totalImageCount}
                          </Text>
                        </Popover>
                      </Col>
                    </Row>
                    <Row>
                      <Link
                        className={styles.camerasCard_link}
                        to={`/modality/${categoryId}/${eachCameraType.type_id}/datasets/${selectedFieldData}`}
                      >
                        <Button
                          className={styles.camerasCard_btn}
                          style={{
                            backgroundColor:
                              eachCameraType.datasetCount > 0
                                ? "#6254FF"
                                : "#dbdbdb",
                          }}
                          disabled={
                            eachCameraType.datasetCount > 0 ? false : true
                          }
                        >
                          Browse{" "}
                          <ImArrowRight2
                            className={styles.camerasCard_btnArrowIcon}
                          />
                        </Button>
                      </Link>
                    </Row>
                  </div>
                </Col>
              );
            })
        )}
      </Row>
    </div>
  );
}

export default ViewCameras;
