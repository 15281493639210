import { getBuckets } from "api/bucketApi";
import { createReviewBucket } from "api/reviewBucketApi";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { userSelector } from "store/userSlice";

export const CreateReviewBucketModalLogic = ({
  dataset,
  setCreateReviewOpenModal,
}: {
  dataset: any;
  setCreateReviewOpenModal: (value: boolean) => void;
}) => {
  const userInfo = useSelector(userSelector);
  const [reviewBucketName, setReviewBucketName] = useState<string>("");
  const [reviewBucketInputDetails, setReviewBucketInputDetails] = useState<any>(
    {
      classifierId: "",
      reviewAgainst: "",
      reviewAgainstUser: "",
      reviewSelectionType: "",
    }
  );
  const [buckets, setBuckets] = useState<any[]>([]);
  const [bucketWiseToggler, setBucketWiseToggler] = useState<boolean>(true);
  const [bucketWiseClassImagesCount, setBucketWiseClassImagesCount] =
    useState<any>({}); // this is due to the percent err that might arise
  const [
    bucketWiseClassImagesCountForApiSend,
    setBucketWiseClassImagesCountForApiSend,
  ] = useState<any>({}); // for sending in backend

  const [confirmLoading, setConfirmLoading] = useState(false);
  // Slider Overall Dataset
  const [sliderOverallDatasetInput, setSliderOverallDatasetInput] =
    useState<number>(20);

  /** Updates everytime toggler changes from count to percentage & viceversa */
  useEffect(() => {
    const bucketWiseClasses: any[] = [];

    buckets?.map((eachBucket: any) => {
      const bucketName = eachBucket?.class.name;
      bucketWiseClasses.push({
        count: 0,
        name: bucketName,
        class_id: eachBucket?.class._id,
      });
    });
    setBucketWiseClassImagesCount(bucketWiseClasses);
    setBucketWiseClassImagesCountForApiSend(bucketWiseClasses);
  }, [bucketWiseToggler]);

  /** Updating for each input given by the user */
  async function handleReviewBucketInputChange(e: any, key: string) {
    setReviewBucketInputDetails({
      ...reviewBucketInputDetails,
      [key]: e,
    });

    // fetching classes
    if (key === "classifierId") {
      const bucketsRes = await getBuckets({
        datasetId: dataset?.dataset_id._id,
        classifierId: e,
      });

      setBuckets(bucketsRes);
      const bucketWiseClasses: any[] = [];

      bucketsRes?.map((eachBucket: any) => {
        const bucketName = eachBucket?.class.name;
        bucketWiseClasses.push({
          name: bucketName,
          count: 0,
          class_id: eachBucket?.class._id,
        });
      });
      setBucketWiseClassImagesCount(bucketWiseClasses);
      setBucketWiseClassImagesCountForApiSend(bucketWiseClasses);
    }
  }

  /** Updates the images count when showing imgCount for each bucekt */
  function handleBucketWiseToggler(
    selectedImgCount: any,
    key: string  ) {
    let imgCount: number;
    // Bucket wise toggler === false then percent wise
    if (!bucketWiseToggler) {
      buckets.length > 0 &&
        buckets.map((eachBucket: any) => {
          if (eachBucket?.class.name === key) {
            imgCount = Math.round(
              (selectedImgCount / 100) * eachBucket?.finalImagesCount
            );
          }
        });
    } else {
      imgCount = selectedImgCount;
    }

    setBucketWiseClassImagesCount(
      bucketWiseClassImagesCount?.map((item: any) =>
        item?.name === key
          ? { ...item, count: selectedImgCount, name: key }
          : item
      )
    );
    setBucketWiseClassImagesCountForApiSend(
      bucketWiseClassImagesCountForApiSend?.map((item: any) =>
        item.name === key ? { ...item, count: imgCount, name: key } : item
      )
    );
  }

  /** on Submit of new review bucket details*/
  const handleOk = async () => {
    if (
      !reviewBucketName ||
      !reviewBucketInputDetails?.classifierId ||
      !reviewBucketInputDetails?.reviewAgainst ||
      (reviewBucketInputDetails?.reviewAgainst === "user_annotation" &&
        !reviewBucketInputDetails?.reviewAgainstUser)
    ) {
      return toast.error("Please fill all the fields");
    }
    setConfirmLoading(true);

    const selectedUserForReview =
      reviewBucketInputDetails?.reviewAgainst === "user_annotation" &&
      reviewBucketInputDetails?.reviewAgainstUser
        ? dataset.dataset_id.annotators?.length > 0 &&
          dataset.dataset_id.annotators.filter((eachAnno: any) => {
            if (
              eachAnno.email === reviewBucketInputDetails?.reviewAgainstUser
            ) {
              return eachAnno;
            }
          })
        : null;

    if (
      (reviewBucketInputDetails?.reviewAgainst === "user_annotation" &&
        reviewBucketInputDetails?.reviewAgainstUser) ||
      reviewBucketInputDetails.reviewAgainst === "initial_annotation" ||
      reviewBucketInputDetails.reviewAgainst === "final_annotation"
    ) {
      try {
        await createReviewBucket({
          reviewBucketName,
          selectedUserIdForReview:
            selectedUserForReview !== null
              ? selectedUserForReview[0]?._id
              : selectedUserForReview,
          datasetId: dataset?.dataset_id._id,
          classifierId: reviewBucketInputDetails?.classifierId,
          reviewerId: userInfo._id,
          modeOfAnnotation: reviewBucketInputDetails?.reviewAgainst,
          typeOfBucketReview: reviewBucketInputDetails?.reviewSelectionType,
          bucketPercentageDivisions:
            reviewBucketInputDetails?.reviewSelectionType === "overall"
              ? sliderOverallDatasetInput
              : bucketWiseClassImagesCountForApiSend,
        });
        setTimeout(() => {
          setCreateReviewOpenModal(false);
          setConfirmLoading(false);
          toast.success("Review Bucket Created Successfully");
        }, 2000);
        setReviewBucketName("");
        setReviewBucketInputDetails({
          classifierId: "",
          reviewAgainst: "",
          reviewAgainstUser: "",
          reviewSelectionType: "",
        });
        setBucketWiseClassImagesCount({});
        setBucketWiseClassImagesCountForApiSend({});
        setSliderOverallDatasetInput(20);
      } catch (err: any) {
        toast.error(err.response?.data.message);
        setConfirmLoading(false);
      }
    } else if (
      reviewBucketInputDetails?.reviewAgainst === "user_annotation" &&
      !reviewBucketInputDetails?.reviewAgainstUser
    ) {
      toast.error("Please select a user to review against");
      setConfirmLoading(false);
    } else {
      toast.error("Something went wrong.");
      setConfirmLoading(false);
    }
  };

  return {
    confirmLoading,
    handleOk,
    reviewBucketInputDetails,
    setReviewBucketInputDetails,
    handleReviewBucketInputChange,
    sliderOverallDatasetInput,
    setSliderOverallDatasetInput,
    buckets,
    bucketWiseToggler,
    setBucketWiseToggler,
    handleBucketWiseToggler,
    bucketWiseClassImagesCount,
    reviewBucketName,
    setReviewBucketName,
  };
};
