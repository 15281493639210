import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Typography,
  message
} from "antd";
import { Toaster } from "react-hot-toast";
import styles from "./UploadData.module.scss";
import { UploadDataLogic } from "./UploadDataLogic";
// import UploadSubmitForm from "components/upload/UploadSubmitForm/UploadSubmitForm";

// Icons
import {
  FileExcelOutlined,
  FileZipOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import api, { authHeader } from "api";
import { AxiosResponse } from "axios";
import { useState } from "react";
import { CgCloseO } from "react-icons/cg";
import { MdOpenInNew } from "react-icons/md";
import { TiTickOutline } from "react-icons/ti";

const { Title } = Typography;
const { Option } = Select;

function UploadData() {
  const {
    uploadedCSVFileName,
    setUploadedCSVFileName,
    uploadedZipFileName,
    setUploadedZipFileName,
    tableHeaders,
    values,
    uploadedCSVHandler,
    openPreviewTableModal,
    setOpenPreviewTableModal,
    allDatasets,
    setUploadedCSVFile,
    setUploadedZipFile,
    setSelectedDatasetId,
    selectedDatasetName,
    setSelectedDatasetName,
    allClassifiersOfDataset,
    setSelectedClassifierId,
    selectedClassifierName,
    setSelectedClassifierName,
    handleUploadSubmit,
  }: {
    uploadedCSVFileName: string;
    setUploadedCSVFileName: (csvFileName: string) => void;
    uploadedZipFileName: string;
    setUploadedZipFileName: (zipFileName: string) => void;
    tableHeaders: any[];
    values: any[];
    uploadedCSVHandler: any;
    openPreviewTableModal: boolean;
    setOpenPreviewTableModal: (value: boolean) => void;
    allDatasets: any;
    openUploadSubmitModal: boolean;
    setOpenUploadSubmitModal: (value: boolean) => void;
    uploadSubmitModalLoading: boolean;
    setUploadSubmitModalLoading: (value: boolean) => void;
    uploadedCSVFile: any;
    setUploadedCSVFile: (csvFile: any) => void;
    uploadedZipFile: any;
    setUploadedZipFile: (zipFile: any) => void;

    selectedDatasetId: string;
    setSelectedDatasetId: (datasetId: string) => void;
    selectedDatasetName: string;
    setSelectedDatasetName: (datasetName: string) => void;
    allClassifiersOfDataset: any[];
    selectedClassifierId: string;
    setSelectedClassifierId: (classifierId: string) => void;
    selectedClassifierName: string;
    setSelectedClassifierName: (classifierName: string) => void;
    handleUploadSubmit: () => void;
  } = UploadDataLogic();

  const [progress, setProgress] = useState<any[]>();
  const [data, setData] = useState<any>();


  return (
    <>
      <Title level={2} className={styles.metrics_heading}>
        Upload Dataset
      </Title>

      {/* Card */}
      <Card bordered={false} className={styles.uploadComponent_card}>
        {/* Dataset */}
        <label className={styles.metrics_inputLabels}>Select Dataset</label>
        <Select
          style={{ width: "90%" }}
          placeholder="Select dataset"
          value={selectedDatasetName}
          onChange={(e) => {
            setSelectedDatasetName(e);
            allDatasets.data?.map((eachDataset: any) => {
              if (eachDataset.dataset?.name === e) {
                setSelectedDatasetId(eachDataset.dataset._id);
              }
            });
          }}
          className={styles.metrics_dropDownField}
        >
          {allDatasets.loading ? (
            <div className={styles.loadingDiv}>
              <Spin
                size="large"
                indicator={
                  <LoadingOutlined className={styles.loadingSpinIcon} spin />
                }
                tip="Fetching all datasets..."
                className={styles.loadingDiv_spin}
              />
            </div>
          ) : allDatasets.error ? (
            <Option key={null} value={""} disabled>
              Oops
            </Option>
          ) : (
            allDatasets.data.length > 0 && (
              <>
                {allDatasets.data.map((eachDataset: any) => (
                  <Option
                    key={eachDataset.dataset._id}
                    value={eachDataset.dataset?.name}
                  >
                    {eachDataset.dataset?.name}
                  </Option>
                ))}
              </>
            )
          )}
        </Select>

        {/* Classifier */}
        <label className={styles.metrics_inputLabels}>Select Classifier</label>
        <Select
          defaultValue={""}
          style={{ width: "90%" }}
          placeholder="Select classifier"
          value={selectedClassifierName}
          onChange={(e) => {
            setSelectedClassifierName(e);
            allClassifiersOfDataset?.map((eachClassifier: any) => {
              if (eachClassifier?.pathology === e) {
                setSelectedClassifierId(eachClassifier._id);
              }
            });
          }}
          className={styles.metrics_dropDownField}
        >
          {allClassifiersOfDataset.length > 0 ? (
            allClassifiersOfDataset.map((eachClassifier: any) => (
              <Option
                key={eachClassifier._id}
                value={eachClassifier?.pathology}
              >
                {eachClassifier?.pathology}
              </Option>
            ))
          ) : (
            <Option key={null} value={"0"} disabled>
              Select a dataset first
            </Option>
          )}
        </Select>

        {/* Upload Parts */}
        {/* CSV Part */}
        <Row style={{ marginTop: "2rem" }}>
          <Col md={24} xs={24} className={styles.uploadDataset_chooseDivs}>
            {uploadedCSVFileName && tableHeaders.length > 0 ? (
              <>
                <CgCloseO
                  className={styles.upload_uploadedFileDivCloseBtn}
                  onClick={() => {
                    setUploadedCSVFile(null);
                    setUploadedCSVFileName("");
                  }}
                />
                <div className={styles.upload_uploadedFileDiv}>
                  <Title level={5} className={styles.upload_uploadedFileText}>
                    <TiTickOutline className={styles.upload_uploadedFileTick} />
                    <span>{uploadedCSVFileName && uploadedCSVFileName}</span>
                  </Title>
                  <Title
                    level={5}
                    className={styles.upload_openPreview}
                    onClick={() => setOpenPreviewTableModal(true)}
                  >
                    <span>Open Preview</span>
                    <MdOpenInNew />
                  </Title>
                </div>
                <Modal
                  bodyStyle={{
                    overflowY: "auto",
                    maxHeight: "calc(100vh - 200px)",
                  }}
                  title={`Viewing ${uploadedCSVFileName} contents`}
                  centered
                  visible={openPreviewTableModal}
                  onOk={() => setOpenPreviewTableModal(false)}
                  footer={[
                    <Button
                      key="submit"
                      type="primary"
                      onClick={() => setOpenPreviewTableModal(false)}
                    >
                      OK
                    </Button>,
                  ]}
                  width={1300}
                >
                  <Table
                    dataSource={values}
                    columns={tableHeaders}
                    // className={styles.table}
                    scroll={{ x: 1000 }}
                  />
                </Modal>
              </>
            ) : (
              <>
                <input
                  id="uploadFile"
                  type="file"
                  name="file"
                  onChange={uploadedCSVHandler}
                  accept=".csv"
                  style={{
                    display: "none",
                    position: "absolute",
                  }}
                />
                <label
                  htmlFor="uploadFile"
                  className={styles.upload_uploadFilePart}
                >
                  <Title
                    level={5}
                    className={styles.uploadDataset_chooseDiv_typeHeading}
                  >
                    <FileExcelOutlined style={{ marginRight: "0.6rem" }} />
                    Choose CSV file to upload
                  </Title>
                </label>
              </>
            )}
          </Col>

          {/* Zip Part */}
          <Col md={24} xs={24} className={styles.uploadDataset_chooseDivs}>
            {uploadedZipFileName ? (
              <>
                <CgCloseO
                  className={styles.upload_uploadedFileDivCloseBtn}
                  onClick={() => {
                    setUploadedZipFile(null);
                    setUploadedZipFileName("");
                  }}
                />
                <div className={styles.upload_uploadedFileDiv}>
                  <Title level={5} className={styles.upload_uploadedFileText}>
                    <TiTickOutline className={styles.upload_uploadedFileTick} />
                    <span>{uploadedZipFileName && uploadedZipFileName}</span>
                  </Title>
                </div>
              </>
            ) : (
              <>
                <input
                  id="uploadZipFile"
                  type="file"
                  name="file"
                  onChange={(e: any) => {
                    setUploadedZipFile(e.target.files[0]);
                    setUploadedZipFileName(e.target.files[0]?.name);
                  }}
                  accept=".zip,.rar,.7zip"
                  style={{
                    display: "none",
                    position: "absolute",
                  }}
                />
                <label
                  htmlFor="uploadZipFile"
                  className={styles.upload_uploadFilePart}
                >
                  <Title
                    level={5}
                    className={styles.uploadDataset_chooseDiv_typeHeading}
                  >
                    <FileZipOutlined style={{ marginRight: "0.6rem" }} />
                    Choose Zip to upload
                  </Title>
                </label>
              </>
            )}
          </Col>
        </Row>

        <Button
          block
          type="primary"
          className={styles.upload_submitBtn}
          onClick={() => handleUploadSubmit()}
        >
          Upload Dataset
        </Button>
      </Card>

      <Toaster
        toastOptions={{
          success: {
            style: {
              border: "1px solid #199c3c",
            },
          },
          error: {
            style: {
              border: "1px solid #FF4C4C",
            },
          },
        }}
      />
    </>
  );
}

export default UploadData;
