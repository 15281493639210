import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "./userSlice";
import { classifierSlice } from "./classifierSlice";
import { sidebarDynamicItemsSlice } from "./sidebarDynamicItemsSlice";
import { reviewerSelectSlice } from "./reviewerSelectSlice";
import { bucketsDatasetInfoSlice } from "./bucketsDatasetInfoSlice";
import { searchImageInfoSlice } from "./searchImageInfoSlice";

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    classifierInfo: classifierSlice.reducer,
    sidebarDynamicItems: sidebarDynamicItemsSlice.reducer,
    reviewerSelectSliceInfo: reviewerSelectSlice.reducer,
    bucketsDatasetInfo: bucketsDatasetInfoSlice.reducer,
    searchImageInfo: searchImageInfoSlice.reducer,
  },
});
export default store;
export type RootState = ReturnType<typeof store.getState>;
