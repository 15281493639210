import { toast } from "react-hot-toast";
import { useState, useEffect } from "react";
import { Modal } from "antd";
import { deleteDataset, getEveryDatasetForAdmin } from "api/datasetApi";
import { getAllCategories } from "api/categoryApi";
import { getAllUsersForAdmin } from "api/usersApi";
import { handleSuspensionApi } from "api/adminViewAnnotations";

export const AdminViewDatasetsLogic = ({
  allDatasets,
  setAllDatasets,
  datasetsToShow,
  setDatasetsToShow,
  allCategories,
  setAllCategories,
  allUsers,
  setAllUsers,
  getDatasets,
  getAllUsers,
}: any) => {
  const [selectedCategory, setSelectedCategory] = useState<any>();

  const [selectedDatasetForDelete, setSelectedDatasetForDelete] = useState<any>(
    {}
  );
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [loadingOnUserSuspension, setLoadingOnUserSuspension] =
    useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [searchedText, setSearchedText] = useState<string>("");

  /** Get allUsers & allDatasets */
  useEffect(() => {
    getDatasets();
    getAllUsers();
  }, []);

  /** Update dataset to show depending on selected category */
  useEffect(() => {
    // when selected category is defined and we are passing search params to input for filtering
    if (selectedCategory && searchedText && selectedCategory.type !== "All") {
      const filteredDatasets = allDatasets.data?.filter(
        (eachDataset: any) =>
          eachDataset.dataset.category_id.type === selectedCategory.type &&
          eachDataset.dataset.name
            .toLowerCase()
            .includes(searchedText.toLowerCase())
      );
      setDatasetsToShow(filteredDatasets);
      // when we are filtering by category only
    } else if (selectedCategory && selectedCategory.type !== "All") {
      const filteredDatasets = allDatasets.data?.filter(
        (eachDataset: any) =>
          eachDataset.dataset.category_id.type === selectedCategory.type
      );
      setDatasetsToShow(filteredDatasets);
    }
    // when selected category is not defined and we are passing search params to input for filtering
    else if (searchedText) {
      const filteredDatasets = allDatasets.data?.filter((eachDataset: any) =>
        eachDataset.dataset.name
          .toLowerCase()
          .includes(searchedText.toLowerCase())
      );
      setDatasetsToShow(filteredDatasets);
    }
    // for initial load
    else {
      setDatasetsToShow(allDatasets.data);
    }
  }, [selectedCategory, searchedText]);

  /** Suspension handler fr annotator */
  async function handleSuspension(datasetId: string, userId: string) {
    setLoadingOnUserSuspension(true);
    try {
      const res = await handleSuspensionApi(datasetId, userId);
      getDatasets();
      Modal.destroyAll();
      setLoadingOnUserSuspension(false);
      toast.success(res.message);
    } catch (err: any) {
      setLoadingOnUserSuspension(false);
      toast.error(err.response?.data?.message);
    }
  }

  // Delete dataset
  async function handleDeleteDataset() {
    setConfirmLoading(true);
    try {
      await deleteDataset(selectedDatasetForDelete.dataset._id!);

      setTimeout(() => {
        setOpenDeleteModal(false);
        setConfirmLoading(false);
        setSelectedDatasetForDelete(null);
        toast.success("Dataset deleted successfully");
      }, 2000);
    } catch (error) {
      toast.error("Couldn't delete dataset");
    }
  }

  return {
    allDatasets,
    allUsers,
    setAllDatasets,
    datasetsToShow,
    setDatasetsToShow,
    handleDeleteDataset,
    allCategories,
    setAllCategories,
    selectedCategory,
    setSelectedCategory,
    openDeleteModal,
    setOpenDeleteModal,
    confirmLoading,
    setConfirmLoading,
    selectedDatasetForDelete,
    setSelectedDatasetForDelete,
    loadingOnUserSuspension,
    handleSuspension,
    searchedText,
    setSearchedText,
  };
};
