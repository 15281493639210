import { Toaster } from "react-hot-toast";
import styles from "./UpdateModal.module.scss";
import { Modal, Input } from "antd";
import { UpdateModalLogic } from "./UpdateModalLogic";

function UpdateModal({
  item,
  setSelectedItemForUpdate,
  visibleUpdateModal,
  setVisibleUpdateModal,
  setClassifiers,
}: {
  item: any;
  setSelectedItemForUpdate: object;
  visibleUpdateModal: boolean;
  setVisibleUpdateModal: (visibleUpdateModal: boolean) => void;
  setClassifiers: (value: any) => void;
}) {
  const {
    handleOk,
    confirmLoading,
    handleCancel,
    newUpdatedClassifier,
    handleInputChange,
  }: {
    handleOk: () => void;
    confirmLoading: boolean;
    handleCancel: () => void;
    newUpdatedClassifier: any;
    handleInputChange: (e: any, key: string) => void;
  } = UpdateModalLogic({
    item,
    setSelectedItemForUpdate,
    visibleUpdateModal,
    setVisibleUpdateModal,
    setClassifiers,
  });

  return (
    <>
      <Modal
        centered
        title="Update Classifier"
        visible={visibleUpdateModal}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <label className={styles.updateComponent_inputLabels}>
          Pathology Name
        </label>
        <Input
          value={newUpdatedClassifier?.pathology}
          onChange={(e) => handleInputChange(e, "pathology")}
          className={styles.updateComponent_inputFields}
        />
      </Modal>
      <Toaster
        toastOptions={{
          success: {
            style: {
              border: "1px solid #199c3c",
            },
          },
          error: {
            style: {
              border: "1px solid #FF4C4C",
            },
          },
        }}
      />
    </>
  );
}

export default UpdateModal;
