import styles from "./UpdateModal.module.scss";
import { Modal, Input } from "antd";
import { UpdateModalLogic } from "./UpdateModalLogic";
import { Toaster } from "react-hot-toast";

function UpdateModal({
  category,
  setSelectedCatForUpdate,
  visibleUpdateModal,
  setVisibleUpdateModal,
}: {
  category: any;
  setSelectedCatForUpdate: object;
  visibleUpdateModal: boolean;
  setVisibleUpdateModal: (visibleUpdateModal: boolean) => void;
}) {
  const {
    handleOk,
    confirmLoading,
    handleCancel,
    newUpdatedCat,
    handleInputChange,
  }: {
    handleOk: () => void;
    confirmLoading: boolean;
    handleCancel: () => void;
    newUpdatedCat: any;
    handleInputChange: (e: any, key: string) => void;
  } = UpdateModalLogic({
    category,
    setSelectedCatForUpdate,
    visibleUpdateModal,
    setVisibleUpdateModal,
  });

  return (
    <>
      <Modal
        centered
        title="Update Modality"
        visible={visibleUpdateModal}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        style={{ zIndex: "2" }}
      >
        <label className={styles.updateComponent_inputLabels}>
          Modality Name
        </label>
        <Input
          value={newUpdatedCat?.name}
          onChange={(e) => handleInputChange(e, "name")}
          className={styles.updateComponent_inputFields}
        />
        <label className={styles.updateComponent_inputLabels}>
          Camera Type
        </label>
        <Input
          value={newUpdatedCat?.type}
          onChange={(e) => handleInputChange(e, "type")}
          className={styles.updateComponent_inputFields}
        />
        <label className={styles.updateComponent_inputLabels}>
          Description
        </label>
        <Input
          value={newUpdatedCat?.description}
          onChange={(e) => handleInputChange(e, "description")}
          className={styles.updateComponent_inputFields}
        />
      </Modal>
      <Toaster
        toastOptions={{
          success: {
            style: {
              border: "1px solid #199c3c",
            },
          },
          error: {
            style: {
              border: "1px solid #FF4C4C",
            },
          },
        }}
      />
    </>
  );
}

export default UpdateModal;
