import {
  createSubClasses,
  getAllClassifiers,
  getClassifierById,
} from "api/classifierApi";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
// import { useDispatch, useSelector } from "react-redux";

type NewSubClassInputType = {
  name: string;
};

export const CreateSubClassesLogic = (form: any) => {
  const [classifiers, setClassifiers] = useState([]);
  const [selectedClassifier, setSelectedClassifier] = useState<any>();
  const [classes, setClasses] = useState<any[]>([]);
  const [subClasses, setSubClasses] = useState<any[]>([]);
  const [newClassInput, setNewClassInput] = useState<NewSubClassInputType>({
    name: "",
  });

  /** Fetch classifiers */
  useEffect(() => {
    async function getClassifiers() {
      const classifiers = await getAllClassifiers();
      setClassifiers(classifiers);
    }
    getClassifiers();
  }, []);

  /** Fetch classes when classifier selected */
  useEffect(() => {
    async function getClassesByClassifierId() {
      if (selectedClassifier) {
        const res = await getClassifierById(selectedClassifier);

        setClasses(res.classes);
      }
    }
    getClassesByClassifierId();
  }, [selectedClassifier]);

  /** onChange new subclass data */
  function handleNewClass() {
    if (newClassInput.name) {
      setSubClasses([...subClasses, newClassInput]);
      setNewClassInput({
        name: "",
      });
    } else {
      toast.error("Please fill in both fields");
    }
  }

  /** Deleting class */
  function handleClassDelete(details: any) {
    const index = subClasses.indexOf(details);

    if (index !== -1) {
      subClasses.splice(index, 1);
    }
    setSubClasses([...subClasses]);
  }

  // Handling submit
  async function onFinish(values: any) {
    try {
      if (subClasses.length === 0) {
        toast.error("Add atleast one subclass");
      } else {
        await createSubClasses({
          class_id: classes[values.classIndex]._id,
          key: classes[values.classIndex].key,
          subClasses,
        });
        toast.success("Subclasses created successfully");
      }

      form.resetFields();
      setSubClasses([]);
    } catch (error) {
      toast.error("Error! Couldn't create sub classes");
    }
  }

  const onFinishFailed = () => {};

  return {
    classifiers,
    onFinish,
    onFinishFailed,
    newClassInput,
    setNewClassInput,
    handleNewClass,
    handleClassDelete,
    subClasses,
    setSelectedClassifier,
    classes,
  };
};
