import {
  createSubClasses,
  getAllClassifiers,
  getClassifierById,
  removeClasses,
  removeSubClasses,
} from "api/classifierApi";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { colors } from "./Colors";

export const ViewAllLogic = () => {
  const [classifiers, setClassifiers] = useState<any>([]);
  const [selectedClass, setSelectedClass] = useState<any>();
  const [existingColor, setExistingColor] = useState<any[]>([]);
  const [updatedColor, setUpdatedColor] = useState<any[]>([]);
  const [selectedClassifier, setSelectedClassifier] = useState<any>();
  const [classesWithSubClass, setClassesWithSubClass] = useState<any[]>([]);
  const [addNewSubClassVisible, setAddNewSubClassVisible] =
    useState<boolean>(false);
  const [newSubClasses, setNewSubClasses] = useState<string>("");
  const [newSubClassKeyInput, setNewSubClassKeyInput] = useState<number>(0);
  const [newSubClassesColor, setNewSubClassesColor] = useState<string>("");
  // Class Delete Modal
  const [selectedClassForDelete, setSelectedClassForDelete] = useState<any>({});
  const [openClassDeleteModal, setOpenClassDeleteModal] =
    useState<boolean>(false);
  const [confirmClassDeleteLoading, setConfirmClassDeleteLoading] =
    useState<boolean>(false);
  // Class Update Modal
  const [visibleUpdateModal, setVisibleUpdateModal] = useState<boolean>(false);
  const [selectedItemForUpdate, setSelectedItemForUpdate] = useState<object>();
  // SubClass Update Modal
  const [visibleSubClassUpdateModal, setVisibleSubClassUpdateModal] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedSubClassForUpdate, setSelectedSubClassForUpdate] =
    useState<object>();
  // SubClass Delete Modal
  const [selectedSubClassForDelete, setSelectedSubClassForDelete] = useState<{
    selectedClassId: string;
    subClassId: string;
  } | null>();
  // subclass id array for delete
  const [
    selectedSubClassIdArrayForDelete,
    setSelectedSubClassIdArrayForDelete,
  ] = useState<string[]>([]);
  // selected class id for deleting subclasses
  const [
    selectedClassIdForSubClassDelete,
    setSelectedClassIdForSubClassDelete,
  ] = useState("");
  const [openSubClassDeleteModal, setOpenSubClassDeleteModal] =
    useState<boolean>(false);
  const [confirmDeleteLoading, setConfirmDeleteLoading] =
    useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isClassModalOpen, setIsClassModalOpen] = useState(false);

  useEffect(() => {
    async function getClassifiers() {
      const res = await getAllClassifiers();
      setClassifiers(res);
    }
    getClassifiers();
  }, []);

  // Updating updatedColorsArray based on existing color
  useEffect(() => {
    if (existingColor.length === 0) {
      setUpdatedColor(colors);
      setNewSubClassesColor(colors[Math.floor(Math.random() * colors.length)]);
    } else {
      const updatedColorArray: any[] = [];
      colors.forEach((element: any) => {
        !existingColor.includes(element) && updatedColorArray.push(element);
      });
      setUpdatedColor(updatedColorArray);
      setNewSubClassesColor(updatedColorArray[0]);
    }
  }, [existingColor]);

  // Fetching subclasses to check existing colors
  async function fetchExistingColors(classId: string) {
    setIsLoading(true);

    const res = await getClassifierById(selectedClassifier._id);

    res.classes.forEach((element: any) => {
      if (element._id === classId) {
        const subClasses = element.subclasses;
        const existingColorsArray: string[] = [];
        subClasses.forEach((e: any) => {
          if (e.colour) {
            !existingColorsArray.includes(e.colour) &&
              existingColorsArray.push(e.colour);
          }
        });
        setExistingColor(existingColorsArray);
      }
    });
    setIsLoading(false);
  }

  useEffect(() => {
    async function getClassesByClassifierId() {
      if (selectedClassifier) {
        const res = await getClassifierById(selectedClassifier._id);

        setClassesWithSubClass(res.classes);
      }
    }
    getClassesByClassifierId();
  }, [selectedClassifier]);

  useEffect(() => {}, [classesWithSubClass]);

  // Create SubClasses
  async function handleNewSubClasses(classId: string, classKey: number) {
    try {
      if (newSubClasses === "" || !newSubClassKeyInput) {
        toast.error("Subclass fields cannot be empty");
      } else {
        const subClasses = [
          {
            name: newSubClasses,
            colour: newSubClassesColor,
            key: newSubClassKeyInput,
          },
        ];

        await createSubClasses({
          class_id: classId,
          key: classKey,
          subClasses,
        });
        toast.success("Subclass created successfully");

        setNewSubClasses("");
        const res = await getClassifierById(selectedClassifier._id);

        res.classes.forEach((element: any) => {
          if (element._id === classId) {
            const subClasses = element.subclasses;
            const existingColorsArray: string[] = [];
            subClasses.forEach((e: any) => {
              if (e.colour) {
                !existingColorsArray.includes(e.colour) &&
                  existingColorsArray.push(e.colour);
              }
            });
            setExistingColor(existingColorsArray);
          }
        });

        const index = Math.floor(Math.random() * updatedColor.length);

        setNewSubClassesColor(updatedColor[index]);

        setClassesWithSubClass(res.classes);
      }
    } catch (error) {
      toast.error("Error! Couldn't create sub classes");
    }
  }

  // Delete class
  async function handleClassDelete() {
    setConfirmClassDeleteLoading(true);
    try {
      await removeClasses(selectedClassifier._id, [selectedClassForDelete._id]);

      setTimeout(() => {
        setOpenClassDeleteModal(false);
        setConfirmClassDeleteLoading(false);
        setSelectedClassForDelete(null);
        toast.success("Class removed successfully");
      }, 1000);

      const res = await getClassifierById(selectedClassifier._id);
      setClassesWithSubClass(res.classes);
    } catch {
      toast.error("Error! Couldn't delete class");
    }
  }

  // Delete SubClass
  async function handleSubClassDelete() {
    setConfirmDeleteLoading(true);
    try {
      await removeSubClasses(
        selectedClassIdForSubClassDelete,
        selectedSubClassIdArrayForDelete
      );

      setTimeout(() => {
        setOpenSubClassDeleteModal(false);
        setConfirmDeleteLoading(false);
        setSelectedSubClassForDelete(null);
        toast.success("SubClass removed successfully");
      }, 600);

      const res = await getClassifierById(selectedClassifier._id);
      setClassesWithSubClass(res.classes);
    } catch {
      toast.error("Error! Couldn't remove subClass");
    }
  }

  return {
    classifiers,
    selectedClassifier,
    setSelectedClassifier,
    classesWithSubClass,
    setClassesWithSubClass,
    openClassDeleteModal,
    setOpenClassDeleteModal,
    selectedClassForDelete,
    setSelectedClassForDelete,
    confirmClassDeleteLoading,
    handleClassDelete,
    updatedColor,
    openSubClassDeleteModal,
    setOpenSubClassDeleteModal,
    confirmDeleteLoading,
    setConfirmDeleteLoading,
    selectedSubClassForDelete,
    setSelectedSubClassForDelete,
    handleSubClassDelete,
    selectedClass,
    setSelectedClass,
    addNewSubClassVisible,
    setAddNewSubClassVisible,
    fetchExistingColors,
    newSubClassKeyInput,
    setNewSubClassKeyInput,
    newSubClasses,
    newSubClassesColor,
    isLoading,
    setNewSubClasses,
    existingColor,
    setNewSubClassesColor,
    handleNewSubClasses,
    visibleUpdateModal,
    setVisibleUpdateModal,
    setExistingColor,
    selectedItemForUpdate,
    setUpdatedColor,
    setSelectedItemForUpdate,
    setIsLoading,
    visibleSubClassUpdateModal,
    setVisibleSubClassUpdateModal,
    selectedSubClassForUpdate,
    setSelectedSubClassForUpdate,
    setSelectedSubClassIdArrayForDelete,
    setSelectedClassIdForSubClassDelete,
    isModalOpen,
    setIsModalOpen,
    isClassModalOpen,
    setIsClassModalOpen,
  };
};
