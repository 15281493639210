import { Button, Card, Form, Input, Select, Typography } from "antd";
import { createNewUser } from "api/usersApi";
import toast, { Toaster } from "react-hot-toast";
import styles from "./CreateUsers.module.scss";

const { Title } = Typography;
const { Option } = Select;

function CreateUsers() {
  const [form] = Form.useForm();

  /** Permission levels for each type of users */
  const userPermissionLevelOptions = [
    {
      _id: 0,
      name: "Viewer",
    },
    {
      _id: 1,
      name: "Annotator",
    },
    {
      _id: 2,
      name: "Reviewer",
    },
    {
      _id: 3,
      name: "Creator / Updater",
    },
    {
      _id: 4,
      name: "Admin",
    },
  ];

  /** On submit of new created user */
  async function onFinish(values: any) {
    try {
      await createNewUser(values);

      form.resetFields();
      toast.success("User created successfully");
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  }

  return (
    <div className={styles.createComponentDiv}>
      <Title level={2} className={styles.createComponent_heading}>
        Create New User
      </Title>
      <Card bordered={false} className={styles.createComponent_card}>
        <Form
          form={form}
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          requiredMark={"optional"}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label={
              <label className={styles.createComponent_inputLabels}>Name</label>
            }
            name="name"
            rules={[{ required: true, message: "Please enter a name" }]}
          >
            <Input className={styles.createComponent_inputFields} />
          </Form.Item>

          <Form.Item
            label={
              <label className={styles.createComponent_inputLabels}>
                Email address
              </label>
            }
            name="email"
            rules={[{ required: true, message: "Please enter a name" }]}
          >
            <Input className={styles.createComponent_inputFields} />
          </Form.Item>

          <Form.Item
            label={
              <label className={styles.createComponent_inputLabels}>
                Password
              </label>
            }
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter a password",
              },
            ]}
          >
            <Input className={styles.createComponent_inputFields} />
          </Form.Item>

          <Form.Item
            label={
              <label className={styles.createComponent_inputLabels}>
                Select User Permission Level
              </label>
            }
            name="permission_level"
            rules={[
              {
                required: true,
                message: "Please select permission level",
              },
            ]}
          >
            <Select className={styles.createComponent_dropdowns}>
              {userPermissionLevelOptions.map((eachType: any) => {
                return (
                  <Option key={eachType._id} value={eachType._id}>
                    {eachType.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              className={styles.createComponent_submitBtn}
            >
              Create New User
            </Button>
          </Form.Item>
        </Form>
      </Card>

      <Toaster
        toastOptions={{
          success: {
            style: {
              border: "1px solid #199c3c",
            },
          },
          error: {
            style: {
              border: "1px solid #FF4C4C",
            },
          },
        }}
      />
    </div>
  );
}

export default CreateUsers;
