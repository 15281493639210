import { Alert, Button, Form, Input, Col, Row, Typography } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TbCircleX, TbEye, TbEyeOff } from "react-icons/tb";
import styles from "./Login.module.scss";
import api from "../../api";
import parseJWT from "utils/parseJWT";
import { useState } from "react";
import { PageTitle } from "utils/hooks";
import { setIsAuthenticated } from "store/userSlice";

import LoginImg from "../../assets/LoginImg.png";

const { Title } = Typography;

function Login({getUserInfo}: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [status, setStatus] = useState({
    isLoading: false,
    isError: false,
    message: "",
  });

  return (
    <>
      <PageTitle title="Login" />
      <Row className={styles.container}>
        <Col md={11} className={styles.container_imgCol}>
          <img
            src={LoginImg}
            alt="Login"
            className={styles.container_loginImg}
          />
        </Col>
        <Col md={13} sm={24}>
          <div className={styles.container_loginContent}>
            <Title level={2} className={styles.container_loginLogo}>
              <span>Artelus</span> Data<span>Prime</span>
            </Title>
            <Title level={3} className={styles.container_loginDescription}>
              Welcome back! Login to your account
            </Title>
            <Form
              requiredMark={"optional"}
              layout="vertical"
              onFinish={(values) => {
                setStatus({ ...status, isLoading: true });
                api
                  .post("/user/login", values)
                  .then((res) => {
                    setStatus({
                      ...status,
                      isLoading: false,
                    });
                    // dispatch(setIsAuthenticated(true));
                    localStorage.setItem(
                      "authToken",
                      res.data.result.authToken
                    );
                    localStorage.setItem(
                      "refreshToken",
                      res.data.result.refToken
                    );
                    
                    
                    const authToken = localStorage.getItem("authToken");

                    if (parseJWT(authToken!).exp * 1000 > Date.now()) {
                      dispatch(setIsAuthenticated(true));
                    }
                    getUserInfo();
                    navigate("/");
                  })
                  .catch((err) => {
                    setStatus({
                      isLoading: false,
                      isError: true,
                      message: err.response?.data?.message
                        ? err.response.data.message
                        : "Some error occured",
                    });
                  });
              }}
            >
              <Form.Item
                label={
                  <label className={styles.login_inputLabels}>
                    Email address
                  </label>
                }
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Enter a valid email",
                  },
                ]}
              >
                <Input className={styles.login_inputFields} />
              </Form.Item>
              <Form.Item
                label={
                  <label className={styles.login_inputLabels}>Password</label>
                }
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Enter your password",
                  },
                ]}
              >
                <Input.Password
                  className={styles.login_inputFields}
                  iconRender={(visible) => (visible ? <TbEye /> : <TbEyeOff />)}
                />
              </Form.Item>
              {status.isError ? (
                <Alert
                  type="error"
                  showIcon
                  icon={
                    <TbCircleX
                      style={{
                        color: "#fff2f0",
                        fill: "red",
                      }}
                    />
                  }
                  description={status.message}
                />
              ) : null}
              <Form.Item>
                <Button
                  loading={status.isLoading}
                  htmlType="submit"
                  block
                  type="primary"
                  className={styles.login_submitBtn}
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
            <p className={styles.version_styles}>Version 1.3.1</p>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Login;
