import { Card, Typography, Button, Form, Input, Select } from "antd";
import { Toaster } from "react-hot-toast";
import styles from "./CreateDataset.module.scss";
import { CreateDatasetLogic } from "./CreateDatasetLogic";

const { Title } = Typography;
const { Option } = Select;

function CreateDataset() {
  const [form] = Form.useForm();
  const {
    onFinish,
    onFinishFailed,
    selectedCatId,
    setSelectedCatId,
    allCategoryData,
    cameraType,
    classifiersList,
  }: {
    onFinish: (values: any) => void;
    onFinishFailed: (errorInfo: any) => void;
    selectedCatId: string;
    setSelectedCatId: (id: string) => void;
    allCategoryData: any;
    cameraType: any;
    classifiersList: any;
  } = CreateDatasetLogic(form);

  return (
    <>
      <div className={styles.createComponentDiv}>
        <Title level={2} className={styles.createComponent_heading}>
          Create a Dataset
        </Title>
        <Card bordered={false} className={styles.createComponent_card}>
          <Form
            form={form}
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            requiredMark={"optional"}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label={
                <label className={styles.createComponent_inputLabels}>
                  Dataset Name
                </label>
              }
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter dataset name",
                },
              ]}
            >
              <Input className={styles.createComponent_inputFields} />
            </Form.Item>

            <Form.Item
              label={
                <label className={styles.createComponent_inputLabels}>
                  Description
                </label>
              }
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please enter some description",
                },
              ]}
            >
              <Input className={styles.createComponent_inputFields} />
            </Form.Item>
            {/* Modality */}
            <Form.Item
              label={
                <label className={styles.createComponent_inputLabels}>
                  Select Modality
                </label>
              }
              name="category_id"
              rules={[
                {
                  required: true,
                  message: "Please select a modality",
                },
              ]}
            >
              <Select
                onChange={(e) => setSelectedCatId(e)}
                className={styles.createComponent_dropdowns}
              >
                {allCategoryData.length &&
                  allCategoryData.map((eachCat: any) => {
                    return <Option value={eachCat._id}>{eachCat.name}</Option>;
                  })}
              </Select>
            </Form.Item>

            {/* Camera Types */}
            <Form.Item
              label={
                <label className={styles.createComponent_inputLabels}>
                  Select Camera
                </label>
              }
              name="cameraType"
              rules={[
                {
                  required: true,
                  message: "Please select a camera type",
                },
              ]}
            >
              <Select className={styles.createComponent_dropdowns}>
                {cameraType.length ? (
                  cameraType.map((eachType: any) => {
                    return (
                      <Option
                        value={
                          eachType.cameraTypeId + " " + eachType.cameraType
                        }
                      >
                        {eachType.cameraType}
                      </Option>
                    );
                  })
                ) : (
                  <Option value="test" disabled>
                    Select a modality first
                  </Option>
                )}
              </Select>
            </Form.Item>

            {/* Classifiers */}
            <Form.Item
              label={
                <label className={styles.createComponent_inputLabels}>
                  Select Classifiers
                </label>
              }
              name="classifiers"
              rules={[
                {
                  required: true,
                  message: "Please select atleast one classifier",
                },
              ]}
            >
              <Select
                mode="tags"
                placeholder="Select Classifiers"
                className={styles.createComponent_dropdowns}
              >
                {selectedCatId ? (
                  classifiersList.length &&
                  classifiersList.map((eachClassifier: any) => {
                    return (
                      <Option value={eachClassifier._id}>
                        {eachClassifier.pathology}
                      </Option>
                    );
                  })
                ) : (
                  <Option value="test" disabled>
                    Select a modality first
                  </Option>
                )}
              </Select>
            </Form.Item>

            <Form.Item>
              <Button
                block
                type="primary"
                htmlType="submit"
                className={styles.createComponent_submitBtn}
              >
                Create Dataset
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>

      <Toaster
        toastOptions={{
          success: {
            style: {
              border: "1px solid #199c3c",
            },
          },
          error: {
            style: {
              border: "1px solid #FF4C4C",
            },
          },
        }}
      />
    </>
  );
}

export default CreateDataset;
