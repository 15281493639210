import api, { authHeader } from "./index";

export async function getMetricsInfoApi({
  pageCount,
  pageLimit,
}: {
  pageCount: number;
  pageLimit: number;
}) {
  const response = await api.get(
    `/metrics/list?page=${pageCount}&limit=${pageLimit}`,
    authHeader()
  );
  return response.data?.result;
}

export async function oneUserWithoutSubClassApi(data: object) {
  const response = await api.post("/metrics/user", data, authHeader());
  return response.data?.result;
}

export async function oneUserWithSubClassApi(data: object) {
  const response = await api.post("/metrics/user-with-sub", data, authHeader());
  return response.data?.result;
}

// Multiple users for both with & without agreement factor
export async function multipleUserAgreementFactorApi(data: object) {
  const response = await api.post("/metrics/users", data, authHeader());
  return response.data?.result;
}

export async function downloadFileApi({
  fileId,
  fileName,
}: {
  fileId: string;
  fileName: string;
}) {
  const response = await api.get(`/metrics/download/${fileName}`, authHeader());

  return response?.data;
}
