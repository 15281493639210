import { getAllAccessibleDatasets } from "api/datasetApi";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { userSelector } from "store/userSlice";
import { timestampToDate } from "utils/functions";

interface DatasetType {
  key: string;
  _id: string;
  name: string;
  description: string;
  cameraType_name: string;
  imagesCount: number;
  dataset_id: object;
  accessible_classifiers: string[];
  createdAt: string;
  updatedAt: string;
}

export const AnnotatorDatasetLogic = ({ datasets, setDatasets }: any) => {
  let dataKeyCount = 0;

  const userInfo: any = useSelector(userSelector);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [datasetTableInfo, setDatasetTableInfo] = useState<DatasetType[]>([]);
  const [createReviewOpenModal, setCreateReviewOpenModal] =
    useState<boolean>(false);

  /** Putting datasets in the interface form for ease of showing in table */
  function addDatasetsInTable() {
    const datasetArray: any[] = [];
    !datasets.loading &&
      datasets.data?.datasets &&
      datasets.data.datasets
        .filter((val: any) =>
          searchTerm === ""
            ? val
            : val.dataset_id.name
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) && val
        )
        .forEach((eachDataset: any) => {
          //
          const newDataset = {
            key: (++dataKeyCount).toString(),
            _id: eachDataset.dataset_id._id,
            name: eachDataset.dataset_id.name,
            description: eachDataset.dataset_id.description,
            cameraType_name: eachDataset.dataset_id.cameraType_name,
            imagesCount: eachDataset.imagesCount,
            dataset_id: eachDataset.dataset_id,
            accessible_classifiers: eachDataset.accessible_classifiers,
            createdAt: timestampToDate(eachDataset.createdAt),
            updatedAt: timestampToDate(eachDataset.updatedAt),
          };

          datasetArray.push(newDataset);
        });
    setDatasetTableInfo(datasetArray);
  }
  useEffect(() => {
    addDatasetsInTable();
  }, [datasets]);

  useEffect(() => {
    addDatasetsInTable();
  }, [searchTerm]);

  return {
    userInfo,
    datasets,
    setSearchTerm,
    datasetTableInfo,
    createReviewOpenModal,
    setCreateReviewOpenModal,
  };
};
