import { Button, Col, Row, Select, Space, Table, Typography } from "antd";
import React from "react";
import { Toaster } from "react-hot-toast";
import styles from "./RemoveSubClasses.module.scss";
import { RemoveSubClassesLogic } from "./RemoveSubClassesLogic";

const { Title } = Typography;
const { Option } = Select;

function RemoveClasses() {
  const {
    classifiers,
    selectedClassifier,
    setSelectedClassifier,
    classes,
    selectedClassId,
    setSelectedClassId,
    subClasses,
    setSelectedSubClasses,
    handleSingleSubClassDelete,
    handleSubClassesDelete,
  }: {
    classifiers: any;
    selectedClassifier: any;
    setSelectedClassifier: any;
    classes: any[];
    selectedClassId: any;
    setSelectedClassId: (e: any) => void;
    subClasses: object[];
    setSelectedSubClasses: (e: any) => void;
    handleSingleSubClassDelete: (classId: any) => void;
    handleSubClassesDelete: () => void;
  } = RemoveSubClassesLogic();

  const columns = [
    {
      title: "Sub Class",
      key: "nameAndDes",
      width: "33.3%",
      render: (details: any) => {
        return <>{details.name}</>;
      },
    },
    {
      title: "Delete",
      key: "delete",
      width: "33.3%",

      render: (details: any) => {
        return (
          <Space size="middle">
            <Button
              onClick={() => {
                handleSingleSubClassDelete(details._id);
              }}
            >
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (_selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedSubClasses(selectedRows.map((row: any) => row._id));
    },
  };

  return (
    <div>
      <Title level={2} className={styles.classes_heading}>
        Remove Sub Classes
      </Title>
      <Title level={5} className={styles.classes_description}>
        Select classifier and class first in order to remove sub classes
      </Title>
      <Row>
        <Col md={12}>
          <div className={styles.filterCol}>
            <Title level={5} className={styles.classes_filterHeading}>
              Select classifier :
            </Title>
            {classifiers.length && (
              <Select
                style={{ width: 120 }}
                className={styles.classes_filter}
                onChange={(val: string) => {
                  const classifier = classifiers.find(
                    (classifier: any) => classifier.pathology === val
                  );
                  setSelectedClassifier(classifier);
                }}
              >
                {classifiers?.map((classifier: any) => (
                  <Option key={classifier._id} value={classifier.pathology}>
                    {classifier.pathology}
                  </Option>
                ))}
              </Select>
            )}
          </div>
          {/* Class Selection */}
          <div style={{ margin: "1.5rem 0" }}>
            <Title level={5} className={styles.classes_filterHeading}>
              Select class :
            </Title>
            <Select
              style={{ width: 120 }}
              className={styles.classes_filter}
              onChange={(e) => setSelectedClassId(e)}
            >
              {classes.length ? (
                classes.map((eachClass: any) => {
                  return (
                    <Option value={eachClass._id}>{eachClass.name}</Option>
                  );
                })
              ) : (
                <Option value="" disabled>
                  Select a classifier first
                </Option>
              )}
            </Select>
          </div>
        </Col>
        <Col md={12}>
          {selectedClassifier && selectedClassId && subClasses.length && (
            <Button
              className={styles.deleteSubClassesBtn}
              onClick={() => handleSubClassesDelete()}
            >
              Delete Selected Sub Classes
            </Button>
          )}
        </Col>
      </Row>

      {/* Table */}
      {selectedClassifier && selectedClassId && (
        <Table
          dataSource={subClasses}
          columns={columns}
          locale={{
            emptyText: "No subclasses present in this class",
          }}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
        />
      )}

      <Toaster
        toastOptions={{
          error: {
            style: {
              border: "1px solid #FF4C4C",
            },
          },
        }}
      />
    </div>
  );
}

export default RemoveClasses;
