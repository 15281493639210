import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

import { getAllReviewersApi } from "api/usersApi";
import {
  assignReviewersInBucketApi,
  getReviewBucketReviewersApi,
} from "api/reviewBucketApi";
import { getAllReviewBuckets } from "api/reviewBucketApi";

interface DatasetType {
  key: string;
  _id: string;
  bucketName: string;
  dataset: string;
  datasetId: string;
  description: string;
  classifier: string;
  classifierId: string;
  annotationType: string;
  imagesCount: string;
  annotator: string;
  annotatorId: string;
  alreadyAssignedReviewers: any[];
}

export const ViewAllReviewBucketsLogic = () => {
  const [allReviewersList, setAllReviewersList] = useState<{
    loading: boolean;
    error: boolean;
    data: any[];
  }>({
    loading: false,
    error: false,
    data: [],
  });
  const [existingAnnotatorsList, setExistingAnnotatorsList] = useState<{
    loading: boolean;
    error: boolean;
    data: any[];
  }>({
    loading: false,
    error: false,
    data: [],
  });
  const [assigningReviewBucketId, setAssigningReviewBucketId] =
    useState<string>("");
  const [assignedReviewerIds, setAssignedReviewerIds] = useState<string[]>([]);

  let dataKeyCount = 0;

  const { categoryId, camTypeId } = useParams<string>();

  let datasetTableInfo: DatasetType[] = [];
  const [datasets, setDatasets] = useState<{
    loading: boolean;
    error: boolean;
    data: any[];
  }>({
    loading: false,
    error: false,
    data: [],
  });
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [openAssignModal, setOpenAssignModal] = useState<boolean>(false);
  const [confirmReviewAssignLoading, setConfirmReviewAssignLoading] =
    useState<boolean>(false);

  const [openReassignModal, setOpenReassignModal] = useState<boolean>(false);
  const [confirmReviewReassignLoading, setConfirmReviewReassignLoading] =
    useState<boolean>(false);

  // Fetch review buckets
  const getDatasets = async () => {
    try {
      const res = await getAllReviewBuckets();
      setDatasets({
        loading: false,
        error: false,
        data: res,
      });
    } catch (error) {
      setDatasets({
        loading: false,
        error: true,
        data: [],
      });
      toast.error("Some error occurred");
    }
  };

  const handleOk = async () => {
    setConfirmReviewAssignLoading(true);
    try {
      await assignReviewersInBucketApi(
        assigningReviewBucketId,
        assignedReviewerIds
      );

      setTimeout(() => {
        setOpenAssignModal(false);
        setConfirmReviewAssignLoading(false);
        toast.success("Reviewers assigned successfully");
      }, 2000);
      setAssigningReviewBucketId("");
      setAssignedReviewerIds([]);
      getDatasets();
    } catch (err: any) {
      toast.error(err.response.data.message);
      setConfirmReviewAssignLoading(false);
    }
  };

  // Fetching reviewers of particular review bucket
  async function handleReassignAnnotatorModal(
    datasetId: string,
    reviewBucketId: string
  ) {
    setExistingAnnotatorsList({
      loading: true,
      error: false,
      data: [],
    });
    setOpenReassignModal(true);
    try {
      const res = await getReviewBucketReviewersApi(datasetId, reviewBucketId);
      setExistingAnnotatorsList({
        loading: false,
        error: false,
        data: res,
      });
      setAssigningReviewBucketId(reviewBucketId);
    } catch (err: any) {
      setExistingAnnotatorsList({
        loading: false,
        error: true,
        data: [],
      });
      toast.error(err.response.data.message);
    }
  }

  // Fetch annotators list for reassign purposes
  const handleReassignAnnotators = async () => {
    setConfirmReviewReassignLoading(true);
    try {
      await assignReviewersInBucketApi(
        assigningReviewBucketId,
        assignedReviewerIds
      );

      setTimeout(() => {
        setOpenReassignModal(false);
        setConfirmReviewReassignLoading(false);
        toast.success("Annotators reassigned successfully");
      }, 2000);
      setAssigningReviewBucketId("");
      setAssignedReviewerIds([]);
      getDatasets();
    } catch (err: any) {
      toast.error(err.response.data.message);
      setConfirmReviewReassignLoading(false);
    }
  };

  // Fetch all review buckets
  useEffect(() => {
    setDatasets({
      loading: true,
      error: false,
      data: [],
    });

    getDatasets();
  }, [categoryId, camTypeId]);

  useEffect(() => {
    addDatasetsInTable();
  }, [searchTerm]);

  // Formatting datasets data for table view
  function addDatasetsInTable() {
    datasetTableInfo = [];

    datasets.data?.length &&
      datasets.data
        .filter((val: any) =>
          searchTerm === ""
            ? val
            : val?.dataset_id.name
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) && val
        )
        .forEach((eachDataset: any) => {
          datasetTableInfo.push({
            key: dataKeyCount.toString(),
            _id: eachDataset._id,
            bucketName: eachDataset?.name,
            dataset: eachDataset?.dataset_id.name,
            datasetId: eachDataset?.dataset_id._id,
            description: eachDataset?.dataset_id.description,
            classifier: eachDataset.classifier_id.pathology,
            classifierId: eachDataset.classifier_id._id,
            annotationType: eachDataset?.against,
            imagesCount: eachDataset?.totalImages,
            annotator: eachDataset?.annotator_id?.name
              ? eachDataset?.annotator_id?.name
              : "",
            annotatorId: eachDataset?.annotator_id?._id
              ? eachDataset?.annotator_id._id
              : "",
            alreadyAssignedReviewers: eachDataset?.reviewer_ids,
          });
          dataKeyCount += 1;
        });
  }
  addDatasetsInTable();

  // Fetching reviewers of particular review bucket
  async function handleAssignReviewerModal(
    datasetId: string,
    reviewBucketId: string
  ) {
    setAllReviewersList({
      loading: true,
      error: false,
      data: [],
    });
    setOpenAssignModal(true);
    try {
      const res = await getReviewBucketReviewersApi(datasetId, reviewBucketId);
      setAllReviewersList({
        loading: false,
        error: false,
        data: res,
      });
      setAssigningReviewBucketId(reviewBucketId);
    } catch (err: any) {
      setAllReviewersList({
        loading: false,
        error: true,
        data: [],
      });
      toast.error(err.response.data.message);
    }
  }

  return {
    datasets,
    datasetTableInfo,
    openAssignModal,
    setOpenAssignModal,
    confirmReviewAssignLoading,
    handleOk,
    searchTerm,
    setSearchTerm,
    allReviewersList,
    setAllReviewersList,
    setAssignedReviewerIds,
    handleAssignReviewerModal,
    openReassignModal,
    setOpenReassignModal,
    confirmReviewReassignLoading,
    setConfirmReviewReassignLoading,
    existingAnnotatorsList,
    setExistingAnnotatorsList,
    handleReassignAnnotators,
    handleReassignAnnotatorModal,
  };
};
