import {
  getBuckets,
  getClassifierByDatasetId,
  getDatasetbyDatasetId,
} from "api/bucketApi";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setBucketsDatasetInfo } from "store/bucketsDatasetInfoSlice";
import { setAllClassifiersInfo } from "store/classifierSlice";
import { sortBucketsByClassKey } from "utils/functions";

export const ViewBucketsLogic = () => {
  const dispatch = useDispatch();
  const reviewerViewAnnotatorName: string | null = localStorage.getItem(
    "reviewerViewAnnotatorName"
  );
  let reviewUserIds: any = localStorage.getItem("reviewerUsersInfo");
  reviewUserIds = JSON.parse(reviewUserIds);
  const { datasetId, classifierId } = useParams<string>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [datasetInfo, setDatasetInfo] = useState<any>({});
  const [classifiers, setClassifiers] = useState<any[]>([]);
  const [selectedClassifier, setSelectedClassifier] = useState<any>({});
  const [saveForLaterId, setSaveForLaterId] = useState<string>("");
  const [buckets, setBuckets] = useState<any>({
    loading: false,
    error: false,
    data: [],
  });
  const [errMsg, setErrMsg] = useState<string>("");
  /** Fetching datasetInfo & classifiers */
  useEffect(() => {
    const fetchDatasetInfo = async () => {
      const res = await getDatasetbyDatasetId(datasetId);
      setDatasetInfo(res);
      dispatch(setBucketsDatasetInfo(res));
    };
    const fetchClassifiers = async () => {
      const classifiersInfo = await getClassifierByDatasetId(datasetId);
      setClassifiers(classifiersInfo);
      // setSelectedClassifier(classifiersInfo[0]);
      setSelectedClassifier(() => {
        if (classifiersInfo.length > 0) {
          return classifiersInfo.filter((classifier: any) => {
            return classifier._id === classifierId;
          })[0];
        }
      });

      dispatch(setAllClassifiersInfo(classifiersInfo));
    };

    fetchDatasetInfo();
    fetchClassifiers();
  }, [datasetId]);

  /** Fetching related buckets every time there is a change in classifier filter */
  useEffect(() => {
    if (classifierId) {
      const fetchBuckets = async () => {
        setBuckets({
          ...buckets,
          loading: true,
        });
        try {
          let classifierBuckets = await getBuckets({
            datasetId,
            classifierId,
            selectedUserIdForReview:
              reviewUserIds !== null && reviewUserIds.length === 1
                ? reviewUserIds[0]
                : null,
          });

          // Sorting buckets based on key
          classifierBuckets = sortBucketsByClassKey(classifierBuckets);
          setBuckets({
            ...buckets,
            loading: false,
            data: classifierBuckets,
          });

          classifierBuckets.forEach((bucket: any) => {
            if (
              bucket.class.name === "Saved for Later" &&
              bucket.isVirtual === true
            ) {
              setSaveForLaterId(bucket.vid);
            }
          });
        } catch (error: any) {
          setBuckets({
            loading: false,
            error: true,
            data: [],
          });
          setErrMsg(
            error.response.data?.message.includes("Cache not found")
              ? "You don't have access to this dataset. Please contact administrator "
              : error.response.data?.message
          );
        }
      };
      fetchBuckets();
    }
  }, [datasetId, classifierId]);

  /** Changing btnColor if bucket is a Bad image or Ungraded */
  function bucketBtnColor(bucketName: string, bucketImgCount: number) {
    const btnColor =
      bucketImgCount === 0
        ? "#dbdbdb"
        : bucketName === "Ungraded"
        ? "#FFBF43"
        : bucketName === "Bad Image"
        ? "#F66262"
        : "#6254FF";

    return btnColor;
  }

  return {
    datasetId,
    datasetInfo,
    classifiers,
    selectedClassifier,
    setSelectedClassifier,
    buckets,
    searchTerm,
    setSearchTerm,
    bucketBtnColor,
    errMsg,
    reviewUserIds,
    reviewerViewAnnotatorName,
    saveForLaterId,
  };
};
