import { Col, Row, Switch, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import styles from "./BucketsCol.module.scss";

// Icons
import { LeftOutlined } from "@ant-design/icons";

const { Title } = Typography;

export default function BucketsCol({
  selectedBucket,
  annotationMode,
  setAnnotationMode,
  bucketsNameAndCount,
  handleBucketImagesCount,
  images,
  viewingSaveForLater,
  savedForLaterBucketDetails,
  notReviewedBucketDetails,
}: {
  buckets: object[];
  selectedBucket: string;
  handleImages: (bucket: object[]) => void;
  annotationMode: boolean;
  setAnnotationMode: (mode: boolean) => void;
  isDoubtToggler: boolean;
  setIsDoubtToggler: (toggler: boolean) => void;
  isReviewRoute: boolean;
  bucketsNameAndCount: any[];
  handleBucketImagesCount: (bucket: any) => void;
  images: any[];
  viewingSaveForLater: any;
  savedForLaterBucketDetails: any;
  notReviewedBucketDetails: any;
}) {
  const navigate = useNavigate();
  const viewingNotAnnotated: any = localStorage.getItem("viewingNotAnnotated");
  return (
    <>
      <div className={styles.images_bucketsCol_goBackBtn}>
        <span onClick={() => navigate(-1)}>
          <LeftOutlined className={styles.buckets_goBackArrow} />
          <span className={styles.buckets_goBackBtn}>Go Back</span>
        </span>
      </div>

      <div className="wrapper">
        <div className={styles.images_bucketsColDiv}>
          {!JSON.parse(viewingSaveForLater) &&
          !JSON.parse(viewingNotAnnotated) &&
          bucketsNameAndCount.length ? (
            bucketsNameAndCount.map((bucket: any) => {
              return (
                <div className={styles.images_bucketNameCard} key={bucket.name}>
                  <Title
                    level={4}
                    className={styles.images_bucketNameCard_name}
                    onClick={() =>
                      bucket.images_count > 0 && handleBucketImagesCount(bucket)
                    }
                    style={{
                      backgroundColor:
                        selectedBucket === bucket.name
                          ? "#626DF6"
                          : bucket.images_count === 0
                          ? "#c7c7c7"
                          : "#fff",
                      color: selectedBucket === bucket.name ? "#fff" : "#000",
                      cursor:
                        bucket.images_count > 0 ? "pointer" : "not-allowed",
                    }}
                  >
                    {bucket.name ? bucket.name : "Loading..."} (
                    {bucket.name ? bucket.images_count : ""})
                  </Title>
                </div>
              );
            })
          ) : JSON.parse(viewingSaveForLater) ? (
            <>
              <Title
                level={4}
                className={styles.images_bucketNameCard_name}
                style={{ color: "#626DF6" }}
              >
                Viewing :
              </Title>

              <div
                className={styles.images_bucketNameCard}
                key={savedForLaterBucketDetails.name}
              >
                <Title
                  level={4}
                  className={styles.images_bucketNameCard_name}
                  onClick={() =>
                    savedForLaterBucketDetails.imagesCount > 0 &&
                    handleBucketImagesCount(savedForLaterBucketDetails)
                  }
                  style={{
                    backgroundColor:
                      selectedBucket === savedForLaterBucketDetails.name
                        ? "#626DF6"
                        : savedForLaterBucketDetails.imagesCount === 0
                        ? "#c7c7c7"
                        : "#fff",
                    color:
                      selectedBucket === savedForLaterBucketDetails.name
                        ? "#fff"
                        : "#000",
                    cursor:
                      savedForLaterBucketDetails.imagesCount > 0
                        ? "pointer"
                        : "not-allowed",
                  }}
                >
                  {savedForLaterBucketDetails.name
                    ? savedForLaterBucketDetails.name
                    : "Loading..."}{" "}
                  (
                  {savedForLaterBucketDetails.name
                    ? savedForLaterBucketDetails.imagesCount
                    : ""}
                  )
                </Title>
              </div>
            </>
          ) : JSON.parse(viewingNotAnnotated) ? (
            <>
              <Title
                level={4}
                className={styles.images_bucketNameCard_name}
                style={{ color: "#626DF6" }}
              >
                Viewing :
              </Title>

              <div
                className={styles.images_bucketNameCard}
                key={notReviewedBucketDetails.name}
              >
                <Title
                  level={4}
                  className={styles.images_bucketNameCard_name}
                  onClick={() =>
                    savedForLaterBucketDetails.imagesCount > 0 &&
                    handleBucketImagesCount(savedForLaterBucketDetails)
                  }
                  style={{
                    backgroundColor:
                      selectedBucket === notReviewedBucketDetails.name
                        ? "#626DF6"
                        : notReviewedBucketDetails.imagesCount === 0
                        ? "#c7c7c7"
                        : "#fff",
                    color:
                      selectedBucket === notReviewedBucketDetails.name
                        ? "#fff"
                        : "#000",
                    cursor:
                      notReviewedBucketDetails.imagesCount > 0
                        ? "pointer"
                        : "not-allowed",
                  }}
                >
                  {notReviewedBucketDetails.name
                    ? notReviewedBucketDetails.name
                    : "Loading..."}{" "}
                  (
                  {notReviewedBucketDetails.name
                    ? notReviewedBucketDetails.imagesCount
                    : ""}
                  )
                </Title>
              </div>
            </>
          ) : null}
        </div>

        <div className={styles.annotationToggleParentDiv}>
          {bucketsNameAndCount.length && images.length ? (
            <Row className={styles.annotationToggleDiv}>
              <Col xl={18} lg={24} md={24} sm={17} xs={17}>
                <Title level={5}>Annotation Mode</Title>
              </Col>
              <Col xl={6} lg={24} md={24} sm={7} xs={7}>
                <Switch
                  checkedChildren="On"
                  unCheckedChildren="Off"
                  checked={annotationMode}
                  onClick={(e) => {
                    setAnnotationMode(e);
                  }}
                />
              </Col>
            </Row>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
