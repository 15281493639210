import type { PaginationProps } from "antd";
import {
  Grid,
  Pagination,
  Radio,
  Select,
  Spin,
  Typography
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { setSearchImageInfo } from "store/searchImageInfoSlice";
import styles from "./ImageGallery.module.scss";
import { ImageGalleryLogic } from "./ImageGalleryLogic";

// Icons
import { LoadingOutlined } from "@ant-design/icons";

const { Title } = Typography;
const { useBreakpoint } = Grid;
const { Option } = Select;

function ImageGallery() {
  const dispatch = useDispatch();
  const { md } = useBreakpoint();
  const { datasetId, datasetName } = useParams();
  const [radioVal, setRadioVal] = useState<string>("all");

  const {
    datasets,
    datasetTableInfo,
    pageCount,
    setPageCount,
    pageLimit,
    setPageLimit,
    totalImageCount,
    bucketData,
  }: {
    userInfo: any;
    datasets: {
      loading: boolean;
      error: boolean;
      data: any[];
    };
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
    datasetTableInfo: any[];
    pageCount: number;
    setPageCount: (page: number) => void;
    pageLimit: number;
    setPageLimit: (limit: number) => void;
    totalAnnotationCount: number;
    totalImageCount: number;
    bucketData: {
      loading: boolean;
      error: boolean;
      data: any[];
    };
    searched: boolean;
    setSearched: (searched: boolean) => void;
  } = ImageGalleryLogic(datasetId, radioVal);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const bucketArr = bucketData.data;

  const pageSizeOptions = [50, 100, 500, 1000];

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    newPageLimit
  ) => {
    setPageLimit(newPageLimit);
  };
  const onChange: PaginationProps["onChange"] = (pageNumber) => {
    setPageCount(pageNumber);
  };

  const filterChange = (e: any) => {
    setRadioVal(e.target.value);
  };

  const [imgData, setImgData] = useState<any>(datasetTableInfo);

  useEffect(() => {
    if (radioVal === "all") {
      setImgData(datasetTableInfo);
      setCurrentImageIndex(0);
    } else {
      const imgDataArr: any[] = [];
      datasetTableInfo.map((eachData) => {
        if (eachData.bucketId === radioVal) imgDataArr.push(eachData);
      });
      setImgData(imgDataArr);
      setCurrentImageIndex(0);
    }
  }, [radioVal, datasetTableInfo?.length]);

  useEffect(() => {
    if (currentImageIndex < imgData.length - 1) {
      const image = new Image();
      image.className = styles.img_thumb;
      image.onload = () => {
        setCurrentImageIndex(currentImageIndex + 1);
      };
      image.onerror = () => {
        console.log("error");
      };
      image.src = imgData[currentImageIndex]?.url;
    }
  }, [currentImageIndex, imgData?.length]);

  return (
    <div className={styles.parent_container}>
      <div className={styles.top_gallery}>
        <div className={styles.img_gallery_container}>
          <Title level={2} className={styles.datasets_heading}>
            Browse Images: {datasetName}
          </Title>
          {/* <Row className={styles.datasets_headingRow_imgGallery}> */}
          {/* <Col xl={14} lg={24} sm={24} md={24} xs={24}> */}
          {datasetTableInfo.length ? (
            <Pagination
              current={pageCount}
              total={totalImageCount}
              pageSize={pageLimit}
              className={styles.datasets_pagination}
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              onChange={onChange}
              pageSizeOptions={pageSizeOptions}
            />
          ) : (
            ""
          )}
          {/* </Col> */}
          {/* </Row> */}
        </div>
        {/* <Radio.Group className={styles.bucket_filter}>
                    <Radio.Button className={styles.radio_button}>
                        All
                    </Radio.Button>
                    <Radio.Button className={styles.radio_button}>
                        DR0
                    </Radio.Button>
                    <Radio.Button className={styles.radio_button}>
                        DR1
                    </Radio.Button>
                    <Radio.Button className={styles.radio_button}>
                        DR2
                    </Radio.Button>
                    <Radio.Button className={styles.radio_button}>
                        DR3
                    </Radio.Button>
                    <Radio.Button className={styles.radio_button}>
                        DR4
                    </Radio.Button>
                    <Radio.Button className={styles.radio_button}>
                        Others
                    </Radio.Button>
                    <Radio.Button className={styles.radio_button}>
                        Bad Image
                    </Radio.Button>
                    <Radio.Button className={styles.radio_button}>
                        Save for Later
                    </Radio.Button>
                </Radio.Group> */}
        <Radio.Group
          defaultValue="all"
          buttonStyle="solid"
          className={styles.bucket_filter}
        >
          <Radio.Button
            className={styles.radio_button}
            value="all"
            onClick={filterChange}
          >
            All
          </Radio.Button>
          {bucketArr.map((bucket) => {
            return (
              <Radio.Button
                className={styles.radio_button}
                value={bucket._id}
                onClick={filterChange}
              >
                {bucket.name}
              </Radio.Button>
            );
          })}
        </Radio.Group>
      </div>

      {/* <div className={styles.gallery_container}>
                {datasets.error ? (
                    <>
                        <Title level={4} className={styles.datasets_heading}>
                            Some error occured
                        </Title>
                    </>
                ) : datasets.loading ? (
                    <div className={styles.loadingDiv}>
                        <Spin
                            size="large"
                            indicator={
                                <LoadingOutlined
                                    className={styles.loadingSpinIcon}
                                    spin
                                />
                            }
                            tip="Fetching Thumbnails..."
                            className={styles.loadingDiv_spin}
                        />
                    </div>
                ) : (
                    <>
                        {datasetTableInfo.length === 0 ? (
                            <>
                                <Title
                                    level={4}
                                    className={styles.datasets_heading}
                                >
                                    No Images Found
                                </Title>
                            </>
                        ) : (
                            datasetTableInfo.map((imageInfo: any) => {
                                return (
                                    <Link
                                        to={`/images/${imageInfo.datasetId}/${imageInfo.classifierId}/${imageInfo.bucketId}/0`}
                                    >
                                        <img
                                            className={styles.img_thumb}
                                            src={imageInfo.url}
                                            alt=""
                                            onClick={() =>
                                                dispatch(
                                                    setSearchImageInfo(
                                                        imageInfo.imgId
                                                    )
                                                )
                                            }
                                        />
                                    </Link>
                                );
                            })
                        )}
                    </>
                )}
            </div> */}

      <div className={styles.gallery_container}>
        {datasets.error ? (
          <>
            <Title level={4} className={styles.datasets_heading}>
              Some error occured
            </Title>
          </>
        ) : datasets.loading ? (
          <div className={styles.loadingDiv}>
            <Spin
              size="large"
              indicator={
                <LoadingOutlined className={styles.loadingSpinIcon} spin />
              }
              tip="Fetching Thumbnails..."
              className={styles.loadingDiv_spin}
            />
          </div>
        ) : (
          <>
            {imgData.length === 0 ? (
              <>
                <Title level={4} className={styles.datasets_heading}>
                  No Images Found
                </Title>
              </>
            ) : (
              <div
                className={styles.gallery_image_container}
                id={"gallery-image-container"}
              >
                {imgData.slice(0, currentImageIndex).map((imageInfo: any) => {
                  return (
                    <Link
                      to={`/images/${imageInfo.datasetId}/${imageInfo.classifierId}/${imageInfo.bucketId}/0`}
                    >
                      <img
                        style={{
                          width: "100%",
                        }}
                        src={imageInfo.url}
                        alt=""
                        onClick={() => {
                          dispatch(setSearchImageInfo(imageInfo.imgId));
                        }}
                      />
                    </Link>
                  );
                })}
              </div>
            )}
          </>
        )}
        {/* {datasetTableInfo.map((imageInfo: any) => {
                    return (
                        <Link
                            to={`/images/${imageInfo.datasetId}/${imageInfo.classifierId}/${imageInfo.bucketId}/0`}
                        >
                            <img
                                className={styles.img_thumb}
                                src={imageInfo.url}
                                alt=""
                                onClick={() =>
                                    dispatch(
                                        setSearchImageInfo(imageInfo.imgId)
                                    )
                                }
                            />
                        </Link>
                    );
                })} */}
      </div>
    </div>
  );
}

export default ImageGallery;
