import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import { Toaster } from "react-hot-toast";
import styles from "./CreateSubClasses.module.scss";
import { CreateSubClassesLogic } from "./CreateSubClassesLogic";

const { Title } = Typography;
const { Option } = Select;

function CreateSubClasses() {
  const [form] = Form.useForm();

  const {
    classifiers,
    onFinish,
    onFinishFailed,
    newClassInput,
    setNewClassInput,
    handleNewClass,
    handleClassDelete,
    subClasses,
    setSelectedClassifier,
    classes,
  }: {
    classifiers: any[];
    onFinish: (values: any) => void;
    onFinishFailed: (errorInfo: any) => void;
    newClassInput: any;
    setNewClassInput: (e: any) => void;
    handleNewClass: () => void;
    handleClassDelete: (details: any) => void;
    subClasses: object[];
    setSelectedClassifier: (e: any) => void;
    classes: any[];
  } = CreateSubClassesLogic(form);

  const columns = [
    {
      title: "SubClass",
      dataIndex: "name",
      key: "name",
      width: "33.3%",
    },
    {
      title: "Delete",
      key: "delete",
      width: "33.3%",

      render: (details: any) => {
        return (
          <Space size="middle">
            <Button onClick={() => handleClassDelete(details)}>Delete</Button>
          </Space>
        );
      },
    },
  ];

  return (
    <div className={styles.createComponentDiv}>
      <Title level={2} className={styles.createComponent_heading}>
        Create Sub Classes
      </Title>
      <Card bordered={false} className={styles.createComponent_card}>
        <Form
          form={form}
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          requiredMark={"optional"}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {/* Classifier Selection */}
          <Form.Item
            label={
              <label className={styles.createComponent_inputLabels}>
                Select Classifier
              </label>
            }
            name="classifier_id"
            rules={[
              {
                required: true,
                message: "Please select a classifier",
              },
            ]}
          >
            <Select
              className={styles.createComponent_dropdowns}
              onChange={(e) => setSelectedClassifier(e)}
            >
              {classifiers.length &&
                classifiers.map((eachClassifier: any) => {
                  return (
                    <Option value={eachClassifier._id}>
                      {eachClassifier.pathology}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          {/* Classes Selection */}
          <Form.Item
            label={
              <label className={styles.createComponent_inputLabels}>
                Select Class
              </label>
            }
            name="classIndex"
            rules={[
              {
                required: true,
                message: "Please select a class type",
              },
            ]}
          >
            <Select className={styles.createComponent_dropdowns}>
              {classes.length ? (
                classes.map((eachClass: any, index: number) => {
                  return (
                    <Option key={index} value={index}>
                      {eachClass.name}
                    </Option>
                  );
                })
              ) : (
                <Option value="" disabled>
                  Select a classifier first
                </Option>
              )}
            </Select>
          </Form.Item>

          <Row style={{ marginBottom: "1rem" }}>
            {/* Name */}
            <Col lg={9} sm={24}>
              <label
                className={styles.createComponent_inputLabels}
                style={{ marginBottom: "0.6rem" }}
              >
                Name of Sub Class
              </label>
              <Input
                value={newClassInput.name}
                className={styles.createComponent_inputFields}
                onChange={(e) =>
                  setNewClassInput({
                    ...newClassInput,
                    name: e.target.value,
                  })
                }
              />
            </Col>
            <Col span={1}></Col>

            <Col lg={5} sm={24}>
              <Button
                className={
                  styles.createComponent_btn +
                  " " +
                  styles.createComponent_addClassBtn
                }
                onClick={() => handleNewClass()}
              >
                Add
              </Button>
            </Col>
          </Row>

          {subClasses.length ? (
            <div style={{ marginTop: "1.5rem" }}>
              <hr />
              <Title level={5} className={styles.table_classesHeading}>
                Classes
              </Title>
              <Table
                dataSource={subClasses}
                columns={columns}
                pagination={false}
              />
            </div>
          ) : (
            "" // For removing antd default '0'
          )}
          <Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              className={styles.createComponent_btn}
            >
              Create Sub Classes
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Toaster
        toastOptions={{
          error: {
            style: {
              border: "1px solid #FF4C4C",
            },
          },
        }}
      />
    </div>
  );
}

export default CreateSubClasses;
