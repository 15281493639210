import api, { authHeader } from "./index";

export async function uploadDatasetFileApi({ formData }: { formData: any }) {
  const response = await api.post(
    "/images/schedule-upload",
    formData,
    authHeader()
  );
  return response.data?.result;
}

export async function getJobsDataApi({
  pageCount,
  pageLimit,
}: {
  pageCount: number;
  pageLimit: number;
}) {
  const response = await api.get(
    `/images/jobs?page=${pageCount}&limit=${pageLimit}`,
    authHeader()
  );
  return response.data?.result;
}

export async function startJobApi(jobIdArr: string[]) {
  const response = await api.post(
    "/images/schedule-start",
    {
      job_id_array: jobIdArr,
    },
    authHeader()
  );
  return response.data?.result;
}
